import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useOnRouterChangeStart = (cb: () => void) => {
  const router = useRouter();

  const [previousPath, setPreviousPath] = useState(router.asPath.split('?')[0]);

  useEffect(() => {
    const onRouterChangeStart = (url: string) => {
      const path = url.split('?')[0];

      if (path !== previousPath) {
        setPreviousPath(path);
        cb();
      }
    };

    router.events.on('routeChangeStart', onRouterChangeStart);
    return () => router.events.off('routeChangeStart', onRouterChangeStart);
  }, [previousPath, router.events, cb]);
};
