import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { tw } from 'twind/css';

import { Maybe } from 'utils/types';
import { LocaleStringType } from 'groq/shared/LocaleString';
import { AccordionRoot, AccordionTab } from 'ui/elements/Accordion';
import { serializeTocBlockContent } from 'utils/serializeBlockContent';
import { FAQItemType } from 'groq/documents/FAQModuleDocument';

import { ContentHeading } from './ContentHeading';

type FaqModuleProps = {
  faqs?: FAQItemType[];
  localeTitle?: Maybe<LocaleStringType>;
  headingTag: string;
  inPortableText?: boolean;
};

export const FaqModule = ({
  localeTitle,
  headingTag,
  faqs,
  inPortableText,
}: FaqModuleProps) => {
  return (
    <div
      className={tw(
        'w-full flex flex-1 flex-col',
        !inPortableText && 'px-6 pb-4'
      )}
    >
      {localeTitle && (
        <ContentHeading
          header={localeTitle}
          className={tw(
            'font-bold leading-tight',
            inPortableText ? 'mt-0!' : 'pb-4',
            'mobile:text-2xl tablet:text-3xl desktop:text-4xl xl:text-5xl'
          )}
          headingTag={headingTag}
        />
      )}
      <AccordionRoot>
        {faqs?.map(item => {
          return (
            <AccordionTab
              title={item.question || ''}
              id={item._id}
              key={item._id}
              expand={false}
              bodyClassNameConfig={{
                baseClassName: !inPortableText ? 'py-4' : '',
              }}
              headerClassNameConfig={{
                baseClassName: 'text-lg font-semibold pt-2 pb-4 capitalize',
              }}
              headerWrapperClassNameConfig={{
                baseClassName: inPortableText ? 'mt-3! mb-2!' : '',
              }}
            >
              <PortableText
                blocks={item.answer}
                serializers={serializeTocBlockContent}
              />
            </AccordionTab>
          );
        })}
      </AccordionRoot>
    </div>
  );
};
