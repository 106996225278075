import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { tw } from 'twind/css';

import { Link } from 'ui/elements/Link';
import { isUrlSegment } from 'utils/urls';
import {
  categoryHeaderNavigationEvent,
  headerNavigationClickEvent,
} from 'utils/analytics';
import { MainNavItemType } from 'groq/shared/MainNavItem';

const arrayToColumns = (input: any[], itemsPerColumn: number) =>
  input.reduce((columns, item, index) => {
    const columnIndex = Math.floor(index / itemsPerColumn);

    if (!columns[columnIndex]) {
      columns[columnIndex] = []; // start a new chunk
    }

    columns[columnIndex].push(item);

    return columns;
  }, []);

export const NavCategorySubCategoriesInline = ({
  categories,
  title,
}: {
  categories: MainNavItemType[];
  title: string;
}) => {
  const { query } = useRouter();
  const categoryColumns: Array<MainNavItemType[]> = useMemo(
    () => arrayToColumns(categories, 4),
    [categories]
  );

  return (
    <ul
      className={tw(['ml-2 p-2 w-full flex flex-row flex-wrap'])}
      role="menu"
      aria-label={title}
    >
      {categoryColumns.map((column, i) => (
        <li
          className="w-1/5 min-w-48 flex-shrink-0 pr-8 pb-6"
          key={i}
          role="none"
        >
          <ul>
            {column.map(category => (
              <li role="none" key={category.id}>
                <Link
                  data-link-location="top-nav-level-3"
                  data-test-id="top-nav-level-3"
                  data-link-name={category.label}
                  role="menuitem"
                  href={category.link?.href ?? '/'}
                  query={category.link?.query}
                  className={tw(
                    isUrlSegment(category.link, query?.category)
                      ? 'text-puma-black'
                      : 'text-puma-black-300 hover:text-puma-black',
                    `text-base leading-6`
                  )}
                  onClick={() => {
                    categoryHeaderNavigationEvent(category);
                    headerNavigationClickEvent(category);
                  }}
                >
                  {category.label}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
