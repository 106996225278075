import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export type PopupName_Type = 'CartButtonPopup'; // names of available popups: 'popup1' | 'popup2'

interface State {
  popupsOpen: PopupName_Type[];
  setPopupsOpen: (value: PopupName_Type[]) => void;
}

const _usePopupsOpenStore = create<State>(set => ({
  popupsOpen: [],
  setPopupsOpen: value => set({ popupsOpen: value }),
}));

// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export const usePopupsOpenStore = () => {
  const { popupsOpen, setPopupsOpen } = _usePopupsOpenStore(
    useShallow(state => ({
      popupsOpen: state.popupsOpen,
      setPopupsOpen: state.setPopupsOpen,
    }))
  );

  const setPopupOpen = (popupName: PopupName_Type, open: boolean) => {
    if (open) {
      setPopupsOpen(
        popupsOpen.filter(e => e !== popupName).concat([popupName])
      );
    } else setPopupsOpen([...popupsOpen.filter(x => x !== popupName)]);
  };

  const isPopupOpen = (popupName: PopupName_Type) =>
    popupsOpen.some(x => x === popupName);

  return { isPopupOpen, setPopupOpen };
};

// ─── Usage ───────────────────────────────────────────────────────────────────
//
// const { isPopupOpen, setPopupOpen } = usePopupsOpenStore();
//
// if (isPopupOpen('foobar')) {
//   setPopupOpen('foobar', false);
// }
//
// ─────────────────────────────────────────────────────────────────────────────
