import { gql } from 'urql';

import { TERMS_AND_CONDITIONS_FRAGMENT } from 'gql/fragments/termsAndConditions';

export const TERMS_AND_CONDITIONS_QUERY = gql`
  query TermsAndConditionsConfigs($isDraft: String) {
    termsAndConditionsConfigs(isDraft: $isDraft) {
      id
      tacOnCartPage {
        ...termsAndConditionsFields
      }
      tacOnItemAddedPopup {
        ...termsAndConditionsFields
      }
      tacOnCheckoutSignInArea {
        ...termsAndConditionsFields
      }
      tacOnCheckoutConfirmation {
        ...termsAndConditionsFields
      }
      tacOnCheckoutAddress {
        ...termsAndConditionsFields
      }
      tacOnCreateAccount {
        ...termsAndConditionsFields
      }
      tacOnCheckoutPayment {
        ...termsAndConditionsFields
      }
      showAbove
    }
  }

  ${TERMS_AND_CONDITIONS_FRAGMENT}
`;
