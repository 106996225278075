import React, { forwardRef } from 'react';
import { tw, style } from 'twind/style';

import { useTranslate } from 'hooks/useTranslations';

import { DotAnimation } from './DotAnimation';

const button = style({
  base: 'relative px-5 py-2 bg-puma-black text-base text-white uppercase font-bold border-1 border-puma-black flex items-center justify-center rounded-sm',
  variants: {
    variant: {
      primary: 'bg-puma-gold border-puma-gold',
      secondary: 'bg-puma-silver border-puma-silver',
      link: 'px-0 py-0 bg-transparent text-puma-black border-0 border-b-1 border-current text-sm uppercase font-bold rounded-none h-auto',
      inline:
        'px-0 py-0 bg-transparent text-current border-0 h-auto text-underline inline-block text-normal-case font-unset text-inherit',
      black: 'bg-puma-black border-puma-black text-white',
    },
    colorscheme: {
      green: 'bg-puma-green text-puma-green bg-opacity-20 border-none',
    },
    height: {
      tall: 'h-14',
    },
    outlined: {
      true: 'bg-transparent',
    },
    disabled: {
      true: 'opacity-40 cursor-not-allowed',
    },
  },
  matches: [
    { outlined: true, use: 'text-puma-black' },
    { variant: 'primary', outlined: true, use: 'text-puma-gold' },
    { variant: 'secondary', outlined: true, use: 'text-puma-silver' },
    { variant: 'black', outlined: true, use: 'text-puma-black' },
  ],
});

export type ButtonStyleProps = Parameters<typeof button>[0];
export type ButtonProps = React.ComponentProps<'a'> &
  ButtonStyleProps & {
    dataTestId?: string;
    id?: string;
    className?: string;
    label?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    type?: 'button' | 'submit' | 'reset';
    variant?: 'primary' | 'secondary' | 'link' | 'inline' | 'black';
    outlined?: boolean;
    height?: 'tall';
    disabled?: boolean;
    loading?: boolean;
    textStyle?: string;
  };
export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const t = useTranslate();
  const onClickButton = e => {
    if (props.loading) return;
    if (typeof props.onClick !== 'function') return;
    props.onClick(e);
  };
  return (
    <button
      ref={ref}
      id={props.id}
      className={tw(
        button({
          variant: props.variant,
          height: props.height,
          disabled: props.loading || props.disabled,
          outlined: props.outlined,
          colorscheme: props.colorscheme,
        }),
        props.className
      )}
      disabled={props.disabled}
      aria-disabled={props.loading || props.disabled}
      aria-label={props.label}
      onClick={onClickButton}
      // eslint-disable-next-line react/button-has-type
      type={props.type ?? 'button'}
      data-test-id={props.dataTestId}
    >
      <span
        className={tw(
          'flex items-center space-x-2 transition-opacity duration-300',
          props.loading ? 'opacity-0' : 'opacity-100',
          props.textStyle
        )}
      >
        {props.children}
      </span>
      <DotAnimation
        on={props.loading}
        className="absolute left-1/2 top-1/2 transform -translate-1/2"
      />
      {props.loading && <span className="sr-only">{t('loading')}</span>}
    </button>
  );
});

Button.displayName = 'Button';
