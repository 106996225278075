import { LoadingSpinner } from 'ui/components/LoadingSpinner';

const SearchLoadingIndicator = () => {
  return (
    <LoadingSpinner
      dataTestId="search-flyout-loading"
      className="text-neutral"
    />
  );
};

export default SearchLoadingIndicator;
