import {
  ToastRoot,
  ToastRootProps,
  ToastIcon,
  ToastTitle,
  ToastDescription,
  IconProps,
} from '@global-ecom/nitro-uds/elements';
import { IconNames } from '@global-ecom/nitro-uds/icons';

import { getIsMobile } from 'utils/media';

export type ToastProps = Omit<ToastRootProps, 'title'> & {
  description?: string;
  title?: string;
  icon?: IconNames;
  iconSize?: IconProps['size'];
};

export const Toast = (props: ToastProps) => {
  const { description, icon, title, ...restProps } = props;

  return (
    <ToastRoot {...restProps} size={getIsMobile() ? 'sm' : 'base'}>
      <ToastIcon icon={icon} />
      {title && <ToastTitle>{title}</ToastTitle>}
      {description && <ToastDescription>{description}</ToastDescription>}
    </ToastRoot>
  );
};
