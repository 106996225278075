import { Variant, VariantProduct } from '__generated__/graphql';

export const extractProductVariants = (
  products: any
): (Variant | VariantProduct)[] => {
  if (!products) return [];
  return products
    .map(product => {
      switch (product?.__typename) {
        case 'Variant':
        case 'VariantProduct':
          return product;
        case 'ProductSearchHit':
          return product.variantProduct;
        default:
          return null;
      }
    })
    .filter(Boolean);
};
