import React from 'react';
import Script from 'next/script';

import { useSiteConfig } from '../useSiteConfig';

const LiveChatToggleWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="livechat-online">{children}</div>
);

export const useAdaLiveChat = () => {
  const { liveChat } = useSiteConfig();

  const liveChatScript = liveChat?.script ?? '';
  const liveChatCode = liveChat?.code ?? '';
  const liveChatEnabled = !!liveChatScript && !!liveChatCode;

  const LiveChatScript = ({ defer }: { defer?: boolean }) => {
    if (!liveChatEnabled) return null;

    return (
      <>
        <Script
          id="__ada"
          type="text/javascript"
          data-handle={liveChatCode}
          src={liveChatScript}
          defer={defer}
        />
      </>
    );
  };

  const openLiveChatWindow = React.useCallback(() => {
    window.adaEmbed.toggle();
  }, []);

  return {
    liveChatCode,
    liveChatEnabled,
    LiveChatScript,
    LiveChatToggleWrapper,
    openLiveChatWindow,
    renderInMainNav: !!liveChat?.renderInMainNav,
  };
};
