import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import {
  SanityVideoObject,
  SanityVideoObjectType,
} from 'groq/objects/SanityVideoObject';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/FeatureHero.ts

export type FeatureHeroDocumentType = {
  _id: string;
  _type: string;
  header?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  footnote?: Maybe<LocaleStringType>;
  backgroundColor?: Maybe<string>;
  textColor?: Maybe<string>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  logo?: Maybe<SanityImageObjectType>;
  optionalPrimaryImage?: Maybe<SanityImageObjectType>;
  primaryVideo?: Maybe<SanityVideoObjectType>;
  optionalSecondaryImage?: Maybe<SanityImageObjectType>;
  secondaryVideo?: Maybe<SanityVideoObjectType>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
};

export const FeatureHeroDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${LocaleString('footnote')},
    backgroundColor,
    textColor,
    ${CallToActionObject('ctas[]')},
    ${SanityImageObject('logo')},
    "optionalPrimaryImage": ${SanityImageObject('primaryImage')},
    ${SanityVideoObject('primaryVideo')},
    "optionalSecondaryImage": ${SanityImageObject('secondaryImage')},
    ${SanityVideoObject('secondaryVideo')},
    availableFrom,
    availableTo
  }
`;
