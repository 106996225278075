import { mapExchange } from 'urql';

import { clientLogger } from 'utils/clientLogger';

const gqlMocksEnabled = process.env.NEXT_PUBLIC_GQL_MOCKS === 'true';

const ignoreError = error => {
  return (
    error.message.match('requested page could not be found') ||
    // when using mock service worker, we get a "socket hang up" error whenever
    // a query is aborted. This error does not cause any problems since the abort
    // was expected, but we ignore it to keep the dev tools console output clean
    (error.message.match('socket hang up') && gqlMocksEnabled)
  );
};

const errorExchangeConfig = {
  onError: (error, operation) => {
    if (ignoreError(error)) return;

    const queryDefinition = operation.query.definitions[0] || undefined;
    const queryName =
      (queryDefinition &&
        'name' in queryDefinition &&
        queryDefinition.name?.value) ||
      queryDefinition?.kind ||
      'unknown';

    const errorCode: string | undefined =
      error?.graphQLErrors[0]?.extensions?.code;
    const errorMessage: string | undefined =
      typeof error?.graphQLErrors[0]?.message === 'string' &&
      error?.graphQLErrors[0]?.message?.toLowerCase();
    const apigwRequestId: string | undefined =
      error?.response?.headers?.get?.('apigw-requestid');

    clientLogger.error(
      {
        queryName,
        errorCode,
        errorMessage,
        apigwRequestId,
      },
      'GraphQL Error'
    );
  },
};

export const errorExchange = mapExchange(errorExchangeConfig);
