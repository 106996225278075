import { gql } from 'urql';

export const ADDRESS_FRAGMENT = gql`
  fragment addressFields on Address {
    id
    title
    firstName
    lastName
    firstNamePronunciation
    lastNamePronunciation
    address1
    address2
    stateCode
    city
    countryCode
    phone
    preferred
    postalCode
    streetNumber
    salutation
    companyName
  }
`;
