import randomString from 'randomstring';
import Script from 'next/script';
import { useEffect, useMemo, useState } from 'react';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { isServer } from 'utils/constants';
import { usePixleeConfigQuery } from '__generated__/graphql';

type PixleeSimpleWidgetProps = {
  widgetId: string;
};

const initPixlee = (apiKey: string) => {
  if (!window.PixleeAsyncInit) {
    window.PixleeAsyncInit = () => {
      window.Pixlee.init({ apiKey });
    };
  }
};

const addPixleeWidget = ({ widgetId, containerId }) => {
  window.Pixlee.addSimpleWidget({ widgetId, containerId });
  window.Pixlee.resizeWidget();
};

export const PixleeSimpleWidget = ({ widgetId }: PixleeSimpleWidgetProps) => {
  const { env } = useSiteConfig();
  const [pixleeConfigResponse] = usePixleeConfigQuery();
  const [widgetAdded, setWidgetAdded] = useState(false);

  const randomId = useMemo(() => randomString.generate(8), []);
  const containerId = `pixlee-simple-widget-${widgetId}-${randomId}`;
  const pixleeEnabled = !!pixleeConfigResponse.data?.pixleeConfig?.enable;
  const shouldInitPixlee = Boolean(!isServer && widgetId && pixleeEnabled);

  useEffect(() => {
    return () => window.Pixlee?.close();
  }, []);

  if (!shouldInitPixlee) return null;

  return (
    <>
      <Script
        key="pixlee-simple-widget-script"
        data-test-id="pixlee-simple-widget-script"
        src="https://assets.pxlecdn.com/assets/pixlee_widget_1_0_0.js"
        onReady={() => {
          initPixlee(env.pixleeApiKey);
          if (!widgetAdded) {
            addPixleeWidget({ widgetId, containerId });
            setWidgetAdded(true);
          }
        }}
      />
      <section id={containerId} data-test-id="pixlee-simple-widget" />
    </>
  );
};
