export const gtmScriptText = ({ gtmId }: { gtmId: string }) => `
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    window.pageData = {
      navigation: {},
      customer: {},
    };
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "${gtmId}");
`;

export const GtmNoScript = ({ gtmId }: { gtmId: string }) => (
  <noscript>
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
      height="0"
      width="0"
      className="display:none;visibility:hidden"
    ></iframe>
  </noscript>
);
