import { retryExchange } from '@urql/exchange-retry';
import { CombinedError, Operation } from 'urql';

const REQUEST_TIMEOUT_STATUS_CODE = 408;

export const retryOperations: Array<string> = [];

export const createRetryExchange = () => {
  return retryExchange({
    initialDelayMs: 1000,
    maxDelayMs: 15000,
    randomDelay: true,
    maxNumberAttempts: 5,
    retryIf: (err: CombinedError, operation: Operation) => {
      return Boolean(
        (err?.graphQLErrors || []).find(
          graphQLError =>
            graphQLError.extensions.code == REQUEST_TIMEOUT_STATUS_CODE
        ) &&
          operation.query.definitions.find(definition => {
            return (
              definition.kind === 'OperationDefinition' &&
              definition.name?.value &&
              retryOperations.includes(definition.name?.value)
            );
          })
      );
    },
  });
};
