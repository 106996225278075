import { SlotsGroqQuery } from 'groq/ContentSlots';
import {
  ProductCarouselDocument,
  ProductRecommenderDocument,
  SignUpFormDocument,
} from 'groq/documents';
import { ProductCarouselDocumentType } from 'groq/documents/ProductCarouselDocument';
import { ProductRecommenderDocumentType } from 'groq/documents/ProductRecommenderDocument';
import { SignUpFormDocumentType } from 'groq/documents/SignUpFormDocument';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import { ComponentWithFilters } from '__generated__/graphql';

export type ProductDetailsPageContent = {
  _id: string;
  _type: string;
  aboveProductStory: ComponentWithFilters[];
  ProductStoryAdditionalContentSlot: ComponentWithFilters[];
  belowProductStory: ComponentWithFilters[];
  belowProductGoogleShopping: ComponentWithFilters[];
  ctaAboveSpecialMessage: CallToActionObjectType[];
  soldOutRecommender: (
    | ProductRecommenderDocumentType
    | ProductCarouselDocumentType
  )[];
  comingSoonSignUpForm: SignUpFormDocumentType;
};

export type AboveProductStory_PDPContent = Pick<
  ProductDetailsPageContent,
  '_id' | '_type' | 'aboveProductStory'
>;

export type ProductStoryAdditionalContentSlot_PDPContent = Pick<
  ProductDetailsPageContent,
  '_id' | '_type' | 'ProductStoryAdditionalContentSlot'
>;

export type BelowProductStory_PDPContent = Pick<
  ProductDetailsPageContent,
  '_id' | '_type' | 'belowProductStory'
>;

export type BelowProductGoogleShopping_PDPContent = Pick<
  ProductDetailsPageContent,
  '_id' | '_type' | 'belowProductGoogleShopping'
>;

export type ExtendedData_PDPContent = Pick<
  ProductDetailsPageContent,
  | '_id'
  | '_type'
  | 'ctaAboveSpecialMessage'
  | 'soldOutRecommender'
  | 'comingSoonSignUpForm'
>;

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    PDP_AboveProductStory: AboveProductStory_PDPContent;
    PDP_ProductStoryAdditionalContentSlot: ProductStoryAdditionalContentSlot_PDPContent;
    PDP_BelowProductStory: BelowProductStory_PDPContent;
    PDP_BelowProductGoogleShopping: BelowProductGoogleShopping_PDPContent;
    PDP_ExtendedData: ExtendedData_PDPContent;
  }
}

export const AboveProductStory_PDPContentQuery = `
  *[_type == "ProductDetailsPage" && country == upper($country) && product match "*" + $productId][0] {
    _id,
    _type,
    aboveProductStory[] ${SlotsGroqQuery()},
  }
`;

export const ProductStoryAdditionalContentSlot_PDPContentQuery = `
  *[_type == "ProductDetailsPage" && country == upper($country) && product match "*" + $productId][0] {
    _id,
    _type,
    ProductStoryAdditionalContentSlot[] ${SlotsGroqQuery()},
  }
`;

export const BelowProductStory_PDPContentQuery = `
  *[_type == "ProductDetailsPage" && country == upper($country) && product match "*" + $productId][0] {
    _id,
    _type,
    belowProductStory[] ${SlotsGroqQuery()},
  }
`;

export const BelowProductGoogleShopping_PDPContentQuery = `
  *[_type == "ProductDetailsPage" && country == upper($country) && product match "*" + $productId][0] {
    _id,
    _type,
    belowProductGoogleShopping[] ${SlotsGroqQuery()},
  }
`;

export const ExtendedData_PDPContentQuery = `
  *[_type == "ProductDetailsPage" && country == upper($country) && product match "*" + $productId][0] {
    _id,
    _type,
    ${CallToActionObject('ctaAboveSpecialMessage[]')},
    soldOutRecommender[] -> {
      ${ProductRecommenderDocument('_type == "ProductRecommender" =>')},
      ${ProductCarouselDocument('_type == "ProductCarousel" =>')},
    },
    ${SignUpFormDocument('comingSoonSignUpForm ->')},
  }
`;
