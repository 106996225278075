import { Maybe } from 'utils/types';

export type CloudinaryPlatform = 'all' | 'desktop' | 'mobile';

export interface CloudinaryEagerTransformation {
  // this is dataset type, only edit when changed in dataset...
  _type: 'cloudinaryEagerTransformation';
  _key: string;
  width?: number;
  height?: number;
  url?: string;
  media?: Maybe<string>;
  platform?: CloudinaryPlatform;
}

export interface CloudinaryOptimization {
  // this is dataset type, only edit when changed in dataset...
  status?: Maybe<'in-progress' | 'optimized' | 'not-optimized'>;
  transformations?: Maybe<CloudinaryEagerTransformation[]>;
}

// you can customize this type only...
export type CloudinaryOptimizationObjectType = CloudinaryOptimization;

const CloudinaryOptimizationObject = (
  field: string,
  platform?: CloudinaryPlatform
) => `
  ${field} {
      status,
      ${
        platform
          ? `transformations[platform == "${platform}"]`
          : 'transformations'
      }
  }
`;

export const CloudinaryImageOptimizationObject = (
  field: string,
  platform: CloudinaryPlatform
) => CloudinaryOptimizationObject(field, platform);

export const CloudinaryVideoOptimizationObject = (field: string) =>
  CloudinaryOptimizationObject(field);
