import Script from 'next/script';
import { useState, useEffect } from 'react';
import { tw } from 'twind/css';

import { AnalyticsEvents, event } from 'utils/analytics';
import {
  StyliticsBundle,
  StyliticsWidgetProps,
} from 'ui/components/StyliticsWidget/types';
import { useWidgetInstanceStore } from 'store';

import { StyliticsModal } from '../components/StyliticsWidget/StyliticsModal';
import { useTranslate } from '../../hooks/useTranslations';
import { useStyliticsWidget } from '../../hooks/useStyliticsWidget';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { usePdp } from '../../hooks/usePdp';
import { useFeature } from '../../hooks/useFeature';
import {
  widgetOverrides,
  shopTheLookBadge,
} from '../components/StyliticsWidget/stylitics-widget-css';

const WRAPPER_ID = 'completeTheLook';
const WIDGET_CONTAINER = 'how-to-wear-it-container';

export const HowToWearIt = ({
  overrideOptions,
  _id,
  _type,
}: StyliticsWidgetProps) => {
  const [renderWidgetHeader, setRenderWidgetHeader] = useState(false);
  const [widget, setWidget] = useState<any>();
  const [bundles, setBundles] = useState<StyliticsBundle[]>([]);
  const [selectedBundle, setSelectedBundle] = useState<StyliticsBundle>();
  const [showModal, setShowModal] = useState(false);
  const isStyliticsEnabled = useFeature('OUTFITS_ENABLED');
  const { setWidgetInstance } = useWidgetInstanceStore();
  const t = useTranslate();
  const {
    env: { styliticsUsername },
  } = useSiteConfig();

  const { variation } = usePdp();

  const { options, onBundle, onBundles, onViewBundle } = useStyliticsWidget({
    itemNumber: variation?.styleNumber || '',
    itemName: variation?.name || '',
  });

  const initWidget = () => {
    // bail if the Stylitics script isn't loaded
    if (!window.StyliticsClassicWidget) return;

    if (!widget) {
      // Stylitics has a problem with instantiating a new instance with the same parameters which
      // could potentially happen here. Remove the `nonce` parameter once this is fixed on their end.
      let widgetOptions = overrideOptions ?? options;
      widgetOptions = { ...widgetOptions, nonce: Math.random().toString(16) };

      const widgetInstance = new window.StyliticsClassicWidget(
        styliticsUsername,
        WIDGET_CONTAINER,
        widgetOptions
      );

      // save the widget instance to later use in the stylitics modal to track clicks/views
      setWidgetInstance(widgetInstance);

      widgetInstance.override('click', 'bundle', data => {
        setSelectedBundle(data.bundle);
        setShowModal(true);

        const firstItemBundle = data.bundle?.items?.[0] || null;

        const itemID = firstItemBundle?.remote_id;
        const itemName = firstItemBundle?.name;

        // Triggers the first GA4 event for a product in the Modal Carousel when the user clicks on the Stylitics Carousel within the PDP
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.GA4EC_Stylitics,
          event_params: {
            user_action: `${AnalyticsEvents.GA4_PRODUCT_CLICK}`,
            item_id_ep: `${itemID}`,
            item_name_ep: `${itemName}`,
            stylitics_outfit_id: `${data.bundle?.primary_stylitics_item_id}`,
            stylitics_product_id: `${data.bundle?.primary_remote_id}`,
          },
        });
      });

      widgetInstance.on('mount', 'bundle', onBundle);

      widgetInstance.on('mount', 'bundles', data => {
        setBundles(data.bundles);
        onBundles(data, setRenderWidgetHeader);
      });

      widgetInstance.on('view', 'bundle', onViewBundle);

      widgetInstance.start();
      setWidget(widgetInstance);
    }
  };

  useEffect(() => {
    if (widget && variation) {
      widget.refresh({ api: { item_number: variation?.styleNumber } });
    }
  }, [variation, widget]);

  return (
    <>
      <Script
        src="https://web-assets.stylitics.com/v3-classic/latest/classic.release.js"
        onReady={initWidget}
        strategy="lazyOnload"
      />
      {isStyliticsEnabled && (
        <div id="complete-the-look-carousel">
          <section aria-label={t(WRAPPER_ID)}>
            {renderWidgetHeader && (
              <h2
                id={WRAPPER_ID}
                className="font-bold text-xl sm:text-2xl pb-4 text-uppercase"
              >
                {t(WRAPPER_ID)}
              </h2>
            )}
            <section
              className={tw(
                'relative w-full',
                widgetOverrides,
                shopTheLookBadge
              )}
              id={WIDGET_CONTAINER}
            />
          </section>
        </div>
      )}

      {selectedBundle && (
        <StyliticsModal
          bundles={bundles}
          selectedBundle={selectedBundle}
          setSelectedBundle={setSelectedBundle}
          open={showModal}
          onOpenChange={setShowModal}
        />
      )}
    </>
  );
};
