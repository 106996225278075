import { Dispatch, SetStateAction, useRef } from 'react';
import { useRouter } from 'next/router';

import { isServer, PaymentMethod } from 'utils/constants';
import { useFeature } from 'hooks/useFeature';
import { useSiteConfig } from 'hooks/useSiteConfig';

export function useAmazon() {
  const isMountRender = useRef(true);
  const isAmazonPayEnabled = useFeature(PaymentMethod.AMAZON_PAY);
  const { countryCode, env, amazonWidget } = useSiteConfig();
  const { query } = useRouter();

  const shouldRenderAmazonPay =
    !isServer &&
    isAmazonPayEnabled &&
    query.payWithAmazon === 'true' &&
    (window as any).sessionStorage.getItem('pay-with-amazonpay') === 'true';

  const isAmazonpayRegisteredAccount = () => {
    if (!shouldRenderAmazonPay) return false;

    const amazonpayRegisteredAccount = window.sessionStorage.getItem(
      'amazonpay-registeredAccount'
    );
    return amazonpayRegisteredAccount === 'true';
  };

  const getAmazonpayUserEmail = () =>
    window.sessionStorage.getItem('amazonpay-userEmail');

  const loadAmazonScript = (
    setIsScriptLoaded: Dispatch<SetStateAction<boolean>>,
    setIsAmazonLoginReady?: Dispatch<SetStateAction<boolean>>
  ) => {
    const initializeAmazonPayScript = url => {
      const script = document.createElement('script');
      script.src = url || '';
      script.async = false;
      script.onload = function () {
        setIsScriptLoaded(true);
      };

      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    };

    const initializeAmazonPay = () => {
      if (!isServer) {
        const regex = new RegExp(`[?|&amp;|#]access_token=([^&;]+?)(&|#|;|$)`);
        const matchAccessToken = (regex.exec(location.hash) || ['', ''])[1];
        const accessToken =
          decodeURIComponent(matchAccessToken.replace(/\+/g, '%20')) || null;
        if (typeof accessToken === 'string' && accessToken.match(/^Atza/)) {
          document.cookie = `amazon_Login_accessToken=${accessToken};path=/;secure`;
        }

        window.onAmazonLoginReady = () => {
          window.amazon?.Login.setUseCookie(true);

          window.amazon?.Login.setClientId(env.amazonPayClientId as string);

          setIsAmazonLoginReady && setIsAmazonLoginReady(true);

          delete window.onAmazonLoginReady;
        };
      }
    };

    if (isAmazonPayEnabled && isMountRender.current && amazonWidget) {
      isMountRender.current = false;
      const amazonMode = env.amazonPayMode === 'sandbox' ? '/sandbox' : '';

      const src = `https://static-${
        amazonWidget.countryCode
      }.payments-amazon.com/OffAmazonPayments/${countryCode + amazonMode}/${
        amazonWidget.widgetSource
      }/Widgets.js?sellerId=${env.amazonPayMerchantId}`;

      initializeAmazonPayScript(src);
      initializeAmazonPay();
    }
  };

  return {
    loadAmazonScript: loadAmazonScript,
    shouldRenderAmazonPay: shouldRenderAmazonPay,
    isAmazonpayRegisteredAccount: isAmazonpayRegisteredAccount,
    getAmazonpayUserEmail: getAmazonpayUserEmail,
  };
}
