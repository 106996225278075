import { useHorizontalScroll } from '@global-ecom/nitro-uds/hooks';
import { createContext, useContext, useState } from 'react';

import { StyliticsLook } from '__generated__/graphql';
import { AnalyticsEvents, event } from 'utils/analytics';
import { Dimensions } from 'types/analyticTypes';
import { TileWithPositionDocumentType } from 'groq/objects/TileWithPosition';

import { ScreenSizes, useClosestMedia } from './useClosestMedia';
import { Ga4Data } from './usePromotionSelect';

export type StyliticsSanityCtx = {
  activeId: string | undefined;
  isCarousel: boolean;
  carouselUi: {
    carousel: ReturnType<typeof useHorizontalScroll>;
    carouselNavHidden: boolean;
    hideCarouselNavBtns: (isHidden: boolean) => void;
  };
  outfitUi: {
    toggleActiveId: (id: string | undefined) => void;
  } & LastInView;
  tiles: Array<StyliticsLook | TileWithPositionDocumentType>;
  fireTracking: () => void;
  ga4Data?: Ga4Data;
};

// Create Context
export const StyliticsSanityContext = createContext<StyliticsSanityCtx>(
  {} as StyliticsSanityCtx
);

// Create Custom Context Hook
export const useStyliticsSanity = () => useContext(StyliticsSanityContext);

// UI: Last in View Hook
type ColumnConfig = {
  [key in ScreenSizes]: number;
};
type LastInView = ReturnType<typeof useLastInView>;
type LastInViewArgs = {
  columns: ColumnConfig;
};
const defaultLastInViewArgs = {
  columns: {
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    '2xl': 3,
  },
};
const useLastInView = (
  numTiles: number,
  args: LastInViewArgs = defaultLastInViewArgs
) => {
  const closest = useClosestMedia();

  const isLastInView = (index: number) => {
    if (index + 1 === numTiles) return true;
    return (index + 1) % args.columns[closest] === 0;
  };

  return {
    columns: args.columns,
    isLastInView,
    screenSize: closest,
  };
};

type StyliticsSanityProviderProps = {
  children: React.ReactNode;
  isCarousel: boolean;
  tags?: Array<string | null> | null;
  tiles: Array<StyliticsLook | TileWithPositionDocumentType>;
  ga4Data?: Ga4Data;
};
// Create Provider
export const StyliticsSanityProvider = (
  props: StyliticsSanityProviderProps
) => {
  const { isCarousel, tiles, ga4Data } = props;

  const [activeId, setActiveId] = useState<string>();
  const [carouselNavHidden, setCarouselNavHidden] = useState(false);

  const carouselProps = useHorizontalScroll({ infinite: false });
  const view = useLastInView(tiles?.length ?? 0);

  const hideCarouselNavBtns = (isHidden: boolean) =>
    setCarouselNavHidden(isHidden);

  const toggleActiveId = (id: string | undefined) => setActiveId(id);

  const fireCarouselTracking = () => {
    event(AnalyticsEvents.STYLITICS_INTERACTION, {
      domevent: 'Stylitics - Product Click',
      domlabel: 'Stacked Carousel',
      [Dimensions.d27]: props.tags,
    });
  };

  const fireGridTracking = () => {
    event(AnalyticsEvents.STYLITICS_INTERACTION, {
      domevent: 'Stylitics - Product Click',
      domlabel: 'Gallery',
      [Dimensions.d27]: props.tags,
    });
  };

  return (
    <StyliticsSanityContext.Provider
      value={{
        activeId,
        isCarousel,
        carouselUi: {
          carousel: carouselProps,
          carouselNavHidden,
          hideCarouselNavBtns,
        },
        outfitUi: {
          toggleActiveId,
          ...view,
        },
        tiles,
        fireTracking: isCarousel ? fireCarouselTracking : fireGridTracking,
        ga4Data,
      }}
    >
      {props.children}
    </StyliticsSanityContext.Provider>
  );
};
