import { useEffect, useState } from 'react';

const MILLISECOND_IN_AN_HOUR = 3600_000;
const MILLISECOND_IN_A_MINUTE = 60_000;

export const useCountdown = ({ countdownEnd }: { countdownEnd: string }) => {
  const [countdown, setCountdown] = useState(() => {
    const target = new Date(countdownEnd).getTime();
    const remaining = target - Date.now();
    return calculateReturnValues(remaining);
  });

  useEffect(() => {
    const target = new Date(countdownEnd).getTime();

    const t = setInterval(() => {
      const remaining = target - Date.now();
      setCountdown(calculateReturnValues(remaining));
      if (remaining <= 0) {
        clearInterval(t);
      }
    }, 1000);

    return () => clearInterval(t);
  }, [countdownEnd]);

  return countdown;
};

const calculateReturnValues = (countdown: number) => {
  // 1000 milliseconds in 1 second
  // 60 seconds in a minute
  // 60 mins in an hour
  // 24 hours in a day

  if (countdown < 1) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      lessThanAnHourRemaining: false,
      lessThanAMinuteRemaining: false,
    };
  }

  // calculate time left
  const days = Math.floor(countdown / (MILLISECOND_IN_AN_HOUR * 24));
  const hours = Math.floor(
    (countdown % (MILLISECOND_IN_AN_HOUR * 24)) / MILLISECOND_IN_AN_HOUR
  );
  const minutes = Math.floor(
    (countdown % MILLISECOND_IN_AN_HOUR) / MILLISECOND_IN_A_MINUTE
  );
  const seconds = Math.floor((countdown % MILLISECOND_IN_A_MINUTE) / 1000);

  const lessThanAnHourRemaining = countdown < MILLISECOND_IN_AN_HOUR;
  const lessThanAMinuteRemaining = countdown < MILLISECOND_IN_A_MINUTE;

  return {
    days,
    hours,
    minutes,
    seconds,
    lessThanAnHourRemaining,
    lessThanAMinuteRemaining,
  };
};
