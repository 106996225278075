/**
 * This function masks the start and end of the
 * input text according to the parameters given.
 * @param {string} input - The input string to be masked
 * @param {number} leftPadding - The number of characters to mask from the left (default: `0`)
 * @param {number} rightPadding - The number of characters to mask from the right (default: `0`)
 * @param {string} maskCharacter - The character to use as a mask (default: `*`)
 */
export const textMask = (
  input: string,
  leftPadding = 0,
  rightPadding = 0,
  maskCharacter = '*'
) => {
  input ??= ''; // coalesce to empty string if undefined

  // Normalise padding to zero if negative numbers are provided
  leftPadding = Math.max(leftPadding, 0);
  rightPadding = Math.max(rightPadding, 0);

  const lengthOfLeftString = Math.max(input.length - rightPadding, 0);

  return `${input}`
    .slice(leftPadding, lengthOfLeftString)
    .padStart(lengthOfLeftString, maskCharacter)
    .padEnd(input.length, maskCharacter);
};

/**
 * This function masks the middle of the
 * input text according to the parameters given.
 * @param {string} input - The input string to be masked
 * @param {number} leftCharsToShow - The number of characters to show on the left (default: `0`)
 * @param {number} rightCharsToShow - The number of characters to show on the right (default: `0`)
 * @param {string} maskCharacter - The character to use as a mask (default: `*`)
 */
export const invertedTextMask = (
  input: string,
  leftCharsToShow = 0,
  rightCharsToShow = 0,
  maskCharacter = '*'
): string => {
  input ??= ''; // coalesce to empty string if undefined

  // Return the original string if charsToShow are >= the input string
  if (leftCharsToShow >= input.length || rightCharsToShow >= input.length)
    return input;

  // Normalise padding to zero if negative numbers are provided
  leftCharsToShow = Math.min(Math.max(leftCharsToShow, 0), input.length);
  rightCharsToShow = Math.min(Math.max(rightCharsToShow, 0), input.length);

  const left = `${input}`.slice(0, leftCharsToShow);
  const right = `${input}`.slice(input.length - rightCharsToShow);
  const middle = ''.padStart(
    input.length - leftCharsToShow - rightCharsToShow,
    maskCharacter
  );

  return `${left}${middle}${right}`;
};
