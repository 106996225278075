type InjectedScriptProps = {
  script: string;
  sanitize?: boolean;
  type?: string;
  [key: string]: unknown;
};

export const InjectedScript = ({ script, ...props }: InjectedScriptProps) => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: script,
      }}
      {...props}
    ></script>
  );
};
