import { gql } from 'urql';

export const SAVED_CARD_FRAGMENT = gql`
  fragment savedCreditCard on SavedCreditCard {
    UUID
    defaultFlag
    creditHolderName
    creditCardSeq
    creditCardType
    creditCardNumber
    maskedCreditCardNumber
    creditCardExpirationMonth
    creditCardExpirationYear
  }
`;
