import { Maybe } from '__generated__/graphql';

export const isContentOnline = (
  doc: {
    online?: Maybe<boolean>;
    onlineFrom?: Maybe<string>;
    onlineTo?: Maybe<string>;
  },
  preview
) => {
  const isDev = !!preview;
  const viewAt = isDev && preview?.viewAt ? +preview.viewAt : Date.now();
  if (doc?.online === false) return false;
  if (doc?.onlineFrom && +new Date(doc.onlineFrom) > viewAt) return false;
  if (doc?.onlineTo && +new Date(doc.onlineTo) < viewAt) return false;
  return true;
};
