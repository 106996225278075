import { tw } from 'twind';

import { Markdown } from 'ui/elements/Markdown';

type TextProps = {
  header: string;
  copy: string;
  footnote: string;
  align: string;
  color: string;
  icon?: any;
};

export const Text = (props: TextProps) => {
  const { header, copy, footnote, align, color, icon } = props;

  return (
    <div
      className={tw(
        'flex flex-col relative',
        `text-${align} text-${color}, ${icon && 'gap-y-4'}`
      )}
    >
      {header && (
        <div className="flex items-center gap-x-4">
          {icon?.img && (
            <img
              className="tablet:hidden desktop:hidden"
              src={icon.img.asset.url}
              alt={icon.alt}
            />
          )}
          <h3 className="font-bold text-2xl leading-snug">
            <Markdown content={header} />
          </h3>
        </div>
      )}
      {copy && (
        <p className="font-normal text-base leading-snug">
          <Markdown content={copy} />
        </p>
      )}
      {footnote && (
        <small className="font-normal text-sm leading-snug">
          <Markdown content={footnote} />
        </small>
      )}
    </div>
  );
};
