import { parseCookies, destroyCookie } from 'nookies';
import router from 'next/router';

import { Update, Logger } from '.';

export const AUTH_COOKIE_NAMES_TO_DESTROY = [
  'country',
  'guest',
  'auth-token',
  'refresh-token',
  'customerId',
  'customerEmail',
  'impersonation',
  'uniqueShopperId',
];

export const deleteLegacyRegionalAuthCookies: Update = {
  name: 'deleteLegacyRegionalAuthCookies',
  test() {
    // only run this if there are auth cookies present
    const cookies = parseCookies();
    return !!cookies['auth-token'];
  },
  run(log: Logger) {
    // remove any existing auth cookies to from the country path scope
    const authCookieNames = AUTH_COOKIE_NAMES_TO_DESTROY;
    const country = router.query.country as string;
    for (const name of authCookieNames) {
      log(`Removing ${name} from "/${country}" scope`);
      destroyCookie(null, name, { path: `/${country}` });
    }
    // need to force a reload to regenerate cookies in correct scope
    return true;
  },
};
