import { useContext, createContext } from 'react';

import { Resources, ResourcesVariables } from 'locales/localeType';

type IOptions = { supportHTMLTags: boolean };

export type ITranslationSupportHTMLTags = { supportHTMLTags: true };

export type TransType = <
  K extends keyof ResourcesVariables,
  T extends IOptions = { supportHTMLTags: false },
  Key extends keyof Resources = keyof Resources
>(
  key: Key,
  variables?: ResourcesVariables[K],
  options?: T
) => T['supportHTMLTags'] extends true ? JSX.Element : string;

export const get = (source: Resources, key: any): any => {
  const path = key.split('.');
  let index = 0;

  while (source && path.length > index) {
    source = source[path[index++]];
  }

  return source;
};

export const getInitialTranslations = (i18n: Resources): TransType =>
  function t(key, variables, options) {
    let translation = get(i18n, key);
    //translations seems to fail sometimes or not return properly, this may be the race condition that breaks the add to cart.
    if (variables && translation) {
      Object.keys(variables).forEach(key => {
        translation = translation.replace(
          new RegExp(`{${key}}`, 'g'),
          variables[key]
        );
      });
    }
    if (options?.supportHTMLTags) {
      return <span dangerouslySetInnerHTML={{ __html: translation }}></span>;
    }
    return translation;
  };

export const TranslationsContext = createContext<{
  t: TransType;
}>({ t: () => '' } as { t: TransType });

export const useTranslate = () => useContext(TranslationsContext).t;
