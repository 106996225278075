type MergeableType = Record<string, any> | Array<any>;

const mergeInternal = (target, source) => {
  if (!source || !(source instanceof Object)) {
    return source;
  }

  Object.keys(source).map(key => {
    target[key] = mergeInternal(target[key], source[key]);
  });

  return target;
};

export const merge = <T extends MergeableType, U>(
  target: T,
  source: U
): U extends MergeableType ? T & U : T => {
  if (!source || !(source instanceof Object)) {
    // @ts-expect-error
    return target;
  }

  const clonedTarget = JSON.parse(JSON.stringify(target));

  Object.keys(source).map(key => {
    clonedTarget[key] = mergeInternal(clonedTarget[key], source[key]);
  });

  return clonedTarget;
};
