const timeouts = new Map<number, NodeJS.Timeout>();

// picked off the internet: a very tiny hashing function called funHash
const hash = s => {
  let i, h;
  for (i = 0, h = 0xdeadbeef; i < s.length; i++)
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  return (h ^ (h >>> 16)) >>> 0;
};

export const debounce = (
  fn: (...x: any) => any,
  ms: number
): ((...x: any) => any) => {
  return (...args) => {
    const id = hash(fn.toString());
    const existingTimeout = timeouts.get(id);
    if (existingTimeout) clearTimeout(existingTimeout);
    const timeout = setTimeout(() => {
      fn(...args);
      timeouts.delete(id);
    }, ms);
    timeouts.set(id, timeout);
    return timeout;
  };
};
