import { Slot, Maybe } from '__generated__/graphql';

import { FullBleedHeroDocument } from './FullBleedHeroDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/WebCarousel.tsx

export type WebCarouselType = {
  _id: string;
  _type?: Maybe<string>;
  id: string;
  content: Maybe<Slot>;
};

export const WebCarouselDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    "content": content[]{
      ...@ -> {
        ${FullBleedHeroDocument('_type == "FullBleedHero" =>')},
      }
    },
  }
`;
