import {
  CloudinaryOptimizationObjectType,
  CloudinaryVideoOptimizationObject,
} from 'groq/objects/CloudinaryOptimizationObject';
import { Maybe } from 'utils/types';

export type VideoAssetDocumentType = {
  _id: string;
  url: string;
  path: string;
  cloudinary?: Maybe<CloudinaryOptimizationObjectType>;
};

export const SanityVideoAssetDocument = field => `
  ${field} {
    _id,
    url,
    path,
    ${CloudinaryVideoOptimizationObject('cloudinary')}
  }
`;
