import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import router from 'next/router';

import { RegisterInput } from '__generated__/graphql';
import { useEmailNotification } from 'hooks/useEmailNotification';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { CustomForm } from 'ui/forms/custom/CustomForm';
import { dySignUpEvent } from 'utils/dynamicYield';
import { useAuth } from 'hooks/useAuth';
import {
  AnalyticsEvents,
  event,
  getPageType,
  hashEmail,
} from 'utils/analytics';
import { useFeature } from 'hooks/useFeature';
import { useMainNavContext } from 'hooks/useMainNav';

type RegisterFormData = Pick<
  RegisterInput,
  | 'email'
  | 'firstName'
  | 'firstNamePronunciation'
  | 'lastName'
  | 'lastNamePronunciation'
  | 'password'
> & {
  emailList: boolean;
  smsOptIn?: boolean;
  phoneNumber?: string;
  day?: number;
  month?: number;
  year?: number;
};

export const RegisterForm = ({
  onSuccess,
  pageviewEventHasFired,
  injectedHashEMail = hashEmail,
  isInPopover,
}: {
  onSuccess?: () => void;
  pageviewEventHasFired: boolean;
  injectedHashEMail?: (email: string | undefined) => string | undefined;
  isInPopover?: boolean;
}) => {
  const { staticFeatures } = useSiteConfig();
  const { injectDynamicYieldScripts } = staticFeatures;
  const { isRegisterOpen } = useMainNavContext();

  const auth = useAuth();

  const {
    forms: { registration: registrationForm, registrationV2 },
  } = useSiteConfig();
  const { createSignupContact } = useEmailNotification();
  const {
    register: [registerResult, register],
  } = useLoginAndRegister();
  const popoverLoginEnabled = useFeature('POPOVER_LOGIN_ENABLED');

  const hasSubmitted = React.useRef(false);

  useEffect(() => {
    return () => {
      if (
        (!isInPopover || isRegisterOpen) &&
        !hasSubmitted.current &&
        pageviewEventHasFired
      ) {
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.SIGN_UP_FORM_CLOSE,
          event_params: {
            method: getPageType(router.asPath),
          },
        });
      }
    };
  }, [pageviewEventHasFired, isInPopover, isRegisterOpen]);

  const onSubmit = async (data: RegisterFormData) => {
    hasSubmitted.current = true;

    let registerInput: RegisterInput = {
      email: data.email,
      firstName: data.firstName,
      firstNamePronunciation: data.firstNamePronunciation,
      lastName: data.lastName,
      lastNamePronunciation: data.lastNamePronunciation,
      password: data.password,
    };

    const isDateDefined = data => {
      return data.year && data.month && data.day;
    };

    if (isDateDefined(data)) {
      registerInput = {
        ...registerInput,
        birthday: new Date(
          Date.UTC(Number(data.year), Number(data.month) - 1, Number(data.day))
        )
          .toISOString()
          .split('T')[0],
      };
    }
    const triggerGA4Event = () => {
      event(AnalyticsEvents.GA4_CustomEvent, {
        event_name: AnalyticsEvents.GA4EC_SignUp,
        event_params: {
          method: getPageType(router.asPath),
          is_guest: 'false',
          hashed_email: injectedHashEMail(data.email),
          custom_user_id: auth.customerId,
        },
      });
    };

    const isPopover =
      popoverLoginEnabled || !window.location.pathname.includes('/account');

    const result = await register(registerInput);
    if (result.error) {
      if (!isPopover) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    if (result.data?.register) {
      if (isPopover) {
        window.scrollTo(0, 0);
      }
    }

    if (!result?.data?.register) return;

    if (data.emailList || data.smsOptIn) {
      const dob = isDateDefined(data)
        ? {
            day: Number(data.day),
            month: Number(data.month),
            year: Number(data.year),
          }
        : undefined;

      try {
        await createSignupContact({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          firstNamePronunciation: data.firstNamePronunciation ?? null,
          lastNamePronunciation: data.lastNamePronunciation ?? null,
          dob: dob ?? null,
          smsOptIn: data.smsOptIn ?? null,
          phoneNumber: data.phoneNumber ?? null,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      }
    }

    if (pageviewEventHasFired) triggerGA4Event();

    if (onSuccess) {
      onSuccess();
    }

    if (onSuccess && injectDynamicYieldScripts) {
      dySignUpEvent(data.email);
    }
  };

  const form = useForm({
    defaultValues: { emailList: true },
    mode: 'onChange',
  });

  useEffect(() => {
    if ((!isInPopover || isRegisterOpen) && pageviewEventHasFired) {
      event(AnalyticsEvents.GA4_CustomEvent, {
        event_name: AnalyticsEvents.GA4EC_SignUpFormOpen,
        event_params: {
          method: getPageType(router.asPath),
        },
      });
    }
  }, [pageviewEventHasFired, isInPopover, isRegisterOpen]);

  return (
    <CustomForm
      form={form}
      onSubmit={onSubmit}
      result={registerResult}
      config={
        popoverLoginEnabled && isInPopover ? registrationV2 : registrationForm
      }
      dataTestId="registration-form"
      gapY={popoverLoginEnabled ? 'sm' : 'base'}
    />
  );
};
