import { gql } from 'urql';

import {
  CART_AVAILABLE_PAYMENT_METHODS_FRAGMENT,
  CART_FRAGMENT,
} from 'gql/fragments/cart';

export const CART_QUERY = gql`
  query Cart {
    activeCart {
      ...cartFields
      availablePaymentMethods {
        ...availablePaymentMethodsFields
      }
      isMixedCartAllowed
    }
  }

  ${CART_FRAGMENT}
  ${CART_AVAILABLE_PAYMENT_METHODS_FRAGMENT}
`;
