// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/localeString.tsx

import { LocaleText as LocaleTextType } from '__generated__/graphql-sanity';

export const LocaleText = (field: string): string => `
  "${field}": coalesce(
    ${field}[$locale],
    ${field}[$language],
    ${field}.en_GB,
    ${field}.en_US,
    ${field}.en,
    ''
  )
`;

export const LocaleTextJSON = (text: string): LocaleTextType => ({
  _type: 'localeString',
  en_US: text,
});
