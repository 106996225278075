import type { ParsedUrlQuery } from 'querystring';

import {
  FilterInputOption,
  FilterOption,
  ProductsInput,
  SortOption,
  Product,
  Size,
  SizeGroup,
} from '__generated__/graphql';

import { decodeFilters } from './productFilters';

// These filters have specific UI.
export const wellKnownFilterIds = {
  color: 'color',
  size: 'size',
  price: 'price',
  minMaxPriceRange: 'minMaxPriceRange',
};

export const DIGITAL_GIFT_CARD_NAME = 'Digital Gift Card';

export const MAX_PRODUCT_PAGE_SIZE = 24;

export const createListVariables = ({
  q,
  limit,
  offset,
  sort,
  ...restQuery
}: ParsedUrlQuery): ProductsInput => {
  const filters: FilterInputOption[] = [];

  const validFilters = decodeFilters(restQuery);
  const { minPrice, maxPrice, ...remainingValidFilters } = validFilters;
  Object.entries(remainingValidFilters).forEach(([k, v]) => {
    if (v && typeof v === 'string') {
      filters.push({
        id: k,
        values: v.split(','),
      });
    }
  });

  if (minPrice && maxPrice) {
    filters.push({
      id: wellKnownFilterIds.price,
      values: [minPrice, maxPrice],
    });
  }

  return {
    q: q as string,
    offset: Number(offset) || 0,
    limit: Number(limit) || MAX_PRODUCT_PAGE_SIZE,
    sort: sort as string,
    filters,
    isDraft: 'false',
  };
};

export interface ListShape {
  products: {
    nodes: Product[];
    sortingOptions: SortOption[];
    filteringOptions: FilterOption[];
    totalCount: number;
  };
}

export const getMaxQuantity = ({
  maxOrderableQuantity = 1,
  inventoryQuantity = 0,
}: {
  maxOrderableQuantity?: number;
  inventoryQuantity?: number;
}): number =>
  inventoryQuantity && inventoryQuantity < maxOrderableQuantity
    ? inventoryQuantity
    : maxOrderableQuantity;

export const getSizesFromSizeGroups = (sizeGroups: SizeGroup[]): Size[] => {
  const allSizes = sizeGroups.flatMap(x => x.sizes);
  const uniqueSizes = new Map<string, Size>();
  for (const size of allSizes) {
    if (!uniqueSizes.has(size.value)) uniqueSizes.set(size.value, size);
  }
  return [...uniqueSizes.values()];
};

export const getFinalProductPrice = (product: any) => {
  if (product) {
    const finalPrice =
      product.productPrice?.promotionPrice ??
      product.productPrice?.salePrice ??
      product.productPrice?.price ??
      product.salePrice ??
      product.price;

    return finalPrice;
  }
};
