import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export type ModalName_Type = 'GWPModal'; // names of available modals: 'modal1' | 'modal2'

interface State {
  modalsOpen: ModalName_Type[];
  setModalsOpen: (value: ModalName_Type[]) => void;
}

const _useModalsOpenStore = create<State>(set => ({
  modalsOpen: [],
  setModalsOpen: value => set({ modalsOpen: value }),
}));

// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export const useModalsOpenStore = () => {
  const { modalsOpen, setModalsOpen } = _useModalsOpenStore(
    useShallow(state => ({
      modalsOpen: state.modalsOpen,
      setModalsOpen: state.setModalsOpen,
    }))
  );

  const setModalOpen = (modalName: ModalName_Type, open: boolean) => {
    if (open) {
      setModalsOpen(
        modalsOpen.filter(e => e !== modalName).concat([modalName])
      );
    } else setModalsOpen([...modalsOpen.filter(x => x !== modalName)]);
  };

  const isModalOpen = (modalName: ModalName_Type) =>
    modalsOpen.some(x => x === modalName);

  return { isModalOpen, setModalOpen };
};

// ─── Usage ───────────────────────────────────────────────────────────────────
//
// const { isModalOpen, setModalOpen } = useModalsOpenStore();
//
// if (isModalOpen('foobar')) {
//   setModalOpen('foobar', false);
// }
//
// ─────────────────────────────────────────────────────────────────────────────
