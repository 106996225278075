import { Cta } from '__generated__/graphql';
import { Maybe } from '__generated__/graphql-sanity';

import { SLOT_LINK_TYPE } from './constants';
import { BannerLinkType } from './types';
// Used to check if the user is currently navigated to a path that the given link relates to.
// Will return a match if the current route is more specific than the link, but still begins with the same path.
// IE: link `/en/us/kids/boys` will return true for the url `/en/us/kids/boys/shoes`
// Used to highlight the link to show the user is currently viewing this category.
export const isUrlSegment = (
  link: { href: string },
  category: string | string[] | undefined
): boolean => {
  if (!link?.href || !Array.isArray(category)) return false;
  return category.join('/').startsWith(link.href.substring(1));
};

/**
 * Builds a URL with the given params.
 * @param url - The base URL to build from.
 * @param params - The params to append to the URL.
 * @example
 * buildUrl('/foo', { bar: 'baz' }) // => '/foo?bar=baz'
 */
export const buildUrl = (
  url: string,
  params: Record<string, string>
): string => {
  const urlWithParams = new URL(url);
  Object.keys(params).forEach(key =>
    urlWithParams.searchParams.append(key, params[key])
  );

  return urlWithParams.href;
};

export const getAccountRedirectLink = (
  asPath: string
): {
  asPathWithoutLocaleAndSearchParams: string;
  searchParams: string;
} => {
  if (asPath.includes('account')) {
    return {
      searchParams: '',
      asPathWithoutLocaleAndSearchParams: '',
    };
  }

  /**
   * Returns searchParams from the asPath while prefixing it with "&".
   * i.e. /in/en/something?foo=bar -> &foo=bar
   */
  const searchParams = asPath.includes('?') ? `&${asPath.split('?')[1]}` : '';

  /**
   * Returns asPath without the locale
   * i.e. /in/en/something?foo=bar -> something?foo=bar
   */
  const asPathWithoutLocale = asPath.split('/').splice(3).join('/');

  /**
   * Returns url without the searchParams.
   * i.e. /in/en/something?foo=bar -> in/en/something
   * and /in/en -> in/en
   */
  const rootPath = asPathWithoutLocale.split('?')[0];
  const asPathWithoutLocaleAndSearchParams = rootPath && `&from=${rootPath}`;

  return {
    searchParams,
    asPathWithoutLocaleAndSearchParams,
  };
};

// Returns the correct link for the constent slot banner, depending on whether it's a cta or a clickeable banner.

export const slotLink = (
  linkType?: string,
  ctas?: Maybe<Cta[]> | undefined,
  link?: BannerLinkType
) => {
  if (linkType === SLOT_LINK_TYPE.CTA && ctas && ctas[0]) return ctas[0];
  else return link;
};
