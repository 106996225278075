import { createContext, useContext } from 'react';

import { PublicEnvironmentConfig } from 'sites/types';
import {
  SUPPORTED_COUNTRY,
  SUPPORTED_LANGUAGE,
  SUPPORTED_LOCALE,
} from 'utils/constants';
import { FeatureFlags } from '__generated__/graphql';

export interface SiteConfigState extends PublicEnvironmentConfig {
  locale: SUPPORTED_LOCALE;
  country: SUPPORTED_COUNTRY;
  language: SUPPORTED_LANGUAGE;
  host: string;
  localizeUrlPath: (path: string) => string;
  localizeDate: (date: string | number | Date) => string;
  selectFeatureFlagsEnabled: (featureFlags: FeatureFlags) => string[];
}

export const SiteConfigContext = createContext<SiteConfigState>(
  {} as SiteConfigState
);

export const useSiteConfig = (): SiteConfigState => {
  return useContext(SiteConfigContext);
};
