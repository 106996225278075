import { QuiltLayout } from '@global-ecom/nitro-uds/elements';
import { UdsResponsiveProp } from '@global-ecom/nitro-uds/hooks';

import { CategoryDocument, CategoryDocumentType } from './Category';
import { PromoTileDocument, PromoTileDocumentType } from './PromoTileDocument';

export type TileMediaRatio =
  | UdsResponsiveProp<number | 'landscape' | 'wide' | 'square' | 'tall'>
  | undefined;

export type QuiltLayoutType = {
  gap: QuiltLayout['gap'];
  layout: QuiltLayout['layout'];
  limit: number;
};

export type QuiltDocumentType = {
  _id: string;
  _type: string;
  tileLayout: string;
  tileMediaRatio?: TileMediaRatio;
  mobile: QuiltLayoutType;
  tablet: QuiltLayoutType;
  desktop: QuiltLayoutType;
  inverted: boolean;
  contentJustify: 'start' | 'center' | 'end';
  items: (PromoTileDocumentType | CategoryDocumentType)[];
};

export const QuiltDocument = (field: string) => {
  return `${field} {
    _id,
    _type,
    tileLayout,
    tileMediaRatio,
    mobile,
    tablet,
    desktop,
    inverted,
    contentJustify,
    items[] -> {
      ${PromoTileDocument('_type == "PromoTile" =>')},
      ${CategoryDocument('_type == "category" =>')},
    },
  }`;
};
