import { Puid } from 'groq/global-types';
import {
  TileWithPositionDocumentType,
  TileWithPositionObject,
} from 'groq/objects/TileWithPosition';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/StyliticsCarousel.tsx

export type StyliticsCarouselDocumentType = {
  _id: string;
  _type: string;
  country: string;
  puid?: Maybe<Puid>;
  header?: Maybe<LocaleStringType>;
  numberOfTiles?: Maybe<number>;
  tags?: Maybe<Array<Maybe<string>>>;
  tileLayout?: Maybe<string>;
  tiles: Maybe<Array<TileWithPositionDocumentType>>;
};

export const StyliticsCarouselDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    country,
    puid,
    ${LocaleString('header')},
    numberOfTiles,
    tags,
    tileLayout,
    ${TileWithPositionObject('tiles[]')}
  }
`;
