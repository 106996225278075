import { parseCookies, destroyCookie } from 'nookies';

import { Update, Logger } from '.';

export const AUTH_COOKIE_NAMES_TO_DESTROY = [
  'country',
  'guest',
  'auth-token',
  'refresh-token',
  'customerId',
  'customerEmail',
  'impersonation',
  'uniqueShopperId',
];

export const deleteLegacyCookies: Update = {
  name: 'deleteLegacyCookies',
  test() {
    // only run this if there are auth cookies present
    const cookies = parseCookies();
    return !!cookies['auth-token'];
  },
  run(log: Logger) {
    // remove any existing auth cookies to from the root path scope
    const authCookieNames = AUTH_COOKIE_NAMES_TO_DESTROY;
    for (const name of authCookieNames) {
      log(`Removing ${name} from root scope`);
      destroyCookie(null, name, { path: '/' });
    }
    // need to force a reload to regenerate cookies in correct scope
    return true;
  },
};
