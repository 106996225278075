import { tw } from 'twind';

import { Link } from 'ui/elements/Link';

export const FooterLink = ({ linkStyle = 'text-base', href, ...props }) => {
  return (
    <Link
      role="menuitem"
      className={tw('font-normal hover:text-white', linkStyle)}
      href={href}
      {...props}
    />
  );
};
