import { ActiveCartItemProduct } from 'types/cart';
import { SiblingVariantsQuery } from '__generated__/graphql';

type VariantFromSearchHit = NonNullable<
  SiblingVariantsQuery['siblingVariants']
>[0];

export const getProductPrice = (
  product?: VariantFromSearchHit | ActiveCartItemProduct
) => product?.salePrice || product?.price || 0;
