import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

import { useDeviceInteraction } from 'hooks/useDeviceInteraction';

import { isServer } from '../../utils/constants';

const focusClassName = 'focus-visible';

export function addFocusVisible(container?) {
  (container || document.querySelector('body'))?.classList.add(focusClassName);
}

export function removeFocusVisible(container?) {
  (container || document.querySelector('body'))?.classList.remove(
    focusClassName
  );
}

export function isFocusVisible(container?) {
  return (container || document.querySelector('body'))?.classList.contains(
    focusClassName
  );
}

export function useFocusVisible<T extends HTMLElement>(
  ref?: React.RefObject<T | null | undefined>
) {
  const container = ref ? ref.current : !isServer && document.body;

  const { isKeyboardActive } = useDeviceInteraction();

  useIsomorphicLayoutEffect(() => {
    if (!container) return;

    const onFocus = () => {
      if (isKeyboardActive) {
        addFocusVisible(container);
      } else {
        removeFocusVisible(container);
      }
    };

    container.addEventListener('focusin', onFocus, true);

    return () => {
      container.addEventListener('focusin', onFocus, true);
    };
  }, [container, isKeyboardActive]);
}
