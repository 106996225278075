import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { UseQueryResponse, gql, useQuery } from 'urql';

import { QueryContentArgs } from '__generated__/graphql';
import { GroqOperationNames } from 'groq/GroqOperationNames';
import { isInitialClientRender } from 'utils/isInitialClientRender';
import { Maybe } from 'utils/types';

import { useAuth } from './useAuth';

export type GroqQuery<T> = string | { ['T']: T };

export const CONTENT_QUERY = (operationName: string) => gql`
  query Content_${operationName}($input: ContentInput!) {
    content(input: $input)
  }
`;

export type ContentResponse<TResult> = { content?: TResult | null };
export type GroqQueryProps<TKey> = {
  query: string;
  variables?: any;
  pause?: boolean;
  /**
   * Give this operation a unique name.
   * You must also extend the GroqOperationNames interface with this new name.
   * */
  operationName: TKey;
};

export type GroqPreviewInput = {
  abTest?: Maybe<string>;
  abTestSegment?: Maybe<string>;
  customerGroups?: Maybe<string>;
  global?: Maybe<string>;
  viewAt?: Maybe<string>;
};

export const groqPreviewInput = (routerQuery: GroqPreviewInput) => {
  return {
    viewAt: routerQuery.viewAt,
    customerGroups: routerQuery.customerGroups?.split(','),
    global: routerQuery.global === 'true',
  };
};

export const useGroqQuery = <
  TKey extends keyof GroqOperationNames,
  TResult = GroqOperationNames[TKey]
>(
  props: GroqQueryProps<TKey>
): UseQueryResponse<ContentResponse<TResult>, QueryContentArgs> => {
  const router = useRouter();
  const auth = useAuth();

  const [result, refetch] = useQuery<
    ContentResponse<TResult>,
    QueryContentArgs
  >({
    query: CONTENT_QUERY(props.operationName),
    variables: {
      input: {
        query: props.query.replace(/[\n]+/g, '').replace(/\s\s+/g, ''),
        params: props.variables,
        src: router.query.src as string,
        draft: router.query.draft as string,
        preview: groqPreviewInput(router.query),
      },
    },
    pause: props.pause,
  });

  useEffect(() => {
    // Refetch this query on the client within the context of a customer
    // if this is the first render after SSR (which is always a guest)
    if (isInitialClientRender(router) && !auth.guest) {
      // Has to be within a timeout until we upgrade URQL to version ^3.0.0
      refetch({ requestPolicy: 'network-only' });
    }
    // We _really_ only want to run this on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [result, refetch];
};
