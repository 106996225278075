import { Maybe } from 'utils/types';
import { ProductObjectType } from 'groq/global-types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/ProductComparison.tsx

type ComparisonFieldsType = {
  [key: string]: string;
};

export type ProductComparisonDocumentType = {
  id: string;
  _id: string;
  _type: string;
  header?: Maybe<string>;
  comparisonFields: Array<string>;
  products: Array<
    ProductObjectType & { comparisonFields: ComparisonFieldsType }
  >;
};

export const ProductComparisonDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    header,
    comparisonFields,
    products,
  }
`;
