import { isServer } from 'utils/constants';

import { deleteLegacyCookies } from './deleteLegacyCookies';
import { deleteLegacyRegionalAuthCookies } from './deleteLegacyRegionalAuthCookies';

export type Logger = (...args: any[]) => void;

export type Update = {
  name: string;
  test: (logger: Logger) => boolean;
  run: (logger: Logger) => boolean;
};

// This file exists as a framework for running single-use code to repair bad data or to prepare for changes
// to the application which require legacy data to be altered in some way. This code will handle the one-time
// execution of these repair scripts.
//
// Updates are to be added to the `updates` array below, and each update will be an object with two methods:
// test(): boolean - checks whether it is safe to run the update (in case it had previously run and is not idempodent)
// run(): boolean - performs the update, and returns true if it requires the page to be reloaded

const updates: Update[] = [
  deleteLegacyCookies,
  deleteLegacyRegionalAuthCookies,
  // update 2....
  // update 3...
  // ...etc
];

export const runUpdates = () => {
  if (isServer) return;

  let lastUpdateNumber = Number(window.localStorage.getItem('update'));
  if (Number.isNaN(lastUpdateNumber)) lastUpdateNumber = 0;

  let shouldReload = false;

  for (let i = lastUpdateNumber; i < updates.length; i += 1) {
    const update = updates[i];
    const log: Logger = (...args: any[]) => {
      // eslint-disable-next-line no-console
      console.log(`[UPDATE ${i}: ${update.name}]`, ...args);
    };
    if (update.test(log)) {
      shouldReload = shouldReload || update.run(log);
    }
  }

  window.localStorage.setItem('update', updates.length.toString());
  return shouldReload;
};
