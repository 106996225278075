import { inStates } from 'sites/states';
import { AddressFormValues } from 'ui/forms/CheckoutAddressForm';

import { GUAM, SUPPORTED_COUNTRY } from './constants';

export type TransformedPlacesResult = Partial<
  Pick<
    AddressFormValues['shippingAddress'],
    | 'address1'
    | 'address2'
    | 'city'
    | 'postalCode'
    | 'stateCode'
    | 'countryCode'
    | 'streetNumber'
    | 'companyName'
  >
> & { street?: string };
const getCity = (address: google.maps.GeocoderAddressComponent[]) => {
  let city: google.maps.GeocoderAddressComponent | undefined;

  // * The desired value for 'city' can sit in any of these three types
  ['postal_town', 'locality', 'sublocality_level_1'].forEach(specificity => {
    if (!city) {
      city = getComponent(address, specificity);
    }
  });

  return city;
};

const getComponent = (
  address: google.maps.GeocoderAddressComponent[],
  component: string
) => address.find(comp => comp.types.includes(component));

export const transformPlaces = (
  googlePlaceResult: google.maps.places.PlaceResult
): TransformedPlacesResult => {
  const address = googlePlaceResult.address_components;
  if (!address) return {};
  // * Google Maps API does not return 'postal_code' for addresses in Guam
  const postalCode = getComponent(address, 'postal_code')?.long_name;
  const street = getComponent(address, 'route')?.long_name;
  const streetNumber = getComponent(address, 'street_number')?.long_name;
  const premise = getComponent(address, 'premise')?.long_name;
  const countryCode = getComponent(address, 'country')?.short_name;
  const subpremise = getComponent(address, 'subpremise')?.long_name;
  let companyName;
  if (countryCode === SUPPORTED_COUNTRY.GB)
    companyName =
      googlePlaceResult?.name ||
      getComponent(address, 'subpremise')?.long_name ||
      premise;
  else companyName = subpremise;
  const locality = getComponent(address, 'sublocality_level_1')?.long_name;
  const city = getCity(address)?.long_name;
  const stateCode = getComponent(
    address,
    'administrative_area_level_1'
  )?.short_name;

  const address1 = () => {
    if (streetNumber && street) return `${streetNumber} ${street}`;
    // there are no street details for some India locations
    // so adding these as fallback
    // if there's really nothing usable, return the place name
    if (premise && locality) return `${premise} ${locality}`;
    // when postcode lookup is enabled, place name will default to the postcode
    // In that case we don't want to return an address1
    if (googlePlaceResult.name && googlePlaceResult.name !== postalCode)
      return googlePlaceResult.name;
  };

  const getCorrectedStateCode = () => {
    if (!stateCode) return;

    if (countryCode === GUAM) return GUAM;

    if (countryCode === SUPPORTED_COUNTRY.IN) {
      return getIndiaSalesforceStateCode(stateCode);
    }

    return stateCode;
  };

  const getCorrectedCountryCode = () => {
    if (countryCode === GUAM) return SUPPORTED_COUNTRY.US;

    return countryCode;
  };

  return {
    address1: address1(),
    address2: companyName,
    city,
    postalCode,
    stateCode: getCorrectedStateCode(),
    countryCode: getCorrectedCountryCode(),
    streetNumber,
    street,
    companyName,
  };
};

/** Salesforce uses non-standard state codes for India. This takes the
 * `ISO 3166-2:IN` state code and returns the Salesforce state code. */
const getIndiaSalesforceStateCode = (stateCode: string) => {
  const mappedState = inStates.find(
    state => stateCode === state.isoCompliantValue
  );

  return mappedState?.value ?? stateCode;
};
