import { gql } from 'urql';

export const WISHLIST_FRAGMENT = gql`
  fragment wishListFields on WishList {
    id
    items {
      __typename
      id
      quantity
      creationDate
      product {
        id
        name
        header
        subHeader
        masterId
        orderable
        inventory
        displayOutOfStock {
          displayValue
          presale
          validTo
        }
        price
        salePrice
        productPrice {
          price
          salePrice
          promotionPrice
          isSalePriceElapsed
        }
        images {
          href
          alt
        }
        styleNumber
        colorValue
        colorName
        size {
          label
          value
        }
        offline
        isAppExclusive
        appOnlyDateTimeFrom
        appOnlyDateTimeTo
      }
    }
  }
`;
