import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import {
  Order,
  useCreateContactMutation,
  ContactInput,
} from '__generated__/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { NewsletterQueryParams } from 'utils/querystring';

const CONTACT_SOURCE_CHECKOUT = 'Checkout';
const CONTACT_SOURCE_SIGN_UP = 'Account Sign Up';

export const useEmailNotification = () => {
  const [, createContact] = useCreateContactMutation();
  const { newsletter } = useSiteConfig();
  const [checkOut, signUp] = useMemo(() => {
    if (newsletter) {
      return [
        newsletter.contactSources.checkOut,
        newsletter.contactSources.signUp,
      ];
    }
    return [CONTACT_SOURCE_CHECKOUT, CONTACT_SOURCE_SIGN_UP];
  }, [newsletter]);
  const router = useRouter();

  const createCheckoutContact = React.useCallback(
    async (order: Order) => {
      if (order.customer?.id && order.customer?.email) {
        await createContact({
          input: {
            contact: {
              contactSource: checkOut,
              firstName: order.billingAddress?.firstName,
              lastName: order.billingAddress?.lastName,
              firstNamePronunciation:
                order.billingAddress?.firstNamePronunciation,
              lastNamePronunciation:
                order.billingAddress?.lastNamePronunciation,
              email: order.customer?.email,
              emailPreference:
                router.query[NewsletterQueryParams.EmailList] === 'true'
                  ? 'optIn'
                  : 'optOut',
              address: order.billingAddress?.address1,
              phoneNumber: order.billingAddress?.phone,
              postalCode: order.billingAddress?.postalCode,
              city: order.billingAddress?.city,
            },
          },
        });
      }
    },
    [createContact, checkOut, router.query]
  );

  const createSignupContact = React.useCallback(
    async (
      data: Required<
        Pick<
          ContactInput,
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'firstNamePronunciation'
          | 'lastNamePronunciation'
          | 'dob'
          | 'smsOptIn'
          | 'phoneNumber'
        >
      >
    ) => {
      await createContact({
        input: {
          contact: {
            contactSource: signUp,
            emailPreference: 'optIn',
            ...data,
          },
        },
      });
    },
    [createContact, signUp]
  );

  return {
    createCheckoutContact,
    createSignupContact,
  };
};
