import { tw } from 'twind';

import { Img, ImgProps } from 'ui/components/Img';
import {
  ResponsiveImageSizes,
  getResponsiveImageSrcSet,
  getResponsiveImageSizes,
  addWidthTransform,
} from 'utils/image';

interface ResponsiveImageProps extends Omit<ImgProps, 'sizes'> {
  src: string;
  widths: number[];
  isVertical?: boolean;
  sizes: ResponsiveImageSizes;
}

export const ResponsiveProductImage = ({
  src,
  widths,
  sizes: responsiveSizes,
  isVertical,
  ratio,
  className,
  ...rest
}: ResponsiveImageProps) => {
  // Vertical product images do not have a transform parameter in the url
  const finalSrc = isVertical ? addWidthTransform(src) : src;

  // Cloudinary product images specify their size via a transform parameter e.g. w_600,h_600
  const originalImageWidth = finalSrc.match(/w_(\d*)[,\/]/)?.[1] || '2000';

  const smallestWidthSrc = finalSrc.replace(
    new RegExp(originalImageWidth, 'g'),
    widths[0].toString()
  );

  const srcSet = getResponsiveImageSrcSet(finalSrc, widths, originalImageWidth);
  const sizes = getResponsiveImageSizes(responsiveSizes);

  const verticalRatio = '2 / 3';
  const imgRatio = isVertical ? verticalRatio : ratio;
  const imgClassName = tw(className, isVertical && 'object-contain');

  return (
    <Img
      {...rest}
      src={smallestWidthSrc}
      srcSet={srcSet}
      sizes={sizes}
      className={imgClassName}
      ratio={imgRatio}
      title={rest.alt}
    />
  );
};
