import { LocaleSimplePortableText } from '__generated__/graphql-sanity';
import {
  FAQModuleDocument,
  FAQ_ITEM_PORTABLE_TEXT_FIELD,
} from 'groq/documents/FAQModuleDocument';
import { CallToActionObject } from 'groq/objects/CallToActionObject';
import { SanityImageObject } from 'groq/objects/SanityImageObject';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/portableText.ts

export type LocalePortableTextType = LocaleSimplePortableText;

export const LocalePortableText = field => `
  "${field}": coalesce(
    ${field}[$locale],
    ${field}.en_US,
    ${field}.en_GB,
    ${field},
  )[] {
    ...,
    markDefs[]{
      _key,
      _type,
      id,
      "href": coalesce(
        @.href,
        ''
      ),
      defined(_ref) => @-> {
        "href": coalesce(
          @.urls[$locale],
          @.urls[$language],
          @.url.current,
          @.url,
          ''
        )
      }
    },
    ${CallToActionObject("_type == 'cta' =>")},
    ${SanityImageObject("_type == 'image' =>")},
    ${
      field === FAQ_ITEM_PORTABLE_TEXT_FIELD
        ? ''
        : FAQModuleDocument("_type == 'FaqModule' => @-> ")
    }
  }
`;
