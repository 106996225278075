import React from 'react';
import { tw } from 'twind';

interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
  bleed?: boolean;
  gutter?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  className,
  bleed = false,
  gutter = true,
  ...props
}) => {
  return (
    <div
      className={tw([
        `relative w-full`,
        className,
        bleed
          ? 'w-screen left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] px-0 sm:px-0'
          : 'max-w-screen-3xl mx-auto',
        !bleed && gutter && 'px-4 sm:px-8',
      ])}
      data-bleed={bleed}
      {...props}
    />
  );
};
