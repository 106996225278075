import { useState } from 'react';

import { AnalyticsCustomEvent, AnalyticsEvents, event } from 'utils/analytics';

type Kind =
  | 'parent'
  | 'child'
  | 'grandchild'
  | 'greatgrandchild'
  | 'quick-link';

export type AppendNavEventCategoryArgs = {
  category: string;
  link?: string;
  kind?: Kind | null;
};

type Cases<T extends string> = {
  [key in T]?: () => void;
};
type SwitchFnArgs<T extends string> = Cases<T> & {
  default: () => void;
};

export const useMainNavAnalytics = () => {
  const [userClicks, setUserClicks] = useState<string[]>([]);

  const buildParentCategory = (category: string) => [category, category]; // [Women, Women] or [Men, Men], etc
  const buildQuickLinkCategory = (category: string) => ['QuickLink', category]; // [QuickLink,Login] or [QuickLink, My Account], etc
  const buildChildrenCategory = (categories: string[]) => [
    'Shop',
    ...categories,
  ]; // [Shop,Men,Shoes,Basketball]
  const formatClickPath = (categories: string[]) => categories.join(':'); // Women:Women, QuickLink:Login, Kid:Girls:Shoes:Classics etc.

  const resetUserClicks = () => setUserClicks([]);

  const fireEvent = (categories: string[], linkClicked: string) => {
    event(AnalyticsEvents.HEADER_LINK_CLICKED, {
      parentNavigation: formatClickPath(categories),
      linkClicked,
    });
    AnalyticsCustomEvent({
      event_name: AnalyticsEvents.NAVIGATION_CLICK,
      user_action: 'header',
      nav_path: formatClickPath(categories).toLowerCase(),
    });
    resetUserClicks();
  };

  function switchFn<T extends string>(
    testCase: T | null,
    funnels: SwitchFnArgs<T>
  ) {
    if (!testCase) return;
    const callback = funnels[testCase];
    callback ? callback() : funnels.default();
  }

  const appendCategory = ({
    category,
    link = '',
    kind = null,
  }: AppendNavEventCategoryArgs) => {
    setUserClicks(prev => [...prev, category]);
    return new Promise<void>(resolve => {
      switchFn<Kind>(kind, {
        parent: () => fireEvent(buildParentCategory(category), link),
        ['quick-link']: () => fireEvent(buildQuickLinkCategory(category), link),
        default: () =>
          fireEvent(buildChildrenCategory([...userClicks, category]), link),
      });
      resolve();
    });
  };

  const removePreviousClick = () =>
    setUserClicks(prev => {
      prev.pop();
      return [...prev];
    });

  return { appendCategory, removePreviousClick };
};
