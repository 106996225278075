type ImageLoaderPropsType = {
  src: string;
  width: number;
  quality?: number;
};

export function sanityImageLoader({
  src,
  width,
  quality,
}: ImageLoaderPropsType) {
  const url = new URL(src);

  url.searchParams.set('auto', 'format');
  url.searchParams.set('w', width.toString());

  if (quality) {
    url.searchParams.set('q', quality?.toString());
  }

  return url.href;
}

export function cloudinaryImageLoader({
  src,
  width,
  quality,
}: ImageLoaderPropsType) {
  let href = src
    .replace(/w_\d+/, `w_${width.toString()}`)
    .replace(/h_\d+/, `h_${width.toString()}`);

  if (quality) {
    href = href.replace(/q_\d+|q_auto/, `q_${quality.toString()}`);
  }

  return href;
}
