import { authExchange } from '@urql/exchange-auth';
import { NextPageContext } from 'next';

import { getLocaleFromRequest } from 'utils/locale';
import { CREATE_SHOPPER_CONTEXT_MUTATION } from 'gql/mutations/account';
import { clientLogger } from 'utils/clientLogger';

import { authStateFromPayload, AuthState } from './authState';
import { getRequestHeaders } from './headers';
import { getGuestToken, isTokenExpired } from './token';
import { getOperationName, hasGraphQLAuthError } from './utils';

const tokenCache: Record<string, AuthState> = {};

// the NextPageContext request object is an IncomingMessage type
// which doesn't have the `query` property type associated with it,
// even though the data is there, and so we need to extend the type
// to make typescript happy with access to it
type IncomingMessageWithQuery = NextPageContext['req'] & {
  query: { [key: string]: string };
};

export const createSSRAuthExchange = (ctx: NextPageContext) =>
  authExchange(async utils => {
    const locale = getLocaleFromRequest(ctx.req!);
    const srcCode = (ctx.req! as IncomingMessageWithQuery).query?.src ?? '';

    // we will be setting shopper context for SSR which contain
    // a src code querystring, and so the cache key needs to be
    // unique between src codes.
    const tokenCacheKey = srcCode ? `${locale}_${srcCode}` : locale;

    return {
      addAuthToOperation(operation) {
        const authState = tokenCache[tokenCacheKey];
        const operationName = getOperationName(operation);

        // setup request headers
        const headers = getRequestHeaders(operationName, authState);
        return utils.appendHeaders(operation, headers);
      },
      willAuthError() {
        const authState = tokenCache[tokenCacheKey];
        if (!authState?.accessToken) {
          return true;
        }

        return isTokenExpired(authState.accessToken);
      },
      didAuthError: hasGraphQLAuthError,
      async refreshAuth() {
        try {
          /**
           * In SSR, we always generate a GUEST token from SFCC
           * so that all SSR cached data will only contain anonymous
           * guest user information and not targeted to any single
           * customer or customer group.
           */
          const token = await getGuestToken({ mutate: utils.mutate });
          const authState = authStateFromPayload(token);
          tokenCache[tokenCacheKey] = authState;

          if (srcCode) {
            await utils.mutate(CREATE_SHOPPER_CONTEXT_MUTATION, {
              input: { sourceCode: srcCode },
            });
          }
        } catch (err) {
          clientLogger.error(
            { err },
            'authExchange: [ssr] failed to refreshAuth'
          );
        }
      },
    };
  });
