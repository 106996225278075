import Script from 'next/script';
import { createContext, useContext, useRef, useState } from 'react';

import { isServer } from 'utils/constants';
import { RadialFrontendContext } from '__generated__/graphql';

import { useSiteConfig } from './useSiteConfig';
import { useMutationObserver } from './useMutationObserver';

export const RADIAL_FINGERPRINT_INPUT_ID = 'RDFUID';

type RadialContextValue = {
  radialFingerPrint: string | null;
  radialFrontendContext: RadialFrontendContext | null;
};

const RadialContext = createContext<RadialContextValue>(
  {} as RadialContextValue
);

type RadialProviderProps = {
  children: React.ReactNode;
};

export const RadialProvider = ({ children }: RadialProviderProps) => {
  const { env, locale } = useSiteConfig();

  /*
   *  radial fingerprint
   */
  const [radialFingerPrint, setRadialFingerPrint] = useState<string | null>(
    () => {
      if (isServer) return null;
      return window.sessionStorage.getItem(RADIAL_FINGERPRINT_INPUT_ID) || null;
    }
  );
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  useMutationObserver(hiddenInputRef, mutationList =>
    mutationList.forEach(mutation => {
      if (mutation.attributeName !== 'value') return;
      const { id, value } = mutation.target as HTMLInputElement;
      if (id === RADIAL_FINGERPRINT_INPUT_ID) {
        setRadialFingerPrint(value);
        window.sessionStorage.setItem(RADIAL_FINGERPRINT_INPUT_ID, value);
        hiddenInputRef.current?.remove();
      }
    })
  );

  /*
   *  radial frontend context
   */
  const radialFrontendContext = isServer
    ? null
    : {
        HostName: window.location.hostname,
        UserAgent: window.navigator.userAgent,
        Language: locale,
      };

  return (
    <RadialContext.Provider
      value={{ radialFingerPrint, radialFrontendContext }}
    >
      {env.radialFingerprint && env.radialStoreId && (
        <>
          <Script
            src={`${env.radialFingerprint}?clientIdentifier=${env.radialStoreId}`}
            strategy="lazyOnload"
          />
          <input
            ref={hiddenInputRef}
            type="hidden"
            id={RADIAL_FINGERPRINT_INPUT_ID}
            data-test-id={RADIAL_FINGERPRINT_INPUT_ID}
            hidden
          />
        </>
      )}
      {children}
    </RadialContext.Provider>
  );
};

export const useRadial = () => useContext(RadialContext);
