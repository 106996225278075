import React, { ReactNode } from 'react';

import { TransType } from 'hooks/useTranslations';

/**
 * Represents all supported templates, and the variables that they require.
 */
export type TranslationVariablesTypes = {
  smsOptInTermsAndConditionsView: {
    termsLink(text: string): ReactNode;
    privacyLink(text: string): ReactNode;
  };
  notificationPreferencesPhoneDisclaimer: {
    termsLink(text: string): ReactNode;
    privacyLink(text: string): ReactNode;
  };
};

/**
 * Translates text, allowing the injection of JSX elements.
 *
 * @example
 * With a template like:
 *   viewTermsAndPrivacy: 'View {termsLink("Terms")} and {privacyLink("Privacy")}.'
 * You can execute this template via:
 *   richTranslate(t, 'viewTermsAndPrivacy', {
 *     termsLink: (text) => <LinkText href="/terms" label={text} />,
 *     privacyLink: (text) => <LinkText href="/privacy" label={text} />,
 *   });
 *
 * @param t - The translate function that comes from the `useTranslate` hook
 * @param translationKey - The translation key, which should contain a translation template
 * @param variables - The variables to inject into the translation template
 */
export function richTranslate<TKey extends keyof TranslationVariablesTypes>(
  t: TransType,
  translationKey: keyof TranslationVariablesTypes,
  variables: TranslationVariablesTypes[TKey]
): ReactNode {
  const translated = t(translationKey);
  return executeTemplate(translated, variables);
}

export type TemplateVariables = Record<
  string,
  ReactNode | ((...args: string[]) => ReactNode)
>;

export function executeTemplate(
  template: string,
  variables: TemplateVariables
): ReactNode {
  const execute = parseTemplate(template);
  return execute(variables);
}

type ParsedTemplate = (variables: TemplateVariables) => ReactNode;

/**
 * Parses the template into an executable function
 */
function parseTemplate(text: string): ParsedTemplate {
  const segments: Array<string | ParsedTemplate> = [];

  // Finds all {expressions} in the template
  const templatePattern = /\{([^}]+)}/g;

  // Build the template results:
  let m: RegExpExecArray | null;
  let lastIndex = 0;
  while ((m = templatePattern.exec(text))) {
    // Add literal text:
    if (lastIndex < m.index) {
      const literalText = text.substring(lastIndex, m.index);
      segments.push(literalText);
    }

    // Add the result of the expression:
    const expression = m[1];
    segments.push(parseExpression(expression));

    lastIndex = templatePattern.lastIndex;
  }

  // Grab the final literal text:
  if (lastIndex !== text.length) {
    segments.push(text.substring(lastIndex, text.length));
  }

  return function executeTemplate(variables: TemplateVariables) {
    const children = segments.map(seg => {
      return typeof seg === 'function' ? seg(variables) : seg;
    });

    return React.createElement(React.Fragment, null, ...children);
  };
}

/**
 * Parses an expression into an executable function.
 * An expression is the text inside the {template} tags.
 *
 * This function is extremely basic, it only supports:
 * - A simple key, eg. {key}
 * - A function call, eg. {key()}
 * - A function call with primitive args, eg. {key("Text")}
 */
function parseExpression(expression: string): ParsedTemplate {
  const [_, key, args] = expression.match(/^(.+?)(?:[(](.*)[)])?$/)!;
  // Args can only be JSON values, usually strings:
  const parsedArgs = args ? JSON.parse(`[${args}]`) : [];

  return function executeExpression(variables: TemplateVariables) {
    let value = variables[key];
    if (typeof value === 'function') {
      value = value(...parsedArgs);
    }
    return value;
  };
}
