import React from 'react';
import { Link } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useCart } from 'hooks/useCart';

import { ItemCountBadge } from './ItemCountBadge';

export const CartLink = (props: {
  darkMode: boolean;
  onClick?: () => void;
}) => {
  const t = useTranslate();

  const { cart } = useCart();
  const count = cart?.productCount || 0;

  return (
    <div className="relative">
      <Link
        href="/cart"
        className="relative"
        id="nav-cart-link"
        dataTestId="nav-cart-link"
        onClick={props.onClick}
        label={`${t('shoppingCart')} ${count}`}
        icon="cart"
        mode="icon"
        variant="ghost"
        invert={props.darkMode}
      />
      <ItemCountBadge float count={count} dataTestId="nav-cart-tally-badge" />
    </div>
  );
};
