import React from 'react';
import dynamic from 'next/dynamic';

import Modal from 'ui/components/Modal';
import { useSiteConfig } from 'hooks/useSiteConfig';

const LocationSelector = dynamic(
  () => import('../ui/components/LocationSelector'),
  { ssr: true }
);

export type Location = {
  userCountry?: {
    id: string;
    name: string;
    url: string;
  };
  siteCountry: {
    id: string;
    name: string;
  };
  selecting?: boolean;
};

export type LocationType = {
  location: Location;
  setLocation: (x: Location) => void;
};

export const LocationContext = React.createContext<LocationType>(
  {} as LocationType
);

export const useLocation = (): LocationType =>
  React.useContext(LocationContext);

export const LocationContextProvider: React.FC = ({ children }) => {
  const { countryCode, countryName } = useSiteConfig();
  const [location, setLocation] = React.useState<Location>({} as Location);

  React.useEffect(() => {
    fetch('/api/getUserLocation')
      .then(res => res.json())
      .then(data => {
        // in local development, the location data is stored in the "data" property,
        // but in deployed environments, fastly defers to a different endpoint
        // which returns location data in the "data.location" property
        //
        // the following just checks both places for the value
        const userCountry = data.location?.userCountry ?? data.userCountry;
        setLocation({
          userCountry,
          siteCountry: {
            id: countryCode,
            name: countryName,
          },
        });
      });
  }, [countryCode, countryName]);

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
      {location?.selecting ? (
        <Modal
          variant="dark"
          onClickOutside={() => setLocation({ ...location, selecting: false })}
          className="max-w-md w-full min-h-screen"
          dataTestId="location-selector"
        >
          <LocationSelector />
        </Modal>
      ) : (
        // Created to render LocationSelector in the DOM for SEO purposes
        <LocationSelector isHidden={true} />
      )}
    </LocationContext.Provider>
  );
};
