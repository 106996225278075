import { FC, useRef } from 'react';
import { useRouter } from 'next/router';

import { Ga4Data } from 'hooks/usePromotionSelect';
import { CallToActions } from 'ui/content/CallToActions';
import { Markdown } from 'ui/elements/Markdown';
import { Container } from 'ui/elements/LayoutContainer';
import { Link } from 'ui/elements/Link';
import { VideoOrImage } from 'ui/components/VideoOrImage';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { FeatureHeroDocumentType } from 'groq/documents/FeatureHeroDocument';
import { SanityImage } from 'ui/components/SanityImage';

import { ContentHeading, ManageHeadingTag } from './ContentHeading';

export const FeatureHero: FC<
  FeatureHeroDocumentType & {
    headingTag?: ManageHeadingTag;
  }
> = props => {
  const { asPath } = useRouter();
  const promotionId = props._id || '';
  const promotionName = props.header || '';
  const creativeName = props._type || 'FeatureHero';

  const featureHeroRef = useRef(null);

  const gaTrackData: GaTrackData = {
    id: promotionId,
    name: promotionName,
    creative: creativeName,
  };

  const extendedGaTrackData = {
    ...gaTrackData,
    cta_links:
      props.ctas && props.ctas.length > 0 ? props.ctas.map(c => c.link) : [],
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    featureHeroRef,
    extendedGaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name: creativeName,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id: promotionId,
    promotion_name: promotionName,
    link_url: props.ctas && props.ctas.length > 0 ? props.ctas[0].link : '',
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <section
      className="sm:pb-10 relative"
      data-test-id="feature-hero-section"
      ref={featureHeroRef}
    >
      <Container
        bleed
        className={`absolute h-4/5 top-10 hidden sm:block bg-[${props.backgroundColor}]`}
      />
      <div className="grid sm:grid-cols-2">
        <div className="relative pt-96 sm:hidden">
          <VideoOrImage
            image={props.optionalPrimaryImage}
            video={props.primaryVideo}
          />
        </div>

        <div className="relative z-10">
          <div className="relative flex flex-col items-center sm:items-start mt-8 mb-10 sm:mb-12 sm:mt-16">
            {props.logo?.asset?.url && (
              <SanityImage
                className="h-24 w-max mobile:m-auto"
                source={props.logo}
                relative
              />
            )}
            <div
              className={`relative z-10 text-center text-[${props.textColor}] sm:text-left px-4 sm:px-0 pt-0 lg:pt-6 sm:pr-16 lg:pr-32`}
            >
              {props.header && (
                <ContentHeading
                  header={props.header}
                  className={'text-4xl lg:text-5xl font-bold leading-tight'}
                  headingTag={props.headingTag}
                />
              )}
              {props.copy && (
                <p className="text-xl lg:text-2xl leading-tight">
                  <Markdown content={props.copy} />
                </p>
              )}
              {props.footnote && (
                <p className="text-sm mt-2">
                  <Markdown content={props.footnote} />
                </p>
              )}

              {props.ctas && (
                <div className="relative flex flex-wrap justify-center sm:justify-start flex-gap-3 md:flex-gap-4 mt-4">
                  <CallToActions ctas={props.ctas} ga4Data={ga4Data} />
                </div>
              )}
            </div>
          </div>
          <div className="relative pt-56 lg:pt-96">
            <VideoOrImage
              image={props.optionalSecondaryImage}
              video={props.secondaryVideo}
            />
          </div>
          {props.ctas && props.ctas[0] && (
            <Link
              query={props.ctas[0].query}
              newtab={!!props.ctas[0].newtab}
              href={props.ctas[0].link}
              className="absolute inset-0 h-full w-full"
              aria-hidden
              tabIndex={-1}
              ga4Data={ga4Data}
            />
          )}
        </div>
        <div className="relative pt-96 -ml-10 -mb-10 z-0 hidden sm:block">
          <VideoOrImage
            image={props.optionalPrimaryImage}
            video={props.primaryVideo}
          />
          {props.ctas && props.ctas[0] && (
            <Link
              query={props.ctas[0].query}
              newtab={!!props.ctas[0].newtab}
              href={props.ctas[0].link}
              className="absolute inset-0 h-full w-full"
              aria-hidden
              tabIndex={-1}
            />
          )}
        </div>
      </div>
    </section>
  );
};
