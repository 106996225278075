import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useCart } from 'hooks/useCart';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { dySyncCartEvent } from 'utils/dynamicYield';

import { useDynamicYieldContext } from './useDynamicYieldContext';
import { useOnRouterChangeStart } from './useOnRouterChangeStart';

export type PageEventsContextValue = {
  pageviewEventHasFired: boolean;
  setPageviewEventHasFired: (hasFired: boolean) => void;
  cartEventFired: boolean;
  setCartEventFired: (hasFired: boolean) => void;

  eventHasFired: (eventName: string) => boolean;
  setEventFired: (eventName: string) => void;
  setEventNotFired: (eventName: string | RegExp) => void;

  pixleeProductDiscovery: boolean;
  setPixleeProductDiscovery: (isProduct: boolean) => void;

  userConsent: boolean;
  setUserConsent: (eventTriggered: boolean) => void;
};

export const PageEventsContext = createContext<PageEventsContextValue>(
  {} as PageEventsContextValue
);

type PageEventsProviderProps = {
  children: React.ReactNode;
};

export const PageEventsProvider = ({ children }: PageEventsProviderProps) => {
  const router = useRouter();

  const [pageviewEventHasFired, setPageviewEventHasFired] = useState(false);
  const [cartEventFired, setCartEventFired] = useState<boolean>(false);
  const [firedEvents, setFiredEvents] = useState<string[]>([]);
  const [pixleeProductDiscovery, setPixleeProductDiscovery] = useState(false);
  const [userConsent, setUserConsent] = useState(false);

  const { cart } = useCart();
  const { currency, staticFeatures } = useSiteConfig();
  const previousCartRef = useRef(cart?.products);
  const previousPathnameRef = useRef('');
  const [eventTriggered, setEventTriggered] = useState(false);

  useDynamicYieldContext();
  useOnRouterChangeStart(
    useCallback(() => {
      // only reset the page view if we actually change page; i.e.,
      // disregard any querystring changes
      setPageviewEventHasFired(false);
      setFiredEvents([]);
    }, [setPageviewEventHasFired, setFiredEvents])
  );

  const setEventFired = (eventName: string) =>
    setFiredEvents(curr => Array.from(new Set<string>([...curr, eventName])));

  const setEventNotFired = (eventName: string | RegExp) => {
    setFiredEvents(curr =>
      curr.filter(x => {
        return typeof eventName === 'string'
          ? x !== eventName
          : !eventName.test(x);
      })
    );
  };

  const eventHasFired = (eventName: string) =>
    firedEvents.some(x => x === eventName);

  useEffect(() => {
    if (!staticFeatures?.injectDynamicYieldScripts) return;

    const previousCart = previousCartRef.current;
    const currentCartProducts = cart?.products ?? [];

    const cartContentsMatch =
      JSON.stringify(previousCart) === JSON.stringify(currentCartProducts);
    const pathnameChanged = previousPathnameRef.current !== router.asPath;

    if (cartContentsMatch && !pathnameChanged) {
      setEventTriggered(false);
    } else {
      if (eventTriggered) return;

      if (cart === null || cart) {
        dySyncCartEvent(currentCartProducts, currency);
        setEventTriggered(true);
      }

      previousPathnameRef.current = router.asPath;
    }

    previousCartRef.current = currentCartProducts;
  }, [
    cart,
    currency,
    eventTriggered,
    staticFeatures?.injectDynamicYieldScripts,
    router.asPath,
  ]);

  return (
    <PageEventsContext.Provider
      value={{
        setPageviewEventHasFired,
        pageviewEventHasFired,
        setEventFired,
        setEventNotFired,
        eventHasFired,
        pixleeProductDiscovery,
        setPixleeProductDiscovery,
        setUserConsent,
        userConsent,
        setCartEventFired,
        cartEventFired,
      }}
    >
      {children}
    </PageEventsContext.Provider>
  );
};

export const usePageEventsContext = () => useContext(PageEventsContext);
