import { tw } from 'twind/style';

import { SizeChart } from '__generated__/graphql';
import { SafeHtml } from 'ui/elements/SafeHtml';

import { sizeGuideStyles } from './shared';

type SizeTableProps = {
  title: string;
  description?: string;
  data: string[][];
  hidden?: boolean;
  children?: React.ReactNode;
  measurementMessages?: SizeChart[];
  showMetricAsDefault?: boolean;
};

const getMeasurementMessages = (
  messages: SizeChart[],
  id: string,
  className?: string
) =>
  messages.map(message =>
    message.id === id ? (
      <SafeHtml
        key={message.id}
        html={message.body}
        className={tw(`${className}`)}
      />
    ) : null
  );

export const SizeTable = ({
  title,
  description,
  data,
  hidden = false,
  children,
  measurementMessages,
  showMetricAsDefault,
}: SizeTableProps) => (
  <div className={tw([sizeGuideStyles, hidden && 'hidden'])}>
    <div
      className={tw(['flex items-center', showMetricAsDefault && 'pb-4 pt-4'])}
    >
      {!showMetricAsDefault && <h2>{title}</h2>}
      {children}
    </div>
    {description && <div className="pb-4">{description}</div>}
    <div className="overflow-x-auto">
      {measurementMessages &&
        getMeasurementMessages(
          measurementMessages,
          'body-measurement-message-top',
          'text-left mb-6'
        )}
      <table>
        <thead>
          <tr>
            {data[0].map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((values, index) => (
            // This array won't be changing so using index as the key is safe!
            <tr key={index}>
              {values.map((value, index) => (
                <td
                  // This array won't be changing so using index as the key is safe!
                  key={index}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {measurementMessages &&
        getMeasurementMessages(
          measurementMessages,
          'body-measurement-message-bottom',
          'text-left mt-6'
        )}
    </div>
  </div>
);
