import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { css, tw } from 'twind/css';

import { SanityImage } from 'ui/components/SanityImage';
import { CallToActions } from 'ui/content/CallToActions';
import { Link } from 'ui/elements/Link';
import { FaqModule } from 'ui/content/FaqModule';
import { Missing } from 'utils/getComponentForContent';

export const BlockContentLink = (props, sCustomStyles, onClick?) => {
  const className = tw(
    'border-b-2 border-puma-black-500 hover:border-puma-gold-light',
    sCustomStyles
  );
  if (props.mark._type === 'mailLink')
    return (
      <a
        className={className}
        href={props.mark.link || props.mark.href}
        rel="noreferrer"
        target="_blank"
        onClick={onClick}
      >
        {props.children}
      </a>
    );

  return (
    <>
      {props.mark.link || props.mark.href ? (
        <Link
          href={props.mark.link || props.mark.href}
          className={className}
          newtab={props.mark.newtab}
          onClick={onClick}
        >
          {props.children}
        </Link>
      ) : null}
    </>
  );
};

/** Add an `id` attribute to an element so it can be a "link target" (e.g. "#element-id"). */
export const BlockContentLinkTarget = ({ _type, children, mark }) => {
  const Tag = _type;

  return (
    <>
      <a className="invisible -top-24 relative inline-block" id={mark.id}></a>
      <Tag>{children}</Tag>
    </>
  );
};

const getHeadingBlock = (id, children, className = '') => {
  return (
    <p
      className={`${className} font-bold font-display leading-tight mb-2`}
      id={id}
    >
      {children}
    </p>
  );
};

export const serializeBlockContent = {
  types: {
    cta: function Cta(props) {
      return (
        <div className={'mt-2'}>
          <CallToActions ctas={[props.node]} className={'inline-flex'} />
        </div>
      );
    },
    block: props => {
      const {
        node: { style, _key },
        children,
      } = props;

      switch (style) {
        case 'h1':
          return getHeadingBlock(_key, children, 'text-3xl md:text-4xl');
        case 'h2':
          return getHeadingBlock(_key, children, 'text-2xl md:text-3xl');
        case 'h3':
          return getHeadingBlock(_key, children, 'text-xl md:text-2xl');
        case 'h4':
          return getHeadingBlock(_key, children, 'text-lg md:text-xl');
        case 'h5':
          return getHeadingBlock(_key, children, 'text md:text-lg');
        case 'h6':
          return getHeadingBlock(_key, children);
        case 'blockquote':
          return (
            <blockquote className="p-2 my-2 bg-gray-50 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800">
              <p className="italic font-medium leading-relaxed text-gray-900">
                <span>&quot;</span>
                {children}
                <span>&quot;</span>
              </p>
            </blockquote>
          );
        case 'normal':
          return children.length === 1 && children[0] === '' ? (
            <br />
          ) : (
            BlockContent.defaultSerializers.types.block(props)
          );
        default:
          return BlockContent.defaultSerializers.types.block(props);
      }
    },
    image: ({ node }) => {
      return <SanityImage source={node} relative className="bg-transparent" />;
    },
    table: ({ node }) => {
      return (
        <table className="table-auto border-collapse border border-gray-300 w-full mt-2 mb-2">
          <tbody>
            {node.rows.map(row => (
              <tr key={row._key} className="border-t">
                {row.cells.map((cell, index) => (
                  <td key={index} className="border px-4 py-2">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    },
    FaqModule: ({ node }) => {
      return <FaqModule {...node} inPortableText />;
    },
    Widget: ({ node }) => {
      return <Missing {...node} />; //TODO Add the correct Widget component when it is implemented.
    },
  },
  list: ({ type, children }) => {
    switch (type) {
      case 'bullet':
        return <ul className="list-disc list-inside ml-4">{children}</ul>;
      case 'number':
        return <ol className="list-decimal list-inside ml-4">{children}</ol>;

      default:
        return <ul>{children}</ul>;
    }
  },
  listItem: ({ children }) => <li className="mb-2 text-left">{children}</li>,
  marks: {
    absoluteLink: BlockContentLink,
    internalLink: BlockContentLink,
    linkTarget: BlockContentLinkTarget,
    mailLink: BlockContentLink,
    sub: ({ children }) => <sub>{children}</sub>,
    sup: ({ children }) => <sup>{children}</sup>,
    code: ({ children }) => <code>{children}</code>,
    highlight: ({ children }) => (
      <span className="bg-puma-red text-white">{children}</span>
    ),
  },
};

export const serializeHeaderToList = {
  types: {
    block: props => {
      if (/^h\d/.test(props.node.style)) {
        return React.createElement(
          'li',
          {},
          React.createElement(
            'a',
            {
              href: `#${props.node._key}`,
              className: 'text-sm underline',
            },
            props.children
          )
        );
      }

      return BlockContent.defaultSerializers.types.block(props);
    },
  },
};

const TocBlockContentLink = props => BlockContentLink(props, 'text-xs');

export const serializeTocBlockContent = {
  ...serializeBlockContent,
  marks: {
    ...serializeBlockContent.marks,
    internalLink: TocBlockContentLink,
    absoluteLink: TocBlockContentLink,
  },
};

export const Prose = ({ content }) => (
  <BlockContent
    className={tw(
      'prose md:prose-m max-w-full',
      css`
        * {
          @apply break-words;
        }
        a {
          text-decoration: none;
        }
        ul {
          list-style-type: disc !important;
          list-style-position: inside !important;
        }
        ul ul {
          list-style-type: circle !important;
        }
        ul ul ul {
          list-style-type: square !important;
        }
        ol {
          list-style-type: decimal !important;
          list-style-position: inside !important;
        }
        ol ol {
          list-style-type: lower-alpha !important;
        }
        ol ol ol {
          list-style-type: lower-roman !important;
        }
      `
    )}
    renderContainerOnSingleChild
    blocks={content}
    serializers={serializeBlockContent}
  />
);
