import {
  MutableRefObject,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { tw } from 'twind';

import { useResizeObserver } from './useResizeObserver';

type RefAndOrdinal = {
  ref: StickyElementRef;
  ordinal: number;
};

type StickyStackContextType = {
  stack: RefAndOrdinal[];
  combinedHeightByOrdinal: Record<string, number>;
  addToStack: (ref: StickyElementRef, ordinal?: number) => void;
  removeFromStack: (ref: StickyElementRef) => void;
};

type StickyElementRef =
  | RefObject<HTMLElement>
  | MutableRefObject<HTMLElement | undefined>;

const StickyStackContext = createContext<StickyStackContextType>({
  stack: [],
  combinedHeightByOrdinal: {
    0: 0,
  },
  /* eslint-disable @typescript-eslint/no-empty-function */
  addToStack: () => {},
  removeFromStack: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
});

export const useSticky = (
  ordinal?: number | undefined | null,
  elementRef?: StickyElementRef
) => {
  const context = useContext(StickyStackContext);
  const [nextTop, setNextTop] = useState<number>(0);

  const { ref, width, height } = useResizeObserver(elementRef);

  useEffect(() => {
    if (ordinal !== null) {
      context.addToStack(ref, ordinal);
      return () => context.removeFromStack(ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currIdx = context.stack.findIndex(x => x.ref === ref);
    let height = 0;
    for (let i = 0; i < currIdx; i += 1) {
      height += (context.stack[i]?.ref.current?.offsetHeight || 0) - 1;
    }
    setNextTop(height);
  }, [context.stack, ref, ref.current?.offsetHeight, width, height]);

  return {
    stickyRef: ref,
    stickyTop: nextTop,
    stickyWidth: width,
    stickyHeight: height,
    stickyCss: tw(`sticky top-[${nextTop}px]`),
    combinedHeightByOrdinal: context.combinedHeightByOrdinal,
  };
};

export const StickyStackContextProvider = ({ children }) => {
  const [stack, setStack] = useState<RefAndOrdinal[]>([]);
  const [combinedHeightByOrdinal, setCombinedHeightByOrdinal] = useState<
    Record<string, number>
  >({
    0: 0,
  });

  const addToStack = (ref: StickyElementRef, ordinal?: number | null) => {
    // Items with null ordinals are not added.  However 'undefined' ordinal will
    // be reassigned to whichever order it renders in the DOM.
    const canAdd =
      ordinal !== null &&
      (ordinal === undefined || typeof ordinal === 'number');
    if (canAdd) {
      setStack(curr => {
        const newStack = [...curr];

        newStack.push({
          ref,
          ordinal: ordinal ?? newStack.length,
        });

        newStack.sort((a, b) => a.ordinal - b.ordinal);
        return newStack;
      });
    }
  };

  const removeFromStack = (ref: StickyElementRef) =>
    setStack(curr => [...curr].filter(x => x.ref !== ref));

  useEffect(() => {
    const combinedHeightByOrdinal: Record<string, number> = stack.reduce(
      (acc: Record<string, number>, element: RefAndOrdinal) => {
        const prevOrdinal = element.ordinal - 1;
        if (element?.ref.current) {
          const elementOffsetHeight = element.ref.current?.offsetHeight || 0;
          acc[element.ordinal] =
            prevOrdinal in acc
              ? acc[prevOrdinal] + elementOffsetHeight
              : elementOffsetHeight;
        } else {
          const prevOrdinal = element.ordinal - 1;
          acc[element.ordinal] = prevOrdinal in acc ? acc[prevOrdinal] : 0;
        }
        return acc;
      },
      {}
    );
    setCombinedHeightByOrdinal(combinedHeightByOrdinal);
  }, [stack]);

  return (
    <StickyStackContext.Provider
      value={{
        stack,
        combinedHeightByOrdinal,
        addToStack,
        removeFromStack,
      }}
    >
      {children}
    </StickyStackContext.Provider>
  );
};
