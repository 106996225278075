import React from 'react';
import { tw } from 'twind';

import { ResourcesVariables } from 'locales/localeType';
import { useTranslate } from 'hooks/useTranslations';
import { useFeature } from 'hooks/useFeature';
import { calculateDiscountPercent } from 'utils/calculateDiscountPercent';
import { Maybe } from '__generated__/graphql';

import {
  PriceOptions,
  PriceUnion,
  ContentVariables,
  PRICE_TYPE,
} from './types';

type PriceContentProps = {
  compact: boolean;
  wrapText?: boolean;
  priceType: PriceUnion;
  prices: {
    regular: number;
    sale?: Maybe<number>;
  };
  formattedPrice: string;
  dataTestId?: string;
  className?: string;
  couponDiscount?: number;
  isSalePriceElapsed?: boolean;
};

export const PriceContent: React.FC<PriceContentProps> = ({
  compact = false,
  wrapText,
  priceType,
  prices,
  formattedPrice,
  dataTestId,
  className = '',
  couponDiscount,
  isSalePriceElapsed,
}) => {
  const t = useTranslate();
  const showAllPricesAtOnce = useFeature('SHOW_ALL_PRICES_AT_ONCE');
  const isGiftWithPurchaseNewDesign = useFeature(
    'GIFT_WITH_PURCHASE_NEW_DESIGN'
  );

  const compactStyles =
    compact || showAllPricesAtOnce
      ? 'text-base'
      : 'text-sm 2xs:text-base sm:text-xl lg:text-lg xl:text-xl';

  const percentDifference = calculateDiscountPercent(
    prices.regular,
    prices.sale ?? prices.regular
  );

  const isFreeItem = prices.sale === 0;

  const getPriceContent = (
    contentKey: keyof ResourcesVariables,
    contentVariables: ContentVariables
  ): string => t<typeof contentKey>(contentKey, contentVariables);

  const priceOptions: PriceOptions = {
    [PRICE_TYPE.REGULAR]: {
      priceStyle: 'font-bold',
      contentKey: 'orderDetailAmount',
      variables: { amount: formattedPrice },
    },
    [PRICE_TYPE.OLD_REGULAR]: {
      priceStyle: 'line-through opacity-50',
      contentKey: 'orderDetailSalePriceWithText',
      variables: { amount: formattedPrice, text: t('regularPrice') },
    },
    [PRICE_TYPE.PERSONALIZED]: {
      priceStyle: 'font-bold',
      contentKey: 'orderDetailAmount',
      variables: { amount: formattedPrice },
    },
    [PRICE_TYPE.SALE]: {
      priceStyle: 'text-puma-red font-bold',
      contentKey: 'orderDetailSalePriceWithPercentage',
      variables: {
        amount: formattedPrice,
        percentage: percentDifference,
      },
    },
    [PRICE_TYPE.OLD_SALE]: {
      priceStyle: 'line-through opacity-50',
      contentKey: 'orderDetailSalePriceWithPercentage',
      variables: {
        amount: formattedPrice,
        percentage: percentDifference,
      },
    },
    [PRICE_TYPE.PROMOTION]: {
      priceStyle: `${
        isGiftWithPurchaseNewDesign && isFreeItem
          ? 'text-success-60'
          : 'text-puma-red'
      } font-bold`,
      contentKey: 'orderDetailSalePriceWithPercentage',
      variables: {
        amount: formattedPrice,
        percentage:
          isSalePriceElapsed && couponDiscount
            ? couponDiscount
            : percentDifference,
      },
    },
  };

  const { contentKey, priceStyle, variables } = priceOptions[priceType];
  const priceContent =
    isGiftWithPurchaseNewDesign && isFreeItem
      ? t('free')
      : getPriceContent(contentKey, variables) ||
        getPriceContent('orderDetailAmount', { amount: formattedPrice });

  return (
    <span
      className={tw(
        !wrapText && 'whitespace-nowrap',
        compactStyles,
        priceStyle,
        className ? `override:(${className})` : ''
      )}
      data-test-id={dataTestId}
    >
      {(priceType === PRICE_TYPE.SALE || priceType === PRICE_TYPE.OLD_SALE) &&
      isSalePriceElapsed
        ? formattedPrice
        : priceContent}
    </span>
  );
};
