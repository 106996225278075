import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputDateSelect } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { CustomBirthday } from 'ui/forms/custom/types';

const SELECT_NAMES = {
  day: 'day',
  month: 'month',
  year: 'year',
};

const hasValidValue = (name: string | undefined) => {
  return name !== '' && typeof name !== 'undefined';
};

const CustomFormBirthday = ({
  field,
  required = false,
}: {
  field: CustomBirthday;
  required?: boolean;
}) => {
  const t = useTranslate();
  const form = useFormContext();
  const { register, watch, errors } = form;

  const currentItems = watch([
    SELECT_NAMES.day,
    SELECT_NAMES.month,
    SELECT_NAMES.year,
  ]);

  const errorText =
    errors[SELECT_NAMES.year]?.message ||
    errors[SELECT_NAMES.month]?.message ||
    errors[SELECT_NAMES.day]?.message;

  const errorFields = {
    year: Boolean(errors.year?.message),
    month: Boolean(errors.month?.message),
    day: Boolean(errors.day?.message),
  };

  const hasInitialValues = useMemo(() => {
    return (
      hasValidValue(currentItems?.day) &&
      hasValidValue(currentItems?.month) &&
      hasValidValue(currentItems?.year)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputDateSelect
      key={field.key}
      name={field.name}
      id={field.id}
      label={t('labelInputBirthday')}
      required={required}
      currentItems={currentItems}
      selectNames={SELECT_NAMES}
      disabled={field.disabled || hasInitialValues}
      dataTestId="birthday"
      errorText={errorText}
      error={errorFields}
      refs={{
        day: register({
          required: t('requiredFieldDay'),
        }),
        month: register({
          required: t('requiredFieldMonth'),
        }),
        year: register({
          required: t('requiredFieldYear'),
        }),
      }}
      supportText={
        !field.hideSupportText ? t(`birthdayInputSupportText`) : undefined
      }
    />
  );
};

export default React.memo(CustomFormBirthday);
