import { Exchange, Operation } from 'urql';
import { pipe, filter } from 'wonka';

import { SUPPORTED_LOCALE } from 'utils/constants';

export const filterNoLocale: Exchange = ({ forward }) => {
  const localesHash = Object.values(SUPPORTED_LOCALE).reduce(
    (prevResult, locale) => {
      prevResult[locale] = locale;

      return prevResult;
    },
    {}
  );

  return operations$ => {
    return forward(
      pipe(
        operations$,
        filter((op: Operation) => {
          // FIXME: This will cause operations that don't match the filter to
          // just hang forever, as the operation will never be handled. Instead,
          // it would be better to return an error for such operations.
          // See the urql documentation: "How to Avoid Dropping Operations":
          // https://formidable.com/open-source/urql/docs/advanced/authoring-exchanges/#how-to-avoid-accidentally-dropping-operations
          if (op.kind === 'mutation' || op.kind === 'query') {
            const fetchOptions =
              typeof op.context?.fetchOptions === 'function'
                ? op.context.fetchOptions()
                : op.context.fetchOptions;

            const locale = fetchOptions?.headers?.['locale'];

            return locale in localesHash;
          }

          return true;
        })
      )
    );
  };
};
