import React from 'react';
import { tw } from 'twind';

import { alignmentToFlexDirection } from 'utils/alignmentToFlexDirection';

import { SanityImage } from '../components/SanityImage';

import { Badge } from './Badge';
import { CallToAction } from './CallToAction';
import { Link } from './Link';
import { Text } from './Text';

export const Card = props => {
  const imageKey =
    props.image && Object.keys(props.image).find(k => props.image[k]?.asset);

  return (
    <div
      className={tw(
        'relative overflow-hidden',
        props.style?.border && 'border',
        props.style?.shadow && 'shadow-lg',
        props.style?.rounded && 'rounded-lg',
        props.style?.background && `bg-[${props.style.background}]`
      )}
    >
      {imageKey ? (
        <div
          className={tw(
            'relative',
            imageKey === 'portrait' && 'pt-[133%]',
            imageKey === 'square' && 'pt-[100%]',
            imageKey === 'landscape' && 'pt-[66%]'
          )}
        >
          <SanityImage source={props.image[imageKey]} />
          <div className="absolute top-4 left-4">
            {props.badge?.label && <Badge {...props.badge} />}
          </div>
        </div>
      ) : (
        <div className="bg-puma-black-700 pt-[100%]"></div>
      )}
      {props.link && (
        <Link
          {...props.link}
          className="absolute inset-0"
          aria-label={`${props.link}`}
        />
      )}
      <div
        className={tw(
          'relative space-y-3',
          props.style?.border ? 'p-5' : 'pt-5'
        )}
      >
        <Text {...props.text} />
        {props.ctas && (
          <div
            className={tw(
              'flex flex-wrap flex-gap-3',
              `justify-${alignmentToFlexDirection(props.text?.align)}`
            )}
          >
            {props.ctas.map(cta => (
              <CallToAction
                key={cta.id}
                {...cta}
                onClick={
                  props.handleClickCTA
                    ? props.handleClickCTA?.(cta.title)
                    : undefined
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
