import { tw } from 'twind';
import { css } from 'twind/css';

import { useStyliticsSanity } from 'hooks/useStyliticsSanity';

import StyliticsTileList from './StyliticsTileList';
import { styliticsCarouselStyles } from './stylitics-sanity-css';

const StyliticsGridContainer = () => {
  const {
    tiles,
    outfitUi: { columns },
  } = useStyliticsSanity();
  const gridColumns = Object.keys(columns).reduce((prev, current) => {
    return { ...prev, [current]: `grid-cols-${columns[current]}` };
  }, {});
  const gridStyles = tw([
    'grid overflow-x-hidden no-scrollbars! py-[30px]',
    css({
      scrollSnapType: 'x mandatory',
    }),
    'gap-5',
    gridColumns,
  ]);
  return (
    <div
      data-test-id="stylitics-grid"
      className={tw(styliticsCarouselStyles, gridStyles)}
    >
      <StyliticsTileList tiles={tiles} />
    </div>
  );
};

export default StyliticsGridContainer;
