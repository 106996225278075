import React from 'react';

import { useSiteConfig } from '../useSiteConfig';

import { useFreshChat } from './useFreshChat';
import { useRadialLiveChat } from './useRadialChat';
import { useAdaLiveChat } from './useAdaChat';

type LiveChat = {
  liveChatCode: string;
  liveChatEnabled: boolean;
  LiveChatScript: ({ defer }: { defer?: boolean }) => JSX.Element | null;
  LiveChatToggleWrapper: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
  openLiveChatWindow: () => void;
  renderInMainNav: boolean;
};

export const useLiveChat = (): LiveChat => {
  const { liveChat } = useSiteConfig();

  const provider = liveChat?.provider;

  const radialBag = useRadialLiveChat();

  const freshChatBag = useFreshChat();

  const adaChatBag = useAdaLiveChat();

  return React.useMemo<LiveChat>(() => {
    switch (provider) {
      case 'ada':
        return adaChatBag;
      case 'freshChat':
        return {
          renderInMainNav: !!liveChat?.renderInMainNav,
          ...freshChatBag,
          LiveChatScript: () => null,
          liveChatCode: '',
          LiveChatToggleWrapper: ({
            children,
          }: {
            children: React.ReactNode;
          }) => <>{children}</>,
        };
      case 'radial':
        return radialBag;
      default:
        return {
          renderInMainNav: false,
          liveChatEnabled: false,
          openLiveChatWindow: () => null,
          LiveChatScript: () => null,
          liveChatCode: '',
          LiveChatToggleWrapper: ({
            children,
          }: {
            children: React.ReactNode;
          }) => <>{children}</>,
        };
    }
  }, [
    provider,
    adaChatBag,
    liveChat?.renderInMainNav,
    freshChatBag,
    radialBag,
  ]);
};
