// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/PixleeSimpleWidget.ts

export type PixleeSimpleWidgetDocumentType = {
  _id: string;
  _type: string;
  widgetId: string;
};

export const PixleeSimpleWidgetDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    widgetId,
  }
`;
