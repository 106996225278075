import React, { useEffect, useMemo } from 'react';
import { UseMutationState } from 'urql';
import { useFormContext } from 'react-hook-form';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { FormFieldConfig, SubmitFunction } from 'ui/forms/custom/types';
import CustomFormInput from 'ui/forms/custom/components/CustomFormInput';
import CustomFormPassword from 'ui/forms/custom/components/CustomFormPassword';
import CustomFormCheckBox from 'ui/forms/custom/components/CustomFormCheckBox';
import CustomFormBirthday from 'ui/forms/custom/components/CustomFormBirthday';
import CustomFormButton from 'ui/forms/custom/components/CustomFormButton';
import CustomFormLink from 'ui/forms/custom/components/CustomFormLink';
import CustomFormRadio from 'ui/forms/custom/components/CustomFormRadio';
import CustomFormDropdown from 'ui/forms/custom/components/CustomFormDropdown';
import CustomFormGoogleAddressLookUp from 'ui/forms/custom/components/CustomFormGoogleAddressLookUp';
import { buildName, resolveField } from 'ui/forms/custom/helpers';
import { useTranslate } from 'hooks/useTranslations';
import TermsAndConditions from 'ui/components/TermsAndConditions';

import CustomFormText from './CustomFormTextOnly';
import CustomFormYubinbangoAddressLookUp from './CustomFormYubinbangoAddressLookUp';
import CustomFormCvv from './CustomFormCvv';
import CustomFormExpirationDate from './CustomFormExpirationDate';
import CustomFormCardNumber from './CustomFormCardNumber';
import CustomFormTextToModal from './CustomFormTextToModal';
import CustomFormPincode from './CustomFormPincode';
import CustomFormSmsOptIn from './CustomFormSmsOptIn';

type CustomFormFieldsProps = {
  fieldConfig: FormFieldConfig;
  result?: UseMutationState;
  prefix?: string;
  required?: boolean;
  submit?: SubmitFunction;
};

function useTriggerDependents(
  fieldPath: string,
  fieldConfig: FormFieldConfig,
  prefix: string | undefined
) {
  const { watch, trigger } = useFormContext();
  const fieldValue = watch(fieldPath);

  useEffect(() => {
    const fieldNames = fieldConfig.dependentFields?.map(dependentField =>
      buildName(dependentField, prefix)
    );

    if (fieldNames) {
      trigger(fieldNames);
    }
  }, [fieldValue, fieldConfig.dependentFields, trigger, prefix]);
}

const CustomFormField = ({
  fieldConfig,
  result,
  prefix,
  submit,
}: CustomFormFieldsProps) => {
  const { countryCode } = useSiteConfig();
  const { fieldProps } = useSiteConfig();

  const t = useTranslate();
  const field = useMemo(
    () => resolveField(fieldConfig, fieldProps, t, prefix),
    [fieldConfig, fieldProps, t, prefix]
  );

  useTriggerDependents(field.name, fieldConfig, prefix);

  switch (field.fieldType) {
    case 'input':
      return (
        <CustomFormInput
          field={field}
          required={fieldConfig.required}
          submit={submit}
        />
      );

    case 'password':
      return <CustomFormPassword field={field} />;

    case 'checkbox':
      return (
        <CustomFormCheckBox field={field} required={fieldConfig.required} />
      );
    case 'birthday':
      return (
        <CustomFormBirthday field={field} required={fieldConfig.required} />
      );

    case 'button':
      return <CustomFormButton button={field} result={result} />;

    case 'link':
      return <CustomFormLink field={field} />;

    case 'blank':
      return <></>;

    case 'input-radio':
      return <CustomFormRadio field={field} />;

    case 'dropdown':
      return (
        <CustomFormDropdown
          dropdownField={field}
          required={fieldConfig.required}
          prefix={prefix}
        />
      );

    case 'google-address-look-up':
      return (
        <CustomFormGoogleAddressLookUp
          field={field}
          country={countryCode}
          prefix={prefix}
          required={fieldConfig.required}
          submit={submit}
        />
      );
    case 'terms-and-conditions':
      return <TermsAndConditions page={field.page} />;

    case 'text-only':
      return <CustomFormText field={field} />;

    case 'yubinbango-address-look-up':
      return (
        <CustomFormYubinbangoAddressLookUp
          field={field}
          prefix={prefix}
          required={fieldConfig.required}
        />
      );
    case 'cvv':
      return (
        <CustomFormCvv
          field={field}
          prefix={prefix}
          required={fieldConfig.required}
          formResult={result}
        />
      );
    case 'expiration-date':
      return (
        <CustomFormExpirationDate
          field={field}
          required={fieldConfig.required}
          formResult={result}
        />
      );
    case 'card-number':
      return (
        <CustomFormCardNumber
          field={field}
          prefix={prefix}
          required={fieldConfig.required}
          formResult={result}
        />
      );
    case 'pincode':
      return (
        <CustomFormPincode
          country={countryCode}
          field={field}
          prefix={prefix}
          submit={submit}
        />
      );
    case 'text-to-modal':
      return <CustomFormTextToModal field={field} />;

    case 'sms-opt-in':
      return (
        <CustomFormSmsOptIn
          field={field}
          phoneNumberFieldName={fieldConfig.args?.phoneNumberFieldName}
          ctaLabel={fieldConfig.args?.ctaLabel}
        />
      );

    default:
      // Should be unreachable
      // @ts-expect-error -- Make sure we aren't missing any 'case' statements:
      // eslint-disable-next-line no-console
      console.error(`No form field found for ${field.fieldType}`);
      return null;
  }
};

export default React.memo(CustomFormField);
