import { useMemo } from 'react';

import { Maybe } from '__generated__/graphql-sanity';
import { CloudinaryEagerTransformation } from 'groq/objects/CloudinaryOptimizationObject';

interface UseOptimizedVideoDisplayProps {
  transformations: Maybe<CloudinaryEagerTransformation[]> | undefined;
  elementWidth: number;
  elementHeight: number;
}

export const useOptimizedVideoUrl = ({
  transformations,
  elementWidth,
  elementHeight,
}: UseOptimizedVideoDisplayProps) => {
  const optimizedUrl = useMemo(() => {
    if (!transformations?.length) return null;

    const eagerOptimized = transformations
      .filter(eager => eager && eager.width && eager.height && eager.url)
      .map(eager => ({
        heightDiff: Math.abs((eager.height as number) - elementHeight),
        widthDiff: Math.abs((eager.width as number) - elementWidth),
        url: eager.url as string,
      }));

    if (eagerOptimized.length === 0) return null;

    const { url: closestUrl } = eagerOptimized.reduce((prev, curr) => {
      const prevDiff = prev.heightDiff + prev.widthDiff;
      const currDiff = curr.heightDiff + curr.widthDiff;
      return prevDiff < currDiff ? prev : curr;
    });

    return closestUrl;
  }, [elementHeight, elementWidth, transformations]);

  return optimizedUrl;
};
