import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { useResizeObserver } from '@global-ecom/nitro-uds/hooks';

import { SanityVideoObjectType } from 'groq/objects/SanityVideoObject';
import { useOptimizedVideoUrl } from 'hooks/useOptimizedVideoUrl';
import { Maybe } from 'utils/types';
import { merge } from 'utils/merge';

import { Video, VideoProps } from './Video';

interface SanityVideoProps
  extends Omit<VideoProps, 'src' | 'videoOptions' | 'muted'> {
  video: Maybe<SanityVideoObjectType>;
  videoOptions?: VideoProps['videoOptions'];
}

export const SanityVideo = React.forwardRef<HTMLVideoElement, SanityVideoProps>(
  (props, ref) => {
    const { video, videoOptions, ...rest } = props;

    const innerRef = useRef<HTMLVideoElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLVideoElement);

    const { width: elementWidth, height: elementHeight } =
      useResizeObserver(innerRef);

    const options = useMemo(
      () =>
        merge(
          {
            controls: video?.controls === null ? false : video?.controls,
            autoPlay: video?.autoplay === null ? true : video?.autoplay,
            loop: video?.loop === null ? true : video?.loop,
            muted: video?.mute === null ? true : video?.mute,
          },
          videoOptions
        ),
      [video, videoOptions]
    );

    const optimizedVideoUrl = useOptimizedVideoUrl({
      transformations: video?.source?.cloudinary?.transformations,
      elementWidth,
      elementHeight,
    });

    if (!video?.source?.url) return null;

    return (
      <Video
        // @ts-expect-error Type 'LegacyRef<HTMLVideoElement>' is not assignable to type 'Ref<HTMLVideoElement> | undefined'
        ref={innerRef}
        {...rest}
        videoOptions={options}
        src={optimizedVideoUrl || video.source.url}
      />
    );
  }
);

SanityVideo.displayName = 'SanityVideo';
