import { ComponentWithFilters } from '__generated__/graphql';
import { GroqQuery } from 'hooks/useGroqQuery';

import {
  AdditionalContentSectionDocument,
  AutomatedProductComparisonDocument,
  BodyContentDocument,
  BodyHeaderDocument,
  CardsAndTilesDocument,
  FeatureHeroDocument,
  FeaturesGridDocument,
  FullBleedHeroDocument,
  IframeDocument,
  InfoBannerDocument,
  PixleeSimpleWidgetDocument,
  ProductCarouselDocument,
  ProductComparisonDocument,
  ProductGroupFeaturesDocument,
  ProductRecommenderDocument,
  QuoteCalloutDocument,
  QuiltDocument,
  SideBySideDocument,
  SignUpFormDocument,
  SplitHeroDocument,
  StyliticsCarouselDocument,
  TrendingInlineDocument,
  VideoHeroDocument,
  YouTubeVideoCarouselDocument,
  HowToWearItDocument,
  FAQModuleDocument,
  WebCarouselDocument,
  PromoTileDocument,
  FeaturesGridRowDocument,
  CountdownClockDocument,
  TrendingTileDocument,
} from './documents';

export type LandingPage = {
  _id: string;
  _type: string;
  title: string;
  slots: ComponentWithFilters[];
  seo: {
    pageTitle: string;
    pageDescription: string;
  };
  category?: {
    id: string;
  };
};

export const componentsGroqQuery = `
  ${AdditionalContentSectionDocument('_type == "AdditionalContentSection" =>')},
  ${AutomatedProductComparisonDocument(
    '_type == "AutomatedProductComparison" =>'
  )},
  ${BodyContentDocument('_type == "BodyContent" =>')},
  ${BodyHeaderDocument('_type == "BodyHeader" =>')},
  ${CardsAndTilesDocument('_type == "LaunchCalendar" =>')},
  ${FeatureHeroDocument('_type == "FeatureHero" =>')},
  ${FeaturesGridDocument('_type == "FeaturesGrid" =>')},
  ${FullBleedHeroDocument('_type == "FullBleedHero" =>')},
  ${IframeDocument('_type == "Iframe" =>')},
  ${InfoBannerDocument('_type == "InfoBanner" =>')},
  ${PixleeSimpleWidgetDocument('_type == "PixleeSimpleWidget" =>')},
  ${ProductCarouselDocument('_type == "ProductCarousel" =>')},
  ${ProductComparisonDocument('_type == "ProductComparison" =>')},
  ${ProductGroupFeaturesDocument('_type == "ProductGroupFeatures" =>')},
  ${ProductRecommenderDocument('_type == "ProductRecommender" =>')},
  ${QuiltDocument('_type == "Quilt" =>')},
  ${QuoteCalloutDocument('_type == "QuoteCallout" =>')},
  ${SideBySideDocument('_type == "SideBySide" =>')},
  ${SignUpFormDocument('_type == "SignUpForm" =>')},
  ${SplitHeroDocument('_type == "SplitHero" =>')},
  ${StyliticsCarouselDocument('_type == "StyliticsCarousel" =>')},
  ${TrendingInlineDocument('_type == "TrendingInline" =>')},
  ${VideoHeroDocument('_type == "VideoHero" =>')},
  ${YouTubeVideoCarouselDocument('_type == "YouTubeVideoCarousel" =>')},
  ${HowToWearItDocument('_type == "HowToWearIt" =>')},
  ${FAQModuleDocument('_type == "FaqModule" =>')},
  ${WebCarouselDocument('_type == "WebCarousel" =>')}
`;

export const subComponentsGroqQuery = ` 
  ${CountdownClockDocument('_type == "CountdownClock" =>')},
  ${FeaturesGridRowDocument('_type == "FeaturesGridRow" =>')},
  ${PromoTileDocument('_type == "PromoTile" =>')},
  ${TrendingTileDocument('_type == "TrendingTile" =>')},
`;

export const SlotsGroqQuery = (): GroqQuery<ComponentWithFilters> => `{
    "id": _key,
    _type,
    "availableFrom": coalesce(component->availableFrom, availableFrom),
    "availableTo": coalesce(component->availableTo, availableTo),
    targetDevices,
    campaign[] -> {
      _id,
      _type,
      availableFrom,
      availableTo,
      customerGroups,
      puid,
      sourceCode,
    },
    component -> {
      ${componentsGroqQuery}
    }
  }
`;
