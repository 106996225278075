import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SHIPPING_LOOKUP_CONSTRAINTS } from 'utils/constants';
import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { CustomAddressLookUp, SubmitFunction } from 'ui/forms/custom/types';
import { TransformedPlacesResult } from 'utils/transformPlaces';
import { buildName } from 'ui/forms/custom/helpers';
import { AddressLookup } from 'ui/components/AddressLookup';

const CustomFormGoogleAddressLookUp = ({
  field,
  country,
  required = false,
  prefix,
  submit,
}: {
  field: CustomAddressLookUp;
  country: string;
  required?: boolean;
  prefix?: string;
  submit?: SubmitFunction;
}) => {
  const t = useTranslate();
  const [validate, dynamicValidation] = useValidation();
  const form = useFormContext();
  const { resolveInputError } = useFormField(field);

  const onSelect = async (placeResults: TransformedPlacesResult) => {
    if (field.mapAddressLookupResultsToFormValues?.length) {
      const affectedFields: string[] = [];
      field.mapAddressLookupResultsToFormValues.forEach(
        ({ resultKey, formKey }) => {
          const field = buildName(formKey, prefix);
          setValue(field, placeResults[resultKey]);
          affectedFields.push(field);
        }
      );
      await trigger(affectedFields);
    }

    if (field.validateOnAddressLookupSelect) {
      await trigger();
    }
    if (field.submitOnUpdate && submit) {
      trigger().then(async () => await submit());
    }
  };

  const { register, trigger, setValue, watch } = form;

  return (
    <AddressLookup
      defaultValue={watch(field.name)}
      lookupConstraints={SHIPPING_LOOKUP_CONSTRAINTS[country]}
      onSelect={onSelect}
      key={field.key}
      name={field.name}
      label={field.label}
      id={field.id}
      dataTestId={field.dataTestId}
      placeholder={field.placeholder || ''}
      maxLength={field.maxLength}
      required={required}
      ref={register({
        required: required && t('requiredField'),
        validate: async value => {
          let errorMessage: string | undefined;
          if (field.validations) {
            for (const v of field.validations) {
              if (typeof v === 'string') {
                errorMessage = validate[v](value);
              } else {
                errorMessage = dynamicValidation(value, form, v);
              }
              if (errorMessage) break;
            }
          }
          return errorMessage;
        },
        maxLength: field.maxLength && {
          value: field.maxLength,
          message: t<'genericFieldMaxLength'>('genericFieldMaxLength', {
            length: field.maxLength,
          }),
        },
        minLength: field.minLength && {
          value: field.minLength,
          message: t<'genericFieldMinLength'>('genericFieldMinLength', {
            length: field.minLength,
          }),
        },
      })}
      errorText={resolveInputError()}
    />
  );
};

export default React.memo(CustomFormGoogleAddressLookUp);
