import { CombinedError } from 'urql';

/** Gets the first error message from a GraphQL request */
export const extractError = (
  ...states: { error?: CombinedError }[]
): string | undefined => {
  const error = getError(states);

  if (!error) return;

  return error.graphQLErrors[0]?.message ?? 'A network error occurred';
};

/** Gets the first error code from a GraphQL request */
export const extractCode = (
  ...states: { error?: CombinedError }[]
): string | undefined => {
  const error = getError(states);

  if (!error) return;

  return (
    (error.graphQLErrors[0]?.extensions?.code as string | undefined) ??
    'A network error occurred'
  );
};

const getError = (states: { error?: CombinedError }[]) =>
  states.find(state => state.error)?.error;
