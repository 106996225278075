import React from 'react';
import { useQuery } from 'urql';
import { useRouter } from 'next/router';
import PortableText from '@sanity/block-content-to-react';

import { serializeTocBlockContent } from 'utils/serializeBlockContent';
import { Maybe } from '__generated__/graphql';
import { TERMS_AND_CONDITIONS_QUERY } from 'gql/queries/termsAndConditions';

interface componentProps {
  children?: React.ReactElement[] | JSX.Element;
  backupContent?: React.ReactElement;
  page: string;
  className?: string;
  blocks?: any;
  serializers?: {
    marks: {
      internalLink: (props: any) => JSX.Element;
      absoluteLink: (props: any) => JSX.Element;
    };
    types: {
      cta: (props: any) => JSX.Element;
      block: (props: any) => any;
    };
  };
}

const TermsAndConditionsSection = ({
  showAbove,
  children,
  className,
  blocks,
  portableText,
  serializers,
}: {
  showAbove: boolean;
  children?: React.ReactElement[] | JSX.Element;
  className?: string;
  blocks?: any;
  portableText: any[];
  serializers?: {
    marks: {
      internalLink: (props: any) => JSX.Element;
      absoluteLink: (props: any) => JSX.Element;
    };
    types: {
      cta: (props: any) => JSX.Element;
      block: (props: any) => any;
    };
  };
}) => {
  return (
    <>
      {!showAbove && children}
      <div
        data-test-id="terms-and-conditions"
        className={className ? className : 'text-xs normal-case'}
      >
        <PortableText
          blocks={blocks?.blocks ? blocks.blocks : portableText}
          serializers={serializers || serializeTocBlockContent}
        />
      </div>
      {showAbove && children}
    </>
  );
};

export default function TermsAndConditions(
  props: componentProps
): React.ReactElement {
  const { children, backupContent, page, className, serializers, blocks } =
    props;
  const router = useRouter();
  const [config] = useQuery({
    query: TERMS_AND_CONDITIONS_QUERY,
    variables: {
      isDraft: router.query.draft as string,
    },
  });
  const portableText: Array<Maybe<PortableText>> =
    config?.data?.termsAndConditionsConfigs &&
    config.data.termsAndConditionsConfigs[page]?.content;
  const showAbove: boolean = config?.data?.termsAndConditionsConfigs?.showAbove;
  if (config.fetching) {
    return <>{children}</>;
  }

  if (config.error || !portableText) {
    return (
      <>
        {children}
        {backupContent}
      </>
    );
  }

  return (
    <TermsAndConditionsSection
      {...{ showAbove, children, className, blocks, portableText, serializers }}
    />
  );
}
