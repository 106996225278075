import { tw } from 'twind/css';
import { useMemo } from 'react';

import { isServer } from 'utils/constants';
import { Maybe } from '__generated__/graphql';
import { getImageMediaCondition } from 'utils/image';
import { ImageOverlay } from 'ui/elements/ImageOverlay';
import { useGA4Events } from 'hooks/useGA4Events';
import { SanityImageObjectType } from 'groq/objects/SanityImageObject';
import { CloudinaryEagerTransformation } from 'groq/objects/CloudinaryOptimizationObject';

export type SanityImageTypeProps = {
  className?: string;
  relative?: boolean;
  priority?: boolean;
  source?: Maybe<SanityImageObjectType>;
  mobileSource?: Maybe<SanityImageObjectType>;
  defaultAlt?: Maybe<string>;
  preserveImageWidth?: boolean;
};

const placeholderImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const imageFormat = 'q=80&auto=format';

export const SanityImage: React.FC<SanityImageTypeProps> = props => {
  const { fireClickProductImage } = useGA4Events();
  const asset = props.source?.asset;
  const mobileAsset = props.mobileSource?.asset;
  const mobileMediaCondition = getImageMediaCondition('mobile');
  const tabletMediaCondition = getImageMediaCondition('tablet');
  const desktopMediaCondition = getImageMediaCondition('desktop');

  const allSourcesNormalized = useMemo(() => {
    const sourcesNormalized = props.source?.asset?.cloudinary?.transformations
      ?.length
      ? props.source.asset.cloudinary.transformations
      : [
          {
            _key: 'sanity-cdn-image-1',
            url: `${asset?.url}?w=2160&${imageFormat}`,
            width: 2160,
            media: desktopMediaCondition,
            platform: 'desktop',
          } as CloudinaryEagerTransformation,
          {
            _key: 'sanity-cdn-image-2',
            url: `${asset?.url}?w=1440&${imageFormat}`,
            width: 1440,
            media: tabletMediaCondition,
            platform: 'desktop', // please keep, it's not a mistake
          } as CloudinaryEagerTransformation,
        ];

    const mobileSourcesNormalized = props.mobileSource?.asset?.cloudinary
      ?.transformations?.length
      ? props.mobileSource.asset.cloudinary.transformations
      : sourcesNormalized.every(s => s.platform !== 'all')
      ? [
          {
            _key: 'sanity-cdn-image-3',
            url: `${mobileAsset?.url || asset?.url}?w=720&${imageFormat}`,
            width: 720,
            media: mobileMediaCondition as string,
            platform: 'mobile',
          } as CloudinaryEagerTransformation,
        ]
      : null;

    return sourcesNormalized.concat(mobileSourcesNormalized || []);
  }, [
    props.source?.asset?.cloudinary?.transformations,
    props.mobileSource?.asset?.cloudinary?.transformations,
    asset?.url,
    mobileAsset?.url,
    desktopMediaCondition,
    tabletMediaCondition,
    mobileMediaCondition,
  ]);

  const hasUrl = asset?.url || mobileAsset?.url;
  if (!hasUrl) return null;

  const blur = asset?.metadata.lqip || mobileAsset?.metadata.lqip;
  const alt = props.source?.alt || props.mobileSource?.alt;

  const shouldRender = props.priority || !isServer;

  return (
    <picture
      data-test-id="sanity-image"
      className={tw(
        'flex bg-puma-black-800 pointer-events-none',
        props.preserveImageWidth ? '' : 'w-full',
        props.relative ? 'relative' : 'absolute inset-0 h-full',
        props.className
      )}
      style={
        shouldRender
          ? {
              backgroundImage: `url(${blur})`,
              backgroundSize: 'cover',
            }
          : undefined
      }
    >
      {shouldRender && (
        <>
          {allSourcesNormalized.map(source => (
            <source
              data-test-id="sanity-image-source"
              key={source._key}
              srcSet={source.url}
              width={props.preserveImageWidth ? source.width : undefined}
              media={source.media || undefined}
              onClick={() => fireClickProductImage(source.url || '')}
            />
          ))}
        </>
      )}
      <img
        data-test-id="sanity-image-element"
        srcSet={placeholderImage}
        className="object-cover w-full h-full"
        alt={alt || props.defaultAlt || undefined}
        loading="eager"
        decoding="auto"
      />
      {props.source?.overlay && (
        <ImageOverlay
          className="invisible tablet:visible desktop:visible"
          {...props.source.overlay}
        />
      )}
      {props.mobileSource?.overlay && (
        <ImageOverlay
          className="invisible mobile:visible"
          {...props.mobileSource?.overlay}
        />
      )}
    </picture>
  );
};
