export const LocaleUrl = `
  "url": coalesce(
    urls[$locale],
    urls[$language],
    urls.en_GB,
    urls.en_US,
    urls.en,
    url,
    ''
  )
`;

export const DereferenceUrl = (referenceDocumentName: string) => `
  coalesce(
    ${referenceDocumentName} -> urls[$locale],
    ${referenceDocumentName} -> urls[$language],
    ${referenceDocumentName} -> urls.en_GB,
    ${referenceDocumentName} -> urls.en_US,
    ${referenceDocumentName} -> urls.en,
    ${referenceDocumentName} -> url
  )
`;

export const LocaleReferenceUrl = (field: string) => `
  "${field}": coalesce(
    ${DereferenceUrl('category')},
    ${DereferenceUrl('route')},
    marketing -> url.current,
    support -> url.current,
    product,
    core,
    absolute,
    link,
    ''
  )
`;

export const QueryByLocaleUrl = (deref = '') => `
  $url == ${deref}urls[$locale] ||
  $url == ${deref}urls[$language] ||
  $url == ${deref}urls.en_GB ||
  $url == ${deref}urls.en_US ||
  $url == ${deref}urls.en ||
  $url == ${deref}url
`;
