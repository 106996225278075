import { Icon, Text } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';

const NoResultsMessage = () => {
  const t = useTranslate();

  return (
    <div
      data-test-id="search-no-results-message"
      className="flex gap-2 items-center pb-6 mb-6 border-b-1"
    >
      <Icon name="exclamation-outline" size="2xl" />
      <Text weight="bold">{t('searchNoResultsMessage')}</Text>
    </div>
  );
};

export default NoResultsMessage;
