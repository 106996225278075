import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

const defaultInit: MutationObserverInit = {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true,
};

export const useMutationObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: MutationCallback,
  init: MutationObserverInit = defaultInit
): void => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, init);
      return () => observer.disconnect();
    }
  }, [callback, init]); // eslint-disable-line react-hooks/exhaustive-deps
};
