import { useEffect, useState } from 'react';
import { tw } from 'twind/style';

import { AlertMessage } from 'ui/elements/AlertMessage';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';

import { StockMessageProps } from './StockMessage';

type StockMessageSFCCProps = StockMessageProps & {
  addToCartReturnCode?: string;
};

export const StockMessageSFCC = ({
  quantity,
  selectedSize,
  addToCartReturnCode,
}: StockMessageSFCCProps) => {
  const [initialInventoryMessage, setInitialInventoryMessage] = useState<{
    outOfStock: boolean;
    lowStock: boolean;
  }>({ outOfStock: false, lowStock: false });
  const [cartReturnCode, setCartReturnCode] = useState<string>();
  const {
    pdpLowInventoryThreshold,
    staticFeatures: { useStockInfoRedesign },
  } = useSiteConfig();
  const lowThresholdValue = pdpLowInventoryThreshold
    ? pdpLowInventoryThreshold
    : 10;
  const t = useTranslate();

  useEffect(() => {
    if (!useStockInfoRedesign) {
      setCartReturnCode(undefined);
      let message: string | undefined;
      switch (addToCartReturnCode) {
        case 'IN_STOCK':
          message = t('cartStockMessageInStock');
          break;
        case 'LIMITED_STOCK':
          message = t('cartStockMessageLimitedStock');
          break;
        case 'NOT_AVAILABLE':
          message = t('cartStockMessageOutOfStock');
          break;
        default:
          message = addToCartReturnCode
            ? t('cartStockMessageNotDefined')
            : t('cartStockMessageInStock');
      }
      setCartReturnCode(message ?? '');
    }
  }, [addToCartReturnCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialInventoryMessage({
      outOfStock: false,
      lowStock: false,
    });
    if (selectedSize && quantity) {
      setInitialInventoryMessage({
        outOfStock: quantity <= 0,
        lowStock: quantity <= lowThresholdValue,
      });
    }
  }, [quantity, selectedSize, lowThresholdValue]);

  const alertMessageProps: any = useStockInfoRedesign
    ? {
        icon: 'clock-outline',
        type: 'plaininfo',
      }
    : {
        icon: 'exclamation-outline',
        type: 'warning',
      };

  return (
    <>
      {!useStockInfoRedesign && cartReturnCode && (
        <p>
          {t('stockStatus')}{' '}
          <span
            className={tw(
              addToCartReturnCode === 'LIMITED_STOCK' ? 'text-puma-red' : ''
            )}
          >
            {cartReturnCode}
          </span>
        </p>
      )}
      {initialInventoryMessage.lowStock && (
        <AlertMessage {...alertMessageProps}>{t('lowStock')}</AlertMessage>
      )}
      {initialInventoryMessage.outOfStock && (
        <AlertMessage {...alertMessageProps}>
          {t('stockStatus')} {t('outOfStock')}
        </AlertMessage>
      )}
    </>
  );
};
