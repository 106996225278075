import { gql } from 'urql';

export const PORTABLE_BLOCK_FRAGMENT = gql`
  fragment portableTextFields on PortableText {
    _type
    _key
    children {
      _type
      _key
      text
      marks
    }
    markDefs {
      _type
      _key
      _ref
      href
      link
      newtab
    }
    style
    level
    listItem
  }
`;
