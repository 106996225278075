import { useState, createContext, useContext, useCallback } from 'react';

type FormInputValues = {
  [key: string]: string;
};

export type AuthFormContextType = {
  data: FormInputValues;
  setValues: (values: FormInputValues) => void;
};

export const AuthFormContext = createContext<AuthFormContextType>(
  {} as AuthFormContextType
);

export const AuthFormContextProvider = ({ children }) => {
  const [data, setData] = useState({});

  const setValues = useCallback((values: FormInputValues) => {
    setData(prevData => ({
      ...prevData,
      ...values,
    }));
  }, []);

  return (
    <AuthFormContext.Provider value={{ data, setValues }}>
      {children}
    </AuthFormContext.Provider>
  );
};

export const useFormData = () => useContext(AuthFormContext);
