import { gql } from 'urql';

export const USER_FRAGMENT = gql`
  fragment userFields on User {
    id
    customerNo
    creationDate
    birthday
    firstName
    lastName
    firstNamePronunciation
    lastNamePronunciation
    email
    phoneNumber
    gender
    birthday
    smsContactNumber
  }
`;
