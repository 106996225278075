import React, { ReactNode } from 'react';
import { tw, animation, css } from 'twind/css';

export const Skeleton = ({
  className,
  children,
  animate = true,
  delay = '0ms',
}: {
  className?: string;
  children?: ReactNode;
  animate?: boolean;
  delay?: `${number}ms`;
}) => {
  const pulse = animation(`4s ease infinite ${delay}`, {
    '0%, 100%': { opacity: '5%' },
    '40%': { opacity: '20%' },
  });
  return (
    <div
      data-test-id="skeleton-loader"
      className={tw(
        'bg-puma-black-400 rounded-sm opacity-5 cursor-wait',
        animate ? css(pulse) : 'opacity-20',
        className
      )}
      aria-hidden="true"
    >
      {children}
    </div>
  );
};
