import { useFeature } from 'hooks/useFeature';
import { usePriceFormatter } from 'hooks/usePriceFormatter';
import { getMinimalNumber } from 'utils/getMinimalNumber';
import { isNumber } from 'utils/isNumber';
import { Maybe } from '__generated__/graphql';
import { calculateDiscountPercent } from 'utils/calculateDiscountPercent';

import { PriceContent } from './PriceContent';
import { PRICE_TYPE, PriceUnion } from './types';

export const PLP_PRICE_STRATEGY = {
  classNames: {
    [PRICE_TYPE.REGULAR]: 'opacity-100',
    [PRICE_TYPE.OLD_REGULAR]: 'text-puma-black-300 opacity-100',
  },
  dataTestIds: {
    [PRICE_TYPE.REGULAR]: 'price',
    [PRICE_TYPE.OLD_REGULAR]: 'price',
    [PRICE_TYPE.SALE]: 'sale-price',
  },
};

export const PDP_PRICE_STRATEGY = {
  classNames: {
    [PRICE_TYPE.REGULAR]: 'md:text-2xl opacity-100',
    [PRICE_TYPE.OLD_REGULAR]: 'font-bold opacity-100',
    [PRICE_TYPE.SALE]: 'text-xl md:text-2xl',
  },
  dataTestIds: {
    [PRICE_TYPE.REGULAR]: 'item-price-pdp',
    [PRICE_TYPE.OLD_REGULAR]: 'item-price-pdp',
    [PRICE_TYPE.SALE]: 'item-sale-price-pdp',
  },
};

export const MINICART_PRICE_STRATEGY = {
  classNames: {
    [PRICE_TYPE.REGULAR]: 'font-normal',
    [PRICE_TYPE.OLD_REGULAR]: 'font-normal',
  },
};

export const RECOMMENDATION_CAROUSEL_PRICE_STRATEGY = {
  classNames: {
    [PRICE_TYPE.REGULAR]: 'xs:text-base text-sm',
    [PRICE_TYPE.OLD_REGULAR]: 'xs:text-base text-sm font-normal',
    [PRICE_TYPE.SALE]: 'xs:text-base text-sm',
    [PRICE_TYPE.OLD_SALE]: 'xs:text-base text-sm font-normal',
    [PRICE_TYPE.PROMOTION]: 'xs:text-base text-sm',
  },
};
type PriceTypeKeys = {
  [key in keyof typeof PRICE_TYPE]?: string;
};

export interface IProductPrice {
  compact?: boolean;
  wrapText?: boolean;
  classNames?: PriceTypeKeys;
  dataTestIds?: PriceTypeKeys;
  isFinalPrice?: boolean;
  price: {
    amount?: Maybe<number>;
    salePrice?: Maybe<number>;
    promotionPrice?: Maybe<number>;
    personalizationPrice?: Maybe<number>;
    isSalePriceElapsed?: Maybe<boolean>;
  };
}

export const ProductPrice: React.FC<IProductPrice> = ({
  compact = false,
  wrapText = false,
  classNames,
  dataTestIds,
  isFinalPrice,
  price,
}) => {
  const {
    amount = 0,
    promotionPrice,
    salePrice,
    personalizationPrice,
    isSalePriceElapsed,
  } = price;
  const showAllPricesAtOnce = useFeature('SHOW_ALL_PRICES_AT_ONCE');

  const formatter = usePriceFormatter();

  const normalizedRegularPrice =
    (isNumber(amount) && amount !== 0 ? amount : salePrice || promotionPrice) ||
    0;

  const finalPrice = isFinalPrice
    ? getMinimalNumber(amount, salePrice, promotionPrice)
    : undefined;

  const regularPrice = finalPrice ?? normalizedRegularPrice;

  const _amount = isNumber(regularPrice) ? formatter(regularPrice) : '';

  const _salePrice = isNumber(salePrice) ? formatter(salePrice) : '';

  const _personalizedPrice = isNumber(personalizationPrice)
    ? formatter(personalizationPrice)
    : '';

  const _promotionPrice = isNumber(promotionPrice)
    ? formatter(promotionPrice)
    : '';

  const couponDiscount =
    salePrice &&
    promotionPrice &&
    calculateDiscountPercent(salePrice, promotionPrice);

  const renderPriceContent = (priceType: PriceUnion) => {
    const props = {
      priceType,
      compact,
      couponDiscount: couponDiscount ?? undefined,
      isSalePriceElapsed: isSalePriceElapsed ?? undefined,
    };
    const dataTestId = dataTestIds && dataTestIds[priceType];
    const className = classNames && classNames[priceType];
    switch (priceType) {
      case PRICE_TYPE.PROMOTION:
        return (
          <PriceContent
            key={priceType}
            {...props}
            prices={{ regular: regularPrice, sale: promotionPrice }}
            formattedPrice={_promotionPrice}
            dataTestId={dataTestId || 'item-price'}
            className={className}
          />
        );
      case PRICE_TYPE.SALE:
        return (
          <PriceContent
            key={priceType}
            {...props}
            prices={{ regular: regularPrice, sale: salePrice }}
            formattedPrice={_salePrice}
            dataTestId={dataTestId || 'item-price'}
            className={className}
          />
        );
      case PRICE_TYPE.OLD_SALE:
        return (
          <PriceContent
            key={priceType}
            {...props}
            prices={{ regular: regularPrice, sale: salePrice }}
            formattedPrice={_salePrice}
            className={className}
          />
        );
      case PRICE_TYPE.REGULAR:
        return (
          <PriceContent
            key={priceType}
            {...props}
            prices={{ regular: regularPrice }}
            formattedPrice={_amount}
            dataTestId={dataTestId || 'item-price'}
            className={className}
          />
        );
      case PRICE_TYPE.PERSONALIZED:
        return (
          <PriceContent
            key={priceType}
            {...props}
            prices={{ regular: personalizationPrice as number }}
            formattedPrice={_personalizedPrice}
            dataTestId={dataTestId || 'item-price-personalized'}
            className={className}
          />
        );
      case PRICE_TYPE.OLD_REGULAR:
        return (
          <PriceContent
            key={priceType}
            {...props}
            prices={{ regular: regularPrice }}
            formattedPrice={_amount}
            dataTestId={dataTestId || 'item-price-base'}
            className={className}
            wrapText={wrapText}
          />
        );
      default:
        return null;
    }
  };

  let prices: PriceUnion[] = [PRICE_TYPE.REGULAR];

  if (isFinalPrice) {
    return <>{prices.map(renderPriceContent)}</>;
  }

  // For personalized products we always show the personalized price
  // There are upcoming changes to how this is handled in SF which will
  // be handled in https://pumaglobal.atlassian.net/browse/WEB-343
  if (personalizationPrice) {
    prices = [PRICE_TYPE.PERSONALIZED];
    return <>{prices.map(renderPriceContent)}</>;
  }

  if (
    isNumber(salePrice) &&
    isNumber(promotionPrice) &&
    salePrice !== regularPrice &&
    promotionPrice !== regularPrice &&
    promotionPrice !== salePrice
  ) {
    prices = showAllPricesAtOnce
      ? [PRICE_TYPE.PROMOTION, PRICE_TYPE.OLD_SALE, PRICE_TYPE.OLD_REGULAR]
      : [PRICE_TYPE.PROMOTION, PRICE_TYPE.OLD_REGULAR];

    if (isSalePriceElapsed) {
      prices = [PRICE_TYPE.PROMOTION, PRICE_TYPE.OLD_SALE];
    }
    return <>{prices.map(renderPriceContent)}</>;
  }

  if (isNumber(salePrice) && salePrice !== regularPrice) {
    prices = [PRICE_TYPE.SALE, PRICE_TYPE.OLD_REGULAR];

    if (isSalePriceElapsed) {
      prices = [PRICE_TYPE.SALE];
    }
    return <>{prices.map(renderPriceContent)}</>;
  }

  if (isNumber(promotionPrice) && promotionPrice !== regularPrice) {
    prices = [PRICE_TYPE.PROMOTION, PRICE_TYPE.OLD_REGULAR];

    return <>{prices.map(renderPriceContent)}</>;
  }

  return <>{prices.map(renderPriceContent)}</>;
};
