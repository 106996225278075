import React, { createContext, useContext, useState } from 'react';

const CrawlerContext = createContext<boolean | undefined>(undefined);

export const useIsCrawler = () => useContext(CrawlerContext);

export const CrawlerProvider: React.FC<{ isCrawler: boolean | undefined }> = ({
  isCrawler,
  children,
}) => {
  const [isCrawlerState] = useState<boolean | undefined>(isCrawler);

  return (
    <CrawlerContext.Provider value={isCrawlerState}>
      {children}
    </CrawlerContext.Provider>
  );
};
