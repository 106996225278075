import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import { HorizontalAlignment, VerticalAlignment } from 'groq/global-types';
import {
  SanityVideoObject,
  SanityVideoObjectType,
} from 'groq/objects/SanityVideoObject';

import {
  SanityVideoAssetDocument,
  VideoAssetDocumentType,
} from './VideoAssetDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/VideoHero.tsx

export type VideoHeroDocumentType = {
  id: string;
  _id: string;
  _type: string;
  autoplay: boolean;
  backgroundVideo?: Maybe<VideoAssetDocumentType>;
  backgroundVideoObject?: Maybe<SanityVideoObjectType>;
  controls: boolean;
  copy?: Maybe<LocaleStringType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  footnote?: Maybe<LocaleStringType>;
  header?: Maybe<LocaleStringType>;
  horizontalAlignment?: Maybe<HorizontalAlignment>;
  loop: boolean;
  minAspectRatio: number;
  mobileBackgroundVideo?: Maybe<VideoAssetDocumentType>;
  mobileBackgroundVideoObject?: Maybe<SanityVideoObjectType>;
  mute: boolean;
  overlayContent?: Maybe<boolean>;
  textColor?: Maybe<string>;
  verticalAlignment?: Maybe<VerticalAlignment>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
};

export const VideoHeroDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    "backgroundVideo": ${SanityVideoAssetDocument('backgroundVideo.asset->')},
    ${SanityVideoObject('backgroundVideoObject')},
    "mobileBackgroundVideo": ${SanityVideoAssetDocument(
      'mobileBackgroundVideo.asset->'
    )},
    ${SanityVideoObject('mobileBackgroundVideoObject')},
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${LocaleString('footnote')},
    ${CallToActionObject('ctas[]')},
    textColor,
    horizontalAlignment,
    verticalAlignment,
    overlayContent,
    autoplay,
    controls,
    loop,
    mute,
    minAspectRatio,
    availableFrom,
    availableTo
  }
`;
