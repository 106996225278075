export const calculateDiscountPercent = (
  price: number,
  activePrice: number
) => {
  // Calculate percentage and convert to fixed point notation to avoid floating point errors
  const percentageString = ((1 - activePrice / price) * 100).toFixed(1);
  // Convert string back into number so we can floor it
  const percentageNumber = parseInt(percentageString, 10);
  // Floor the value to prevent any point percentages
  return Math.floor(percentageNumber);
};
