import { Badge, BadgeProps } from '@global-ecom/nitro-uds/elements';
import { tw } from 'twind';

type ItemCountBadgeProps = Partial<BadgeProps> & {
  count: number;
  float?: boolean;
  className?: string;
};

export const ItemCountBadge = ({
  count,
  float = false,
  className = '',
  ...props
}: ItemCountBadgeProps) =>
  count > 0 ? (
    <Badge
      className={tw(
        'pointer-events-none',
        float && 'absolute z-10 -top-1 -right-1',
        className
      )}
      label={String(count)}
      aria-hidden="true"
      color="brand"
      size="sm"
      round
      {...props}
    />
  ) : null;
