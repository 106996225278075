import React, { useEffect } from 'react';
import {
  Button,
  Container,
  Spacer,
  Stack,
  Text,
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogHeading,
  DialogBody,
  LiveRegion,
  Alert,
  Heading,
} from '@global-ecom/nitro-uds/elements';
import { tw } from 'twind';

import {
  Size,
  Variant,
  useProductQuery,
  useVariantsQuery,
} from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { useCart } from 'hooks/useCart';
import { getSizesFromSizeGroups } from 'utils/products';
import { buildProductUrl } from 'utils/buildProductUrl';
import { resizeProductImage } from 'utils/image';
import { extractError } from 'utils/extractError';
import { useMiniCart } from 'hooks/useMiniCart';
import { event, AnalyticsEvents } from 'utils/analytics';
import { Dimensions } from 'types/analyticTypes';
import { Link as LegacyLink } from 'ui/elements/Link';
import { useWidgetInstanceStore } from 'store';
import { setReferrerType } from 'utils/referrerParametersUtils';
import { REFERRER_TYPE_VAL } from 'utils/constants';
import { useSiteConfig } from 'hooks/useSiteConfig';

import { ProductPrice } from '../ProductPrice/ProductPrice';
import { Sizes } from '../Sizes';
import { ProductListItemImages } from '../ProductListItemImages';
import { Skeleton } from '../Skeleton';
import ChevronButton from '../ChevronButton';
import { Img } from '../Img';

import { StyliticsBundle, StyliticsItem } from './types';

const MAX_PRODUCTS_PER_BUNDLE = 5;

type StyliticsModalProps = {
  bundles: StyliticsBundle[];
  selectedBundle: StyliticsBundle;
  setSelectedBundle: (
    value: React.SetStateAction<StyliticsBundle | undefined>
  ) => void;
  open: boolean;
  onOpenChange: (value: React.SetStateAction<boolean>) => void;
};

export const StyliticsModal = (props: StyliticsModalProps) => {
  const { isMiniCartVisible } = useMiniCart();
  const t = useTranslate();

  return (
    <DialogRoot
      dataTestId="stylitics-modal"
      size={{ _: 'full', xs: 'sm' }}
      drawer={{ _: false, xs: true }}
      justify="end"
      open={props.open}
      onOpenChange={open => {
        event(AnalyticsEvents.STYLITICS_INTERACTION, {
          domevent: 'Stylitics - Close',
          [Dimensions.d27]: props.selectedBundle?.id,
        });

        props.onOpenChange(open);
      }}
      autoFocus="content"
    >
      <DialogContent
        onInteractOutside={e => {
          const target = e.detail.originalEvent.target as HTMLElement | null;
          const originatesFromMiniCart = Boolean(target?.closest('#modal'));

          // Prevents `StyliticsModal` from closing when the user interacts with `MiniCart` or other Modal
          if (isMiniCartVisible || originatesFromMiniCart) e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogHeading>{t('completeTheLook')}</DialogHeading>
        </DialogHeader>
        <DialogBody className="overflow-y-auto overflow-x-hidden">
          <Bundle
            bundle={props.selectedBundle}
            closeModal={() => props.onOpenChange(false)}
          />
          <Scroller
            bundles={props.bundles}
            currentBundle={props.selectedBundle}
            setCurrentBundle={props.setSelectedBundle}
          />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

const Bundle = (props: { bundle: StyliticsBundle; closeModal: () => void }) => {
  const [currentItem, setCurrentItem] = React.useState<StyliticsItem>();
  const { items, primary_remote_id } = props.bundle;
  const {
    staticFeatures: { showHeaderSubHeaderProductDetail },
  } = useSiteConfig();
  React.useEffect(() => setCurrentItem(items[0]), [items, primary_remote_id]);

  // Sometimes Stylitics sends us more than 5 items per bundle. We only want to show the first 5.
  const itemsToRender = items.slice(0, MAX_PRODUCTS_PER_BUNDLE);

  return (
    <Container>
      <ProductSelector
        items={itemsToRender}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        currentBundle={props.bundle}
      />
      <Spacer gap="sm" />
      {currentItem && (
        <Product
          key={currentItem.item_id}
          item={currentItem}
          bundle={props.bundle}
          closeModal={props.closeModal}
          flagHeaderSubHeader={showHeaderSubHeaderProductDetail}
        />
      )}
    </Container>
  );
};

type ProductSelectorProps = {
  items: StyliticsItem[];
  currentItem?: StyliticsItem;
  setCurrentItem: (
    value: React.SetStateAction<StyliticsItem | undefined>
  ) => void;
  currentBundle: StyliticsBundle;
};

const ProductSelector = (props: ProductSelectorProps) => {
  const numberOfItems = props.items.length;

  const { widgetInstance } = useWidgetInstanceStore();

  const [variantsQuery] = useVariantsQuery({
    variables: {
      input: { styleNumberIds: props.items.map(item => item.remote_id) },
    },
    pause: props.items.length === 0,
  });

  return (
    <Stack
      direction="inline"
      gap="xs"
      className="justify-center"
      dataTestId="stylitics-modal-product-selector"
    >
      {variantsQuery.fetching
        ? [...Array(numberOfItems)].map((_, i) => (
            <Skeleton
              key={i}
              className="w-16 aspect-1-1 flex-none"
              delay={`${(i + 1) * 100}ms`}
            />
          ))
        : props.items.map(product => {
            const variant = variantsQuery.data?.variants?.find(variant => {
              const styleNumber = `${variant.masterId}_${variant.colorValue}`;
              return styleNumber === product.remote_id;
            });
            const srcImg = variant?.images[0].href ?? product.image_url;

            const isCurrentItem =
              product.item_id === props.currentItem?.item_id;

            return (
              <div
                key={product.item_id}
                className="w-16 flex-none transform-gpu"
              >
                <Img
                  src={resizeProductImage(srcImg, 128, '2000')}
                  alt={variant?.images[0].alt ?? ''}
                  title={variant?.images[0].alt}
                  className={tw([
                    !product.stocked && 'opacity-50',
                    !variant && 'opacity-50',
                    isCurrentItem && 'border border-puma-black-200 rounded-sm',
                  ])}
                  onClick={() => {
                    event(AnalyticsEvents.STYLITICS_INTERACTION, {
                      domevent: 'Stylitics - Select Product',
                      [Dimensions.d27]: props.currentBundle.id,
                      stylitics_product: variant?.name,
                      stylitics_product_id: product?.remote_id,
                    });

                    if (product?.remote_id) {
                      widgetInstance?.trackClickItem(product?.remote_id);
                    }

                    event(AnalyticsEvents.GA4_CustomEvent, {
                      event_name: AnalyticsEvents.GA4EC_Stylitics,
                      event_params: {
                        user_action: `${AnalyticsEvents.GA4_PRODUCT_CLICK}`,
                        item_id_ep: `${variant?.id}`,
                        item_name_ep: `${variant?.name}`,
                        stylitics_outfit_id: `${props.currentBundle.id}`,
                        stylitics_product_id: `${product?.remote_id}`,
                      },
                    });
                    if (!variant) return;

                    props.setCurrentItem(product);
                  }}
                />
              </div>
            );
          })}
    </Stack>
  );
};

type ProductProps = {
  item: StyliticsItem;
  bundle: StyliticsBundle;
  closeModal: () => void;
  flagHeaderSubHeader: boolean | undefined;
};

const Product = (props: ProductProps) => {
  const t = useTranslate();

  const { widgetInstance } = useWidgetInstanceStore();
  const { addToCart } = useCart({ pause: true });

  const [masterProductId, colorValue] = props.item.remote_id.split('_');

  const [productQuery] = useProductQuery({
    variables: { id: masterProductId },
  });

  const masterProduct = productQuery.data?.product;
  const variant = masterProduct?.variations.find(
    variation => variation.colorValue === colorValue
  );

  const [selectedSize, setSelectedSize] = React.useState<Size>();
  const [addingToCart, setAddingToCart] = React.useState(false);
  const [addToCartErrorMsg, setAddToCartErrorMsg] = React.useState<string>();

  useEffect(() => {
    if (!productQuery.fetching && props.item?.remote_id) {
      // track view item when product is fetched
      widgetInstance?.trackViewItem(props.item?.remote_id);
    }
  }, [productQuery.fetching, props.item?.remote_id, widgetInstance]);

  // Skeleton loader
  if (productQuery.fetching) {
    const delay = '100ms';

    return (
      <Container dataTestId="stylitics-modal-skeleton-loader">
        <Stack gap="sm">
          <Skeleton className="w-full aspect-1-1" delay={delay} />
          <Stack direction="row" gap="none">
            <Skeleton className="w-full h-14" delay={delay} />
            <Stack direction="column" gap="none">
              <Skeleton className="w-16 h-6" delay={delay} />
            </Stack>
          </Stack>
          <Stack>
            <Skeleton className="w-full h-32" delay={delay} />
          </Stack>
          <Skeleton className="w-full h-10" delay={delay} />
        </Stack>
      </Container>
    );
  }

  if (!variant || !masterProduct) return null;

  const productUrl = buildProductUrl(masterProduct.id, masterProduct.name, {
    swatch: variant.colorValue,
  });

  const productName = props.flagHeaderSubHeader
    ? masterProduct?.header
    : masterProduct?.name;
  const productNameHeading = (
    <Heading dataTestId="stylitics-modal-product-name" size="sm" asChild>
      <div>
        <h2>{productName}</h2>
        {props.flagHeaderSubHeader && masterProduct?.subHeader && (
          <Text
            data-test-id="pdp-product-subheader"
            className="w-full mobile:text-md text-lg font-normal text-puma-gray-60 line-clamp-2"
          >
            {masterProduct.subHeader}
          </Text>
        )}
      </div>
    </Heading>
  );

  return (
    <Container dataTestId="stylitics-modal-product">
      <Stack gap="sm">
        <ProductListItemImages
          variant={variant as Variant}
          dataTestId="stylitics-modal-product-images"
        />
        <Stack direction="row" gap="xs">
          {/* Don't link to current product */}
          {props.item.remote_id !== props.bundle.primary_remote_id ? (
            <LegacyLink
              href={productUrl}
              onClick={() => {
                setReferrerType(REFERRER_TYPE_VAL.Stylitics);
                event(AnalyticsEvents.STYLITICS_INTERACTION, {
                  domevent: 'Stylitics - Product Detail Click',
                  [Dimensions.d27]: props.bundle.id,
                  stylitics_product: variant.name,
                  stylitics_product_id: props.item.remote_id,
                });
                props.closeModal();
              }}
              className="hover:underline"
            >
              {productNameHeading}
            </LegacyLink>
          ) : (
            productNameHeading
          )}
          <Stack direction="column" gap="none">
            <ProductPrice
              price={{
                amount: variant.productPrice?.price,
                promotionPrice: variant.productPrice?.promotionPrice,
                salePrice: variant.productPrice?.salePrice,
              }}
            />
          </Stack>
        </Stack>
        <Sizes
          sizeGroups={variant.sizeGroups}
          desiredSize={selectedSize?.id}
          onChange={sizeId => {
            const sizes = getSizesFromSizeGroups(variant.sizeGroups);
            const desiredSize = sizes.find(size => size.id === sizeId);

            event(AnalyticsEvents.STYLITICS_INTERACTION, {
              domevent: 'Stylitics - Size Click',
              domlabel: desiredSize?.label,
              [Dimensions.d27]: props.bundle.id,
              stylitics_product: variant.name,
              stylitics_product_id: props.item.remote_id,
            });

            setSelectedSize(desiredSize);
            setAddToCartErrorMsg(undefined);
          }}
        />
        <LiveRegion dataTestId="stylitics-modal-add-to-cart-error">
          {addToCartErrorMsg && (
            <>
              <Alert variant="error" content={addToCartErrorMsg} />
              <Spacer gap="sm" />
            </>
          )}
        </LiveRegion>
        <Button
          label={t('addToCart')}
          disabled={Boolean(
            productQuery.fetching || variant.displayOutOfStock || !selectedSize
          )}
          loading={addingToCart}
          onClick={async () => {
            setAddingToCart(true);
            setAddToCartErrorMsg(undefined);

            const result = await addToCart(
              {
                swatch: variant.colorValue,
                size: selectedSize?.id,
                quantity: 1,
                productId: selectedSize?.productId ?? '',
                masterId: masterProduct.id,
              },
              { [Dimensions.d100]: 'stylitics' }
            );

            widgetInstance?.trackAddItemToCart({
              itemId: masterProduct.id,
              price: variant.productPrice?.price || undefined,
              placement: 'widget',
            });

            /**
             * This fix is necessary to prevent issues caused by the MiniCart `Modal` appearing on top of the UDS Dialog component.
             * It unsets the `top` value which is set in the `useBodyScrollLook` hook, which is already being set by the UDS Dialog component.
             *
             * TODO: Remove this when the MiniCart Modal is replaced with a UDS Dialog, or MiniCart is removed entirely. */
            document.body.style.top = '';

            if (!result.error) {
              setSelectedSize(undefined);

              event(AnalyticsEvents.STYLITICS_INTERACTION, {
                domevent: 'Stylitics - Add to Cart',
                [Dimensions.d27]: props.bundle.id,
                stylitics_product: variant.name,
                stylitics_product_id: props.item.remote_id,
              });
            }

            // This will set the error message if there is one, or unset it if there isn't
            setAddToCartErrorMsg(extractError(result));
            setAddingToCart(false);
          }}
          className="w-full"
          dataTestId="stylitics-modal-add-to-cart-button"
        />
      </Stack>
    </Container>
  );
};

type ScrollerProps = {
  bundles: StyliticsBundle[];
  currentBundle: StyliticsBundle;
  setCurrentBundle: (
    value: React.SetStateAction<StyliticsBundle | undefined>
  ) => void;
};

const Scroller = ({
  bundles,
  currentBundle,
  setCurrentBundle,
}: ScrollerProps) => {
  const t = useTranslate();

  const previous = () => setCurrentBundle(bundles[currentBundle.position - 1]);
  const next = () => setCurrentBundle(bundles[currentBundle.position + 1]);

  const isFirstBundle = currentBundle.position === 0;
  const isLastBundle = currentBundle.position === bundles.length - 1;

  return (
    <Stack
      direction="row"
      className="justify-around items-center my-4"
      dataTestId="stylitics-modal-look-scroller"
    >
      <div className="w-8">
        {!isFirstBundle && (
          <ChevronButton
            direction="left"
            variant="ghost"
            onClick={previous}
            dataTestId="stylitics-modal-look-scroller-left"
          />
        )}
      </div>
      <Text weight="bold" dataTestId="stylitics-modal-look-scroller-text">
        {t('lookXOfY', { x: currentBundle.position + 1, y: bundles.length })}
      </Text>
      <div className="w-8">
        {!isLastBundle && (
          <ChevronButton
            direction="right"
            variant="ghost"
            onClick={next}
            dataTestId="stylitics-modal-look-scroller-right"
          />
        )}
      </div>
    </Stack>
  );
};
