import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { removeLocalePrefix } from 'utils/locale';

import { useSiteConfig } from './useSiteConfig';
export const useGetRecommenderLocation = () => {
  const { locale } = useSiteConfig();
  const router = useRouter();

  return useCallback(() => {
    const recommenderPath = removeLocalePrefix(router.asPath, locale);
    const pdpMatcher = /^\/pd\/.*/;

    if (recommenderPath === '') return 'home';
    if (pdpMatcher.test(recommenderPath)) return 'pdp';
    return recommenderPath;
  }, [locale, router.asPath]);
};
