import { ResourcesVariables } from 'locales/localeType';

export enum PRICE_TYPE {
  PROMOTION = 'PROMOTION',
  SALE = 'SALE',
  OLD_SALE = 'OLD_SALE',
  OLD_REGULAR = 'OLD_REGULAR',
  REGULAR = 'REGULAR',
  PERSONALIZED = 'PERSONALIZED',
}

export type PriceUnion = keyof typeof PRICE_TYPE;

export type ContentVariables = ResourcesVariables[keyof ResourcesVariables];

export type PriceOptions = {
  [Key in PriceUnion]: {
    priceStyle: string;
    contentKey: keyof ResourcesVariables;
    variables: ContentVariables;
  };
};
