import { gql } from 'urql';

import { NESTED_ORDER_DETAILS_FRAGMENT } from 'gql/fragments/nestedOrder';
import {
  STANDARD_ORDER_DETAILS_FRAGMENT,
  ORDER_PREVIEW_FRAGMENT,
} from 'gql/fragments/standardOrder';
import { ADDRESS_FRAGMENT } from 'gql/fragments/address';
import { USER_FRAGMENT } from 'gql/fragments/user';
import {
  ORDER_CONFIRMATION_FRAGMENT,
  PAYMENT_INSTRUMENT_FRAGMENT,
} from 'gql/fragments/baseOrder';
import {
  CART_PRODUCT_ITEM_FRAGMENT,
  PRODUCT_CART_ITEM_ANALYTICS_DIMENSIONS_FIELDS,
} from 'gql/fragments/cart';

export const ORDER_QUERY = gql`
  query Order(
    $orderNo: String!
    $includeProductAnalyticsDetails: Boolean = false
  ) {
    order(orderNo: $orderNo) {
      ... on StandardOrder {
        subTotal
        shippingCosts
        shippingSurcharge {
          amount
          label
          tooltip
        }
        orderAdjustments {
          promotionId
          itemText
          price
          couponCode
        }
        shippingAdjustments {
          promotionId
          itemText
          price
          couponCode
        }
        couponItems {
          code
          couponItemId
          statusCode
          valid
        }
        orderNo
        taxTotal
        taxation
        estimatedTotal
        promoDiscountValue
        revenue @include(if: $includeProductAnalyticsDetails)
        orderStatus {
          code
          label
        }
        __typename
        refundInformation
        createdAt
        products {
          ...productCartItemFields
          ...productCartItemAnalyticsDimensionsFields
            @include(if: $includeProductAnalyticsDetails)
        }
        ...orderPaymentInstrumentFields
        ...orderConfirmationFields
        payPayAsyncResultNoticed
      }
    }
  }

  ${ORDER_CONFIRMATION_FRAGMENT}
  ${PAYMENT_INSTRUMENT_FRAGMENT}
  ${CART_PRODUCT_ITEM_FRAGMENT}
  ${PRODUCT_CART_ITEM_ANALYTICS_DIMENSIONS_FIELDS}
`;

export const ORDER_DETAILS_QUERY = gql`
  query OrderDetails($orderNo: String!) {
    order(orderNo: $orderNo) {
      ...standardOrderDetailsFields
      ...nestedOrderDetailsFields
    }
  }

  ${STANDARD_ORDER_DETAILS_FRAGMENT}
  ${NESTED_ORDER_DETAILS_FRAGMENT}
`;

export const ORDER_HISTORY_ME_QUERY = gql`
  query OrderHistoryMe(
    $from: String
    $until: String
    $offset: Int
    $limit: Int
  ) {
    me {
      ...userFields
      addresses {
        ...addressFields
      }
      orders(from: $from, until: $until, offset: $offset, limit: $limit) {
        nodes {
          ...orderPreviewFields
        }
        pageInfo {
          offset
          nextOffset
          totalCount
        }
      }
    }
  }

  ${USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${ORDER_PREVIEW_FRAGMENT}
`;

export const SEARCH_ORDER_BY_PHONE_QUERY = gql`
  query SearchOrderByPhone($orderNo: String!, $phone: String!) {
    searchOrderByPhone(orderNo: $orderNo, phone: $phone) {
      ...standardOrderDetailsFields
      ...nestedOrderDetailsFields
    }
  }

  ${STANDARD_ORDER_DETAILS_FRAGMENT}
  ${NESTED_ORDER_DETAILS_FRAGMENT}
`;

export const SEARCH_ORDER_BY_EMAIL_POSTCODE_QUERY = gql`
  query SearchOrderByEmailPostcode(
    $orderNo: String!
    $email: String!
    $postcode: String!
  ) {
    searchOrderByEmailPostcode(
      orderNo: $orderNo
      email: $email
      postcode: $postcode
    ) {
      ...standardOrderDetailsFields
      ...nestedOrderDetailsFields
    }
  }

  ${STANDARD_ORDER_DETAILS_FRAGMENT}
  ${NESTED_ORDER_DETAILS_FRAGMENT}
`;

export const SEARCH_ORDER_BY_EMAIL_QUERY = gql`
  query SearchOrderByEmail($orderNo: String!, $email: String!) {
    searchOrderByEmail(orderNo: $orderNo, email: $email) {
      ...standardOrderDetailsFields
      ...nestedOrderDetailsFields
    }
  }

  ${STANDARD_ORDER_DETAILS_FRAGMENT}
  ${NESTED_ORDER_DETAILS_FRAGMENT}
`;

export const SEARCH_ORDER_BY_EMAIL_PHONE_QUERY = gql`
  query SearchOrderByEmailPhone(
    $orderNo: String!
    $phone: String!
    $email: String!
  ) {
    searchOrderByEmailPhone(orderNo: $orderNo, phone: $phone, email: $email) {
      ...standardOrderDetailsFields
      ...nestedOrderDetailsFields
    }
  }

  ${STANDARD_ORDER_DETAILS_FRAGMENT}
  ${NESTED_ORDER_DETAILS_FRAGMENT}
`;
