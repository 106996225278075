import { SearchOption, Text, Anchor } from '@global-ecom/nitro-uds/elements';
import { tw } from 'twind';

import { capitalize } from 'utils/capitalize';

import { SearchOptionList } from './SearchOptionList';

type SearchTermListProps = {
  heading: string;
  terms: string[];
  onClick?: (term: string) => void;
};

export const SearchTermList = ({
  heading,
  terms,
  onClick,
}: SearchTermListProps) => {
  // show maximum of 6 search term suggestions
  const termsToRender = terms.filter(x => x.length > 0).slice(0, 6);

  return (
    <SearchOptionList
      id="search-term-list"
      dataTestId="search-term-list"
      heading={heading}
    >
      {termsToRender.map((str, idx) => {
        const term = capitalize(str);
        const searchUrl = `/search?q=${term}`;
        return (
          <li key={term} className={tw(idx <= 3 ? 'block' : 'hidden sm:block')}>
            <SearchOption>
              <Anchor
                dataTestId="search-term-list-item"
                href={searchUrl}
                onClick={() => onClick?.(term)}
                className="text-neutral-60 hover:text-neutral-100 [data-focused=true]:text-neutral-100 hover:bg-none"
              >
                <Text weight="bold">{term}</Text>
              </Anchor>
            </SearchOption>
          </li>
        );
      })}
    </SearchOptionList>
  );
};
