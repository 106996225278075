import { useRouter } from 'next/router';

import { useClampedText } from 'hooks/useClampedText';
import { isServer } from 'utils/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import { getIsMobile } from 'utils/media';

export const ClampedInfoMessageText = ({
  infoMessage,
  setShowInfoMessage,
  readMoreLink,
}: {
  infoMessage: string;
  setShowInfoMessage: React.Dispatch<React.SetStateAction<boolean>>;
  readMoreLink: string;
}) => {
  const isMobile = getIsMobile();
  const router = useRouter();
  const { width } = useWindowSize();

  if (!isServer && !window?.router) {
    Object.defineProperty(window, 'router', { value: router });
  }
  if (!isServer && !window?.setShowInfoMessage) {
    Object.defineProperty(window, 'setShowInfoMessage', {
      value: setShowInfoMessage,
    });
  }

  const clampedTextInnerHtml = useClampedText({
    maxLines: 6, // NOTE: using 6 lines the clamp.js lib will clamp the text to 4 lines because inside the logic of the lib calculates with the extra padding that we need to add
    maxWidthInPx: isMobile ? `${String(width)}px` : '375px',
    text: infoMessage || '',
    endHtml: `<a href="${readMoreLink}" onclick="((event) => {
            event.preventDefault();
            window.router.replace(event.target.href);
            window.setShowInfoMessage(false)
          })(event)" class="text-puma-black underline">Read more</a>`,
  });

  return (
    <p
      dangerouslySetInnerHTML={{
        __html: clampedTextInnerHtml,
      }}
    ></p>
  );
};
