import { authExchange } from '@urql/exchange-auth';
import router from 'next/router';

import { authEventEmitter } from 'utils/authEventEmitter';
import { clientLogger } from 'utils/clientLogger';

import {
  destroyStoredAuthData,
  getStoredAuthData,
  storeAuthData,
} from './cookie';
import { getRequestHeaders } from './headers';
import { getGuestToken, isTokenExpired, refreshAuthToken } from './token';
import { getOperationName, hasGraphQLAuthError } from './utils';

export const createClientAuthExchange = () =>
  authExchange(async utils => {
    const country = router.query.country as string;

    return {
      addAuthToOperation(operation) {
        const authState = getStoredAuthData();
        const operationName = getOperationName(operation) || '';

        // capture debugging info
        const isAuth = ['GuestLogon', 'RefreshLogon'].includes(operationName);
        if (!isAuth) {
          if (!authState?.accessToken) {
            clientLogger.warn(
              { operationName },
              'authExchange: [client] send operation without a token'
            );
          } else if (isTokenExpired(authState.accessToken)) {
            clientLogger.warn(
              { operationName },
              'authExchange: [client] send operation with expired token'
            );
          }
        }

        const headers = getRequestHeaders(operationName, authState);
        return utils.appendHeaders(operation, headers);
      },
      willAuthError() {
        const authState = getStoredAuthData();
        if (!authState.accessToken) {
          return true;
        }

        return isTokenExpired(authState.accessToken);
      },
      didAuthError: hasGraphQLAuthError,
      async refreshAuth() {
        const authState = getStoredAuthData();

        try {
          // if the token expired and we have a refresh token then try use it
          if (authState.refreshToken) {
            const result = await refreshAuthToken({
              refreshToken: authState.refreshToken,
              mutate: utils.mutate,
            });

            if (result) {
              // successful refresh, update all stored tokens
              storeAuthData({ payload: result, country });
              return;
            }

            // we have an invalid refresh token, so destroy
            // all the current stored auth data so we can rebuild
            destroyStoredAuthData(country);
          }

          // refreshing the token failed or we didn't have one so create a new one
          const result = await getGuestToken({ mutate: utils.mutate });
          storeAuthData({ payload: result, country });
          authEventEmitter.emit('token-changed');
        } catch (err) {
          clientLogger.error(
            { err },
            'authExchange: [client] failed to refreshAuth'
          );

          destroyStoredAuthData(country);
          authEventEmitter.emit('token-changed');
        }
      },
    };
  });
