import { useEffect } from 'react';

type UseDialogDismissProps<T> = {
  ref: React.MutableRefObject<T | null>;
  external?: React.MutableRefObject<HTMLElement | null>;
  handler: (e?: MouseEvent | TouchEvent | KeyboardEvent) => void;
};

export function useDialogDismiss<T extends HTMLElement>(
  props: UseDialogDismissProps<T>
) {
  const { ref, external, handler } = props;

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (
        event.isComposing ||
        event.defaultPrevented ||
        event.code !== 'Escape'
      ) {
        return;
      }

      event.preventDefault();
      handler(event);
    };

    const onClick = (event: MouseEvent | TouchEvent) => {
      if (
        external?.current === (event.target as Node) ||
        external?.current?.contains(event.target as Node) ||
        !ref.current ||
        ref.current.contains(event.target as Node)
      ) {
        return;
      }

      event.stopPropagation();
      handler(event);
    };

    document.addEventListener('keydown', onKeydown);
    document.addEventListener('mousedown', onClick);
    document.addEventListener('touchstart', onClick);

    return () => {
      document.removeEventListener('keydown', onKeydown);
      document.removeEventListener('mousedown', onClick);
      document.removeEventListener('touchstart', onClick);
    };
  }, [ref, external, handler]);
}
