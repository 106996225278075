import { tw } from 'twind';

export type StyliticsChevronBtnProps = {
  direction: 'left' | 'right';
  onClick: () => void;
};

const StyliticsChevronButton = (props: StyliticsChevronBtnProps) => {
  const directionClass =
    props.direction === 'left'
      ? 'stylitics-chevron-button-prev'
      : 'stylitics-chevron-button-next';
  return (
    <button
      className={tw('stylitics-chevron-button', directionClass)}
      type="button"
      data-test-id={directionClass}
      aria-label={`${props.direction}-arrow`}
      onClick={props.onClick}
    >
      <svg
        viewBox="0 0 9 16"
        xmlns="http://www.w3.org/2000/svg"
        className="stylitics-chevron-arrow-svg"
        data-test-id={`${directionClass}-svg`}
      >
        <path d="M0,0 L9,8 L0,16"></path>
      </svg>
    </button>
  );
};

export default StyliticsChevronButton;
