import React, { useCallback, useEffect, useRef, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

import {
  VIDEO_GA4_EVENT_PERCENTAGES,
  VIDEO_GA4_EVENT_PERCENTAGE_TYPE,
} from 'utils/constants';
import { useOnRouterChangeStart } from 'hooks/useOnRouterChangeStart';
import {
  firePreviousAndCurrentGA4VideoEvents,
  GA4VideoDataType,
} from 'utils/ga4VideoEvents';
import { useFiredEventsStore } from 'store';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { sleep } from 'utils/sleep';

type YoutubePlayerWithGA4EventsProps = {
  opts: YouTubeProps['opts'];
  videoId: string;
  className: string;
  title: string;
};

// when import normally use this component
// this is a wrapper of YoutubePlayerWithGA4Events_Child and only add the hook of pageEventContext
// NOTE: maybe in a future we can save and consume the pageviewEventHasFired with zustand
export const YoutubePlayerWithGA4Events = (
  props: YoutubePlayerWithGA4EventsProps
) => {
  const { pageviewEventHasFired } = usePageEventsContext();

  return (
    <YoutubePlayerWithGA4Events_Child
      {...{ pageviewEventHasFired }}
      {...props}
    />
  );
};

// when testing use this export
// this is the component without using pageEventContext hook so we can hardcode the pageviewEventHasFired when testing
export const YoutubePlayerWithGA4Events_Child = ({
  opts,
  className,
  title,
  videoId,
  // ───────────────────
  pageviewEventHasFired,
  onVideoStartCallback, // NOTE: this is only for testing purposes, to let storybook know when the video starts and use timeouts to check the fired events
}: YoutubePlayerWithGA4EventsProps & {
  pageviewEventHasFired: boolean;
  onVideoStartCallback?: () => void;
}) => {
  const { eventHasFired, setEventFired } = useFiredEventsStore();

  const youtubeComponentRef = useRef<YouTube>(null);

  const [videoProgress, setVideoProgress] = useState<number>(-1);
  const [currentPercentage, setCurrentPercentage] = useState<
    VIDEO_GA4_EVENT_PERCENTAGE_TYPE | undefined
  >(undefined);

  useOnRouterChangeStart(
    useCallback(() => {
      if (pageviewEventHasFired) {
        // when page start to change -> reset current local state
        // this avoid problems of fire again the events previously cleaned on pageEventContext
        setVideoProgress(-1);
        setCurrentPercentage(undefined);
      }
    }, [pageviewEventHasFired])
  );

  const getPlayerData = async () => {
    const player = youtubeComponentRef.current?.getInternalPlayer();
    const currentTime = (await player?.getCurrentTime()) || 0;
    const duration = (await player?.getDuration()) || 0;

    return { currentTime, duration };
  };

  useEffect(() => {
    // every 50 milis check the video progress and save to local state
    const every50Milis = async () => {
      const { currentTime, duration } = await getPlayerData();

      if (duration) {
        const toFixed0 = (value: number) => parseInt(value.toFixed(0), 10);
        const percent = toFixed0((currentTime / duration) * 100);

        setVideoProgress(percent);
      }
    };

    const interval = setInterval(every50Milis, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // get the "current" percentage from the percentages (0, 10, 25, 50, 75, 100)
    if (videoProgress !== -1 && pageviewEventHasFired) {
      const percentageToFire = [...VIDEO_GA4_EVENT_PERCENTAGES]
        .reverse()
        .find(percentage =>
          videoProgress >= parseInt(percentage) ? percentage : null
        );

      setCurrentPercentage(percentageToFire);
    }
  }, [videoProgress, pageviewEventHasFired]);

  const getVideoData = useCallback(async (): Promise<GA4VideoDataType> => {
    const { currentTime, duration } = await getPlayerData();
    return {
      video_current_time: (currentTime || 0).toFixed(2),
      video_duration: (duration || 0).toFixed(2),
      video_percent: 0, // the percentage will be set on the event
      video_provider: 'youtube',
      video_title: title,
      video_url: `https://www.youtube.com/watch?v=${videoId}`,
      visible: `${true}`,
    };
  }, [title, videoId]);

  useEffect(() => {
    // only call function to fire event when the current percentage changes (0, 10, 25, 50, 75, 100)
    if (pageviewEventHasFired) {
      if (currentPercentage !== undefined) {
        firePreviousAndCurrentGA4VideoEvents({
          percentage: currentPercentage,
          eventHasFired,
          setEventFired,
          getVideoData,
          onVideoStartCallback,
        });
      }
    }
    // NOTE: do not add eventHasFired neither setEventFired to the dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPercentage, pageviewEventHasFired, getVideoData]);

  return (
    <div data-test-id="youtube-player-with-ga4-events">
      <YouTube
        ref={youtubeComponentRef}
        videoId={videoId}
        iframeClassName={className}
        title={title}
        opts={opts}
        onReady={async e => {
          // NOTE: this fix a bug that the first duration obtained is
          //       different than the real duration and causes that the
          //       event 100% is never fired
          e.target.stopVideo();
          e.target.playVideo();
          await sleep(200);
        }}
        onPlay={() => setCurrentPercentage('0')}
        onEnd={() => setCurrentPercentage('100')}
      />
    </div>
  );
};
