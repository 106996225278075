import { gql } from 'urql';

export const PAYMENT_INSTRUMENT_FRAGMENT = gql`
  fragment orderPaymentInstrumentFields on BaseOrder {
    paymentInstruments {
      ... on Adyen {
        id
        __typename
        amount
        type
      }
      ... on AdyenKlarna {
        id
        __typename
        amount
        type
      }
      ... on AdyenKlarnaAccount {
        id
        __typename
        amount
        type
      }
      ... on AmbassadorCard {
        id
        __typename
        authorizationStatus
        maskedNumber
        amount
        type
      }
      ... on Scheme {
        id
        amount
        authorizationStatus
        type
        __typename
      }
      ... on CreditCard {
        id
        amount
        maskedNumber
        cardType
        authorizationStatus
        type
        __typename
      }
      ... on AfterPay {
        id
        authorizationStatus
        type
        __typename
      }

      ... on PayPay {
        id
        authorizationStatus
        __typename
      }
      ... on ApplePay {
        id
        authorizationStatus
        type
        __typename
      }
      ... on PayPal {
        id
        authorizationStatus
        type
        __typename
      }
      ... on GiftCard {
        id
        __typename
        maskedNumber
        authorizationStatus
        amount
        type
      }
      ... on CashOnDelivery {
        id
        amount
        type
        __typename
      }
      ... on UPI {
        id
        amount
        authorizationStatus
        type
        __typename
      }
      ... on Wallet {
        id
        amount
        authorizationStatus
        type
        __typename
      }
      ... on OnlineBanking {
        id
        amount
        authorizationStatus
        type
        __typename
      }
      ... on PayTm {
        id
        authorizationStatus
        amount
        type
        __typename
      }
      ... on StoreCreditInstrument {
        id
        amount
        type
        __typename
      }
      ... on ZipPay {
        id
        type
        __typename
      }
      ... on WechatPay {
        id
        type
        __typename
      }
      ... on Alipay {
        id
        type
        __typename
      }
      ... on KlarnaPayments {
        id
        type
        amount
        __typename
      }
      ... on Razorpay {
        id
        amount
        type
        __typename
      }
    }
  }
`;

export const ORDER_CONFIRMATION_FRAGMENT = gql`
  fragment orderAddress on OrderAddress {
    id
    salutation
    firstName
    lastName
    firstNamePronunciation
    lastNamePronunciation
    companyName
    address1
    address2
    streetNumber
    postalCode
    city
    countryCode
    stateCode
    phone
  }
  fragment orderConfirmationFields on BaseOrder {
    billingAddress {
      ...orderAddress
    }
    shipment {
      id
      shipmentMethod {
        id
        name
        price
      }
      address {
        ...orderAddress
      }
      deliveryDate
      deliveryTimeDisplayText
    }
    customer {
      email
      id
    }
  }
`;
