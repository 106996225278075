import { tw } from 'twind';

export const Badge = props => {
  return (
    <div
      className={tw(
        'rounded leading-none pb-1 px-1.5 font-bold text-sm',
        `bg-${props.color}`,
        `text-${props.color === 'black' ? 'white' : 'black'}`
      )}
    >
      <small>{props.label}</small>
    </div>
  );
};
