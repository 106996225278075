import { gql } from 'urql';

import { PORTABLE_BLOCK_FRAGMENT } from 'gql/fragments/portableBlock';

export const TERMS_AND_CONDITIONS_FRAGMENT = gql`
  fragment termsAndConditionsFields on TermsAndConditions {
    id
    __typename
    content {
      ...portableTextFields
    }
  }
  ${PORTABLE_BLOCK_FRAGMENT}
`;
