import { tw } from 'twind';

import { useTranslate } from 'hooks/useTranslations';

type SizeGroupOptionsProps = {
  names: (string | null | undefined)[];
  selectedName: string;
  onChange: (selectedName: string) => void;
};

export const SizeGroupOptions = ({
  names,
  selectedName,
  onChange,
}: SizeGroupOptionsProps) => {
  const t = useTranslate();

  // it isn't important what the ID is, just that it can be used to uniquely identify
  // the aria-describedby element for the group
  const id = `size-group-${Date.now()}`;

  return (
    <div role="group" aria-describedby={id} data-test-id="size-group-options">
      <h4 id={id} className="sr-only">
        {t('selectSizeGroup')}
      </h4>
      <div role="radiogroup" className="flex flex-row gap-1">
        {names.map(name => {
          if (!name) return;

          const idSafeName = name.replace(/\s/g, '-').toLowerCase();
          const isSelected = name === selectedName;

          return (
            <label
              key={name}
              data-size-group={idSafeName}
              className={tw(
                'relative border flex items-center justify-center flex-none rounded-sm cursor-pointer',
                isSelected && 'bg-puma-black text-white border-puma-black'
              )}
            >
              <input
                type="radio"
                name="size-group"
                data-test-id="size-group"
                checked={isSelected}
                className="absolute block appearance-none inset-0 cursor-pointer"
                onChange={() => onChange(name)}
              />
              <span className="inset-0 flex items-center justify-center h-8">
                <span
                  data-content="size-group-name"
                  className="text-sm px-4 text-uppercase font-bold"
                >
                  {name}
                </span>
              </span>
            </label>
          );
        })}
      </div>
    </div>
  );
};
