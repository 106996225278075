import clamp from 'clamp-js';
import { useCallback, useEffect, useState } from 'react';

const TEXT_TO_CLAMP_GHOST_ELEMENT = 'text-to-clamp-ghost-element';

export const useClampedText = ({
  text,
  maxLines,
  maxWidthInPx,
  endHtml,
}: {
  text: string;
  maxLines: number;
  maxWidthInPx: string;
  endHtml?: string;
}) => {
  const [clampedTextInnerHtml, setClampedTextInnerHtml] = useState<string>('');

  const setTheGhostElement = useCallback(() => {
    if (text) {
      const previousGhostTextToClampElement = document.getElementById(
        TEXT_TO_CLAMP_GHOST_ELEMENT
      );
      if (previousGhostTextToClampElement)
        previousGhostTextToClampElement.remove();

      const ghostElement = document.createElement('p');
      ghostElement.id = TEXT_TO_CLAMP_GHOST_ELEMENT;
      ghostElement.classList.add('absolute', 'opacity-0', '-z-50');
      ghostElement.style.width = maxWidthInPx;
      ghostElement.style.padding = '8px 12px';
      ghostElement.style.fontSize = '14px';
      ghostElement.style.lineHeight = '1.428';
      ghostElement.innerHTML = text;
      document.body.appendChild(ghostElement);
    }
  }, [text, maxWidthInPx]);

  const prepareClampedText = useCallback(() => {
    if (text) {
      const ghostTextToClampElement = document.getElementById(
        TEXT_TO_CLAMP_GHOST_ELEMENT
      );

      clamp(ghostTextToClampElement, {
        clamp: maxLines,
        useNativeClamp: false,
        animate: false,
        truncationChar: '  ',
        truncationHTML: `...${endHtml ? ' ' + endHtml : ''}`,
      });

      if (ghostTextToClampElement) {
        setClampedTextInnerHtml(ghostTextToClampElement.innerHTML || '');
        ghostTextToClampElement.remove();
      }
    }
  }, [text, endHtml, maxLines]);

  useEffect(() => {
    setTheGhostElement();
    prepareClampedText();
  }, [setTheGhostElement, prepareClampedText]);

  return clampedTextInnerHtml;
};
