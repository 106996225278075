/**
 * Resolves node in `obj` by `path`
 * @param path - path in the object string or string[]
 * @param obj - object
 * @param separator - separator used in the path
 * @return object | object[] | undefined
 */

export const resolveNode = <T>(
  path: string | string[],
  obj: Record<string, any>,
  separator = '.'
): T | undefined => {
  if (Array.isArray(path) && !path.length) return undefined;
  const properties = Array.isArray(path) ? path : path.split(separator);
  return (properties || []).reduce((prev, curr) => prev && prev[curr], obj);
};

export const avoidEmptyProperties = (
  object: Record<string, any>
): Record<string, any> => {
  const objectModified = {} as Record<string, any>;
  for (const propertyName of Object.keys(object)) {
    if (typeof object[propertyName] !== 'undefined') {
      objectModified[propertyName] = object[propertyName];
    }
  }
  return objectModified;
};

export const isPlainObject = <K extends string = string, V = any>(
  maybeObject: unknown
): maybeObject is Record<K, V> =>
  !!maybeObject &&
  typeof maybeObject === 'object' &&
  // @ts-expect-error -- __proto__ is what we check for, can be null.
  (maybeObject.__proto__ === null ||
    // @ts-expect-error -- __proto__ is what we check for, can be null.
    maybeObject.__proto__ === Object.prototype);
