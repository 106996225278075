import { tw } from 'twind';

import { Link as LegacyLink } from 'ui/elements/Link';
import { GaTrackData } from 'hooks/usePromotionView';
import useSerializeLocaleString from 'hooks/useSerializeLocaleString';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { Cta, LinkVariant } from '__generated__/graphql';
import { CallToActionObjectType } from 'groq/objects/CallToActionObject';

type CtaLinkProps = {
  underlined?: boolean;
  variant?: LinkVariant;
};

type CallToActionsProps = {
  ctas?: CallToActionObjectType[] | Cta[] | null;
  className?: string;
  linkProps?: CtaLinkProps;
  gaBannerData?: GaTrackData;
  ga4Data?: Ga4Data;
};

export const CallToActions = (props: CallToActionsProps) => {
  const serialize = useSerializeLocaleString();

  if (!props.ctas?.length) return null;

  return (
    <>
      {props.ctas.map((cta: CallToActionObjectType | Cta) => (
        <LegacyLink
          dataTestId="call-to-action"
          key={cta.id}
          href={cta.link}
          as="button"
          variant={props.linkProps?.variant ?? cta.variant ?? 'black'}
          className={tw(
            'pointer-events-auto whitespace-normal',
            props.className
          )}
          outlined={!!cta.outlined}
          newtab={!!cta.newtab}
          popup={!!cta.popup}
          query={cta.query}
          action={cta.action || undefined}
          underlined={props.linkProps?.underlined}
          gaBannerData={props.gaBannerData}
          ga4Data={
            props.ga4Data && {
              ...props.ga4Data,
              link_url: cta.link,
              cta_click: cta.title || '',
            }
          }
        >
          {serialize(cta.title)}
        </LegacyLink>
      ))}
    </>
  );
};
