import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@global-ecom/nitro-uds/elements';

import { useValidation } from 'hooks/useValidation';
import { useTranslate } from 'hooks/useTranslations';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { checkoutAddressHelpers } from 'ui/forms/custom/helpers';
import { CustomInput } from 'ui/forms/custom/types';
import { useAmazon } from 'hooks/useAmazon';

const CustomFormInput = ({
  field,
  required = false,
  prefix,
  submit,
}: {
  field: CustomInput;
  required?: boolean;
  prefix?: string;
  submit?: () => void;
}) => {
  const form = useFormContext();
  const [validate, dynamicValidation] = useValidation();
  const t = useTranslate();
  const { resolveInputError, getErrorMessageOrDefault } = useFormField(field);
  const shouldRenderAmazonPay = useAmazon().shouldRenderAmazonPay;

  return (
    <InputText
      key={field.key}
      name={field.name}
      id={field.id}
      dataTestId={field.dataTestId}
      required={required}
      placeholder={field.placeholder}
      label={field.label}
      maxLength={field.maxLength}
      minLength={field.minLength}
      errorText={resolveInputError()}
      autoCapitalize={field.autoCapitalize}
      ref={form.register({
        validate: async value => {
          let errorMessage: string | undefined;
          if (
            value &&
            field.pattern &&
            required &&
            !new RegExp(field.pattern).test(value.trim()) &&
            !shouldRenderAmazonPay
          ) {
            return field.patternError || t('invalidInput');
          }
          if (required && !shouldRenderAmazonPay && !value.trim()) {
            return t('invalidInput');
          }
          if (
            value &&
            field.pattern &&
            !new RegExp(field.pattern).test(value)
          ) {
            return field.patternError || t('invalidInput');
          }
          if (field.validations) {
            for (const v of field.validations) {
              if (typeof v === 'string') {
                errorMessage = validate[v](value, form.getValues());
              } else {
                errorMessage = dynamicValidation(value, form, v);
              }
              if (errorMessage) break;
            }
          }
          return errorMessage;
        },
        required:
          required &&
          getErrorMessageOrDefault(
            field.name.substring(field.name.indexOf('.') + 1),
            'requiredField'
          ),
        maxLength: field.maxLength && {
          value: field.maxLength,
          message: t<'genericFieldMaxLength'>('genericFieldMaxLength', {
            length: field.maxLength,
          }),
        },
        minLength: field.minLength && {
          value: field.minLength,
          message: t<'genericFieldMinLength'>('genericFieldMinLength', {
            length: field.minLength,
          }),
        },
      })}
      type={field.inputType || field.type}
      onChange={() => {
        if (field.onChangeTrigger) {
          const helperFunc = checkoutAddressHelpers[field.onChangeTrigger];
          if (helperFunc) helperFunc(form, prefix);
        }
        if (field.submitOnUpdate && submit) {
          form.trigger().then(() => {
            submit();
          });
        }
      }}
    />
  );
};
export default React.memo(CustomFormInput);
