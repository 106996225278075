import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  createContext,
} from 'react';
import { useRouter } from 'next/router';

import { activateOptimize } from '../utils/analytics';

export type BrowsingHistory = ReturnType<typeof useBrowsingHistoryProvider>;
const BrowsingHistoryContext = createContext<BrowsingHistory>({
  pathHistory: [],
  prevPath: '',
});

export const useBrowsingHistory = () => useContext(BrowsingHistoryContext);

const useBrowsingHistoryProvider = () => {
  const router = useRouter();

  const [pathHistory, setPathHistory] = useState<string[]>([router.asPath]);
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setPathHistory(prev => [...prev, url]);

      const prevPath = pathHistory[pathHistory.length - 1]?.split('?')[0];
      const path = url.split('?')[0];

      if (prevPath !== path) {
        activateOptimize();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [pathHistory, router.events]);

  const browsingHistory = useMemo(
    () => ({
      pathHistory,
      prevPath: pathHistory[pathHistory.length - 2],
    }),
    [pathHistory]
  );
  return browsingHistory;
};

export const BrowsingHistoryProvider: React.FC = ({ children }) => {
  const browsingHistory = useBrowsingHistoryProvider();
  return (
    <BrowsingHistoryContext.Provider value={browsingHistory}>
      {children}
    </BrowsingHistoryContext.Provider>
  );
};
