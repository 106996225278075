import { Icon } from '@global-ecom/nitro-uds/elements';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { Link } from 'ui/elements/Link';

type TermsLinkProps = {
  externalUrl: string | undefined;
  text: string;
};
const TermsLink = ({ externalUrl, text }: TermsLinkProps) => (
  <Link
    href={externalUrl ?? '/help/terms-and-conditions'}
    rel="noopener"
    className="underline text-xs px-0.5"
    target={externalUrl ? '_blank' : undefined}
  >
    <span>
      {text}
      {externalUrl && (
        <Icon name="external-link" size="xxs" className="inline" />
      )}
    </span>
  </Link>
);

type TermsAndConditionsBackupProps = {
  page?: string;
};

export default function TermsAndConditionsBackup(
  props: TermsAndConditionsBackupProps
): React.ReactElement {
  const t = useTranslate();
  const { legal } = useSiteConfig();

  const prefixText =
    props.page === 'tacOnCreateAccount' ? t('byLogging') : t('byContinuing');
  return (
    <>
      <p className="text-xs">
        {prefixText}{' '}
        <TermsLink
          externalUrl={legal?.termsAndConditions}
          text={t('termsAndConditions')}
        />
        {t('andThe')}{' '}
        <TermsLink
          externalUrl={legal?.privacyPolicy}
          text={t('privacyPolicy')}
        />
        .
      </p>
    </>
  );
}
