import { ComponentType, useRef } from 'react';
import { useRouter } from 'next/router';

import {
  ProductRecommender,
  SideBySide as SideBySideType,
} from '__generated__/graphql';
import { getComponent } from 'utils/getComponentForContent';
import { DEFAULT_PRODUCT_GRID_SIZE } from 'ui/content/ProductCarousel';
import { SideBySideDocumentType } from 'groq/documents/SideBySideDocument';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

import { TrendingTile } from './TrendingTile';

type SideBySideProps = SideBySideType & {
  requiredTiles:
    | SideBySideType['componentTiles']
    | SideBySideDocumentType['requiredTiles'];
};

type Recommender = ComponentType<ProductRecommender>;

export const SideBySide = (props: SideBySideProps) => {
  const { asPath } = useRouter();

  const promotion_id = props.id || '';
  const promotion_name = '';
  const creative_name = props.__typename || 'SideBySide';

  const sideBySideRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    sideBySideRef,
    gaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: undefined,
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <section
      className="md:grid gap-4 sm:gap-8 md:gap-8 desktop:grid-cols-2 tablet:grid-cols-1"
      data-test-id="side-by-side-section"
      ref={sideBySideRef}
    >
      {props.requiredTiles?.map(tile => {
        const additionalProps = {
          inSideBySide: true,
          id: props.id,
          gridTemplateColumnConfig: {},
        };
        const type = tile.__typename || tile._type;

        // Include grid template if rendering Product Recommender
        if (type === 'ProductRecommender' && !tile.showAsGrid) {
          additionalProps.gridTemplateColumnConfig = {
            mobile: DEFAULT_PRODUCT_GRID_SIZE,
            tablet: DEFAULT_PRODUCT_GRID_SIZE,
            desktop: DEFAULT_PRODUCT_GRID_SIZE,
          };
          const Component = getComponent(type) as Recommender;
          return <Component {...tile} {...additionalProps} key={tile.id} />;
        }

        if (type === 'TrendingTile') {
          return <TrendingTile {...tile} key={tile.id} ga4Data={ga4Data} />;
        }

        return null;
      })}
    </section>
  );
};
