export const throttle = (
  fn: (...x: any) => any,
  ms = 500
): ((...x: any) => any) => {
  let lock = false;
  return (...args) => {
    if (!lock) {
      lock = true;
      setTimeout(() => (lock = false), ms);
      return fn(...args);
    }
    return undefined;
  };
};
