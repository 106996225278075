import { tw } from 'twind';

import { Skeleton } from '../Skeleton';

export const MainNavSkeleton = () => {
  return (
    <div className="desktop:absolute grid grid-cols-1 desktop:grid-cols-4 gap-2 desktop:gap-8 w-full desktop:h-full justify-center desktop:px-8">
      <Skeleton className={tw('w-full h-8')} />
      <Skeleton className={tw('w-full h-8')} />
      <Skeleton className={tw('w-full h-8')} />
      <Skeleton className={tw('w-full h-8')} />
    </div>
  );
};
