import {
  Cache,
  cacheExchange as normalizedCacheExchange,
  DataField,
} from '@urql/exchange-graphcache';

import { CART_QUERY } from 'gql/queries/cart';
import { ME_QUERY } from 'gql/queries/me';
import { WISHLIST_QUERY } from 'gql/queries/wishList';
import { GraphqlRequired } from 'utils/graphqlRequired';
import {
  Address,
  MutationDeleteAddressArgs,
  RemoveItemFromWishListInput,
  User,
  WishList,
  SavedCreditCard,
  MutationAddItemsToCartArgs,
  MutationAddGiftCardToCartArgs,
  CartQuery,
  CancelOrderPayload,
  Product,
  ProductQueryVariables,
  QuerySiblingVariantsArgs,
  MeQuery,
  ProductPromotionsArgs,
  StartAdyenPaymentMutation,
  AdyenResultCodeEnum,
} from '__generated__/graphql';

import schema from '../../graphql.urql.schema.json';

const ROOT_QUERY_NAME = 'Query';
const VARIANTS_FOR_MASTER_QUERY_NAME = 'siblingVariants';

type ProductDataFields = Partial<{ [key in keyof Product]: DataField }>;

const invalidateCategoryPLPAndProduct = (_, __, cache: Cache) => {
  const key = 'Query';

  cache.inspectFields(key).forEach(field => {
    if (field.fieldName === 'categoryByUrl' || field.fieldName === 'product') {
      cache.invalidate(key, field.fieldKey);
    }
  });
};

const invalidateCachesAfterPurchase = (cache: Cache, result) => {
  if (!result) return;
  cache.invalidate('Query', 'wishList');

  // invalidate the "me" query so that the order history/most recent purchase
  // data will have to be refetched after the customer places a new order
  cache.invalidate('Query', 'me');

  cacheCart(cache, null);
};

// Updates preffered address in cache,
// this dedupes the property from address list
const updatePreferredAddress = (currentAddress, prevData) => {
  // Only update if preffered address updated to current address
  if (!prevData || !prevData.me || !currentAddress.preferred) return null;

  prevData.me.addresses = prevData.me.addresses.map(cachedAddress => {
    if (currentAddress.id === cachedAddress.id) {
      return currentAddress;
    }
    return {
      ...cachedAddress,
      preferred: false,
    };
  });

  return prevData;
};

const cacheCart = (cache: Cache, cart: any) => {
  const current = cache.readQuery<CartQuery>({ query: CART_QUERY });

  // Only the `activeCart` query includes the availablePaymentMethods fields,
  // since that is subresolved and potentially expensive.  Other cart related
  // operations such as `addItemsToCart`, `cancelOrder` etc. will not return
  // this field, but we still want it in the cache.  Therefore, when updating
  // the value of the cached `CART_QUERY` data, we should retain the existing
  // cached `availablePaymentMethods` data if it is not present in the cart
  // data passed into this function
  const updated = cart
    ? {
        ...cart,
        availablePaymentMethods:
          cart?.availablePaymentMethods ??
          current?.activeCart?.availablePaymentMethods,
      }
    : null;

  cache.updateQuery<CartQuery>({ query: CART_QUERY }, () => ({
    activeCart: updated,
  }));
};

const getAllVariantsFromCache = (cache: Cache, masterId: string) => {
  const queryElements = cache.inspectFields(ROOT_QUERY_NAME);

  // attempt to find a cache entry which has the same master ID as supplied
  const cacheEntryWithSameMasterId = queryElements.find(queries => {
    if (queries.fieldName !== VARIANTS_FOR_MASTER_QUERY_NAME) return false;
    const { input } = (queries?.arguments as QuerySiblingVariantsArgs) ?? {};
    return input?.styleNumberId?.split('_')[0] === masterId;
  });

  // if we cannot find one, bail
  if (!cacheEntryWithSameMasterId) return;

  // get the style number for the cached entry
  const styleNumberIdForSameMaster = (
    cacheEntryWithSameMasterId.arguments as QuerySiblingVariantsArgs
  ).input.styleNumberId;

  // return the cached data for that matching style number
  return cache.resolve(ROOT_QUERY_NAME, VARIANTS_FOR_MASTER_QUERY_NAME, {
    input: { styleNumberId: styleNumberIdForSameMaster },
  });
};

export const cacheExchange = normalizedCacheExchange({
  schema,
  resolvers: {
    Product: {
      look: (_, __, cache, info) =>
        cache.resolve(info.parentKey, 'look', {
          id: info.variables.variationId,
        }),
      promotions: (
        _,
        args: GraphqlRequired<ProductPromotionsArgs>,
        cache,
        info
      ) =>
        cache.resolve(info.parentKey, 'promotions', {
          page: args.page,
        }),
    },
    Query: {
      // This is the dedup logic for the variants query.
      siblingVariants: (_, args: QuerySiblingVariantsArgs, cache) => {
        const {
          input: { styleNumberId },
        } = args;

        // "id" is styleNumber like "311123_01".
        // Splitting on _ will give us the masterId.
        const masterId = styleNumberId?.split('_')[0] ?? '';
        const result = getAllVariantsFromCache(cache, masterId);

        if (!Array.isArray(result) || !result.length) {
          return;
        }

        return result;
      },
      // This is to resuse as much data from plp -> pdp transition.
      product: (_, args: ProductQueryVariables, cache, info) => {
        const fields = cache.inspectFields(`Product:${args.id}`);

        const resolvedProduct = fields.reduce((acc, { fieldName }) => {
          acc[fieldName] = cache.resolve(`Product:${args.id}`, fieldName);
          return acc;
        }, {} as ProductDataFields);

        const masterId = resolvedProduct.id;
        if (masterId) {
          const variantId = info.variables.variationId;

          const isVariationsEmpty =
            Array.isArray(resolvedProduct.variations) &&
            !resolvedProduct.variations.length;

          const isVariationsNull = !resolvedProduct.variations;

          if (isVariationsEmpty || isVariationsNull) {
            const currentVariantFromCache = [`Variant:${variantId}`];
            const variations =
              resolvedProduct.orderableColorCount === 1
                ? // if there is only one variant, we have the data for that
                  currentVariantFromCache
                : // if more than one variant, attempt to fetch all of them from
                  // cache, or default to the cached data for the current variant
                  getAllVariantsFromCache(cache, masterId as string) ??
                  currentVariantFromCache;

            resolvedProduct.variations = variations;
          }
          return resolvedProduct;
        }

        // If there is no product in the cache then return undefined
        // and let the request result populate the cache
        return;
      },
    },
  },
  keys: {
    AuthorityToLeave: () => null,
    Adyen: () => null,
    OmsEntry: () => null,
    EstimatedShippingDate: () => null,
    FilterOptionValue: () => null,
    ReviewAspects: () => null,
    ReviewSummary: () => null,
    BackgroundOverlay: () => null,
    CTA: () => null,
    ColorInput: () => null,
    Rgba: () => null,
    SanityImage: () => null,
    SanityImageCrop: () => null,
    SanityImageHotspot: () => null,
    SanityImageMetaData: () => null,
    ProductImage: () => null,
    ProductColor: () => null,
    Message: () => null,
    Coordinates: () => null,
    Information: () => null,
    VariantSize: () => null,
    Size: () => null,
    SizeGroup: () => null,
    VariantColor: () => null,
    PaginatedOutput: () => null,
    FilterOption: () => null,
    Score: () => null,
    SEO: () => null,
    TimelineStatus: () => null,
    TrendingSearchTerm: () => null,
    ProductMeasurements: () => null,
    NewsletterSubscription: entity => (entity.email ?? null) as string | null,
    ProductStory: () => null,
    DisplayOutOfStock: () => null,
    Shipment: () => null,
    StandardOrder: order => (order.orderNo ?? null) as string | null,
    NestedOrder: order => (order.orderNo ?? null) as string | null,
    Suborder: suborder => (suborder.orderNo ?? null) as string | null,
    CustomerInfo: () => null,
    AfterPayConfig: () => null,
    PaymentCardSpec: () => null,
    OrderEdge: () => null,
    DOB: () => null,
    ShipmentMethodInfo: () => null,
    Adjustment: () => null,
    ProductWithExclusion: () => null,
    ShippingSurcharge: () => null,
    CallToAction: () => null,
    Alignment: () => null,
    Image: () => null,
    Badge: () => null,
    Text: () => null,
    Link: () => null,
    Style: () => null,
    Tile: () => null,
    Card: () => null,
    SearchSuggestions: () => null,
    SuggestedTerm: () => null,
    SuggestedPhrase: () => null,
    SanityImageMetaDataDimensions: () => null,
    TextSection: () => null,
    PortableText: () => null,
    Children: () => null,
    MarkDefs: () => null,
    ManufacturerInfo: () => null,
    Info: () => null,
    UserGeneratedContent: () => null,
    PixleeCdnPhotos: () => null,
    Inventory: () => null,
    FrontendConfiguration: () => null,
    FeatureFlags: () => null,
    SiteConfig: () => null,
    DeliveryDateDays: () => null,
    Environment: () => null,
    PincodeServiceability: () => null,
    CouponItem: couponItem =>
      (couponItem.couponItemId ?? null) as string | null,
    SavedCreditCard: () => null,
    ComparisonProduct: () => null,
    ComparisonField: () => null,
    ConfigureIDSettings: () => null,
    NotFoundContentPage: () => null,
    BannerIdentifier: () => null,
    RecommendationsOutput: recommendations =>
      recommendations.recommenderId as string,
    Campaign: () => null,
    ProductPrice: () => null,
    OrderStatus: () => null,
    GlossaryCategory: category => category.value as string,
    GlossaryPage: page => page.id as string,
    GlossaryTermFlattened: term => term.id as string,
    ShopperContextPayload: () => null,
    ScheduledFreeShippingThreshold: () => null,
    UrlRedirect: () => null,
  },
  updates: {
    Mutation: {
      addItemsToCart: (
        result,
        args: MutationAddItemsToCartArgs,
        cache: Cache
      ) => {
        if (
          typeof result?.addItemsToCart === 'object' &&
          !args?.input?.basketId
        ) {
          cacheCart(cache, result.addItemsToCart);
        }
      },
      addGiftCardToCart: (
        result,
        args: MutationAddGiftCardToCartArgs,
        cache: Cache
      ) => {
        if (
          typeof result?.addGiftCardToCart === 'object' &&
          !args?.input?.basketId
        ) {
          cacheCart(cache, result.addGiftCardToCart);
        }
      },
      ambassadorCard: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.ambassadorCard);
      },
      removeCouponFromCart: invalidateCategoryPLPAndProduct,
      addCouponToCart: invalidateCategoryPLPAndProduct,
      giftCard: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.giftCard);
      },
      googlePay: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.googlePay);
      },
      creditCard: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.creditCard);
      },
      confirmCreditCard: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmCreditCard);
      },
      confirmCashOnDelivery: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmCashOnDelivery);
      },
      confirmAfterPay: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmAfterPay);
      },
      confirmPayPay: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmPayPay);
      },
      confirmApplePay: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmApplePay);
      },
      confirmPayPal: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmPayPal);
      },
      confirmAmazonPay: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmAmazonPay);
      },
      completeAdyenPayment: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.completeAdyenPayment);
      },
      startAdyenPayment: (result, _args, cache) => {
        const resultData = result as unknown as StartAdyenPaymentMutation;

        const resultCode = resultData?.startAdyenPayment?.resultCode;

        // Only invalidate the cart when this mutation retuns 'Authorised'.
        // The mutation places the order in GQL already.
        if (resultCode === AdyenResultCodeEnum.Authorised) {
          invalidateCachesAfterPurchase(cache, result.startAdyenPayment);
        }
      },
      confirmRazorPay: (result, _args, cache) => {
        invalidateCachesAfterPurchase(cache, result.confirmRazorPay);
      },
      cancelGooglePay: (result, _args, cache) => {
        if (result.cancelGooglePay) {
          cacheCart(cache, result.cancelGooglePay);
        }
      },
      cancelOrder: (
        result: { cancelOrder: CancelOrderPayload },
        _args,
        cache
      ) => {
        cacheCart(cache, result.cancelOrder?.cart);
      },
      cancelApplePay: (result, _args, cache) => {
        if (result?.cancelApplePay) {
          cacheCart(cache, result.cancelApplePay);
        }
      },
      manageCustomerLevelNotification: (result, _args, cache) => {
        const res = result.manageCustomerLevelNotification as string | null;

        cache.updateQuery<MeQuery>({ query: ME_QUERY }, prevData => {
          if (!prevData?.me) return null;

          const data = prevData;
          data.me.smsContactNumber = res;
          return data;
        });
      },
      updateAddress: (result, _args, cache) => {
        if (result.updateAddress) {
          const resultData = result as unknown as { updateAddress: Address };

          if (!resultData.updateAddress.preferred) {
            return;
          }

          cache.updateQuery<MeQuery>(
            { query: ME_QUERY, variables: { includeAddresses: true } },
            (prevData: any) => {
              // Updates preferred address in the cache list of addresses
              return updatePreferredAddress(resultData.updateAddress, prevData);
            }
          );
        }
      },
      updateProfile: (result, _args, cache) => {
        if (result.updateProfile) {
          cache.updateQuery<MeQuery>({ query: ME_QUERY }, (prevData: any) => {
            if (!prevData || !prevData.me) return null;
            prevData.me = result.updateProfile;
            return prevData;
          });
        }
      },
      addAddress: (result, _args, cache) => {
        if (result.addAddress) {
          const resultData = result as unknown as { addAddress: Address };

          cache.updateQuery<MeQuery>(
            { query: ME_QUERY, variables: { includeAddresses: true } },
            prevData => {
              if (!prevData?.me) return null;

              const data = { ...prevData };

              if (!data.me.addresses) {
                data.me.addresses = [];
              }

              data.me.addresses.push(resultData.addAddress);

              // Updates preferred address in the cache list of addresses
              if (resultData.addAddress.preferred) {
                return updatePreferredAddress(resultData.addAddress, prevData);
              }

              return data;
            }
          );
        }
      },
      deleteAddress: (result, args, cache) => {
        const variables = args as MutationDeleteAddressArgs;

        if (result.deleteAddress) {
          cache.updateQuery<MeQuery>(
            { query: ME_QUERY, variables: { includeAddresses: true } },
            (prevData: any) => {
              if (!prevData || !prevData.me) return null;

              const data = prevData as { me: User };
              return {
                me: {
                  ...prevData.me,
                  addresses: data.me.addresses.filter(
                    a => a.id !== variables.id
                  ),
                },
              };
            }
          );
        }
      },
      deleteCreditCard: (result, _args, cache) => {
        if (result.deleteCreditCard) {
          cache.updateQuery<MeQuery>(
            { query: ME_QUERY, variables: { includeSavedCards: true } },
            (prevData: any) => {
              if (!prevData || !prevData.me) return null;
              const resultData = result as unknown as {
                deleteCreditCard: { savedCards: SavedCreditCard[] };
              };

              return {
                me: {
                  ...prevData.me,
                  savedCards: resultData.deleteCreditCard.savedCards,
                },
              };
            }
          );
        }
      },
      saveCreditCard: (result, _args, cache) => {
        if (result.saveCreditCard) {
          cache.updateQuery<MeQuery>(
            { query: ME_QUERY, variables: { includeSavedCards: true } },
            (prevData: any) => {
              if (!prevData || !prevData.me) return null;
              const resultData = result as unknown as {
                saveCreditCard: { savedCards: SavedCreditCard[] };
              };

              return {
                me: {
                  ...prevData.me,
                  savedCards: resultData.saveCreditCard.savedCards,
                },
              };
            }
          );
        }
      },
      removeItemFromWishList: (result, args, cache) => {
        const variables = args as { input: RemoveItemFromWishListInput };

        if (result.removeItemFromWishList) {
          cache.updateQuery({ query: WISHLIST_QUERY }, (prevData: any) => {
            if (!prevData || !prevData.wishList) return null;

            const data = prevData as { wishList: WishList };
            return {
              wishList: {
                ...data.wishList,
                items: data.wishList.items.filter(
                  item => item.id !== variables.input.itemId
                ),
              },
            };
          });
        }
      },
    },
  },
  optimistic: {
    removeItemFromWishList: () => true as any,
  },
});
