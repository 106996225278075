import { forwardRef } from 'react';
import { ButtonIcon, ButtonIconProps } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';

type Props = Partial<ButtonIconProps> & {
  dataTestId?: string;
  size?: ButtonIconProps['size'];
};

const CloseButton = forwardRef<HTMLButtonElement, Props>(function CloseButton(
  props,
  ref
) {
  const t = useTranslate();
  const { label, dataTestId = 'close-btn', size = 'base', ...rest } = props;

  return (
    <ButtonIcon
      dataTestId={dataTestId}
      icon="close"
      label={label ?? t('close')}
      mode="icon"
      size={size}
      variant="ghost"
      {...rest}
      ref={ref}
    />
  );
});

export default CloseButton;
