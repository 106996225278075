import { Dictionary, UdsProvider } from '@global-ecom/nitro-uds/hooks';
import { useRouter } from 'next/router';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';

export const UdsProviderWrapper = ({ children }) => {
  const { localizeUrlPath } = useSiteConfig();
  const t = useTranslate();
  const router = useRouter();

  const dictionary: Dictionary = {
    loading: t('loading'),
    optional: t('optional'),
    january: t('january'),
    february: t('february'),
    march: t('march'),
    april: t('april'),
    may: t('may'),
    june: t('june'),
    july: t('july'),
    august: t('august'),
    september: t('september'),
    october: t('october'),
    november: t('november'),
    december: t('december'),
    day: t('day'),
    month: t('month'),
    year: t('year'),
    showPassword: t('showPassword'),
    hidePassword: t('hidePassword'),
    charactersRemaining: t<'charactersRemaining'>('charactersRemaining'),
    genericFieldMinLength: t('genericFieldMinLength'),
    genericFieldMaxLength: t('genericFieldMaxLength'),
    close: t('close'),
    clear: t('clear'),
    search: t('search'),
    srNewSearchResults: t('newSearchResults'),
    srNoSearchResults: t('noSearchResults'),
  };

  return (
    <UdsProvider
      value={{
        dictionary: dictionary,
        iconDirectory: '/_next/static/assets/icons',
        utils: {
          localizeUrlPath,
          navigate: router.push,
        },
      }}
    >
      {children}
    </UdsProvider>
  );
};
