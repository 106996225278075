import React, { Dispatch, SetStateAction } from 'react';

import { myAccountUserSettingsEvent } from 'utils/analytics';

export enum ModalContentType {
  PersonalDetails = 'personal details',
  EmailAndPassword = 'email & password',
  EmailNotifications = 'email notifications',
  SmsDeliveryNotifications = 'sms delivery notifications',
}

export type Dob = {
  day: number;
  month: number;
  year: number;
};

export type UserProfile = {
  firstName?: string;
  lastName?: string;
  firstNamePronunciation?: string;
  lastNamePronunciation?: string;
  phoneNumber?: string;
  email?: string;
  year?: string;
  month?: string;
  day?: string;
  gender?: string;
};

export type Newsletter = {
  shoppingPreference?: string;
  emailPreference: string;
  day?: number;
  month?: number;
  year?: number;
  lastName?: string;
  firstName?: string;
  email?: string;
  firstNamePronunciation?: string;
  lastNamePronunciation?: string;
  gender?: string;
  memberId?: string;
  isConfirmed?: boolean;
  iframeURL?: string;
  dob?: Dob;
};

export type NotificationPreferences = {
  marketingSmsOptIn: boolean;
  customerSmsOptIn: boolean;
  phoneNumber?: string;
};

type AccountSettingsContextType = {
  contentType?: ModalContentType;
  openModal: (form: ModalContentType) => void;
  closeModal: () => void;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  userProfile: UserProfile | undefined;
  setUserProfile: Dispatch<SetStateAction<UserProfile | undefined>>;
  notificationPreferences: NotificationPreferences | undefined;
  setNotificationPreferences: Dispatch<
    SetStateAction<NotificationPreferences | undefined>
  >;
  newsletterPreferences: Newsletter | undefined;
  setNewsletterPreferences: Dispatch<SetStateAction<Newsletter | undefined>>;
};

export const AccountSettingsContext =
  React.createContext<AccountSettingsContextType>({
    contentType: undefined,
    openModal: () => null,
    closeModal: () => null,
    isModalVisible: false,
    setIsModalVisible: () => null,
    userProfile: undefined,
    setUserProfile: () => null,
    notificationPreferences: undefined,
    setNotificationPreferences: () => null,
    newsletterPreferences: undefined,
    setNewsletterPreferences: () => null,
  });

export const AccountSettingsProvider = props => {
  const [contentType, setContentType] = React.useState<ModalContentType>();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [userProfile, setUserProfile] = React.useState<UserProfile>();
  const [notificationPreferences, setNotificationPreferences] =
    React.useState<NotificationPreferences>();
  const [newsletterPreferences, setNewsletterPreferences] =
    React.useState<Newsletter>();

  const openModal = React.useCallback((contentType: ModalContentType) => {
    setContentType(contentType);
    setIsModalVisible(true);
    myAccountUserSettingsEvent({
      userAction: 'edit',
      section: contentType,
    });
  }, []);

  const closeModal = React.useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const providerValue = React.useMemo(() => {
    return {
      contentType,
      openModal,
      closeModal,
      isModalVisible,
      setIsModalVisible,
      userProfile,
      setUserProfile,
      notificationPreferences,
      setNotificationPreferences,
      newsletterPreferences,
      setNewsletterPreferences,
    };
  }, [
    contentType,
    openModal,
    closeModal,
    isModalVisible,
    setIsModalVisible,
    userProfile,
    setUserProfile,
    notificationPreferences,
    setNotificationPreferences,
    newsletterPreferences,
    setNewsletterPreferences,
  ]);

  return (
    <AccountSettingsContext.Provider value={providerValue}>
      {props.children}
    </AccountSettingsContext.Provider>
  );
};

export const useAccountSettings = (): AccountSettingsContextType =>
  React.useContext(AccountSettingsContext);
