import React, { ReactNode, forwardRef } from 'react';
import { Heading } from '@global-ecom/nitro-uds/elements';

import { Ga4Data } from 'hooks/usePromotionSelect';
import { LinkType } from 'utils/constants';
import { Link } from 'ui/elements/Link';
import { Maybe } from '__generated__/graphql-sanity';
import { GaTrackData } from 'hooks/usePromotionView';

type LinkOrTagProps = {
  dataLinkLoc?: string;
  query?: Maybe<string>;
  dataTestId?: string;
  newtab?: boolean | undefined;
  gaBannerData?: GaTrackData;
  className?: string;
  href?: Maybe<string>;
  ga4Data?: Ga4Data;
  children: ReactNode;
  linkType: LinkType;
  tag?: TagType;
  headingSize?: headingSize;
};

type headingSize = { initial: any; sm: any; md: any };

export enum TagType {
  DIV = 'div',
  SECTION = 'section',
  ARTICLE = 'article',
  H1 = 'h1',
}

export const LinkOrTag = forwardRef(
  (props: LinkOrTagProps, ref: any): JSX.Element => {
    const {
      className,
      query,
      href,
      dataTestId,
      gaBannerData,
      ga4Data,
      newtab,
      dataLinkLoc,
      linkType,
      headingSize,
      ...otherLinkProps
    } = props;

    const Tag = props.tag || TagType.DIV;
    /** we need to use the UDS Heading components for the H1 tags */

    if (Tag === TagType.H1 && !props.href)
      return (
        <Heading
          dataTestId="body-header"
          ref={ref}
          asChild
          size={props.headingSize}
        >
          {props.children}
        </Heading>
      );

    return linkType && href ? (
      <Link
        ref={ref}
        aria-hidden
        {...otherLinkProps}
        className={className}
        query={query}
        href={href || null}
        dataTestId={dataTestId}
        gaBannerData={gaBannerData}
        ga4Data={ga4Data}
        dataLinkLoc={dataLinkLoc}
        newtab={newtab}
      >
        {props.children}
      </Link>
    ) : (
      <Tag
        {...otherLinkProps}
        ref={ref}
        className={className}
        data-test-id={dataTestId}
        data-link-loc={dataLinkLoc}
      >
        {props.children}
      </Tag>
    );
  }
);

LinkOrTag.displayName = 'LinkOrTag';
