import { gql } from 'urql';

export const SAVED_AMBASSADOR_CARD_FRAGMENT = gql`
  fragment ambassadorCardFields on SavedAmbassadorCard {
    id
    maskedNumber
    name
    preferred
  }
`;

export const AMBASSADOR_CARD_PAYMENT_FRAGMENT = gql`
  fragment ambassadorCardPaymentFields on PaymentInstrument {
    ... on AmbassadorCard {
      __typename
      id
      maskedNumber
      amount
      authorizationStatus
      type
    }
  }
`;
