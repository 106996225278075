import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { FieldError } from 'react-hook-form/dist/types/errors';

import { resolveNode } from 'utils/object';
import { FormField } from 'ui/forms/custom/types';
import { useTranslationFieldOrDefault } from 'hooks/useTranslationFieldOrDefault';

export const useFormField = (field: FormField) => {
  const { errors } = useFormContext();

  const getErrorMessageOrDefault = useTranslationFieldOrDefault();

  const resolveInputError = React.useCallback(
    () => resolveNode<FieldError>(field.name, errors)?.message,
    [field, errors]
  );
  return {
    getErrorMessageOrDefault,
    resolveInputError,
  };
};
