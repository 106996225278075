import { gql } from 'urql';

export const PINCODE_QUERY = gql`
  query Pincode($pincode: String!) {
    pincode(pincode: $pincode) {
      expectedDate
      city
      stateCode
    }
  }
`;
