import { gql } from 'urql';

import {
  ORDER_CONFIRMATION_FRAGMENT,
  PAYMENT_INSTRUMENT_FRAGMENT,
} from 'gql/fragments/baseOrder';

export const NESTED_ORDER_DETAILS_FRAGMENT = gql`
  fragment nestedOrderDetailsFields on NestedOrder {
    __typename
    orderNo
    status
    taxTotal
    subTotal
    createdAt
    currency
    orderStatus {
      code
      label
    }
    isReturnable
    ...orderPaymentInstrumentFields
    ...orderConfirmationFields
    suborders {
      __typename
      orderNo
      awbNo
      courierPartner
      status
      statuses {
        status
        label
        order
      }
      returnBy
      isReturnable
      returnUrl
      products {
        ... on ProductCartItem {
          __typename
          id
          status
          inCartPrice
          bonusItemId
          restricted
          quantity
          purchasedPrice {
            price
            promotionPrice
          }
          product {
            masterId
            id
            name
            price
            salePrice
            productPrice {
              price
              salePrice
              promotionPrice
            }
            images {
              href
              alt
            }
            inventory
            primaryCategoryId
            styleNumber
            colorValue
            colorName
            size {
              label
              value
            }
            offline
            orderable
          }
          productReturn {
            id
            returnOrderCode
            forwardAwb
            returnAwb
            transporter
            channelReturnOrderTime
            type
            status
            statuses {
              status
              label
              order
            }
            price
            quantity
          }
        }
      }
    }
  }
  ${ORDER_CONFIRMATION_FRAGMENT}
  ${PAYMENT_INSTRUMENT_FRAGMENT}
`;
