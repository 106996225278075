import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';

import { isServer } from 'utils/constants';

export type UserPreferences = {
  'prefers-region': boolean;
  'last-viewed-product'?: {
    id: string;
    name: string;
    swatch: string;
    size?: string;
    country?: string;
  };
  'last-ga4-item'?: {
    id: string;
    name: string;
    swatch: string;
  };
  'last-login-email'?: string;
  seen: string[];
};

const preferencesAndDefaults: UserPreferences = {
  'prefers-region': false,
  'last-viewed-product': undefined,
  'last-ga4-item': undefined,
  'last-login-email': undefined,
  seen: [],
};

export type PreferencesContextType = {
  preferences: UserPreferences;
  setPreferences: Dispatch<SetStateAction<UserPreferences>>;
};

export const getInitialPreferences = (): UserPreferences => {
  // get the user preferences from local storage
  // only loads preferences predefined in the preferencesAndDefaults type above
  const initial = Object.entries(preferencesAndDefaults).reduce(
    (value, [k, v]) => {
      const serialized = !isServer && window?.localStorage.getItem(k);
      const propValue = (serialized && JSON.parse(serialized)) || v;
      value[k] = propValue;
      return value;
    },
    {}
  ) as UserPreferences;

  // ensure last viewed products have the required keys
  const lastViewedProduct = initial['last-viewed-product'];
  if (
    lastViewedProduct &&
    !['id', 'name', 'swatch'].every(key => key in lastViewedProduct)
  ) {
    initial['last-viewed-product'] = undefined;
  }

  // ensure last ga4 product has the required keys
  const lastGa4Item = initial['last-ga4-item'];
  if (
    lastGa4Item &&
    !['id', 'name', 'swatch'].every(key => key in lastGa4Item)
  ) {
    initial['last-ga4-item'] = undefined;
  }

  return initial;
};

export const PreferencesContext = createContext<PreferencesContextType>(
  {} as PreferencesContextType
);

export const usePreferences = (): PreferencesContextType => {
  return useContext(PreferencesContext);
};

export const PreferencesProvider = ({ children }) => {
  const [preferences, setPreferences] = useState<UserPreferences>(() =>
    getInitialPreferences()
  );

  // cleanup storage entries set to undefined
  useEffect(() => {
    Object.entries(preferences).forEach(([k, v]) => {
      if (v === undefined) window.localStorage.removeItem(k);
      else window.localStorage.setItem(k, JSON.stringify(v));
    });
  }, [preferences]);

  return (
    <PreferencesContext.Provider value={{ preferences, setPreferences }}>
      {children}
    </PreferencesContext.Provider>
  );
};
