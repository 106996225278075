import { useEffect, useState } from 'react';

import { isServer } from 'utils/constants';
import { debounce } from 'utils/debounce';

// Credit: https://upmostly.com/tutorials/how-to-use-media-queries-in-react
export type ScreenSizes = keyof typeof Screens;
const Screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

const DEFAULT_QUERIES = Object.keys(Screens) as ScreenSizes[];
const isMatch = (media: ScreenSizes) => {
  const query = `(min-width: ${Screens[media]})`;
  return window.matchMedia(query).matches;
};

const findClosest = (queries: ScreenSizes[] = DEFAULT_QUERIES) => {
  if (isServer) return 'sm';
  for (let i = queries.length - 1; i >= 0; i--) {
    if (isMatch(queries[i])) {
      return queries[i];
    }
  }
  return 'sm';
};
export const useClosestMedia = () => {
  const [closest, setClosest] = useState<ScreenSizes>(() => findClosest());

  useEffect(() => {
    const listener = debounce(() => setClosest(findClosest()), 500);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener); //Cleanup
  }, []);

  return closest;
};
