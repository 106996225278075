import { isContentOnline } from 'utils/isContentOnline';

export const useAvailableNavItemFilter = (result, preview) => {
  if (!result) return [];
  return JSON.parse(JSON.stringify(result), (key, value) => {
    return key === 'children' || key === 'navChildren'
      ? value?.filter(child => {
          const isItemAvailable = isContentOnline(
            {
              onlineFrom: child?.availableFrom,
              onlineTo: child?.availableTo,
            },
            preview
          );

          if (!isItemAvailable) return false;

          // NOTE: taken from the current graphql resolver still used by the app
          // https://github.com/cyber-cats/puma-gql/blob/main/src/graphql/content/MainNav.ts#L182
          if (child.link.category) {
            const category = child.link.category;

            if (child.image) {
              category.image = child.image;
            }

            const hasStock = category.hasItemsInStock;
            const showEmpty = category.showEvenEmpty;
            const isOnline =
              category.online &&
              (!category.onlineFrom ||
                +new Date(category.onlineFrom) < +new Date()) &&
              (!category.onlineTo ||
                +new Date(category.onlineTo) > +new Date());
            return isOnline && (hasStock || showEmpty);
          }

          return true;
        })
      : value;
  });
};
