import { useTranslate } from 'hooks/useTranslations';
import { usePriceFormatter } from 'hooks/usePriceFormatter';

interface IPrice {
  amount: number;
  showSign?: boolean;
}

export const Price = (props: IPrice) => {
  const t = useTranslate();

  const formatter = usePriceFormatter({ showSign: props.showSign });
  const formattedPrice = formatter(props.amount);

  return (
    <>
      {t<'orderDetailAmount'>('orderDetailAmount', {
        amount: formattedPrice,
      })}
    </>
  );
};
