export const withTrailingSpaceOrEmpty = (
  v: string | undefined | null
): string => (v ? `${v} ` : '');

export const lowerCaseOrBlank = (val?: string | null) =>
  val?.toLowerCase() ?? '';

export const upperCaseOrBlank = (val?: string | null) =>
  val?.toUpperCase() ?? '';

export const lowerCaseRemoveSpecialChars = (val?: string | null) =>
  val?.replace(/[^a-zA-Z0-9- ]/g, '').toLowerCase() ?? '';

export const normalizeDiacriticalCharacters = (val: string) =>
  val.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const toCamelCase = (val?: string | null): string => {
  if (!val) return '';
  const punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  const splittedString = (val &&
    val.replaceAll(punctuation, ' ').split(' ')) as string[];

  const camelCasedWord = splittedString
    .reduce((acc, curr, idx) => {
      if (idx === 0) return [curr.toLowerCase()];
      if (!curr.length) return acc;

      const capitalizedWord =
        curr.charAt(0).toUpperCase() + curr.slice(1).toLowerCase();
      return [...acc, capitalizedWord];
    }, [] as string[])
    .join('');

  return camelCasedWord;
};
