import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';

import { SignUpForm } from '__generated__/graphql';
import {
  SignUpActions,
  SignUpLocationsKey,
  SignUpFormAnalyticsEventType,
  SignUpFormAnalyticsIdentifiers,
} from 'types/analyticTypes';
import { signUpFormAnalyticsEvent } from 'utils/analytics';
import {
  COOKIE_BANNER_ACKNOWLEDGED_EVENT_NAME,
  MaxAgeExpiration,
  SUPPORTED_COUNTRY,
} from 'utils/constants';

import { useAuth } from './useAuth';
import { usePreferences } from './usePreferences';
import { useSiteConfig } from './useSiteConfig';

export type NewsletterSignupResult = {
  isButtonVisible: boolean;
  isFormVisible: boolean;
  show: (location: SignUpLocationsKey, hideButton?: boolean) => void;
  handleClose: (showButton: boolean) => void;
  isEmarsysEnabled: boolean;
  emarsysSignupURL: string | null;
  signUpLocation: SignUpLocationsKey | undefined;
  analyticsIdentifier: SignUpFormAnalyticsIdentifiers | undefined;
};

export const useNewsletterSignup = (
  signUpForm?: Pick<SignUpForm, 'contactSource' | 'analyticsIdentifier'> | null
): NewsletterSignupResult => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [signUpLocation, setSignUpLocation] = useState<SignUpLocationsKey>();

  const { guest: isGuest } = useAuth();
  const { preferences, setPreferences } = usePreferences();
  const { env, newsletter, country } = useSiteConfig();
  const { emarsysSignupURL } = env;

  const STICKY_BUTTON_CLOSED = 'stickyButtonClosed';
  const UK_SITE = country === SUPPORTED_COUNTRY.GB;
  const hasClosedStickyButtonUk = !!parseCookies()[STICKY_BUTTON_CLOSED];

  const hasSeenCookieBanner = !!parseCookies()['OptanonConsent'];

  const [cookiesAccepted, setCookiesAccepted] = useState(hasSeenCookieBanner);

  const hasSpecifiedPreferedRegion = preferences['prefers-region'];
  const contactSource =
    signUpForm?.contactSource.toLowerCase() ||
    newsletter?.contactSources.footer;

  const isEmarsysEnabled = !!signUpForm || !!emarsysSignupURL;
  const identifier =
    signUpForm?.analyticsIdentifier as SignUpFormAnalyticsEventType['identifier'];

  useEffect(() => {
    const handler = () => {
      setCookiesAccepted(true);
    };

    if (!hasSeenCookieBanner) {
      // if the cookie banner is showing, wait for it to be dismissed before
      // shoing the newsletter signup button
      window.addEventListener(COOKIE_BANNER_ACKNOWLEDGED_EVENT_NAME, handler);
    }

    return () =>
      window.removeEventListener(
        COOKIE_BANNER_ACKNOWLEDGED_EVENT_NAME,
        handler
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldShowButton =
    contactSource &&
    isGuest &&
    cookiesAccepted &&
    hasSpecifiedPreferedRegion &&
    isEmarsysEnabled &&
    (UK_SITE ? !hasClosedStickyButtonUk : true);

  useEffect(() => {
    if (shouldShowButton) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  }, [shouldShowButton]);

  const show = (location: SignUpLocationsKey, hideButton = true) => {
    setSignUpLocation(location);
    setIsFormVisible(true);
    setIsButtonVisible(!hideButton);
    signUpFormAnalyticsEvent({
      identifier,
      action: SignUpActions.Opened,
      location,
    });
  };

  const setContactSourceSeen = () => {
    const seen = new Set<string>(preferences.seen);
    if (contactSource) {
      seen.add(contactSource);
      setPreferences(curr => ({
        ...curr,
        seen: Array.from(seen),
      }));
    }
  };

  const handleClose = (markAsSeen = true) => {
    if (markAsSeen) {
      setContactSourceSeen();
    } else {
      signUpFormAnalyticsEvent({
        identifier,
        action: SignUpActions.Closed,
        location: signUpLocation,
      });
    }

    if (UK_SITE) {
      setCookie(null, STICKY_BUTTON_CLOSED, 'true', {
        maxAge: MaxAgeExpiration.WEEKLY,
      });
    }

    setIsFormVisible(false);
    if (isGuest && !markAsSeen) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
    setSignUpLocation(undefined);
  };

  return {
    isButtonVisible,
    isFormVisible,
    show,
    handleClose,
    isEmarsysEnabled,
    emarsysSignupURL: emarsysSignupURL ?? null,
    signUpLocation,
    analyticsIdentifier: identifier,
  };
};
