import { tw } from 'twind';
import { css } from 'twind/css';

import { useStyliticsSanity } from 'hooks/useStyliticsSanity';

import StyliticsChevronButton from './StyliticsChevronButton';
import { styliticsCarouselStyles } from './stylitics-sanity-css';
import StyliticsTileList from './StyliticsTileList';

const Carousel = () => {
  const { tiles } = useStyliticsSanity();
  return <StyliticsTileList tiles={tiles} />;
};

const StyliticsCarouselContainer = () => {
  const {
    carouselUi: { carousel, carouselNavHidden },
    outfitUi: { screenSize },
    tiles,
  } = useStyliticsSanity();
  const isMobile = screenSize === 'md' || screenSize === 'sm';
  const gridStyles = tw([
    'no-scrollbars!',
    css({
      scrollSnapType: 'x mandatory',
    }),
    {
      mobile: css({
        gridTemplateColumns: `repeat(${tiles.length}, calc(100% - 16px))`,
      }),
      tablet: css({
        gridTemplateColumns: `repeat(${tiles.length}, calc(50% - 16px))`,
      }),
      desktop: css({
        gridTemplateColumns: `repeat(${tiles.length}, calc(33.333% - 16px))`,
      }),
    },
  ]);
  return (
    <div
      data-test-id="stylitics-carousel"
      className={tw(styliticsCarouselStyles, 'py-[30px] px-0')}
    >
      <div className="relative overflow-x-hidden">
        <div className="flex justify-center relative flex-row w-full pr-0 pl-0 my-0 mx-auto">
          {carousel.hasPrev && !carouselNavHidden && (
            <StyliticsChevronButton
              direction="left"
              onClick={carousel.scrollToPrev}
            />
          )}
          <div
            ref={carousel.ref}
            className="overflow-x-hidden pb-0 pt-[8px]"
            style={{ width: `calc(100% + 16px)` }}
          >
            <div
              className={tw('stylitics-carousel-group', gridStyles, {
                desktop: !isMobile,
              })}
            >
              <Carousel />
            </div>
          </div>
          {carousel.hasNext && !carouselNavHidden && (
            <StyliticsChevronButton
              direction="right"
              onClick={carousel.scrollToNext}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StyliticsCarouselContainer;
