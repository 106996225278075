const prefix = 'pref_';

export type QueryStringBasedFilters = {
  [key: string]: string | string[] | undefined;
};

export const encodeFilters = (
  filters: QueryStringBasedFilters
): Record<string, string> => {
  const encoded: Record<string, string> = {};
  for (const [k, v] of Object.entries(filters)) {
    if (v) encoded[encodeFilterName(k)] = v as string;
  }

  return encoded;
};

export const decodeFilters = (
  filters: QueryStringBasedFilters
): Record<string, string> => {
  const decoded: Record<string, string> = {};
  for (const [k, v] of Object.entries(filters)) {
    const decodedKey = decodeFilterName(k);
    if (decodedKey !== null && v) decoded[decodedKey] = v as string;
  }

  return decoded;
};

export const isValidFilter = (filterName: string): boolean => {
  return filterName.startsWith(prefix);
};

export const encodeFilterName = (filterName: string): string => {
  return `${prefix}${filterName}`;
};

export const decodeFilterName = (filterName: string): string | null => {
  if (!isValidFilter(filterName)) return null;
  return filterName.replace(prefix, '');
};
