import { tw } from 'twind/style';

import { useTranslate } from 'hooks/useTranslations';
import { Size } from '__generated__/graphql';

type SizeOptionProps = {
  size: Size;
  onChange?: (selectedSize: string) => void;
  selected: boolean;
};

export const SizeOption = (props: SizeOptionProps) => {
  const { size, selected, onChange } = props;
  const t = useTranslate();

  return (
    <label
      data-disabled={!size.orderable}
      data-size={size.id}
      className={tw(
        'relative border flex items-center justify-center flex-none rounded-sm cursor-pointer',
        selected && 'bg-puma-black text-white border-puma-black',
        !size.orderable &&
          'bg-puma-black-700 text-puma-black-300 border-puma-black-500'
      )}
    >
      <input
        type="radio"
        name="size"
        data-test-id="size"
        checked={selected}
        disabled={!size.orderable}
        className="absolute block appearance-none w-full h-full inset-0 cursor-pointer"
        tabIndex={size.orderable ? 0 : -1}
        onChange={() => onChange && onChange(size.id)}
      />
      <svg
        aria-hidden="true"
        viewBox="0 0 1 1"
        className="w-full opacity-0"
      ></svg>
      <span className="absolute inset-0 flex items-center justify-center">
        <span className="sr-only">{t('size')}</span>
        <span data-content="size-value" className="text-sm">
          {size.label}
        </span>
      </span>
    </label>
  );
};
