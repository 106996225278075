import { Maybe } from 'utils/types';
import { Puid } from 'groq/global-types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/HowToWearIt.ts

export type HowToWearItDocumentType = {
  id: string;
  _id: string;
  _type: string;
  country: string;
  puid?: Maybe<Puid>;
  description?: string;
};

export const HowToWearItDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    country,
    puid, 
    description,
  }
`;
