import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export type TempStateName_Type = {
  IdOfLastProductAddedToCart: string;
};

const initialTempState: TempStateName_Type = {
  IdOfLastProductAddedToCart: '',
};

interface State {
  tempState: TempStateName_Type;
  setTempState: (value: TempStateName_Type) => void;
}

const _useTempStateStore = create<State>(set => ({
  tempState: initialTempState,
  setTempState: value => set({ tempState: value }),
}));

// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export const useTempStateStore = () => {
  const { tempState, setTempState: _setTempState } = _useTempStateStore(
    useShallow(state => ({
      tempState: state.tempState,
      setTempState: state.setTempState,
    }))
  );

  const setTempState = (
    tempStateName: keyof TempStateName_Type,
    value: string
  ) => _setTempState({ ...tempState, [tempStateName]: value });

  const getTempState = (tempStateName: keyof TempStateName_Type) =>
    tempState[tempStateName];

  return { getTempState, setTempState };
};

// ─── Usage ───────────────────────────────────────────────────────────────────
//
// const { getTempState, setTempState } = useTempStateStore();
//
// if (!getTempState('foobar')) {
//   setTempState('foobar', 'asd123');
// }
//
// ─────────────────────────────────────────────────────────────────────────────
