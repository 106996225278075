import { useEffect, useState } from 'react';

import { useWindowSize } from './useWindowSize';

export function useDeviceOrientation() {
  type DeviceOrientation = 'portrait' | 'landscape';

  const [deviceOrientation, setDeviceOrientation] =
    useState<DeviceOrientation>('portrait');

  const { width, height } = useWindowSize();

  useEffect(() => {
    if (width !== null && height !== null) {
      if (width > height) {
        setDeviceOrientation('landscape');
      } else {
        setDeviceOrientation('portrait');
      }
    }
  }, [width, height]);

  return deviceOrientation;
}

// usage: `const deviceOrientation = useDeviceOrientation();`
