import { IncomingMessage } from 'http';
import { ParsedUrlQuery } from 'querystring';

import i18n from '../i18n.json';

import {
  SUPPORTED_COUNTRY,
  SUPPORTED_LANGUAGE,
  SUPPORTED_LOCALE,
} from './constants';

type RequestInfo = {
  country: string;
  language: string;
};

/**
 * Returns the remapped country code for countries that use
 * alternate ISO country codes such as UK
 * @param countryCode The ISO country code
 */
export const getMappedCountryCode = (countryCode: string) => {
  const mapping = Object.keys(i18n.urlCountryCodeMap).reduce((result, key) => {
    result[i18n.urlCountryCodeMap[key]] = key;
    return result;
  }, {});

  return mapping[countryCode.toUpperCase()]?.toLowerCase();
};

/**
 * Gets a URL path prefix for the current locale
 * @param locale The locale
 */
export const getUrlFromLocale = (locale: string) => {
  if (!locale) {
    locale = i18n.defaultLocale;
  }

  const [language, country] = locale.split('-');
  const urlCountry = getMappedCountryCode(country) || country.toLowerCase();
  return `/${urlCountry}/${language}`;
};

/**
 * Gets a locale string from the NextJS Router query parameters
 * Should only be used in client side code.
 * For SSR, use `getLocaleFromRequest` method
 * @param query Incoming Nextjs Router query
 */
export const getLocaleFromUrl = (query: ParsedUrlQuery): string => {
  const requestInfo = parseUrlData(query);
  return getLocaleString(requestInfo);
};

/**
 * Gets the locale information from the NextJS Router query parameters
 * Should only be used in client side code.
 * For SSR, use `parseRequestUrl` method
 * @param query Incoming Nextjs Router query
 */
export const parseUrlData = (query: ParsedUrlQuery): RequestInfo => {
  const { language, country } = query;
  const ucCountry = (country as string)?.toUpperCase();

  return {
    language: language as string,
    country: i18n.urlCountryCodeMap[ucCountry]?.toLowerCase() || country,
  };
};

/**
 * Gets a locale string from the NextJS Request
 * Should only be used in SSR code.
 * For client side, use `getLocaleFromUrl` method
 * @param request Incoming Nextjs request
 */
export const getLocaleFromRequest = (request: IncomingMessage) => {
  const requestInfo = parseRequestUrl(request);
  return getLocaleString(requestInfo);
};

/**
 * Gets the locale information from the NextJS Request
 * Should only be used in SSR code.
 * For client side, use `parseUrlData` method
 * @param request Incoming Nextjs request
 */
export const parseRequestUrl = (request: IncomingMessage): RequestInfo => {
  const url = request.url || '';
  const { host } = request.headers;

  // The requested URL that we can extract country and language
  // to create the locale that the user requested
  const [pathname] = url.split('?');
  let [country, language] = pathname.split('/').slice(1);

  // all requests at this stage MUST have a language and country in the URL
  if (!(language && country)) {
    // We are rendering a page outside of the
    // `pages/[country]/[language]/*` directory and we must
    // provide a default locale for the domain in order
    // for special pages like _error and 404 to prerender
    const domain = i18n.domains?.find(({ domain }) => domain === host) || i18n;
    const [defaultLanguage, defaultCountry] = domain.defaultLocale.split('-');

    language = defaultLanguage.toLowerCase();
    country = defaultCountry.toLowerCase();
  }

  return {
    language,

    // country codes can be remapped for vanity (uk => gb)
    country:
      i18n.urlCountryCodeMap[country.toUpperCase()]?.toLowerCase() || country,
  };
};

/**
 * Returns the url path without the locale prefix
 * @param path The NextJS Router url path
 * @param locale The locale
 */
export const removeLocalePrefix = (
  path: string,
  locale: SUPPORTED_LOCALE
): string => {
  const prefix = getUrlFromLocale(locale);
  return path.replace(prefix, '');
};

// create an ISO locale string from locale information
function getLocaleString(requestInfo: RequestInfo): string {
  if (requestInfo.country && requestInfo.language) {
    return `${requestInfo.language.toLowerCase()}-${requestInfo.country.toUpperCase()}`;
  }
  return '';
}

export function getCountryLanguageFromLocale(locale: SUPPORTED_LOCALE) {
  const [language, country] = locale.split('-');
  return {
    language: language as SUPPORTED_LANGUAGE,
    country: country as SUPPORTED_COUNTRY,
  };
}
