import { useRef } from 'react';
import { useRouter } from 'next/router';

import {
  BodyHeader as BodyHeaderType,
  GenericTextSize,
} from '__generated__/graphql';
import { SLOT_LINK_TYPE } from 'utils/constants';
import { BannerLinkType } from 'utils/types';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

import { LinkOrTag, TagType } from './LinkOrTag';

export const BodyHeader = ({
  id,
  header,
  horizontalAlignment,
  size,
  _id,
  _type,
  link,
}: BodyHeaderType & {
  _id?: string;
  _type?: string;
  link?: BannerLinkType;
}) => {
  const { asPath } = useRouter();
  const Tag = (
    {
      [GenericTextSize.Large]: 'h1',
      [GenericTextSize.Medium]: 'h2',
      [GenericTextSize.Small]: 'h3',
    } as const
  )[size || GenericTextSize.Small];

  const headingSize = (
    {
      [GenericTextSize.Large]: { initial: 'sm', sm: 'base', md: 'lg' },
      [GenericTextSize.Medium]: { initial: 'xs', sm: 'sm', md: 'base' },
      [GenericTextSize.Small]: { initial: 'xxs', sm: 'xs', md: 'sm' },
    } as const
  )[size || GenericTextSize.Small];

  const promotion_id = _id || id || '';
  const promotion_name = header || '';
  const creative_name = _type || 'BodyHeader';

  const bodyHeaderRef = useRef(null);

  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  const extendedGaTrackData = {
    ...gaTrackData,
    cta_links: link?.link ? [link.link] : [],
  };
  const { ga4PromotionTrackerPosition } = usePromotionView(
    bodyHeaderRef,
    extendedGaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    link_url: link?.link || '',
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <LinkOrTag
      dataTestId="body-header"
      ga4Data={ga4Data}
      href={link?.link}
      query={link?.query}
      linkType={SLOT_LINK_TYPE.LINK}
      tag={TagType.H1}
      ref={bodyHeaderRef}
      headingSize={headingSize}
    >
      <Tag className={`text-${horizontalAlignment}`}>{header}</Tag>
    </LinkOrTag>
  );
};
