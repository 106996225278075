import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import { CallToActionObject } from 'groq/objects/CallToActionObject';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/Header.ts

export type BodyHeaderDocumentType = {
  _id: string;
  _type?: Maybe<string>;
  header: LocaleStringType;
  horizontalAlignment: string;
  size: string;
};

export const BodyHeaderDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    ${LocaleString('header')},
    horizontalAlignment,
    size,
    ${CallToActionObject('link')},

  }
`;
