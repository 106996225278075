import { parseCookies } from 'nookies';

export const FORTER_TOKEN_STORAGE_KEY = 'forterToken';
export const FORTER_WEBID_STORAGE_KEY = 'forterWebId';
export const FORTER_CUSTOMER_WEBID_STORAGE_KEY = 'forterCustomerWebId';

export const formatForterWebId = (webId: string | null) => {
  if (!webId) return '';

  let result = '';

  for (let i = 1; i <= webId.length; i += 1) {
    result += webId[i - 1];
    if (i % 3 === 0) result += ' ';
  }

  return result.trim();
};

export const validateForterWebId = (webId: string | null) => {
  if (!webId) return false;

  // web ID must be a valid luhn number. implementing validation
  // here to avoid typos having forter reject the payment
  const webIdNoSpaces = webId.replaceAll(' ', '');
  let sum = 0;
  let mul = 1;
  let len = webIdNoSpaces.length;
  while (len--) {
    const ca = parseInt(webIdNoSpaces.charAt(len), 10) * mul;
    sum += ca - (ca > 9 ? 1 : 0) * 9;
    mul = 3 - mul;
  }
  return sum % 10 === 0 && sum > 0;
};

export const getForterToken = (): string => {
  return parseCookies()['forterToken'] ?? '';
};

export const retrieveWebId = (): string | null => {
  return localStorage.getItem(FORTER_WEBID_STORAGE_KEY);
};

export const retrieveCustomerWebId = (): string | null => {
  return (
    window.sessionStorage.getItem(FORTER_CUSTOMER_WEBID_STORAGE_KEY) ?? null
  );
};

export const storeCustomerWebId = (value: string | null) => {
  if (value) {
    window.sessionStorage.setItem(FORTER_CUSTOMER_WEBID_STORAGE_KEY, value);
  } else {
    window.sessionStorage.removeItem(FORTER_CUSTOMER_WEBID_STORAGE_KEY);
  }
};
