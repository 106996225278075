export const auStates = [
  { name: 'New South Wales', value: 'NSW' },
  { name: 'Victoria', value: 'VIC' },
  { name: 'Queensland', value: 'QLD' },
  { name: 'Western Australia', value: 'WA' },
  { name: 'South Australia', value: 'SA' },
  { name: 'Tasmania', value: 'TAS' },
  { name: 'Australian Capital Territory', value: 'ACT' },
  { name: 'Northern Territory', value: 'NT' },
];

export const caStates = [
  { name: 'Alberta', value: 'AB' },
  { name: 'British Columbia', value: 'BC' },
  { name: 'Manitoba', value: 'MB' },
  { name: 'New Brunswick', value: 'NB' },
  { name: 'Newfoundland and Labrador', value: 'NL' },
  { name: 'Northwest Territories', value: 'NT' },
  { name: 'Nova Scotia', value: 'NS' },
  { name: 'Nunavut', value: 'NU' },
  { name: 'Ontario', value: 'ON' },
  { name: 'Prince Edward Island', value: 'PE' },
  { name: 'Quebec', value: 'QC' },
  { name: 'Saskatchewan', value: 'SK' },
  { name: 'Yukon Territory', value: 'YT' },
];

/**
 * @TODO: Does this need to be expansed to be multi lanaguage or is that handled in site translations?
 */
export const deStates = [
  { name: 'Baden-Württemberg', value: 'BW' },
  { name: 'Bayern', value: 'BY' },
  { name: 'Berlin', value: 'BE' },
  { name: 'Brandenburg', value: 'BB' },
  { name: 'Bremen', value: 'HB' },
  { name: 'Hamburg', value: 'HH' },
  { name: 'Hessen', value: 'HE' },
  { name: 'Mecklenburg-Vorpommern', value: 'MV' },
  { name: 'Niedersachsen', value: 'NI' },
  { name: 'Nordrhein-Westfalen', value: 'NW' },
  { name: 'Rheinland-Pfalz', value: 'RP' },
  { name: 'Saarland', value: 'SL' },
  { name: 'Sachsen', value: 'SN' },
  { name: 'Sachsen-Anhalt', value: 'ST' },
  { name: 'Schleswig-Holstein', value: 'SH' },
  { name: 'Thüringen', value: 'TH' },
];

export const gbStates = [
  { name: 'Aberdeenshire', value: 'Aberdeenshire' },
  { name: 'Angus', value: 'Angus' },
  { name: 'Argyll', value: 'Argyll' },
  { name: 'Avon', value: 'Avon' },
  { name: 'Ayrshire', value: 'Ayrshire' },
  { name: 'Banffshire', value: 'Banffshire' },
  { name: 'Bedfordshire', value: 'Bedfordshire' },
  { name: 'Berkshire', value: 'Berkshire' },
  { name: 'Berwickshire', value: 'Berwickshire' },
  { name: 'Buckinghamshire', value: 'Buckinghamshire' },
  { name: 'Caithness', value: 'Caithness' },
  { name: 'Cambridgeshire', value: 'Cambridgeshire' },
  { name: 'Cheshire', value: 'Cheshire' },
  { name: 'Clackmannanshire', value: 'Clackmannanshire' },
  { name: 'Cleveland', value: 'Cleveland' },
  { name: 'Clwyd', value: 'Clwyd' },
  { name: 'Cornwall', value: 'Cornwall' },
  { name: 'County Antrim', value: 'County Antrim' },
  { name: 'County Armagh', value: 'County Armagh' },
  { name: 'County Down', value: 'County Down' },
  { name: 'County Durham', value: 'County Durham' },
  { name: 'County Fermanagh', value: 'County Fermanagh' },
  { name: 'County Londonderry', value: 'County Londonderry' },
  { name: 'County Tyrone', value: 'County Tyrone' },
  { name: 'Cumbria', value: 'Cumbria' },
  { name: 'Derbyshire', value: 'Derbyshire' },
  { name: 'Devon', value: 'Devon' },
  { name: 'Dorset', value: 'Dorset' },
  { name: 'Dumfriesshire', value: 'Dumfriesshire' },
  { name: 'Dunbartonshire', value: 'Dunbartonshire' },
  { name: 'Dyfed', value: 'Dyfed' },
  { name: 'East Lothian', value: 'East Lothian' },
  { name: 'East Sussex', value: 'East Sussex' },
  { name: 'Essex', value: 'Essex' },
  { name: 'Fife', value: 'Fife' },
  { name: 'Gloucestershire', value: 'Gloucestershire' },
  { name: 'Gwent', value: 'Gwent' },
  { name: 'Gwynedd', value: 'Gwynedd' },
  { name: 'Hampshire', value: 'Hampshire' },
  { name: 'Herefordshire', value: 'Herefordshire' },
  { name: 'Hertfordshire', value: 'Hertfordshire' },
  { name: 'Inverness-shire', value: 'Inverness-shire' },
  { name: 'Isle of Arran', value: 'Isle of Arran' },
  { name: 'Isle of Barra', value: 'Isle of Barra' },
  { name: 'Isle of Benbecula', value: 'Isle of Benbecula' },
  { name: 'Isle of Bute', value: 'Isle of Bute' },
  { name: 'Isle of Canna', value: 'Isle of Canna' },
  { name: 'Isle of Coll', value: 'Isle of Coll' },
  { name: 'Isle of Colonsay', value: 'Isle of Colonsay' },
  { name: 'Isle of Cumbrae', value: 'Isle of Cumbrae' },
  { name: 'Isle of Eigg', value: 'Isle of Eigg' },
  { name: 'Isle of Gigha', value: 'Isle of Gigha' },
  { name: 'Isle of Harris', value: 'Isle of Harris' },
  { name: 'Isle of Iona', value: 'Isle of Iona' },
  { name: 'Isle of Islay', value: 'Isle of Islay' },
  { name: 'Isle of Jura', value: 'Isle of Jura' },
  { name: 'Isle of Lewis', value: 'Isle of Lewis' },
  { name: 'Isle of Mull', value: 'Isle of Mull' },
  { name: 'Isle of North Uist', value: 'Isle of North Uist' },
  { name: 'Isle of Rhum', value: 'Isle of Rhum' },
  { name: 'Isle of Scalpay', value: 'Isle of Scalpay' },
  { name: 'Isle of Skye', value: 'Isle of Skye' },
  { name: 'Isle of South Uist', value: 'Isle of South Uist' },
  { name: 'Isle of Tiree', value: 'Isle of Tiree' },
  { name: 'Isle of Wight', value: 'Isle of Wight' },
  { name: 'Kent', value: 'Kent' },
  { name: 'Kincardineshire', value: 'Kincardineshire' },
  { name: 'Kinross-shire', value: 'Kinross-shire' },
  { name: 'Kirkcudbrightshire', value: 'Kirkcudbrightshire' },
  { name: 'Lanarkshire', value: 'Lanarkshire' },
  { name: 'Lancashire', value: 'Lancashire' },
  { name: 'Leicestershire', value: 'Leicestershire' },
  { name: 'Lincolnshire', value: 'Lincolnshire' },
  { name: 'London', value: 'London' },
  { name: 'Merseyside', value: 'Merseyside' },
  { name: 'Mid Glamorgan', value: 'Mid Glamorgan' },
  { name: 'Middlesex', value: 'Middlesex' },
  { name: 'Midlothian', value: 'Midlothian' },
  { name: 'Morayshire', value: 'Morayshire' },
  { name: 'Nairnshire', value: 'Nairnshire' },
  { name: 'Norfolk', value: 'Norfolk' },
  { name: 'North Humberside', value: 'North Humberside' },
  { name: 'North Yorkshire', value: 'North Yorkshire' },
  { name: 'Northamptonshire', value: 'Northamptonshire' },
  { name: 'Northumberland', value: 'Northumberland' },
  { name: 'Nottinghamshire', value: 'Nottinghamshire' },
  { name: 'Orkney', value: 'Orkney' },
  { name: 'Oxfordshire', value: 'Oxfordshire' },
  { name: 'Peeblesshire', value: 'Peeblesshire' },
  { name: 'Perthshire', value: 'Perthshire' },
  { name: 'Powys', value: 'Powys' },
  { name: 'Renfrewshire', value: 'Renfrewshire' },
  { name: 'Ross-shire', value: 'Ross-shire' },
  { name: 'Roxburghshire', value: 'Roxburghshire' },
  { name: 'Selkirkshire', value: 'Selkirkshire' },
  { name: 'Shetland', value: 'Shetland' },
  { name: 'Shropshire', value: 'Shropshire' },
  { name: 'Somerset', value: 'Somerset' },
  { name: 'South Glamorgan', value: 'South Glamorgan' },
  { name: 'South Humberside', value: 'South Humberside' },
  { name: 'South Yorkshire', value: 'South Yorkshire' },
  { name: 'Staffordshire', value: 'Staffordshire' },
  { name: 'Stirlingshire', value: 'Stirlingshire' },
  { name: 'Suffolk', value: 'Suffolk' },
  { name: 'Surrey', value: 'Surrey' },
  { name: 'Sutherland', value: 'Sutherland' },
  { name: 'Tyne and Wear', value: 'Tyne and Wear' },
  { name: 'Warwickshire', value: 'Warwickshire' },
  { name: 'West Glamorgan', value: 'West Glamorgan' },
  { name: 'West Lothian', value: 'West Lothian' },
  { name: 'West Midlands', value: 'West Midlands' },
  { name: 'West Sussex', value: 'West Sussex' },
  { name: 'West Yorkshire', value: 'West Yorkshire' },
  { name: 'Wigtownshire', value: 'Wigtownshire' },
  { name: 'Wiltshire', value: 'Wiltshire' },
  { name: 'Worcestershire', value: 'Worcestershire' },
];

export const inStates = [
  { name: 'Andhra Pradesh', value: 'AP' },
  { name: 'Arunachal Pradesh', value: 'AR' },
  { name: 'Assam', value: 'AS' },
  { name: 'Bihar', value: 'BR' },
  { name: 'Chhattisgarh', value: 'CG', isoCompliantValue: 'CT' },
  { name: 'Goa', value: 'GA' },
  { name: 'Gujarat', value: 'GJ' },
  { name: 'Haryana', value: 'HR' },
  { name: 'Himachal Pradesh', value: 'HP' },
  { name: 'Jammu and Kashmir', value: 'JK' },
  { name: 'Jharkhand', value: 'JH' },
  { name: 'Karnataka', value: 'KA' },
  { name: 'Kerala', value: 'KL' },
  { name: 'Madhya Pradesh', value: 'MP' },
  { name: 'Maharashtra', value: 'MH' },
  { name: 'Manipur', value: 'MN' },
  { name: 'Meghalaya', value: 'ML' },
  { name: 'Mizoram', value: 'MZ' },
  { name: 'Nagaland', value: 'NL' },
  { name: 'Odisha', value: 'OD', isoCompliantValue: 'OR' },
  { name: 'Punjab', value: 'PB' },
  { name: 'Rajasthan', value: 'RJ' },
  { name: 'Sikkim', value: 'SK' },
  { name: 'Tamil Nadu', value: 'TN' },
  { name: 'Telangana', value: 'TS', isoCompliantValue: 'TG' },
  { name: 'Tripura', value: 'TR' },
  { name: 'Uttar Pradesh', value: 'UP' },
  { name: 'Uttarakhand', value: 'UK', isoCompliantValue: 'UT' },
  { name: 'West Bengal', value: 'WB' },
  { name: 'Andaman and Nicobar Islands', value: 'AN' },
  { name: 'Chandigarh', value: 'CH' },
  { name: 'Dadra and Nagar Haveli', value: 'DN', isoCompliantValue: 'DH' },
  { name: 'Daman and Diu', value: 'DD', isoCompliantValue: 'DH' },
  { name: 'Lakshadweep', value: 'LD' },
  { name: 'NCT Delhi', value: 'DL' },
  { name: 'Puducherry', value: 'PY' },
];

export const jpStates = [
  { name: '北海道', value: 1 },
  { name: '青森県', value: 2 },
  { name: '岩手県', value: 3 },
  { name: '宮城県', value: 4 },
  { name: '秋田県', value: 5 },
  { name: '山形県', value: 6 },
  { name: '福島県', value: 7 },
  { name: '茨城県', value: 8 },
  { name: '栃木県', value: 9 },
  { name: '群馬県', value: 10 },
  { name: '埼玉県', value: 11 },
  { name: '千葉県', value: 12 },
  { name: '東京都', value: 13 },
  { name: '神奈川県', value: 14 },
  { name: '新潟県', value: 15 },
  { name: '富山県', value: 16 },
  { name: '石川県', value: 17 },
  { name: '福井県', value: 18 },
  { name: '山梨県', value: 19 },
  { name: '長野県', value: 20 },
  { name: '岐阜県', value: 21 },
  { name: '静岡県', value: 22 },
  { name: '愛知県', value: 23 },
  { name: '三重県', value: 24 },
  { name: '滋賀県', value: 25 },
  { name: '京都府', value: 26 },
  { name: '大阪府', value: 27 },
  { name: '兵庫県', value: 28 },
  { name: '奈良県', value: 29 },
  { name: '和歌山県', value: 30 },
  { name: '鳥取県', value: 31 },
  { name: '島根県', value: 32 },
  { name: '岡山県', value: 33 },
  { name: '広島県', value: 34 },
  { name: '山口県', value: 35 },
  { name: '徳島県', value: 36 },
  { name: '香川県', value: 37 },
  { name: '愛媛県', value: 38 },
  { name: '高知県', value: 39 },
  { name: '福岡県', value: 40 },
  { name: '佐賀県', value: 41 },
  { name: '長崎県', value: 42 },
  { name: '熊本県', value: 43 },
  { name: '大分県', value: 44 },
  { name: '宮崎県', value: 45 },
  { name: '鹿児島県', value: 46 },
  { name: '沖縄県', value: 47 },
];

export const nzStates = [
  { name: 'Auckland', value: 'Auckland' },
  { name: 'Bay of Plenty', value: 'Bay of Plenty' },
  { name: 'Canterbury', value: 'Canterbury' },
  { name: 'Chatham Islands Territory', value: 'Chatham Islands Territory' },
  { name: 'Gisborne', value: 'Gisborne' },
  { name: "Hawke's Bay", value: "Hawke's Bay" },
  { name: 'Manawatū-Whanganui', value: 'Manawatū-Whanganui' },
  { name: 'Marlborough', value: 'Marlborough' },
  { name: 'Nelson', value: 'Nelson' },
  { name: 'Northland', value: 'Northland' },
  { name: 'Otago', value: 'Otago' },
  { name: 'Southland', value: 'Southland' },
  { name: 'Taranaki', value: 'Taranaki' },
  { name: 'Tasman', value: 'Tasman' },
  { name: 'Waikato', value: 'Waikato' },
  { name: 'Wellington', value: 'Wellington' },
  { name: 'West Coast', value: 'West Coast' },
];

export const usStates = [
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'American Samoa', value: 'AS' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'Delaware', value: 'DE' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Guam', value: 'GU' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Northern Mariana Islands', value: 'MP' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'US Minor Outlying Islands', value: 'UM' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virgin Islands, U.S.', value: 'VI' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
  { name: 'Armed Forces Africa', value: 'AF' },
  { name: 'Armed Forces America (exc. Canada)', value: 'AA' },
  { name: 'Armed Forces Canada', value: 'AC' },
  { name: 'Armed Forces Europe', value: 'AE' },
  { name: 'Armed Forces Middle East', value: 'AM' },
  { name: 'Armed Forces Pacific', value: 'AP' },
];
