import { REFERRER_TYPE, REFERRER_CATEGORY } from './constants';

export const setReferrerType = (type: string) => {
  localStorage.setItem(REFERRER_TYPE, type);
  localStorage.removeItem(REFERRER_CATEGORY);
};

export const setReferrerCategory = (category: string) => {
  localStorage.setItem(REFERRER_CATEGORY, category);
  localStorage.removeItem(REFERRER_TYPE);
};

export const removeReferrerTypeAndCategory = () => {
  localStorage.removeItem(REFERRER_TYPE);
  localStorage.removeItem(REFERRER_CATEGORY);
};
