import React, { useRef } from 'react';
import { tw } from 'twind';

import { Iframe as IframeType } from '__generated__/graphql';
import { Container } from 'ui/elements/LayoutContainer';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { Maybe } from '__generated__/graphql-sanity';

export const Iframe = (props: IframeType) => {
  if (!props.src) return null;
  return (
    <IframeContainer
      id={props.id}
      typename={props.__typename}
      fullScreen={props.fullScreen}
      height={props.height}
      src={props.src}
    />
  );
};

const IframeContainer = ({
  id,
  typename,
  fullScreen,
  height,
  src,
}: {
  id: string;
  typename: 'Iframe' | undefined;
  fullScreen: boolean;
  height: Maybe<string> | undefined;
  src: string;
}) => {
  const iframeRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: id || '',
    name: '',
    creative: typename || 'Iframe',
  };
  usePromotionView(iframeRef, gaTrackData, true);
  return (
    <Container bleed={fullScreen} data-test-id="iframe-container">
      <section
        className={tw(fullScreen && 'mobile:-my-4 tablet:-my-6 desktop:-my-8')}
        ref={iframeRef}
      >
        <div
          className={tw(
            'w-full',
            fullScreen ? 'h-screen' : `h-[${height || '600'}px]`
          )}
        >
          <iframe
            className="w-full h-full"
            src={src}
            allow="geolocation"
          ></iframe>
        </div>
      </section>
    </Container>
  );
};
