import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useCancelOrderMutation } from '__generated__/graphql';
import { PAYMENT_METHOD_SESSION_STORAGE_ENTRY } from 'utils/constants';

const sessionStorageEntries = Object.values(
  PAYMENT_METHOD_SESSION_STORAGE_ENTRY
);

type SessionStorageEntry = {
  sessionStorageEntryName: string;
  orderNo: string;
};

/**
 * When a user starts checkout, they can initialize a payment method.
 * If they navigate away from checkout, this hook will cancel those pending payments.
 */
export const useCancelCustomerOrder = (customerId: string | undefined) => {
  const [, cancelOrder] = useCancelOrderMutation();
  const router = useRouter();

  useEffect(() => {
    const isConfirmationPage = router.asPath.includes('checkout/confirmation');
    const isPaypalPage = router.asPath.includes('checkout/paypal');
    const isValidationPage = router.asPath.includes('validation');
    const isPayPayPage = router.asPath.includes('paypay');

    if (
      isConfirmationPage ||
      isValidationPage ||
      isPaypalPage ||
      isPayPayPage
    ) {
      return;
    }

    const retreivedEntriesFromSessionStorage: SessionStorageEntry[] = [];

    // Get all the sessionStorageEntries, and save the order number if we find any.
    for (const sessionStorageEntryName of sessionStorageEntries) {
      const orderNo = window.sessionStorage.getItem(sessionStorageEntryName);

      if (orderNo) {
        retreivedEntriesFromSessionStorage.push({
          sessionStorageEntryName,
          orderNo,
        });
      }
    }

    (async () => {
      const promises: Promise<any>[] = [];

      for (const sessionStorageEntry of retreivedEntriesFromSessionStorage) {
        const { orderNo, token, ...query } = router.query;

        // Remove query and token from the router params.
        router.replace({
          pathname: router.pathname,
          query,
        });

        // Remove the session storage entries.
        window.sessionStorage.removeItem(
          sessionStorageEntry.sessionStorageEntryName
        );

        // CancelOrder in GQL.
        promises.push(
          cancelOrder({
            input: { orderNo: sessionStorageEntry.orderNo },
          })
        );
      }

      await Promise.all(promises);
    })();

    // We only want to re-run this if the path or customerId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, router.asPath.split('?')[0]]);
};
