import { isServer } from './constants';

const isTouchScreenOperationSystem = (userAgent: string) =>
  /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
  /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent);

export const isTouchScreenDevice = (): boolean => {
  if (isServer) {
    return false;
  }
  const UA = navigator.userAgent;
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    if (window.screen.width >= 1024) {
      hasTouchScreen = isTouchScreenOperationSystem(UA);
    } else {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    }
  } else {
    const mQ =
      typeof window.matchMedia === 'function' && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      hasTouchScreen = isTouchScreenOperationSystem(UA);
    }
  }

  return hasTouchScreen;
};
