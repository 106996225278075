import { useRouter } from 'next/router';
import React from 'react';

import { GlobalPromoBanner } from 'ui/content/GlobalPromoBanner';
import { SecondGlobalBanner } from 'ui/content/SecondGlobalBanner';
import { MainNav } from 'ui/components/MainNav';

const getBannerHeightInView = (elem: HTMLElement | null) => {
  if (!elem) {
    return 0;
  }

  const bounds = elem.getBoundingClientRect();
  if (!bounds) {
    return elem.offsetHeight;
  }

  return bounds.bottom < 0 ? 0 : Math.min(bounds.bottom, bounds.height);
};

export const Header = () => {
  const router = useRouter();

  // Used to provide height information to MainNav
  const globalPromoBannerRef = React.useRef<HTMLElement | null>(null);
  const [mobileNavOffset, setMobileNavOffset] = React.useState<number>(
    getBannerHeightInView(globalPromoBannerRef.current)
  );

  const handleScroll = React.useCallback(() => {
    const amountInViewPx = getBannerHeightInView(globalPromoBannerRef.current);
    if (mobileNavOffset !== amountInViewPx) {
      setMobileNavOffset(amountInViewPx);
    }
  }, [mobileNavOffset]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <GlobalPromoBanner ref={globalPromoBannerRef} />
      <MainNav
        minimal={
          router.asPath.includes('/checkout') ||
          router.asPath.includes('showQuickRegister')
        }
        mobileNavOffsetPx={mobileNavOffset}
      />
      <SecondGlobalBanner />
    </>
  );
};
