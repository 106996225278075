import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import ForterScript from 'ui/elements/ForterScript';
import { useSiteConfig } from 'hooks/useSiteConfig';

type ForterContextType = {
  isWaitingForForter: boolean;
  shouldRenderForter: boolean;
  onRenderForter: () => void;
};

const ForterContext = createContext<ForterContextType>({
  isWaitingForForter: false,
  shouldRenderForter: true,
  onRenderForter: () => null,
});

export const ForterProvider: React.FC = ({ children }) => {
  const {
    env,
    staticFeatures: { delayForterLoad },
  } = useSiteConfig();

  const forterEnabled = !!env.forterSiteId;

  const [shouldRenderForter, setShouldRenderForter] = useState(
    !delayForterLoad && forterEnabled
  );

  const [isWaitingForForter, setIsWaitingForForter] = useState(forterEnabled);

  const providerValue = useMemo(
    () => ({
      shouldRenderForter,
      isWaitingForForter,
      onRenderForter: () =>
        forterEnabled ? setShouldRenderForter(true) : null,
    }),
    [isWaitingForForter, shouldRenderForter, forterEnabled]
  );

  useEffect(() => {
    if (!forterEnabled) return;

    const handler = () => setIsWaitingForForter(false);
    document.addEventListener('ftr:tokenReady', handler);
    return () => document.removeEventListener('ftr:tokenReady', handler);
  }, [forterEnabled]);

  return (
    <ForterContext.Provider value={providerValue}>
      {shouldRenderForter ? <ForterScript /> : null}
      {children}
    </ForterContext.Provider>
  );
};

export const useForter = (): ForterContextType => useContext(ForterContext);
