import { tw } from 'twind/css';

export type ResponsiveImageSizes = {
  mobile: `${number}vw`;
  tablet: `${number}vw`;
  desktop: `${number}vw`;
};

export const getImageMediaCondition = (screen: keyof ResponsiveImageSizes) => {
  return (tw.theme(`screens.${screen}`) as any).raw.replace('screen and ', '');
};

export const getResponsiveImageSizes = (sizes: ResponsiveImageSizes) => {
  const mobileMediaCondition = getImageMediaCondition('mobile');
  const tabletMediaCondition = getImageMediaCondition('tablet');
  const desktopMediaCondition = getImageMediaCondition('desktop');

  return `${mobileMediaCondition} ${sizes.mobile}, ${tabletMediaCondition} ${sizes.tablet}, ${desktopMediaCondition} ${sizes.desktop}`;
};

/**
 * Resizes the product image by changing the width value in the image's URL.
 * The `originalImageWidth` parameter is used to identify the width to replace, which defaults to `'2000'`.
 * If the `originalImageWidth` is not found in the src URL, it returns the original src URL.
 *
 * Works for the following URL format:
 * - https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/374915/01/sv01/fnd/PNA/fmt/png
 */
export const resizeProductImage = (
  src: string | undefined,
  newWidth: number,
  originalImageWidth = '2000'
) => {
  if (!src) return '';

  // Captures the width or height value in the image URL, e.g. 'w_2000', 'h_2000'
  const widthHeightRegex = new RegExp(`([wh])_${originalImageWidth}`, 'g');

  if (!src.match(widthHeightRegex)) return src;

  return src.replace(widthHeightRegex, `$1_${newWidth.toString()}`);
};

/**
 * Resizes the product image by changing the width value in the image's URL.
 * The `originalImageWidth` parameter is used to identify the width to replace, which defaults to `'1200'`.
 * If the `originalImageWidth` is not found in the src URL, it returns the original src URL.
 *
 * Works for the following URL format:
 * - https://images.puma.net/images/534038/74/fnd/PNA/w/1200/h/1200/
 */
export const resizeStyliticsImage = (
  src: string | undefined,
  newWidth: number,
  originalImageWidth = '1200'
) => {
  if (!src) return '';

  // Captures the width or height value in the image URL, e.g. 'w/1200', 'h/1200'.
  const widthHeightRegex = new RegExp(`([wh])/${originalImageWidth}`, 'g');

  if (!src.match(widthHeightRegex)) return src;

  return src.replace(widthHeightRegex, `$1/${newWidth.toString()}`);
};

/**
 * Creates a string for a responsive image srcset. It replaces the original width in the src URL with each
 * of the specified widths, creating a separate URL for each.
 *
 * @param {string} src - The URL of the original image, where the width is specified as 'w_[originalImageWidth]', e.g. 'w_900'.
 * @param {number[]} widths - The list of widths to be applied. Each width generates a new URL.
 * @param {string} originalImageWidth - The width value to be replaced in the original image URL, e.g. '900'.
 * @returns {string} The generated srcset string, with each URL-width pair separated by a comma.
 */
export const getResponsiveImageSrcSet = (
  src: string,
  widths: number[],
  originalImageWidth: string
) => {
  const srcSetArray = widths.map(width => {
    const newSrc = resizeProductImage(src, width, originalImageWidth);
    return `${newSrc} ${width}w`;
  });

  return srcSetArray.join(',');
};

/**
 * Adds a width transform to Cloudinary images.
 * Needed as product images using a named transform do not have an explicit width transform in the URL.
 * Adds w_2000, as this matches the default transform used for square images
 *
 * Works for the following URL format:
 * - https://images.puma.com/image/upload/t_vertical_model/global/193923/02/mod03/fnd/PNA/
 */

export const addWidthTransform = (src: string) =>
  src.replace(/\/upload\/[^/]+/, match => `${match},w_2000`);
