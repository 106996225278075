import * as Sentry from '@sentry/nextjs';
import { AuthUtilities } from '@urql/exchange-auth';
import { backOff } from 'exponential-backoff';
import { decode } from 'js-base64';
import { gql } from 'urql';

import {
  GuestLogonMutation,
  GuestLogonMutationVariables,
  RefreshLogonMutation,
  RefreshLogonMutationVariables,
} from '__generated__/graphql';
import { isServer } from 'utils/constants';
import { clientLogger } from 'utils/clientLogger';

type TokenParams = { mutate: AuthUtilities['mutate'] };
export type RefreshTokenParams = TokenParams & { refreshToken: string };
export type AuthToken = {
  exp: number;
  isb?: string;
  jti?: string;
};

// renew token if expires in less than X minute(s)
const EXPIRY_THRESHOLD = 1000 * 60 * 5;

const TOKEN_PAYLOAD_FRAGMENT = gql`
  fragment tokenPayload on TokenPayload {
    __typename
    accessToken
    refreshToken
    customerId
    uniqueShopperId
    customerContext {
      __typename
      hashKey
      customerGroups
    }
    user {
      customerNo
      email
    }
  }
`;

export const GUEST_LOGON_MUTATION = gql`
  mutation GuestLogon {
    guestLogon {
      ...tokenPayload
    }
  }
  ${TOKEN_PAYLOAD_FRAGMENT}
`;

export const REFRESH_LOGON_MUTATION = gql`
  mutation RefreshLogon($input: RefreshLogonInput!) {
    refreshLogon(input: $input) {
      ...tokenPayload
    }
  }
  ${TOKEN_PAYLOAD_FRAGMENT}
`;

export async function getGuestToken({
  mutate,
}: TokenParams): Promise<GuestLogonMutation['guestLogon']> {
  try {
    const getToken = async () => {
      const guestLogonResult = await mutate<
        GuestLogonMutation,
        GuestLogonMutationVariables
      >(GUEST_LOGON_MUTATION, {});

      const data = guestLogonResult?.data?.guestLogon;
      if (!data) {
        Sentry.addBreadcrumb({ message: 'Guest logon returned no data' });
        if (guestLogonResult.error) {
          throw guestLogonResult.error;
        }
        throw new Error('Guest logon returned no data');
      }
      return data;
    };

    const result = await backOff(getToken, {
      jitter: 'full',
      numOfAttempts: 3,
      retry: (e, attemptNumber) => {
        Sentry.addBreadcrumb({
          message: `authExchange: ${
            isServer ? 'server' : 'client'
          } guest logon failed attempt #${attemptNumber}`,
          data: {
            e,
          },
        });

        // will retry until max attempts is reached
        return true;
      },
    });

    return result;
  } catch (err) {
    clientLogger.error(
      { err },
      `authExchange: ${isServer ? 'server' : 'client'} guest logon failed`
    );

    throw err;
  }
}

export function decodeToken(token: string): AuthToken | null {
  if (!token) return null;
  try {
    if (isServer) {
      const buff = Buffer.from(token.split('.')[1], 'base64');
      return JSON.parse(buff.toString('ascii'));
    } else {
      return JSON.parse(decode(token.split('.')[1]));
    }
  } catch (err) {
    clientLogger.error({ err }, 'authExchange: failed to decode token');
    return null;
  }
}

export function isTokenExpired(token: string): boolean {
  const decodedToken = decodeToken(token);

  return (
    !decodedToken?.exp ||
    Date.now() + EXPIRY_THRESHOLD > decodedToken.exp * 1000
  );
}

export async function refreshAuthToken({
  refreshToken,
  mutate,
}: RefreshTokenParams): Promise<RefreshLogonMutation['refreshLogon'] | null> {
  if (!refreshToken) {
    clientLogger.warn({}, `authExchange: refresh called without token`);
    return null;
  }

  try {
    const refreshLogonResult = await mutate<
      RefreshLogonMutation,
      RefreshLogonMutationVariables
    >(REFRESH_LOGON_MUTATION, {
      input: {
        refreshToken,
      },
    });

    const data = refreshLogonResult?.data?.refreshLogon;
    if (!data) {
      Sentry.addBreadcrumb({ message: 'Refresh logon returned no data' });
      if (refreshLogonResult.error) {
        throw refreshLogonResult.error;
      }
      throw new Error('Refresh logon returned no data');
    }
    return data;
  } catch (err) {
    clientLogger.error({ err }, 'authExchange: refresh logon failed');

    // intentionally return null
    return null;
  }
}
