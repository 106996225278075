import { useEffect, useRef } from 'react';

import { isServer } from 'utils/constants';

export const useIntersectionObserver = <T extends HTMLElement | null>(
  container: React.MutableRefObject<T>,
  callback: (item: NonNullable<T>, observer: IntersectionObserver) => any,
  enabled?: boolean,
  options?: IntersectionObserverInit,
  onIntersectionLoss?: (v?: any, v2?: any) => void
) => {
  const callbackRef = useRef(callback);
  const onIntersectionLossRef = useRef(onIntersectionLoss);
  useEffect(() => {
    callbackRef.current = callback;
    onIntersectionLossRef.current = onIntersectionLoss;
  }, [callback, onIntersectionLoss]);

  useEffect(() => {
    let observer;
    if (container.current && !isServer) {
      observer = new IntersectionObserver(([element]) => {
        if (element.isIntersecting && enabled) {
          callbackRef.current(element.target as NonNullable<T>, observer);
        }
      }, options);
      observer.observe(container.current);
    }

    return () => {
      onIntersectionLossRef.current && onIntersectionLossRef.current();
      if (observer) observer.disconnect();
    };
  }, [container, options, enabled]);
};
