import { SUPPORTED_COUNTRY } from 'utils/constants';

import { isPlainObject } from './object';

export type ProductHistoryItem = {
  id: string;
  color: string;
};

export type ProductHistory = Partial<
  Record<SUPPORTED_COUNTRY, ProductHistoryItem[]>
>;

export const PRODUCT_HISTORY_MAX_LENGTH = 8;

const SUPPORTED_COUNTRIES = Object.values(SUPPORTED_COUNTRY);

const validateCountry = (country: string): country is SUPPORTED_COUNTRY => {
  // @ts-expect-error
  return SUPPORTED_COUNTRIES.includes(country.toUpperCase());
};

/**
 * Makes sure that the param passed to a function will have the structure that
 * can be processed as a product history entry (i.e. it has type `ProductHistory`).
 */
const validateProductHistory = (productHistory: unknown): ProductHistory => {
  const validatedProductHistory: ProductHistory = {};

  try {
    if (
      !isPlainObject<SUPPORTED_COUNTRY, ProductHistoryItem[]>(productHistory)
    ) {
      throw new Error('productHistory cannot be parsed to a plain object.');
    }

    Object.entries(productHistory).forEach(([country, items]) => {
      if (!validateCountry(country) || !Array.isArray(items)) return;

      const validItemsForCountry = items.filter(
        item =>
          typeof item === 'object' &&
          !!item?.id &&
          !!item?.color &&
          typeof item.id === 'string' &&
          typeof item.color === 'string'
      ) as ProductHistoryItem[];

      if (validItemsForCountry.length > 0) {
        validatedProductHistory[country] = validItemsForCountry;
      }
    });
  } finally {
    return validatedProductHistory;
  }
};

/**
 * Takes an `item`, `country` and `productHistory`.
 *
 * Adds the item to the top of the product history (i.e. `productHistory[0]`).
 *
 * If the product history contains more than 8 items, then it will remove the last
 * one.
 *
 * If the product history already contains the item, then the item will be removed
 * (regardless of its previous position) and moved to the top.
 */
export const pushItemForCountryToProductHistory = (
  item: ProductHistoryItem,
  country: string,
  productHistory: unknown
): ProductHistory => {
  country = country.toUpperCase();
  if (!validateCountry(country)) return {};

  const validatedProductHistory = validateProductHistory(productHistory);
  const productHistoryForCountry = validatedProductHistory[country] ?? [];

  // First, get rid of all all potential entries with the same master id.
  // It does not matter if the color is the same or not. We will replace it anyway
  // and move the new item to the top of the array.
  let newProductHistoryForCountry = productHistoryForCountry.filter(
    x => x.id !== item.id
  );

  // If the size has reached the limit, then discard the oldest elements.
  if (newProductHistoryForCountry.length >= PRODUCT_HISTORY_MAX_LENGTH) {
    newProductHistoryForCountry = newProductHistoryForCountry.slice(0, 7);
  }

  newProductHistoryForCountry.unshift(item);

  validatedProductHistory[country] = newProductHistoryForCountry;

  return validatedProductHistory;
};

/**
 * Returns an array of `styleNumber`s for a `country` from the `productHistory`.
 */
export const getRecentlyViewedProductsForCountry = (
  country: string,
  productHistory: unknown
): string[] => {
  if (!validateCountry(country)) return [];
  return (
    validateProductHistory(productHistory)[country]?.map(
      item => `${item.id}_${item.color}`
    ) ?? []
  );
};
