import { AuthState } from './authState';

/**
 * Get the request headers for all GQL requests
 * @param operationName The optional operation name
 */
export function getRequestHeaders(
  operationName?: string,
  authState?: AuthState | null
) {
  const headers = {
    'puma-request-source': 'web',
    'x-operation-name': operationName || 'unknown',
  };

  // add the authorization headers
  if (authState?.accessToken) {
    headers['authorization'] = `Bearer ${authState.accessToken}`;
    headers['refresh-token'] = authState.refreshToken;
    headers['customer-id'] = authState.customerId;
  }

  // add the customer-group received from the API
  if (authState?.customerContext?.hashKey) {
    headers['customer-group'] = authState.customerContext.hashKey;
  }

  return headers;
}
