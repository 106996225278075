import { Handler } from 'mitt';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useRouteChangeStart = (callback: Handler) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', callback);

    return () => {
      router.events.off('routeChangeStart', callback);
    };
  }, [router.events, callback]);
};
