import { Maybe } from 'graphql/jsutils/Maybe';

import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';

import { LocaleString } from './LocaleString';
import { LocaleUrl, LocaleReferenceUrl } from './LocaleUrl';

type MainNavItemLinkCategoryType = {
  url: string;
  hasItemsInStock: boolean;
  online: boolean;
  onlineFrom?: Maybe<string>;
  onlineTo?: Maybe<string>;
  showEvenEmpty: boolean;
  title: string;
  image?: Maybe<SanityImageObjectType>;
};

export type MainNavItemLinkType = {
  category?: Maybe<MainNavItemLinkCategoryType>;
  href: string;
  query?: Maybe<string>;
  newtab?: Maybe<boolean>;
};

export const MainNavItemLink = (field: string) => `
  ${field} {
    category -> {
      ${LocaleUrl},
      hasItemsInStock,
      online,
      onlineFrom,
      onlineTo,
      showEvenEmpty,
      ${LocaleString('title')},
      ${SanityImageObject('image')},
    },
    ${LocaleReferenceUrl('href')},
    query,
    newtab,
  }
`;
