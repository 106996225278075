import { SiteConfig } from 'sites/types';

import { SUPPORTED_LOCALE } from './constants';

export const longDateFormat = (date: string, locale: SUPPORTED_LOCALE) =>
  new Intl.DateTimeFormat(locale).format(new Date(date));

export const standardDateFormat = (date: string) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return `${month} ${day}, ${year}`;
};

/**
 * Formats the given date string to display the date, month, and hour in the
 * user's timezone, along with the timezone name if `showTimeZoneName` is true.
 *
 * @param date - The date string to format (e.g. '2023-04-10T12:00:00Z').
 * @param locale - The locale identifier to format the date in (e.g. 'en-US').
 * @param showTimeZoneName - Whether to show the timezone name (e.g. 'PDT'), defaults to `false`.
 * @return string - The formatted date string (e.g. '4/10, 12 PM PDT').
 *
 * @example
 * // Returns '4/10, 12 PM PDT'
 * timezoneDateFormat('2023-04-10T12:00:00Z', 'en-US', true, 'America/Los_Angeles');
 *
 * @example
 * // Returns '4/10, 12 PM'
 * timezoneDateFormat('2023-04-10T12:00:00Z', 'en-US', false, 'America/Los_Angeles');
 */
export const timezoneDateFormat = (
  date: string,
  locale: SUPPORTED_LOCALE,
  showTimeZoneName = false,
  timeZone?: string
) =>
  new Intl.DateTimeFormat(locale, {
    timeZoneName: showTimeZoneName ? 'short' : undefined,
    day: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: locale === 'en-IN' ? 'numeric' : undefined,
    hourCycle: locale === 'en-GB' || locale === 'ja-JP' ? 'h12' : undefined,
    timeZone,
  })
    .format(new Date(date))
    .toUpperCase();

/**
 * Checks if the current day and hour are within the specified opening hours.
 *
 * @param openingHours The opening hours for the live chat, typed in `sites/types.ts`.
 * @returns {boolean} `true` if currently open, `false` otherwise (or no hours given).
 */
export const isOpenNow = (
  openingHours?: NonNullable<SiteConfig['liveChat']>['openingHours']
): boolean => {
  if (!openingHours) return false;

  const now = new Date();

  const currentDay = now.getDay();
  const currentHour = now.getHours();

  return (
    openingHours.days.includes(currentDay) &&
    openingHours.hours.includes(currentHour)
  );
};

type IsDateBetweenArgs = Record<'startDate' | 'endDate' | 'dateToCheck', Date>;
/**
 * Checks if a given date is between two other dates
 *
 * @param {Object} dates - The object containing the three dates
 * @param {Date} dates.startDate - The start date of the interval
 * @param {Date} dates.endDate - The end date of the interval
 * @param {Date} dates.dateToCheck - The date which will be checked
 * @returns {boolean} `true` if the date to check is between the start and the
 * end date, `false` otherwise
 */
export const isDateBetween = ({
  startDate,
  endDate,
  dateToCheck,
}: IsDateBetweenArgs): boolean =>
  startDate.getTime() <= dateToCheck.getTime() &&
  endDate.getTime() >= dateToCheck.getTime();

type IsDateAfterArgs = Record<'dateToCheck' | 'startDate', Date>;
/**
 * Checks if a given date is between two other dates
 *
 * @param {Object} dates - The object containing the two dates
 * @param {Date} dates.dateToCheck - The date which will be checked
 * @param {Date} dates.startDate - The start date of the interval
 * @returns {boolean} `true` if the date to check is after the start date,
 * `false` otherwise
 */
export const IsDateAfter = ({
  dateToCheck,
  startDate,
}: IsDateAfterArgs): boolean => startDate.getTime() <= dateToCheck.getTime();

type IsDateBeforeArgs = Record<'dateToCheck' | 'endDate', Date>;
/**
 * Checks if a given date is between two other dates
 *
 * @param {Object} dates - The object containing the two dates
 * @param {Date} dates.dateToCheck - The date which will be checked
 * @param {Date} dates.endDate - The end date of the interval
 * @returns {boolean} `true` if the date to check is before the end date,
 * `false` otherwise
 */
export const isDateBefore = ({
  dateToCheck,
  endDate,
}: IsDateBeforeArgs): boolean => endDate.getTime() >= dateToCheck.getTime();
