import { Maybe } from 'utils/types';
import { Puid } from 'groq/global-types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';
import { Tag } from '__generated__/graphql-sanity';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/FAQModule.ts

export type FAQItemType = {
  question?: Maybe<LocaleStringType>;
  answer: Maybe<LocalePortableTextType>;
  tags: Tag[];
  _id: string;
};

export type FAQModuleDocumentType = {
  id: string;
  _id: string;
  _type: string;
  country: string;
  puid?: Maybe<Puid>;
  localeTitle?: Maybe<LocaleStringType>;
  faqs?: FAQItemType[];
};

export const FAQ_ITEM_PORTABLE_TEXT_FIELD = 'answer';

export const FAQModuleDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    ${LocaleString('localeTitle')},
    faqs[]-> {
      _id,
      ${LocaleString('question')},
      ${LocalePortableText(FAQ_ITEM_PORTABLE_TEXT_FIELD)},
      tags[] -> {
        value,
        ${LocaleString('label')},
      }
    }
  
  }
`;
