import { Button } from '@global-ecom/nitro-uds/elements';
import { useRef, useCallback, useEffect } from 'react';

import { useMounted } from 'hooks/useMounted';
import { signUpFormAnalyticsEvent } from 'utils/analytics';
import {
  SignUpLocationsKey,
  SignUpActions,
  SignUpFormAnalyticsIdentifiers,
} from 'types/analyticTypes';

type NewsletterSignUpButtonProps = {
  analyticsIdentifier?: SignUpFormAnalyticsIdentifiers;
  label: string;
  location?: SignUpLocationsKey;
  onClick?: () => void;
  onDismiss?: () => void;
};

export const NewsletterSignUpButton = ({
  analyticsIdentifier,
  label,
  location,
  onClick,
  onDismiss,
}: NewsletterSignUpButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted()) {
      signUpFormAnalyticsEvent({
        identifier: analyticsIdentifier,
        action: SignUpActions.Impression,
        location,
      });
    }
  }, [analyticsIdentifier, location, isMounted]);

  const handleClick = useCallback(e => {
    const clickTarget = e.target;

    if (buttonRef.current) {
      const buttonSvg = buttonRef.current.querySelector('svg') as SVGSVGElement;
      const clickTargetClosestSvg = clickTarget.closest('svg') as SVGSVGElement;

      // we don't currently have a way to handle clicking on the icon vs. the
      // rest of the button, so we can determine that the icon was clicked
      // if the reference icon and click target icon have the same parent
      const wasCloseIconClick =
        buttonSvg &&
        clickTargetClosestSvg &&
        buttonSvg.parentElement === clickTargetClosestSvg.parentElement;

      if (wasCloseIconClick) {
        onDismiss?.();
      } else {
        onClick?.();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div
      className={`relative signup-button-appear -left-full bg-clip-border inline-block lg:mb-0 md:mb-0 transition-all duration-[1500ms]`}
    >
      <div className="signup-button-outer">
        <div className="signup-button-inner">
          <Button
            ref={buttonRef}
            variant="primary"
            className="pointer-events-all"
            data-test-id="newsletter-sign-up-form-button"
            label={label}
            icon="close"
            iconEnd
            invert
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};
