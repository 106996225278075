//https://baymard.com/checkout-usability/credit-card-patterns
// http://support.worldpay.com/support/kb/bg/testandgolive/tgl5103.html

import type { TCardType } from './creditCardValidator';

const getCardFormat = (value: string, type: TCardType) => {
  // if we don't have card formatting, return a default 4-4-4-4-4
  if (typeof type === 'string') return [4, 4, 4, 4, 4];

  // if the format is a single array, return it as is
  if (Array.isArray(type.format)) return type.format;

  // else there are multiple formats for this card type
  // get the length of our current input
  // and use the valid_length array, to find
  // the first length that's longer than our string and
  // return the format for that string length
  const nextPossibleLength =
    type.valid_length.find(v => v >= value.length) ||
    type.valid_length[type.valid_length.length - 1];

  return type.format[nextPossibleLength];
};

export function formatExpirationDate(dateTrimmed: string): string {
  let lastIndex = 0;
  const shards = [2, 2].reduce<string[]>((res, cur) => {
    // short circuit
    if (dateTrimmed.length < lastIndex) return res;
    // create slice and add to shards
    const slice = dateTrimmed.slice(lastIndex, lastIndex + cur);
    res.push(slice);
    // update controls
    lastIndex += cur;
    return res;
  }, []);

  return shards.join('/').trim();
}
export function formatCreditCard(value: string, type: TCardType): string {
  const card: string = value.replace(/\D+/g, '');

  // get format for this card type
  const format = getCardFormat(card, type);

  // use the format array to create slices of the card input
  let lastIndex = 0;
  let shardsCombinedLength = 0;
  const shards = format.reduce((res, cur) => {
    // short cirquit
    if (card.length < lastIndex) return res;

    // create slice and add to shards
    const slice = card.slice(lastIndex, lastIndex + cur);
    res.push(slice);

    // update controls
    lastIndex += cur;
    shardsCombinedLength += slice.length;

    return res;
  }, [] as string[]);

  // if the input is loner than the expected format,
  // add the remaining to an extra shard
  if (card.length > shardsCombinedLength)
    shards.push(card.slice(shardsCombinedLength));

  // join with spaces and trim the whitespace
  return shards
    .join(' ')
    .replace(/ +(?= )/g, '')
    .trim();
}
