import { parseCookies, setCookie } from 'nookies';

import { MaxAgeExpiration } from './constants';

const OPTANON_CONSENT_KEY = 'OptanonConsent';
const OPTANON_ALERT_BOX_CLOSED = 'OptanonAlertBoxClosed';

export const getHasSeenCookieBannerOT = (): boolean =>
  !!parseCookies()[OPTANON_ALERT_BOX_CLOSED];

export const getHasSeenCookieBanner = (): boolean =>
  !!parseCookies()[OPTANON_CONSENT_KEY];

export const parseConsentCookie = (
  defaultValue = {
    userExperience: true,
    marketing: true,
  }
) => {
  const cookie = parseCookies()[OPTANON_CONSENT_KEY];
  if (!cookie) return defaultValue;

  return cookie
    .split(',')
    .reduce(
      (acc: { userExperience: boolean; marketing: boolean }, curr: string) => {
        if (curr.startsWith('C0002')) {
          acc.userExperience = curr.endsWith('1');
        }
        if (curr.startsWith('C0004')) {
          acc.marketing = curr.endsWith('1');
        }
        if (curr.startsWith('C0005')) {
          acc.marketing = curr.endsWith('1');
        }
        return acc;
      },
      defaultValue
    );
};

export const setConsentCookie = (
  userExperience: boolean,
  marketing: boolean,
  maxAgeCookiesExpires?: MaxAgeExpiration
) => {
  setCookie(
    null,
    OPTANON_CONSENT_KEY,
    `groups=C0001:1,C0002:${userExperience ? 1 : 0},C0003:1,C0004:${
      marketing ? 1 : 0
    },C0005:${marketing ? 1 : 0}`,
    {
      path: '/',
      maxAge: maxAgeCookiesExpires ?? MaxAgeExpiration.DAILY,
    }
  );
};
