// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/video.ts

import {
  SanityVideoAssetDocument,
  VideoAssetDocumentType,
} from 'groq/documents/VideoAssetDocument';
import { Maybe } from 'utils/types';

export type SanityVideoObjectType = {
  source?: Maybe<VideoAssetDocumentType>;
  autoplay?: Maybe<boolean>;
  controls?: Maybe<boolean>;
  loop?: Maybe<boolean>;
  mute?: Maybe<boolean>;
};

export const SanityVideoObject = (field: string) => `
  ${field} {
    "source": ${SanityVideoAssetDocument('source.asset->')},
    autoplay,
    loop,
    mute,
  }
`;
