import { Maybe } from 'graphql/jsutils/Maybe';

import { inStates } from 'sites/states';

import {
  SUPPORTED_COUNTRY,
  UNITED_STATES_ARMED_FORCES,
  UNITED_STATES_TERRITORY,
} from './constants';

// Below method uses google Geocoding API to check for valid postalCode
// in addition to whether the postalCode is from the state selected
// https://developers.google.com/maps/documentation/geocoding/overview

export const matchStateAndPostalCode = async ({
  postalCode,
  googleApiKey,
  stateCode,
  country,
}: {
  postalCode: string;
  country: string;
  googleApiKey: string;
  stateCode: string | null;
}): Promise<{
  isValid: boolean;
  isMatch: boolean;
  geoStateCode: string | null;
}> => {
  country = country.toUpperCase();

  const isUs = country === SUPPORTED_COUNTRY.US;
  const isUsArmedForces =
    isUs &&
    stateCode &&
    Object.keys(UNITED_STATES_ARMED_FORCES).includes(stateCode);
  const isUsTerritory =
    isUs &&
    stateCode &&
    Object.keys(UNITED_STATES_TERRITORY).includes(stateCode);

  if (isUsArmedForces) {
    return {
      isValid: true,
      isMatch: true,
      geoStateCode: stateCode,
    };
  }

  if (isUsTerritory) {
    country = stateCode;
  }

  const geocodeResult = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode?.toUpperCase()}&components=country:${country}&key=${googleApiKey}`
  )
    .then(data => data.json())
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(
        `Error while fetching geocode result for postalCode: ${postalCode}`,
        error
      );
      return {
        isValid: false,
        isMatch: false,
        geoStateCode: null,
      };
    });

  if (geocodeResult.status === 'OK' && geocodeResult.results.length > 0) {
    // check if result is for the same country
    const filteredForCountry = geocodeResult.results[0].address_components.find(
      address => address.types.includes('country')
    );

    if (
      filteredForCountry?.short_name &&
      filteredForCountry?.short_name.toUpperCase() !== country
    ) {
      return {
        isValid: false,
        isMatch: false,
        geoStateCode: null,
      };
    }

    // check if result is for the same state
    const filteredForState = geocodeResult.results[0].address_components.find(
      address => {
        if (isUsTerritory) {
          return (
            geocodeResult.results[0].types.includes('postal_code') &&
            address.types.includes('country')
          );
        }
        return address.types.includes('administrative_area_level_1');
      }
    );

    const getCorrectedStateCode = (stateCode?: Maybe<string>) => {
      if (!stateCode) return;

      if (country === SUPPORTED_COUNTRY.IN) {
        return getIndiaISOStateCode(stateCode);
      }

      return stateCode;
    };

    if (filteredForState?.short_name === getCorrectedStateCode(stateCode)) {
      return {
        isValid: true,
        isMatch: true,
        geoStateCode: stateCode,
      };
    }

    return {
      isValid: true,
      isMatch: false,
      geoStateCode: filteredForState?.short_name,
    };
  } else {
    // postalCode not found
    return {
      isValid: false,
      isMatch: false,
      geoStateCode: null,
    };
  }
};

/** Salesforce uses non-standard state codes for India. This takes the
 * Salesforce state code and returns the `ISO 3166-2:IN` state code. */
const getIndiaISOStateCode = (stateCode: string) => {
  const mappedState = inStates.find(state => stateCode === state.value);

  return mappedState?.isoCompliantValue ?? stateCode;
};
