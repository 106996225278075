import { Maybe, ColorInput } from '__generated__/graphql';

enum DIRECTION {
  right,
  left,
  top,
  bottom,
}

export type ImageOverlayProps = {
  className?: string;
  direction?: Maybe<DIRECTION | string>;
  overlayColorInput?: Maybe<ColorInput>;
  overlayType?: Maybe<string>;
};

export const ImageOverlay = ({
  className,
  direction = DIRECTION.left,
  overlayColorInput,
  overlayType,
}: ImageOverlayProps) => {
  if (!overlayColorInput) return null;

  const { rgb } = overlayColorInput;
  const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;

  const backgroundImage =
    overlayType === 'Linear'
      ? `linear-gradient(to ${direction}, ${rgba}, transparent 125%)`
      : `linear-gradient(${rgba}, ${rgba})`;

  return (
    <div
      data-test-id="image-overlay"
      className={className}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage,
      }}
    />
  );
};
