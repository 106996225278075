import { createElement } from 'react';

const tags = {
  '*': 'b',
  '~': 's',
  _: 'i',
} as const;

const replaceNewLines = (str: string) =>
  str.split('\\n').flatMap((value, index, array) => {
    if (index === array.length - 1) return [value];

    const uniqueKey = 'br' + Math.random().toString(36).slice(2);
    const newElement = createElement('br', { key: uniqueKey });
    return [value, newElement];
  });

export const markdown = (str: string) => {
  const tokenizer = /(__)(.*?)(__)|(\*\*)(.*?)(\*\*)|(~~)(.*?)(~~)/;
  const content: React.ReactChild[] = [];
  let token: RegExpExecArray | null;

  // There are no special tokens in the content
  if (!str.match(tokenizer)) return replaceNewLines(str);

  // Loop until there are no more markdown tokens
  while ((token = tokenizer.exec(str))) {
    if (token.index > 0) {
      // Add the plain text before the token
      content.push(...replaceNewLines(str.substring(0, token.index)));
    }

    const contentWithMarkdown = token[0];
    const htmlTag = tags[contentWithMarkdown.charAt(0) as keyof typeof tags];
    const strippedContent = replaceNewLines(contentWithMarkdown.slice(2, -2));

    const newElement = createElement(
      htmlTag,
      { key: strippedContent.toString() },
      strippedContent
    );
    content.push(newElement);

    str = str.substring(token.index + contentWithMarkdown.length);
  }

  // Append any remaining content
  if (str) content.push(...replaceNewLines(str));

  return content;
};
