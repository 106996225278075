import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  InputRadio,
  Alert,
  LiveRegion,
  Stack,
} from '@global-ecom/nitro-uds/elements';

import { CustomInputRadioGroup, RadioOption } from 'ui/forms/custom/types';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';

const CustomFormRadio = ({
  field,
  required = false,
}: {
  field: CustomInputRadioGroup;
  required?: boolean;
}) => {
  const { register } = useFormContext();
  const { getErrorMessageOrDefault, resolveInputError } = useFormField(field);
  const error = resolveInputError();

  return (
    <Stack gap="sm">
      {field.values.map((value: RadioOption) => (
        <InputRadio
          key={`${field.name}-${value.value}`}
          dataTestId={field.dataTestId}
          name={field.name}
          id={`${field.name}-${value.value}`}
          ref={register({
            required:
              required && getErrorMessageOrDefault(field.name, 'requiredField'),
          })}
          className="p-1"
          value={value.value}
          aria-describedby={`${field.name}_error`}
        >
          {value.label}
        </InputRadio>
      ))}
      <LiveRegion type="polite" id={`${field.name}_error`}>
        {error && <Alert variant="error" content={error} />}
      </LiveRegion>
    </Stack>
  );
};

export default React.memo(CustomFormRadio);
