import React from 'react';
import router from 'next/router';
import { UseMutationResponse } from 'urql';

import {
  AuthPayloadFragment,
  LoginInput,
  LoginWithEmailAndPasswordMutation,
  LoginWithEmailAndPasswordMutationVariables,
  LoginWithOtpMutation,
  LoginWithOtpMutationVariables,
  LoginWithPhoneInput,
  LoginWithPhoneMutation,
  LoginWithPhoneMutationVariables,
  OtpVerificationInput,
  RegisterMutation,
  RegisterMutationVariables,
  ResendOtpMutation,
  ResendOtpMutationVariables,
  useLoginWithEmailAndPasswordMutation,
  useLoginWithOtpMutation,
  useLoginWithPhoneMutation,
  useRegisterMutation,
  useResendOtpMutation,
  useVerifyLoginWithOtpMutation,
  VerifyLoginWithOtpMutation,
  VerifyLoginWithOtpMutationVariables,
} from '__generated__/graphql';
import { storeAuthData } from 'gql/exchanges/auth/cookie';
import { AnalyticsEvents, event, getPageType } from 'utils/analytics';
import { authEventEmitter } from 'utils/authEventEmitter';
import { usePageEventsContext } from 'hooks/usePageEventsContext';

import { useAuth } from './useAuth';

export type UseLoginAndRegisterPayload = {
  register: UseMutationResponse<RegisterMutation, RegisterMutationVariables>;
  loginWithEmailAndPassword: UseMutationResponse<
    LoginWithEmailAndPasswordMutation,
    LoginWithEmailAndPasswordMutationVariables
  >;
  loginWithPhoneAndPassword: UseMutationResponse<
    LoginWithPhoneMutation,
    LoginWithPhoneMutationVariables
  >;
  verifyLoginWithOTP: UseMutationResponse<
    VerifyLoginWithOtpMutation,
    VerifyLoginWithOtpMutationVariables
  >;
  loginWithOTP: UseMutationResponse<
    LoginWithOtpMutation,
    LoginWithOtpMutationVariables
  >;
  resendOTP: UseMutationResponse<ResendOtpMutation, ResendOtpMutationVariables>;
};

type SideEffectParams = {
  payload: AuthPayloadFragment;
  email?: string;
};

export const useLoginAndRegister = (): UseLoginAndRegisterPayload => {
  const auth = useAuth();
  const [registerResult, registerHandler] = useRegisterMutation();
  const [loginWithPhoneAndPasswordResult, loginWithPhoneAndPasswordHandler] =
    useLoginWithPhoneMutation();
  const [loginWithEmailAndPasswordResult, loginWithEmailAndPasswordHandler] =
    useLoginWithEmailAndPasswordMutation();
  const [verifyLoginWithOTPResult, verifyLoginWithOTPHandler] =
    useVerifyLoginWithOtpMutation();
  const loginWithOTP = useLoginWithOtpMutation();
  const resendOTP = useResendOtpMutation();
  const { pageviewEventHasFired } = usePageEventsContext();

  const handleAuthSideEffects = React.useCallback(
    ({ payload, email }: SideEffectParams): void => {
      storeAuthData({ payload, country: router.query.country as string });
      authEventEmitter.emit('token-changed');
      event(AnalyticsEvents.ACCOUNT, {
        domevent: 'account login',
        domlabel: email,
      });
    },
    []
  );

  const registerCallback = React.useCallback(
    async args => {
      const operationResult = await registerHandler(args);

      if (operationResult.data?.register) {
        const email = args?.email;
        handleAuthSideEffects({
          payload: operationResult.data.register,
          email,
        });
        event(AnalyticsEvents.ACCOUNT, {
          domevent: 'account created',
          domlabel: email,
        });
      } else {
        if (pageviewEventHasFired)
          event(AnalyticsEvents.GA4_CustomEvent, {
            event_name: AnalyticsEvents.SIGN_UP_FORM_ERROR,
            event_params: {
              method: getPageType(router.asPath),
            },
          });
      }

      return operationResult;
    },
    [registerHandler, handleAuthSideEffects, pageviewEventHasFired]
  );

  const loginWithPhoneAndPasswordCallback = React.useCallback(
    async args => {
      const operationResult = await loginWithPhoneAndPasswordHandler({
        ...(args as LoginWithPhoneInput),
        uniqueShopperId: auth.uniqueShopperId,
      });

      if (operationResult.data?.loginWithPhone) {
        handleAuthSideEffects({
          payload: operationResult.data.loginWithPhone,
        });
      }

      return operationResult;
    },
    [loginWithPhoneAndPasswordHandler, handleAuthSideEffects, auth]
  );

  const loginWithEmailAndPasswordCallback = React.useCallback(
    async args => {
      const operationResult = await loginWithEmailAndPasswordHandler({
        ...(args as LoginInput),
        uniqueShopperId: auth.uniqueShopperId,
      });

      if (operationResult.data?.login) {
        handleAuthSideEffects({
          payload: operationResult.data.login,
          email: args?.email,
        });
      }

      return operationResult;
    },
    [loginWithEmailAndPasswordHandler, handleAuthSideEffects, auth]
  );

  const verifyLoginWithOTPCallback = React.useCallback(
    async args => {
      const operationResult = await verifyLoginWithOTPHandler({
        ...(args as OtpVerificationInput),
        uniqueShopperId: auth.uniqueShopperId,
      });

      if (operationResult.data?.verifyLoginWithOTP.authPayload) {
        handleAuthSideEffects({
          payload: operationResult.data.verifyLoginWithOTP.authPayload,
        });
      }

      window.localStorage.setItem(
        'otpToken',
        operationResult.data?.verifyLoginWithOTP.otpVerifyToken ?? ''
      );
      return operationResult;
    },
    [verifyLoginWithOTPHandler, handleAuthSideEffects, auth]
  );

  const loginAndRegisterPayload =
    React.useMemo<UseLoginAndRegisterPayload>(() => {
      return {
        register: [registerResult, registerCallback],
        loginWithEmailAndPassword: [
          loginWithEmailAndPasswordResult,
          loginWithEmailAndPasswordCallback,
        ],
        loginWithPhoneAndPassword: [
          loginWithPhoneAndPasswordResult,
          loginWithPhoneAndPasswordCallback,
        ],
        verifyLoginWithOTP: [
          verifyLoginWithOTPResult,
          verifyLoginWithOTPCallback,
        ],
        loginWithOTP,
        resendOTP,
      };
    }, [
      registerResult,
      registerCallback,
      loginWithEmailAndPasswordCallback,
      loginWithEmailAndPasswordResult,
      loginWithPhoneAndPasswordCallback,
      loginWithPhoneAndPasswordResult,
      verifyLoginWithOTPResult,
      verifyLoginWithOTPCallback,
      loginWithOTP,
      resendOTP,
    ]);

  return loginAndRegisterPayload;
};
