export const splitName = (
  fullName: string
): { firstName: string; lastName: string } => {
  const [firstName, ...otherNames] = fullName.split(' ');

  return {
    firstName: otherNames.length ? firstName : '',
    lastName: otherNames.length ? otherNames.join(' ') : fullName,
  };
};
