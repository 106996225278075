import { DateTime } from 'groq-js';

import { LocaleString } from 'groq/shared/LocaleString';
import { LocaleSeo, LocaleSeoType } from 'groq/shared/LocaleSeo';
import { QueryByLocaleUrl } from 'groq/shared/LocaleUrl';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/category.tsx

export type CategoryDocumentType = {
  _id: string;
  _type?: 'category';
  country: string;
  id: string;
  url: string;
  urls: string;
  name: string;
  title: string;
  description: string;
  image?: Maybe<SanityImageObjectType>;
  visible: boolean;
  online: boolean;
  onlineFrom: DateTime;
  onlineTo: DateTime;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  hasItemsInStock: number;
  showEvenIfEmpty: boolean;
  sizeChartId: string;
  seo: LocaleSeoType;
};

export const CategoryByUrl = `
  [country == $country]
  [_type == "category"]
  [${QueryByLocaleUrl()}]
`;

export const CategoryDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    country,
    id,
    url,
    ${LocaleString('urls')},
    ${LocaleString('name')},
    ${LocaleString('title')},
    ${LocaleString('description')},
    ${SanityImageObject('image')},
    visible,
    online,
    onlineFrom,
    onlineTo,
    hasItemsInStock,
    showEvenIfEmpty,
    sizeChartId,
    ${LocaleSeo('seo')},
  }
`;
