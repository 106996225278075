import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import { RegisterInput } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { useEmailNotification } from 'hooks/useEmailNotification';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { Link } from 'ui/elements/Link';
import { AnalyticsEvents, event, getPageType } from 'utils/analytics';
import { usePageEventsContext } from 'hooks/usePageEventsContext';

import { CustomForm } from './custom/CustomForm';

export const QuickRegisterForm = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const t = useTranslate();
  const {
    forms: { quickRegistration: quickRegistrationForm },
  } = useSiteConfig();
  const { createSignupContact } = useEmailNotification();
  const { pageviewEventHasFired } = usePageEventsContext();

  const {
    register: [registerResult, register],
  } = useLoginAndRegister();

  const router = useRouter();
  const { inflowCode } = router.query;

  const form = useForm({
    defaultValues: { emailList: true },
    mode: 'onChange',
  });

  const hasSubmitted = React.useRef(false);

  // we only want to run this when unmounth the component without submit.
  React.useEffect(() => {
    return () => {
      if (!hasSubmitted.current && pageviewEventHasFired) {
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.SIGN_UP_FORM_CLOSE,
          event_params: {
            method: getPageType(router.asPath),
          },
        });
      }
    };
  }, [pageviewEventHasFired, router.asPath]);

  const onSubmit = async (
    data: RegisterInput & {
      emailList: boolean;
    }
  ) => {
    hasSubmitted.current = true;

    let registerValues = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    };

    if (inflowCode) {
      const inflowProperty = {
        inflowCode,
      };
      registerValues = { ...registerValues, ...inflowProperty };
    }

    const result = await register(registerValues);

    if (!result?.data?.register) return;

    if (data.emailList) {
      await createSignupContact({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        firstNamePronunciation: data.firstNamePronunciation ?? null,
        lastNamePronunciation: data.lastNamePronunciation ?? null,
        dob: null,
        smsOptIn: null,
        phoneNumber: null,
      });
    }

    if (onSuccess) onSuccess();
  };

  return (
    <div className="flex flex-col">
      {quickRegistrationForm && (
        <CustomForm
          form={form}
          onSubmit={onSubmit}
          config={quickRegistrationForm}
          result={registerResult}
          dataTestId="quick-registration-form"
        />
      )}
      {
        <p className="text-xs mt-4">
          {t('byContinuing')}{' '}
          <Link
            className="underline text-xs"
            href="/help/terms-and-conditions"
            rel="noopener"
          >
            {t('termsAndConditions')}
          </Link>{' '}
          {t('andThe')}{' '}
          <Link
            className="underline text-xs"
            href="/help/privacy-policy"
            rel="noopener"
          >
            {t('privacyPolicy')}
          </Link>
          .
        </p>
      }
    </div>
  );
};
