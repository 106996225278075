import { Maybe } from 'utils/types';
import { ProductObjectType } from 'groq/global-types';

export type AutomatedProductComparisonDocumentType = {
  _id: string;
  _type: string;
  header?: Maybe<string>;
  comparisonFields: string[];
  products: ProductObjectType[];
};

export const AutomatedProductComparisonDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    header,
    comparisonFields,
    products,
  }
`;
