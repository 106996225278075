import { Maybe } from 'graphql/jsutils/Maybe';

import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';

import { LocaleString } from './LocaleString';
import { MainNavItemLink, MainNavItemLinkType } from './MainNavItemLink';

export type MainNavItemType = {
  id: string;
  _type: string;
  label: string;
  image?: Maybe<SanityImageObjectType>;
  link: MainNavItemLinkType;
  navColor?: Maybe<string>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  children?: Maybe<Array<MainNavItemType>>;
  navChildren?: Maybe<Array<MainNavItemType>>;
  childrenCount: number;
};

export const MainNavItem = (field: string, depth = 0) => `
  ${field} {
    "id": _key,
    _type,
    ${LocaleString('label')},
    ${SanityImageObject('image')},
    ${MainNavItemLink('link')},
    navColor,
    availableFrom,
    availableTo,
    "childrenCount": count(children),
    ${depth > 0 ? MainNavItem('children[]', depth - 1) : ''}
  }
`;
