import { CSSProperties } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { Size } from '__generated__/graphql';

import { SizeOption } from './SizeOption';

type SizeOptionsProps = {
  sizes: Size[];
  layoutStyle: CSSProperties;
  desiredSize?: string;
  showOnlyOrderableSizes: boolean;
  onChange?: (selectedSize: string) => void;
};

export const SizeOptions = ({
  sizes,
  layoutStyle,
  desiredSize,
  showOnlyOrderableSizes,
  onChange,
}: SizeOptionsProps) => {
  const t = useTranslate();

  const visibleSizes = showOnlyOrderableSizes
    ? sizes.filter(size => size.orderable)
    : sizes;

  // sort sizes by ID to determine display order;
  // OCAPI returns the array in non-sized order in some cases
  const sortedSizes = visibleSizes.sort(
    (a, b) => parseInt(a.id) - parseInt(b.id)
  );

  return (
    <div
      id="size-picker"
      data-test-id="size-picker"
      role="radiogroup"
      aria-label={t('selectSize')}
      className="grid gap-1"
      style={layoutStyle}
    >
      {sortedSizes.map(size => (
        <SizeOption
          key={size.id}
          size={size}
          selected={size.id === desiredSize && size.orderable}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
