import { Maybe } from 'utils/types';

import {
  ProductRecommenderDocument,
  ProductRecommenderDocumentType,
} from './ProductRecommenderDocument';
import {
  TrendingTileDocument,
  TrendingTileDocumentType,
} from './TrendingTileDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/SideBySide.ts

export type SideBySideDocumentType = {
  id: string;
  _id: string;
  _type: string;
  requiredTiles: Maybe<
    Array<TrendingTileDocumentType | ProductRecommenderDocumentType>
  >;
};

export const SideBySideDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    "requiredTiles": componentTiles[] -> {
      ${TrendingTileDocument('_type == "TrendingTile" =>')},
      ${ProductRecommenderDocument('_type == "ProductRecommender" =>')},
    }
  }
`;
