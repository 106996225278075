import { gql } from 'urql';

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    )
  }
`;

export const UPDATE_PHONE_MUTATION = gql`
  mutation UpdatePhone($input: UpdatePhoneInput!) {
    updatePhone(input: $input) {
      code
      message
      pinId
    }
  }
`;

export const CONFIRM_UPDATE_PHONE = gql`
  mutation ConfirmUpdatePhone($input: ConfirmUpdatePhoneInput!) {
    confirmUpdatePhone(input: $input) {
      code
      message
      user {
        id
        phoneNumber
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const COMPLETE_RESET_PASSWORD_MUTATION = gql`
  mutation CompleteResetPassword($password: String!, $token: String!) {
    completeResetPassword(password: $password, token: $token)
  }
`;

export const CREATE_SHOPPER_CONTEXT_MUTATION = gql`
  mutation CreateShopperContext($input: CreateShopperContextInput!) {
    createShopperContext(input: $input) {
      sourceCode
    }
  }
`;

export const VERIFY_USER_AFFILIATIONS = gql`
  mutation VerifyUserAffiliations($input: VerifyUserAffiliationsInput!) {
    verifyUserAffiliations(input: $input) {
      verified
      affiliations
    }
  }
`;
