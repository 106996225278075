import { Maybe } from 'utils/types';
import { Puid } from 'groq/global-types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/ProductRecommender.ts

export type ProductRecommenderDocumentType = {
  _id: string;
  _type: string;
  puid?: Maybe<Puid>;
  header?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  cta?: Maybe<CallToActionObjectType>;
  type: string;
  showAsGrid?: Maybe<boolean>;
};

export const ProductRecommenderDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    "__typename": _type,
    puid,
    ${LocaleString('header')},
    ${LocaleString('copy')},
    type,
    showAsGrid,
    ${CallToActionObject('cta[]')},
  }
`;
