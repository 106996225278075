import { LocaleReferenceUrl } from 'groq/shared/LocaleUrl';
import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { CallToActionObjectType } from 'groq/objects/CallToActionObject';
import { LaunchCalendar } from '__generated__/graphql-sanity';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/LaunchCalendar.ts

export const CalendarContentField = (field: string) => `
  ${field} {
    _key,
    _id,
    _type,
    image {
      ${SanityImageObject('portrait')},
      ${SanityImageObject('square')},
      ${SanityImageObject('landscape')},
    },
    icon {
      _type == "udsIcon" => {
        ${SanityImageObject('img')},
        ${LocaleString('alt')}
      }
    },
    text {
      ${LocaleString('header')},
      ${LocaleString('copy')},
      ${LocaleString('footnote')},
      align,
      color,
    },
    link {
      ${LocaleReferenceUrl('href')},
      query,
      newtab,
    },
    ctas[] {
      "id": _key,
      _key,
      _type,
      ${LocaleString('title')},
      link { ${LocaleReferenceUrl('href')} , query, newtab },
      variant,
      width
    },
    alignment {
      vertical,
      horizontal,
    },
    style {
      background,
      border,
      rounded,
      shadow,
    },
    aspectRatio
  }
`;

export type LaunchCalendarType = Omit<LaunchCalendar, 'calendarContent'> & {
  calendarContent: Maybe<Array<Maybe<CalendarContentType>>>;
};

export type CalendarContentType = {
  _key: string;
  _id: string;
  _type: string;
  image: {
    portrait?: Maybe<SanityImageObjectType>;
    square?: Maybe<SanityImageObjectType>;
    landscape?: Maybe<SanityImageObjectType>;
  };
  icon: {
    alt?: Maybe<LocaleStringType>;
    img?: Maybe<SanityImageObjectType>;
  };
  text: {
    header?: Maybe<LocaleStringType>;
    copy?: Maybe<LocaleStringType>;
    footnote?: Maybe<LocaleStringType>;
    align?: string;
    color?: string;
  };
  link: {
    href: string;
    query?: Maybe<string>;
    newtab?: Maybe<boolean>;
  };
  ctas: Maybe<CallToActionObjectType[]>;
  alignment: {
    vertical?: string;
    horizontal?: string;
  };
  style: {
    background?: string;
    rounded: boolean;
    border: boolean;
    shadow: boolean;
  };
  aspectRatio: Maybe<string>;
};

export const CardsAndTilesDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    calendarContent[] {
      ${CalendarContentField('_type == "Tile" =>')},
      ${CalendarContentField('_type == "Card" =>')}
    }
  }
`;
