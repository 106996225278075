import React from 'react';
import Script from 'next/script';

import { useSiteConfig } from '../useSiteConfig';

const LiveChatToggleWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="livechat-online" style={{ display: 'none' }}>
    {children}
  </div>
);

export const useRadialLiveChat = () => {
  const { liveChat, localizeUrlPath } = useSiteConfig();

  const liveChatScript = liveChat?.script ?? '';
  const liveChatCode = liveChat?.code ?? '';
  const liveChatEnabled = !!liveChatScript && !!liveChatCode;

  const LiveChatScript = ({ defer }: { defer?: boolean }) => {
    if (!liveChatEnabled) return null;

    return (
      <>
        <Script
          type="text/javascript"
          src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js"
        />
        <Script type="text/javascript" src="/assets/chat.js" />
        <Script type="text/javascript" src={liveChatScript} />
        <Script
          type="text/javascript"
          src="/assets/live-chat.js"
          defer={defer}
        />
      </>
    );
  };

  const openLiveChatWindow = () => {
    // calculate screen position in order to open live chat window in center of browser
    const browserW = window.outerWidth;
    const browserH = window.outerHeight;
    const browserX = window.screenLeft;
    const browserY = window.screenTop;
    const chatW = 500;
    const chatH = 700;
    const chatX = browserX + browserW / 2 - chatW / 2;
    const chatY = browserY + browserH / 2 - chatH / 2;

    window.open(
      localizeUrlPath('/live-chat'),
      'chat-window',
      `menubar=0,resizable=1,width=${chatW},height=${chatH},left=${chatX},top=${chatY}`
    );
  };

  return {
    liveChatCode,
    liveChatEnabled,
    LiveChatScript,
    LiveChatToggleWrapper,
    openLiveChatWindow,
    renderInMainNav: !!liveChat?.renderInMainNav,
  };
};
