declare global {
  interface Window {
    ScarabQueue: ScarabQueueItem[];
  }
}

export type ScarabCartItem = {
  item: string;
  quantity: number;
  price: number;
};
type PurchaseOrder = { orderId: string; items: ScarabCartItem[] };
type PayloadOf<T> = T extends [string, infer U] ? U : never;

type CartCommand = ['cart', ScarabCartItem[]];
type CategoryCommand = ['category', string];
type EmailCommand = ['setEmail', string];
type GoCommand = ['go'];
type PurchaseCommand = ['purchase', PurchaseOrder];
type TagCommand = ['tag', string, object];
type TestModeCommand = ['testMode'];
type ViewCommand = ['view', string];

type ScarabQueueTrackItem = CategoryCommand | PurchaseCommand | ViewCommand;

type ScarabQueueItem =
  | CartCommand
  | EmailCommand
  | GoCommand
  | TagCommand
  | TestModeCommand
  | ScarabQueueTrackItem;

/**
 * EmarsysScarab is a class that provides a stateful wrapper around the ScarabQueue
 * API from the scarab-v2.js script.
 *
 * Methods should be idempotent to avoid duplicate tracking calls to Emarsys.
 *
 * For more information, see the following resources:
 * Jira Ticket: https://pumaglobal.atlassian.net/browse/PNA-5360
 * Emarsys Web Extend documentation: https://dev.emarsys.com/docs/web-extend/321befdc63bc6-web-extend-api-introduction
 *
 */
export class EmarsysScarab {
  private isSubmitted = false;
  private cart: PayloadOf<CartCommand> | null = null;
  private user: PayloadOf<EmailCommand> | null = null;
  private tracking: ScarabQueueTrackItem | 'none' | null = null;
  private queue: ScarabQueueItem[] = [];

  constructor(
    public config: { testMode: boolean; disabled: boolean } = {
      testMode: false,
      disabled: false,
    }
  ) {}

  /**
   * Submits the current tracking commands if all required data is present.
   * @returns This instance of EmarsysScarab
   */
  safeSubmit({ allowResubmit = false } = {}) {
    if (this.isSubmitted && !allowResubmit) {
      return this;
    }

    if (this.config.disabled) {
      return this;
    }

    if (!this.cart || !this.user || !this.tracking) {
      return this;
    }

    if (this.config.testMode) {
      this.queue.push(['testMode']);
    }

    return this.go();
  }

  /**
   * Tracks a Product Detail Page view.
   * @param productId - The ID of the product being viewed
   * @returns This instance of EmarsysScarab
   */
  trackPdp(productId: string) {
    if (this.isSubmitted || this.tracking) {
      return this;
    }

    this.setView(productId);
    return this;
  }

  /**
   * Tracks a category (PLP) view.
   * @param categoryId - An array of hierarchical category IDs
   * @returns This instance of EmarsysScarab
   */
  trackCategory(categoryId: string[]) {
    if (this.isSubmitted || this.tracking) {
      return this;
    }

    this.setCategory(categoryId);
    return this;
  }

  /**
   * Tracks a completed store purchase.
   * @param orderId - The ID of the order being tracked
   * @param items - An array of items representing the products in the order
   * @returns This instance of EmarsysScarab
   */
  trackPurchase(orderId: string, items: ScarabCartItem[]) {
    if (this.isSubmitted || this.tracking) {
      return this;
    }

    this.setPurchase({ orderId, items });
    return this;
  }

  /**
   * Skips adding any specific tracking command to the queue.
   * @returns This instance of EmarsysScarab
   */
  skipTracking() {
    this.tracking = 'none';
    return this;
  }

  /**
   * Clears all queued tracking data and resets tracking status flags.
   * @returns This instance of EmarsysScarab
   */
  clear() {
    this.queue = [];
    this.isSubmitted = false;
    this.tracking = null;
    this.cart = null;
    this.user = null;
    return this;
  }

  /**
   * Sets the user ID for tracking.
   * @param id - The ID of the user to track
   * @returns This instance of EmarsysScarab
   */
  setUser(id: string, { update = false } = {}) {
    if (this.user && !update) {
      return this;
    }

    this.user = id;
    return this;
  }

  /**
   * Sets the cart items for tracking.
   * @param items - An array of items representing the products in the cart
   * @returns This instance of EmarsysScarab
   */
  setCart(items: ScarabCartItem[], { overwrite = false } = {}) {
    if (this.cart && !overwrite) {
      return this;
    }

    this.cart = items;
    return this;
  }

  private setPurchase(order: PurchaseOrder) {
    this.tracking = ['purchase', order];
    return this;
  }

  private setView(productId: string) {
    this.tracking = ['view', productId];
    return this;
  }

  private setCategory(category: string[]) {
    this.tracking = ['category', category.join(' > ')];
    return this;
  }

  private go() {
    this.isSubmitted = true;
    this.flushToWindow();
    return this;
  }

  private flushToWindow() {
    const dataFlush: ScarabQueueItem[] = [];

    this.queue.push(['cart', this.cart!]);

    if (this.user !== 'none') {
      this.queue.push(['setEmail', this.user!]);
    }

    if (this.tracking !== 'none') {
      this.queue.push(this.tracking!);
    }

    this.queue.push(['go']);

    while (this.queue.length) {
      const item = this.queue.shift();

      if (!item) {
        break;
      }

      dataFlush.push(item);

      window.ScarabQueue.push(item);
    }
  }
}
