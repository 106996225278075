import { useQuery, UseQueryState } from 'urql';

import { ORDER_QUERY } from 'gql/queries/order';
import { Order, QueryOrderArgs } from '__generated__/graphql';

import { useAuth } from './useAuth';

export type UseOrderArgs = QueryOrderArgs & {
  includeProductAnalyticsDetails: boolean;
};

export const useOrder = (
  orderNo?: string,
  includeProductAnalyticsDetails = false
): [UseQueryState<{ order: Order }, UseOrderArgs>] => {
  const auth = useAuth();

  const [order] = useQuery<{ order: Order }, UseOrderArgs>({
    query: ORDER_QUERY,
    variables: {
      orderNo: orderNo!,
      includeProductAnalyticsDetails,
    },
    pause: !(auth.customerId && orderNo),
  });

  return [order];
};
