import { Link } from '@global-ecom/nitro-uds/elements';
import { useEffect, useState } from 'react';
import { tw } from 'twind';

import { ProductSuggestion, useLookQuery } from '__generated__/graphql';
import { useStyliticsSanity } from 'hooks/useStyliticsSanity';
import CloseButton from 'ui/components/CloseButton';
import { Price } from 'ui/components/Price';
import { buildProductUrl } from 'utils/buildProductUrl';
import { setReferrerType } from 'utils/referrerParametersUtils';
import { REFERRER_TYPE_VAL } from 'utils/constants';
import { useSiteConfig } from 'hooks/useSiteConfig';

import StyliticsLoader from './StyliticsLoader';

type ProductDetailsProps = {
  product: ProductSuggestion;
};
const ProductDetails = ({ product }: ProductDetailsProps) => {
  const { name, price, salePrice, styleId } = product;
  const isOnSale = salePrice && salePrice !== price;
  const { localizeUrlPath } = useSiteConfig();
  const [productUrl, setProductUrl] = useState<string>();

  useEffect(() => {
    const productId = styleId.split('_')[0];
    const productColor = styleId.split('_')[1];
    const url = buildProductUrl(productId, name, {
      color: productColor,
    });

    setProductUrl(url);
  }, [name, styleId]);

  return (
    <div
      data-test-id="stylitics-outfit-flyout-details"
      className="stylitics-product-info"
    >
      <div className="stylitics-product-info-inner">
        <a
          onClick={() => setReferrerType(REFERRER_TYPE_VAL.Stylitics)}
          className="stylitics-product-name stylitics-product-name-wrap"
          href={productUrl && localizeUrlPath(productUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {product.name}
        </a>
      </div>
      <div className="stylitics-product-price-and-cta">
        <p className="stylitics-product-price space-x-2">
          <span
            className={tw('stylitics-product-regular-price', {
              'stylitics-product-regular-price-slashed': isOnSale,
            })}
          >
            <Price amount={price} />
          </span>
          <span>
            {isOnSale && (
              <span className="stylitics-product-sale-price">
                <Price amount={salePrice} />
              </span>
            )}
          </span>
        </p>
        <div>
          <Link
            onClick={() => setReferrerType(REFERRER_TYPE_VAL.Stylitics)}
            href={productUrl ?? ''}
            target="_blank"
            variant="underline"
            label="SHOP"
          />
        </div>
      </div>
    </div>
  );
};

type ProductContainerProps = {
  product: ProductSuggestion;
};
export const ProductContainer = ({ product }: ProductContainerProps) => (
  <li
    data-test-id="stylitics-outfit-flyout-product"
    className="stylitics-product-container"
  >
    <button type="button" className="stylitics-product-inner-container">
      <div className="stylitics-product-image-wrapper">
        <div
          className="stylitics-product-image"
          style={{
            backgroundImage: `url(${product.image.href})`,
          }}
          role="img"
          aria-label={product.image.alt}
          tabIndex={-1}
        />
      </div>
      <ProductDetails product={product} />
    </button>
  </li>
);

type FlyoutDesktopProps = {
  isLoading: boolean;
  products: ProductSuggestion[];
  onClose: () => void;
};
const FlyoutDesktop = (props: FlyoutDesktopProps) => (
  <div
    className="stylitics-outfit-container stylitics-outfit-details"
    data-test-id="stylitics-outfit-flyout-desktop"
  >
    <div className="stylitics-product-list-container">
      <StyliticsLoader loading={props.isLoading}>
        <ul className="stylitics-product-list">
          {props.products.map(product => (
            <ProductContainer key={product.id} product={product} />
          ))}
        </ul>
      </StyliticsLoader>
    </div>
    <CloseButton className="stylitics-close" onClick={props.onClose} />
  </div>
);

type FlyoutMobileProps = {
  isLoading: boolean;
  products: ProductSuggestion[];
  onClose: () => void;
};

const FlyoutMobile = (props: FlyoutMobileProps) => {
  useEffect(() => {
    // prevent the user from scrolling behind the "modal"
    document.body.classList.toggle('overflow-hidden', true);
  }, []);

  const handleClose = () => {
    document.body.classList.toggle('overflow-hidden', false);
    props.onClose();
  };
  return (
    <>
      <div
        data-test-id="stylitics-outfit-flyout-mobile"
        className="stylitics-outfit-details-modal"
        aria-modal="true"
      >
        <div className="stylitics-modal-container">
          <div className="stylitics-modal">
            <StyliticsLoader loading={props.isLoading}>
              <ul className="stylitics-modal-product-list">
                <li className="stylitics-modal-header">
                  <h1>Product List</h1>
                </li>
                {props.products.map(product => (
                  <ProductContainer key={product.id} product={product} />
                ))}
              </ul>
              <CloseButton className="stylitics-close" onClick={handleClose} />
            </StyliticsLoader>
          </div>
        </div>
      </div>
    </>
  );
};

export type StyliticsOutfitFlyoutProps = {
  isActive: boolean;
  outfitId: string;
  onClose: () => void;
};

const StyliticsOutfitFlyout = (props: StyliticsOutfitFlyoutProps) => {
  const [outfitProducts] = useLookQuery({
    variables: { id: props.outfitId },
    pause: !props.isActive,
  });

  const {
    outfitUi: { screenSize },
  } = useStyliticsSanity();
  const Flyout =
    screenSize === 'md' || screenSize === 'sm' ? FlyoutMobile : FlyoutDesktop;

  return (
    <>
      {props.isActive && (
        <Flyout
          isLoading={
            outfitProducts.stale ||
            outfitProducts.fetching ||
            !outfitProducts.data
          }
          products={
            (outfitProducts.data?.look?.products ?? []) as ProductSuggestion[]
          }
          onClose={props.onClose}
        />
      )}
    </>
  );
};

export default StyliticsOutfitFlyout;
