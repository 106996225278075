import { useEffect, useState } from 'react';
import { tw } from 'twind';

import {
  destroyStoredAgentAuthData,
  destroyStoredAuthData,
  getStoredAgentAuthData,
  getStoredAuthData,
} from 'gql/exchanges/auth/cookie';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { Button } from 'ui/elements/Button';
import { isServer } from 'utils/constants';
import {
  formatForterWebId,
  retrieveCustomerWebId,
  storeCustomerWebId,
  validateForterWebId,
} from 'utils/forter';

import { useSticky } from '../../hooks/useSticky';

export type NonImpersonationData = {
  impersonating: false;
};

export type AgentImpersonationData = {
  impersonating: true;
  agentDisplayName: string;
  customerEmail?: string;
  customerWebId?: string;
};

export type ImpersonationData = NonImpersonationData | AgentImpersonationData;

const nonImpersonation: NonImpersonationData = { impersonating: false };

export const getImpersonationData = (): ImpersonationData => {
  const agent = getStoredAgentAuthData();
  if (agent) {
    try {
      const { user } = getStoredAuthData();
      return {
        impersonating: true,
        agentDisplayName: agent.displayName,
        customerEmail: user?.email || undefined,
        customerWebId: retrieveCustomerWebId() ?? undefined,
      };
    } catch {
      return nonImpersonation;
    }
  }

  return nonImpersonation;
};

const ForterWebId = () => {
  const [webIdToDisplay, setWebIdToDisplay] = useState<string | null>(
    retrieveCustomerWebId
  );

  const requestCustomerWebId = () => {
    const value = prompt('Enter customer web ID');
    if (value === null) return;

    const valueWithoutSpaces = value.replace(/\s/g, '');
    const isValid = validateForterWebId(valueWithoutSpaces);
    if (!isValid) {
      alert('Invalid Web ID, please confirm the number and try again.');
    }

    const customerWebId = isValid ? valueWithoutSpaces : null;

    setWebIdToDisplay(customerWebId);
    storeCustomerWebId(customerWebId);
  };

  return (
    <>
      {webIdToDisplay && (
        <>
          <span className="font-bold">
            Customer Web ID: {formatForterWebId(webIdToDisplay)}
          </span>
        </>
      )}
      <Button variant="inline" onClick={requestCustomerWebId}>
        {webIdToDisplay ? (
          <>Change</>
        ) : (
          <span className="font-bold">Set customer Web ID</span>
        )}
      </Button>
      <span>|</span>
    </>
  );
};

export const ImpersonationBanner = () => {
  const { stickyRef, stickyCss } = useSticky(0);
  const [data, setData] = useState<ImpersonationData>(nonImpersonation);
  const { country, env } = useSiteConfig();

  useEffect(() => {
    if (!isServer) {
      const data = getImpersonationData();
      setData(data);
    }
  }, []);

  if (!data.impersonating) return null;

  const forterEnabled = !!env.forterSiteId;

  const handleCloseSession = () => {
    destroyStoredAuthData(country);
    destroyStoredAgentAuthData(country);
    storeCustomerWebId(null);
    window.close();
  };

  const Message = () =>
    data.customerEmail ? (
      <span>
        You are <span className={tw('font-bold')}>{data.agentDisplayName}</span>{' '}
        logged in on behalf of{' '}
        <span className={tw('font-bold')}>{data.customerEmail}</span>
      </span>
    ) : (
      <span>
        You are <span className={tw('font-bold')}>{data.agentDisplayName}</span>{' '}
        logged in on behalf of a{' '}
        <span className={tw('font-bold')}>guest customer</span>
      </span>
    );

  return (
    <div
      ref={stickyRef}
      className={tw('z-50', stickyCss)}
      data-test-id="impersonation-banner"
    >
      <aside
        className={tw(
          'flex justify-between items-center text-swatch-white bg-red-700 p-3 gap-3'
        )}
        data-test-id="impersonation-title"
      >
        <div className="flex-1">
          <Message />
        </div>
        {forterEnabled && <ForterWebId />}
        <Button variant="inline" onClick={handleCloseSession}>
          Close session
        </Button>
      </aside>
    </div>
  );
};
