import { useRouter } from 'next/router';
import { css, tw } from 'twind/style';
import { Button, Link } from '@global-ecom/nitro-uds/elements';
import { useMemo, useRef, useState } from 'react';
import Image from 'next/image';

import {
  ProductComparison as ProductComparisonType,
  ComparisonField,
  VariantProduct,
} from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { toKebabCase } from 'utils/toKebabCase';
import { buildProductUrl } from 'utils/buildProductUrl';
import { Link as ImageLink } from 'ui/elements/Link';
import { useItemListViewEvent } from 'hooks/useItemListViewEvent';
import { transformListProductToAnalyticsItem } from 'utils/analytics';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useGA4Events } from 'hooks/useGA4Events';
import { useProductClickHandling } from 'hooks/useProductClickHandling';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { usePdp } from 'hooks/usePdp';
import { setGa4ItemInMap } from 'utils/ga4Items';
import { setReferrerType } from 'utils/referrerParametersUtils';
import { REFERRER_TYPE_VAL } from 'utils/constants';
import { cloudinaryImageLoader } from 'utils/imageLoaders';

const tableStyles = css`
  td {
    @apply px-4 py-4 text-center;
  }
  th {
    @apply px-6 py-4 text-left uppercase border-white border-r-[1vw];
  }
  & > tbody > :nth-child(odd) {
    @apply bg-puma-black-800;
  }
  & > tbody > :nth-child(even) {
    @apply bg-white;
  }
  & > tbody > :last-of-type {
    @apply bg-white;
  }
`;

export type ProductComparisonProps = Omit<ProductComparisonType, 'products'> & {
  id: string;
  header: string;
  comparisonFields: ComparisonField[] | string[];
  products: any[];
  variant?: VariantProduct;
  _type?: string;
};

export const ProductComparison = (props: ProductComparisonProps) => {
  const { header } = props;
  const t = useTranslate();
  const pdp = usePdp();
  const {
    currency,
    regionCode,
    staticFeatures: {
      searchLocalImagesOnProductComparison,
      showHeaderSubHeaderProductDetail,
    },
  } = useSiteConfig();
  const router = useRouter();
  const productId = router.query.id as string | undefined;

  const [products] = useState(() => {
    if (!props.__typename) return props.products;
    return props.products
      .map(product => {
        return {
          ...product.product,
          comparisonFields: product.comparisonFields.reduce((acc, curr) => {
            acc[curr.name] = curr.value;
            return acc;
          }, {}),
        };
      })
      .sort(a => (a.masterId === productId ? -1 : 1));
  });

  const getImageURL = (product: any): string => {
    if (product?.images?.[0]) return product.images[0].href;

    let url = `https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/${
      product?.masterId
    }/${product?.color || product?.colorValue}`;

    if (!searchLocalImagesOnProductComparison) return `${url}/sv01`;

    if (!pdp || (pdp && pdp.productDivision === 'Footwear')) {
      url += '/sv01';
    }
    return `${url}/fnd/${regionCode}`;
  };

  const itemListId = props.id;
  const itemListName = props._type || '';

  const listItems = useMemo(() => {
    if (!props.products) return [];
    return props.products.map((product, index) => {
      return transformListProductToAnalyticsItem({
        product,
        currency: currency.code,
        quantity: 1,
        categories: {
          item_category: undefined,
        },
        itemListId,
        itemListName,
        index,
      });
    });
  }, [props.products, currency.code, itemListId, itemListName]);

  const productComparisonRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: props.id,
    name: header || '',
    creative: props._type || 'ProductComparison',
  };

  usePromotionView(productComparisonRef, gaTrackData, false, true);

  useItemListViewEvent({
    itemListId,
    itemListName,
    items: listItems.map(item => {
      return {
        ...item,
      };
    }),
  });

  // To differentiate in which ProductComparision the click occurred.
  const setIsProductClicked = useProductClickHandling();

  const { setProduct, fireClickProductImage } = useGA4Events();

  const handleButtonClick = () => {
    setReferrerType(REFERRER_TYPE_VAL.Comparison);
    document
      .querySelector('#pdp-product-title')
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLinkClick = (product: any, i: number) => {
    setReferrerType(REFERRER_TYPE_VAL.Comparison);
    const imageUrl = getImageURL(product);
    fireClickProductImage(imageUrl);
    setGa4ItemInMap(
      `${product.masterId}_${product?.colorValue ?? product?.color}`,
      {
        lid: itemListId,
        lname: itemListName,
        idx: i - 1,
      }
    );
    setProduct(product);
  };

  return (
    <section
      className={tw('relative w-full')}
      aria-label={header || undefined}
      ref={productComparisonRef}
    >
      <div className={tw('max-w-full overflow-x-auto')}>
        <table className={tw('table-fixed', tableStyles)}>
          <tbody>
            <tr onClick={() => setIsProductClicked(true)}>
              {[props.header, ...products].map((product, i) => {
                return i === 0 ? (
                  <th
                    key={header}
                    className="min-w-[220px] lg:min-w-[315px] lg:max-w-[360px]"
                  >
                    <h2 className="font-bold uppercase text-[1.75rem]">
                      {header}
                    </h2>
                  </th>
                ) : (
                  <td key={product.productId} className="bg-white!">
                    <ImageLink
                      key={product.productId}
                      href={buildProductUrl(
                        product.masterId,
                        product?.productName || product?.name,
                        {
                          color: product?.colorValue || product.color,
                        }
                      )}
                      onClick={() => handleLinkClick(product, i)}
                    >
                      <div className="p-4 border flex justify-center">
                        <Image
                          alt={product?.productName || product?.name}
                          src={getImageURL(product)}
                          loader={cloudinaryImageLoader}
                          height={600}
                          width={600}
                        />
                      </div>
                    </ImageLink>
                  </td>
                );
              })}
            </tr>
            {props.comparisonFields.map((field, idx) => {
              return (
                <tr key={field + idx}>
                  {[field, ...products].map((product, i) => {
                    return i === 0 ? (
                      <th key={field}>{field}</th>
                    ) : (
                      <td key={product.productId + field}>
                        {field === 'Name' &&
                        showHeaderSubHeaderProductDetail &&
                        product.header
                          ? product.header
                          : field === 'Category' &&
                            showHeaderSubHeaderProductDetail &&
                            product.subHeader
                          ? product.subHeader
                          : product.comparisonFields?.[
                              `field_${idx}_${product.masterId}`
                            ]
                          ? product.comparisonFields[
                              `field_${idx}_${product.masterId}`
                            ]
                          : product.comparisonFields?.[toKebabCase(field)]
                          ? product.comparisonFields[toKebabCase(field)]
                          : '-'}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {[props.header, ...products].map((product, i) => {
                return i === 0 ? (
                  <th key={header}></th>
                ) : (
                  <td key={product.productId}>
                    <div
                      className="flex justify-center w-[14rem] mx-auto"
                      onClick={() => setIsProductClicked(true)}
                    >
                      {product.masterId === productId ? (
                        <Button
                          className="w-full"
                          type="button"
                          variant="secondary"
                          onClick={() => handleButtonClick()}
                          label={t('productComparisonCtaCurrent')}
                        ></Button>
                      ) : (
                        <Link
                          className="w-full"
                          variant="secondary"
                          href={buildProductUrl(
                            product.masterId,
                            product?.productName || product?.name,
                            {
                              color: product?.colorValue || product.color,
                            }
                          )}
                          label={t('productComparisonCta')}
                          onClick={() => handleLinkClick(product, i)}
                        />
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};
