import { useEffect, useState } from 'react';

import { useGA4Events } from './useGA4Events';

// This hook is designed to trigger a GA4 event when a user clicks on a product
export const useProductClickHandling = (itemId?: string) => {
  const { fireSelectItemEvent, product } = useGA4Events();
  const [isProductClicked, setIsProductClicked] = useState(false);

  useEffect(() => {
    if (product && isProductClicked) {
      fireSelectItemEvent(itemId);
      setIsProductClicked(false);
    }
  }, [product, isProductClicked, itemId, fireSelectItemEvent]);

  return setIsProductClicked;
};
