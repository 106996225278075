import { Maybe } from '__generated__/graphql';

import {
  TrendingTileDocument,
  TrendingTileDocumentType,
} from './TrendingTileDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/TrendingInline.ts

export type TrendingInlineDocumentType = {
  _id: string;
  _type: string;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  requiredTiles: Array<TrendingTileDocumentType>;
};

export const TrendingInlineDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    availableFrom,
    availableTo,
    "requiredTiles": ${TrendingTileDocument(`tiles[]->`)}
  }
`;
