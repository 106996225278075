export const getIntlNumberFormatter = (
  locale: string,
  options: Intl.NumberFormatOptions
) => {
  try {
    return new Intl.NumberFormat(locale, options);
  } catch (e: any) {
    if ('constructor' in e && e.constructor === RangeError) {
      return new Intl.NumberFormat(locale, {
        ...options,
        // Overwrite currencyDisplay if it is not supported
        currencyDisplay: 'symbol',
      });
    } else {
      throw e;
    }
  }
};
