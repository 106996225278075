import React from 'react';
import { tw } from 'twind/css';
import { useRouter } from 'next/router';

import { Link } from 'ui/elements/Link';
import { isUrlSegment } from 'utils/urls';
import {
  categoryHeaderNavigationEvent,
  headerNavigationClickEvent,
} from 'utils/analytics';
import { MainNavItemType } from 'groq/shared/MainNavItem';

import { useSiteConfig } from '../../../hooks/useSiteConfig';

const DEFAULT_COLUMN_COUNT = 5;
const REM_FOR_COLUMN = 48;

type NavCategorySubCategoriesColumnsProps = {
  categories: MainNavItemType[];
};

export const NavCategorySubCategoriesColumns: React.FC<
  NavCategorySubCategoriesColumnsProps
> = ({ categories }) => {
  const { query } = useRouter();
  const { navCategoryMaxColumnCount } = useSiteConfig();
  const countColumn =
    typeof navCategoryMaxColumnCount === 'number' &&
    categories.length >= navCategoryMaxColumnCount
      ? navCategoryMaxColumnCount
      : DEFAULT_COLUMN_COUNT;

  return (
    <>
      {categories.map(category => (
        <ul
          key={category.id}
          className={`w-1/${countColumn} min-w-${
            REM_FOR_COLUMN / countColumn
          } flex-shrink-0 pr-5`}
          role="menu"
          aria-label={category.label}
        >
          <li className="border-b-2 mb-2 border-puma-black pb-2" role="none">
            <Link
              role="menuitem"
              data-link-name={category.label}
              data-link-location="top-nav-level-2-top"
              data-test-id="top-nav-level-2-top"
              href={category.link?.href ?? '/'}
              query={category.link?.query}
              className={tw(
                isUrlSegment(category.link, query?.category)
                  ? 'text-puma-gold'
                  : 'text-puma-black hover:text-puma-gold',
                `pb-0.5 text-lg font-bold`
              )}
              onClick={() => {
                categoryHeaderNavigationEvent(category);
                headerNavigationClickEvent(category);
              }}
            >
              {category.label}
            </Link>
          </li>

          {category.children &&
            category.children.map(subcategory => (
              <React.Fragment key={subcategory.id}>
                <li role="none">
                  <Link
                    role="menuitem"
                    data-link-name={subcategory.label}
                    data-link-location="top-nav-level-3"
                    data-test-id="top-nav-level-3"
                    href={subcategory.link?.href ?? '/'}
                    query={subcategory.link?.query}
                    className={tw(
                      'text-base py-1 leading-6',
                      isUrlSegment(subcategory.link, query?.category)
                        ? 'text-puma-black'
                        : 'text-puma-black-300 hover:text-puma-black',
                      category?.children?.find(
                        child => child.children && child.children.length > 0
                      ) && 'block font-bold'
                    )}
                    onClick={() => {
                      categoryHeaderNavigationEvent(subcategory);
                      headerNavigationClickEvent(subcategory);
                    }}
                  >
                    {subcategory.label}
                  </Link>
                  {subcategory.children && subcategory.children.length > 0 && (
                    <ul className="pt-1 pb-2">
                      {subcategory.children.map(subsubcategory => (
                        <li key={subsubcategory.id} role="none">
                          <Link
                            role="menuitem"
                            data-link-name={subsubcategory.label}
                            data-link-location="top-nav-level-4"
                            data-test-id="top-nav-level-4"
                            href={subsubcategory.link?.href ?? '/'}
                            query={subsubcategory.link?.query}
                            className={tw(
                              'text-base px-6 py-1 leading-6',
                              isUrlSegment(subsubcategory.link, query?.category)
                                ? 'text-puma-black'
                                : 'text-puma-black-300 hover:text-puma-black'
                            )}
                            onClick={() => {
                              categoryHeaderNavigationEvent(subsubcategory);
                              headerNavigationClickEvent(subsubcategory);
                            }}
                          >
                            {subsubcategory.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </React.Fragment>
            ))}
        </ul>
      ))}
    </>
  );
};
