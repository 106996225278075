import React from 'react';

import { Maybe } from '__generated__/graphql';
import { SanityVideoObjectType } from 'groq/objects/SanityVideoObject';
import { SanityImage } from 'ui/components/SanityImage';
import { SanityImageObjectType } from 'groq/objects/SanityImageObject';

import { SanityVideo } from './SanityVideo';

export interface VideoOrImageProps {
  header?: Maybe<string>;
  video?: Maybe<SanityVideoObjectType>;
  mobileImage?: Maybe<SanityImageObjectType>;
  image?: Maybe<SanityImageObjectType>;
}

export const VideoOrImage: React.FC<VideoOrImageProps> = props => {
  const { header, video, mobileImage, image } = props;

  if (video?.source) {
    return (
      <SanityVideo
        className="absolute inset-0 h-full w-full object-cover object-center"
        video={video}
      />
    );
  }

  if (image || mobileImage) {
    return (
      <SanityImage
        source={image}
        mobileSource={mobileImage}
        defaultAlt={header}
      />
    );
  }

  return null;
};
