/**
 * Ensures a value is neither null nor undefined.
 * This type guard facilitates safe usage in contexts that don't handle `null` or `undefined`.
 *
 * @param value The value to check against `null` and `undefined`.
 * @returns {boolean} Returns `true` if the value is not `null` or `undefined`, otherwise returns `false`.
 *
 * @example
 * isNotNullish(null);        // Expected output: false
 * isNotNullish(undefined);   // Expected output: false
 * isNotNullish(0);           // Expected output: true
 * isNotNullish('abc');       // Expected output: true
 */
export const isNotNullish = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;
