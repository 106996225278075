import { useEffect, useRef } from 'react';

import { AnalyticsEvents, event } from 'utils/analytics';

import { usePageEventsContext } from './usePageEventsContext';

export type ItemListViewProps = {
  itemListId?: string;
  itemListName?: string;
  itemIdEp?: string;
  itemNameEp?: string;
  items?: any[];
};

export const useItemListViewEvent = ({
  itemListId,
  itemListName,
  itemIdEp,
  itemNameEp,
  items,
}: ItemListViewProps) => {
  const previousItemstRef = useRef<Array<any>>([]);

  const { pageviewEventHasFired } = usePageEventsContext();

  useEffect(() => {
    const notExecuted =
      JSON.stringify(previousItemstRef.current) === JSON.stringify(items);

    const fireEvent =
      itemListId &&
      itemListName &&
      items &&
      items?.length > 0 &&
      !notExecuted &&
      pageviewEventHasFired;

    if (!fireEvent) return;

    event(AnalyticsEvents.GA4EC_ItemListView, {
      event_name: AnalyticsEvents.VIEW_ITEM_LIST,
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        item_id_ep: itemIdEp,
        item_name_ep: itemNameEp,
        items,
      },
    });

    previousItemstRef.current = items;
  }, [
    itemListId,
    itemListName,
    itemIdEp,
    itemNameEp,
    items,
    pageviewEventHasFired,
  ]);
};
