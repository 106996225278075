import Script from 'next/script';
import { createContext, useContext, useEffect, useRef } from 'react';

import { throttle } from 'utils/throttle';

import { useSiteConfig } from './useSiteConfig';

type AutoCompleteFunction = (
  term: string,
  countryRestrictions?: string[]
) => Promise<google.maps.places.AutocompletePrediction[]>;

type GetPlaceDetailsFunction = (
  id: string
) => Promise<google.maps.places.PlaceResult>;

type GeocodeFunction = (
  postalCode: string,
  country: string
) => Promise<google.maps.GeocoderResult>;

const errorMessage = 'Not inside <GoogleServicesProvider />.';

const isOkStatus = (
  status: google.maps.places.PlacesServiceStatus | google.maps.GeocoderStatus
) => {
  return [
    window.google.maps.places.PlacesServiceStatus.OK,
    window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
    window.google.maps.GeocoderStatus.OK,
    window.google.maps.GeocoderStatus.ZERO_RESULTS,
  ].includes(status);
};

export const GoogleServicesContext = createContext<{
  /** This takes a search term and returns an array of autoComplete
   * suggestions using the Google Maps JavaScript API. */
  autoComplete: AutoCompleteFunction;
  /** This takes a Google Place id and returns human-readable
   * address components using the Google Maps JavaScript API. */
  getPlaceDetails: GetPlaceDetailsFunction;
  /** This takes a postal code and returns geographical data and
   * coordinates using the Google Maps JavaScript API. */
  geocode: GeocodeFunction;
}>({
  autoComplete: () => Promise.reject(new Error(errorMessage)),
  getPlaceDetails: () => Promise.reject(new Error(errorMessage)),
  geocode: () => Promise.reject(new Error(errorMessage)),
});

export const GoogleServicesProvider = ({ children }) => {
  const { env } = useSiteConfig();

  const autoCompleteService = useRef<google.maps.places.AutocompleteService>();
  const placesService = useRef<google.maps.places.PlacesService>();
  const geocodeService = useRef<google.maps.Geocoder>();

  useEffect(() => {
    const initializeServices = () => {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      geocodeService.current = new window.google.maps.Geocoder();
    };
    if (window.google?.maps?.places?.AutocompleteService) {
      initializeServices();
    } else {
      window.onGoogleMapsLoad = () => {
        initializeServices();
        delete window.onGoogleMapsLoad;
      };
    }
  }, []);

  const autoComplete: AutoCompleteFunction = (
    term,
    countryRestrictions
  ): Promise<google.maps.places.AutocompletePrediction[]> => {
    if (!autoCompleteService.current) {
      return Promise.reject(new Error('Google Service not available.'));
    }
    return new Promise((resolve, reject) =>
      autoCompleteService.current!.getPlacePredictions(
        {
          input: term,
          componentRestrictions: {
            country: countryRestrictions || [],
          },
        },
        (result, status) => {
          if (isOkStatus(status)) {
            resolve(result || []);
          } else {
            reject(new Error(`Google Service not available: ${status}`));
          }
        }
      )
    );
  };

  const getPlaceDetails: GetPlaceDetailsFunction = throttle(
    (id: string): Promise<google.maps.places.PlaceResult> => {
      if (!placesService.current) {
        return Promise.reject(new Error('Google Service not available.'));
      }
      return new Promise((resolve, reject) =>
        placesService.current!.getDetails({ placeId: id }, (place, status) => {
          if (isOkStatus(status)) {
            resolve(place);
          } else {
            reject(new Error(`Google Service not available: ${status}`));
          }
        })
      );
    }
  );

  const geocode: GeocodeFunction = (
    postalCode,
    country
  ): Promise<google.maps.GeocoderResult> => {
    if (!geocodeService.current) {
      return Promise.reject(new Error('Google Service not available.'));
    }
    return new Promise((resolve, reject) =>
      geocodeService.current!.geocode(
        {
          componentRestrictions: {
            postalCode,
            country: country,
          },
        },
        (result, status) => {
          if (isOkStatus(status)) {
            resolve(result[0]);
          } else {
            reject(new Error(`Google Service not available: ${status}`));
          }
        }
      )
    );
  };

  return (
    <GoogleServicesContext.Provider
      value={{ autoComplete, getPlaceDetails, geocode }}
    >
      <Script
        async
        src={`https://maps.googleapis.com/maps/api/js?key=${env.googleApiKey}&callback=onGoogleMapsLoad&libraries=places`}
      />
      {children}
    </GoogleServicesContext.Provider>
  );
};

export const useGoogleServices = () => useContext(GoogleServicesContext);
