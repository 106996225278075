import { Maybe } from 'utils/types';
import {
  ImageAssetDocument,
  ImageAssetDocumentType,
} from 'groq/documents/ImageAssetDocument';
import { LocaleString } from 'groq/shared/LocaleString';
import { BackgroundOverlay } from '__generated__/graphql';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/figure.ts

export type SanityImageObjectType = {
  alt?: Maybe<string>;
  asset?: Maybe<ImageAssetDocumentType>;
  overlay?: Maybe<BackgroundOverlay>;
};

export const SanityImageObject = (
  field,
  platform: 'all' | 'desktop' | 'mobile' = 'all'
) => `
  ${field} {
    ${LocaleString('alt')},
    ${ImageAssetDocument('asset->', platform)},
    overlay
  }
`;
