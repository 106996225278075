import { tw } from 'twind';

import { PromoTileDocumentType } from 'groq/documents/PromoTileDocument';
import { usePromotionView } from 'hooks/usePromotionView';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { VideoOrImage } from 'ui/components/VideoOrImage';
import { CallToActions } from 'ui/content/CallToActions';
import { Link } from 'ui/elements/Link';
import { Markdown } from 'ui/elements/Markdown';
import { useStyliticsSanity } from 'hooks/useStyliticsSanity';

const flexFromVerticalAlignment = alignment =>
  ({ top: 'start', middle: 'center', bottom: 'end' }[alignment]);

const StyliticsPromoTile = (props: PromoTileDocumentType) => {
  const textColor = props.textColor || '#000000';
  const bgColor = props.backgroundColor || 'transparent';
  const { ref, width } = useResizeObserver();
  const fontSize = tw(
    width <= 280 && 'text-md',
    width > 280 && width <= 360 && 'text-lg',
    width > 360 && width < 640 && 'text-xl',
    width >= 640 && 'text-2xl'
  );
  const { additionalCtaClass } = useSiteConfig();
  const { ga4Data } = useStyliticsSanity();

  const gaTrackData = {
    id: props.puid?.current || '',
    name: props.header || props?.cta?.title || '',
    creative: 'promo-tile',
    campaign: props.puid?.current || '',
    position: '',
  };

  const extendedGaTrackData = {
    ...gaTrackData,
    promotion_id: ga4Data?.promotion_id,
    creative_name: ga4Data?.creative_name,
    cta_links: props.cta?.link ? [props.cta.link] : [],
  };

  const { promotionTrackerPosition } = usePromotionView(
    ref,
    extendedGaTrackData
  );

  if (promotionTrackerPosition) {
    gaTrackData.position = promotionTrackerPosition;
  }

  return (
    <div
      className="stylitics-card"
      style={{ scrollSnapAlign: 'start' }}
      data-test-id="stylitics-carousel-promo-tile"
    >
      <div
        ref={ref}
        className={tw(
          `bg-[${bgColor}]`,
          `relative flex flex-col flex-none w-full h-full`,
          `justify-${flexFromVerticalAlignment(props.verticalAlignment)}`
        )}
        data-test-id="stylitics-promo-tile"
      >
        <div
          className={tw(
            !props.contentBelow
              ? 'absolute inset-0'
              : 'relative w-full h-full flex-grow'
          )}
        >
          <VideoOrImage
            image={props.backgroundImage}
            header={props.header}
            video={props.video}
            mobileImage={props.mobileBackgroundImage}
          />
        </div>
        {props.cta && (
          <Link
            dataTestId="stylitics-promo-tile-cta"
            query={props.cta.query}
            newtab={!!props.cta.newtab}
            href={props.cta.link}
            className="absolute inset-0 h-full w-full"
            aria-hidden
            tabIndex={-1}
            gaBannerData={gaTrackData}
            ga4Data={
              ga4Data && {
                ...ga4Data,
                link_url: props.cta.link,
              }
            }
          />
        )}
        {(props.header || props.copy || props.cta) && (
          <div
            className={tw(
              `relative p-6 text-center w-full flex flex-col items-center text-[${textColor}]`,
              props.contentBelow && 'mt-4'
            )}
          >
            <div className={fontSize}>
              {props.header && (
                <h3
                  data-test-id="stylitics-promo-tile-header"
                  className={`font-bold text-[1em] leading-none`}
                >
                  <Markdown content={props.header} />
                </h3>
              )}
              {props.copy && (
                <p
                  data-test-id="stylitics-promo-tile-copy"
                  className="mt-3 font-bold text-[0.62em] leading-snug"
                >
                  <Markdown content={props.copy} />
                </p>
              )}
            </div>
            {props.cta && props.cta.title && (
              <div className="mt-3">
                <CallToActions
                  ctas={[props.cta]}
                  className={additionalCtaClass ?? ''}
                  gaBannerData={gaTrackData}
                  ga4Data={ga4Data}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StyliticsPromoTile;
