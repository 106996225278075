import { LocaleReferenceUrl } from 'groq/shared/LocaleUrl';
import { LocaleString } from 'groq/shared/LocaleString';
import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/cta.ts

const LinkVariant = {
  Black: 'black',
  Primary: 'primary',
  Secondary: 'secondary',
  White: 'white',
} as const;

type LinkVariant = (typeof LinkVariant)[keyof typeof LinkVariant];

export type CallToActionObjectType = {
  id?: Maybe<string>;
  _key?: Maybe<string>;
  _type: string;
  link: string;
  newtab?: Maybe<boolean>;
  outlined?: Maybe<boolean>;
  popup?: Maybe<boolean>;
  query?: Maybe<string>;
  title?: Maybe<string>;
  variant?: Maybe<LinkVariant>;
  action?: Maybe<string>;
};

export const CallToActionObject = field => `
  ${field} {
    "id": _key,
    _key,
    _type,
    ${LocaleString('title')},
    ${LocaleReferenceUrl('link')},
    newtab,
    variant,
    outlined,
    popup,
    query,
    action
  }
`;
