import { useEffect } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { SearchTermList } from 'ui/components/Search/SearchTermList';
import { SearchViewComponent } from 'ui/components/Search/types';
import { AnalyticsEvents, event } from 'utils/analytics';
import { useTrendingSearchTermsQuery } from '__generated__/graphql';

const TrendingSearchTerms: SearchViewComponent = ({
  setIsLoading,
  setSessionStorageData,
}) => {
  const t = useTranslate();
  const [trendingSearchTermsResult] = useTrendingSearchTermsQuery();

  useEffect(() => {
    setIsLoading(trendingSearchTermsResult.fetching);
  }, [trendingSearchTermsResult.fetching, setIsLoading]);

  const trackTrendingSearchTermClick = (term: string) => {
    if (setSessionStorageData) {
      setSessionStorageData(t('trendingSearchTerms'), term);
    }

    event(AnalyticsEvents.SEARCH_TRENDING_SEARCH_TERM_CLICK, {
      trending_search_term: term,
    });
  };

  const trendingSearchTerms =
    trendingSearchTermsResult.data?.trendingSearchTerms;

  const terms = (trendingSearchTerms ?? [])
    .sort((a, b) => {
      return a.rank - b.rank;
    })
    .map(item => item.searchTerm);

  return (
    <SearchTermList
      heading={t('trendingSearchTerms')}
      terms={terms}
      onClick={trackTrendingSearchTermClick}
    />
  );
};

export default TrendingSearchTerms;
