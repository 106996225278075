import { Icon } from '@global-ecom/nitro-uds/elements';
import {
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { tw } from 'twind';

type TTabRefsState = {
  [key: string]: RefObject<HTMLButtonElement>;
};

type TTabsOpenState = {
  [key: string]: boolean;
};

type TypeContext = {
  addTabRef: (ref: RefObject<HTMLButtonElement>, id: string) => void;
  tabRefs: TTabRefsState;
  tabsOpen: TTabsOpenState;
  toggleTabById: (id: string) => void;
  focusTabById: (id: string) => void;
  focusNextTabById: (id: string) => void;
  focusPreviousTabById: (id: string) => void;
  focusFirstTab: () => void;
  focusLastTab: () => void;
};

const AccordionContext = createContext<TypeContext>({
  tabRefs: {},
  addTabRef: (_ref, _id) => null,
  tabsOpen: {},
  toggleTabById: _id => null,
  focusTabById: _id => null,
  focusNextTabById: _id => null,
  focusPreviousTabById: _id => null,
  focusFirstTab: () => null,
  focusLastTab: () => null,
});

export const AccordionRoot = ({ children }) => {
  const [tabRefs, setTabRefs] = useState<TTabRefsState>({});
  const [tabsOpen, setTabsOpen] = useState<TTabsOpenState>({});

  const getTabIndexById = useCallback(
    (id: string) => {
      const tabKeys = Object.keys(tabRefs);
      const tabIndex = tabKeys.findIndex(v => v == id);
      return tabIndex;
    },
    [tabRefs]
  );

  const focusTabById = useCallback(
    (id: string) => {
      const tabRef = tabRefs[id];
      tabRef.current?.focus();
    },
    [tabRefs]
  );

  const focusNextTabById = useCallback(
    (id: string) => {
      const tabKeys = Object.keys(tabRefs);
      const keyIndex = getTabIndexById(id);
      const nextKeyIndex = keyIndex + 1 < tabKeys.length ? keyIndex + 1 : 0;
      focusTabById(tabKeys[nextKeyIndex]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabRefs, focusTabById]
  );

  const focusPreviousTabById = useCallback(
    (id: string) => {
      const tabKeys = Object.keys(tabRefs);
      const keyIndex = getTabIndexById(id);
      const nextKeyIndex =
        keyIndex - 1 >= 0 ? keyIndex - 1 : tabKeys.length - 1;
      focusTabById(tabKeys[nextKeyIndex]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabRefs, focusTabById]
  );

  /** Focus First Tab */
  const focusFirstTab = useCallback(() => {
    const tabKeys = Object.keys(tabRefs);
    focusTabById(tabKeys[0]);
  }, [tabRefs, focusTabById]);

  const focusLastTab = useCallback(() => {
    const tabKeys = Object.keys(tabRefs);
    focusTabById(tabKeys[tabKeys.length - 1]);
  }, [tabRefs, focusTabById]);

  const toggleTabById = useCallback(
    (id: string) => {
      setTabsOpen(state => {
        const newState = { ...state };
        newState[id] = !newState[id];
        return newState;
      });
    },
    [setTabsOpen]
  );

  const addTabRef = useCallback(
    (ref: RefObject<HTMLButtonElement>, id: string) => {
      setTabRefs(state => {
        const newState = { ...state };
        newState[id] = ref;
        return newState;
      });

      setTabsOpen(state => {
        const newState = { ...state };
        newState[id] = false;
        return newState;
      });
    },
    [setTabRefs, setTabsOpen]
  );

  const providerValue = useMemo(() => {
    return {
      tabRefs,
      addTabRef,
      tabsOpen,
      toggleTabById,
      focusTabById,
      focusNextTabById,
      focusPreviousTabById,
      focusFirstTab,
      focusLastTab,
    };
  }, [
    tabRefs,
    addTabRef,
    tabsOpen,
    toggleTabById,
    focusTabById,
    focusNextTabById,
    focusPreviousTabById,
    focusFirstTab,
    focusLastTab,
  ]);

  return (
    <AccordionContext.Provider value={providerValue}>
      {children}
    </AccordionContext.Provider>
  );
};

export const useAccordionTab = (id: string) => {
  const {
    addTabRef,
    tabsOpen,
    toggleTabById,
    focusNextTabById,
    focusPreviousTabById,
    focusFirstTab,
    focusLastTab,
  } = useContext(AccordionContext);

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    addTabRef(ref, id);
  }, [addTabRef, ref, id]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      const { key } = event;

      if (key !== 'Tab' && key !== 'Enter') {
        event.preventDefault();
      }

      switch (true) {
        case key === 'ArrowDown':
          focusNextTabById(id);
          break;
        case key === 'ArrowUp':
          focusPreviousTabById(id);
          break;
        case key === 'Home':
          focusFirstTab();
          break;
        case key === 'End':
          focusLastTab();
          break;
      }
    },
    [id, focusNextTabById, focusPreviousTabById, focusFirstTab, focusLastTab]
  );

  const handleOnClick = useCallback(() => {
    toggleTabById(id);
  }, [toggleTabById, id]);

  return {
    ref,
    expanded: tabsOpen[id],
    toggleTab: toggleTabById,
    onClick: handleOnClick,
    onKeyDown: handleKeyDown,
  };
};

export interface AccordionTabProps extends React.ComponentProps<'button'> {
  id: string;
  bodyClassNameConfig?: {
    baseClassName?: string;
  };
  className?: string;
  dataTestId?: string;
  expand?: boolean;
  headerWrapperClassNameConfig?: {
    baseClassName?: string;
  };
  headerClassNameConfig?: {
    baseClassName?: string;
    expanded?: string;
    closed?: string;
  };
  title?: string;
}

export const AccordionTab: React.FC<AccordionTabProps> = ({
  id,
  bodyClassNameConfig,
  children,
  className,
  expand = true,
  headerClassNameConfig,
  headerWrapperClassNameConfig,
  title,
  dataTestId,
  ...rest
}) => {
  const { ref, expanded, toggleTab, ...hookRest } = useAccordionTab(id);

  useEffect(() => {
    if (expand) {
      toggleTab(id);
    }
  }, [expand, id, toggleTab]);

  const expandedClassName = headerClassNameConfig?.expanded ?? 'pb-0';
  const closedClassName = headerClassNameConfig?.closed ?? 'pb-4';

  return (
    <div
      id={`accordion-${id}`}
      data-test-id={dataTestId}
      className={tw(`border-b`)}
    >
      <h3
        id={`heading-${id}`}
        className={tw('flex', headerWrapperClassNameConfig?.baseClassName)}
      >
        <button
          data-test-id={`${dataTestId}-btn`}
          ref={ref}
          aria-expanded={expanded}
          aria-controls={`tabregion-${id}`}
          className={tw([
            'w-full flex justify-between relative text-left text-puma-black focus:outline-none h-[60px] items-center',
            headerClassNameConfig?.baseClassName,
            expanded ? expandedClassName : closedClassName,
          ])}
          type="button"
          {...rest}
          {...hookRest}
        >
          <span>{title}</span>
          <span className={tw(expanded && 'transform rotate-180')}>
            <Icon name="chevron-down" size="2xl" />
          </span>
        </button>
      </h3>
      <div
        role="region"
        id={`tabregion-${id}`}
        aria-labelledby={`heading-${id}`}
        hidden={!expanded}
        className={tw(['w-full', bodyClassNameConfig?.baseClassName])}
      >
        {children}
      </div>
    </div>
  );
};
