import {
  InputCheckbox,
  InputText,
  Stack,
  Text,
} from '@global-ecom/nitro-uds/elements';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Resources } from 'locales/localeType';
import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { CustomSmsOptIn } from 'ui/forms/custom/types';

const SmsOptInTermsAndConditions = ({ ctaLabel }: { ctaLabel?: string }) => {
  const t = useTranslate();
  return (
    <Text size="xs">
      {t('smsOptInTermsAndConditions', {
        ctaLabel: ctaLabel ? t(ctaLabel as keyof Resources) : t('submit'),
      })}
    </Text>
  );
};

const CustomFormSmsOptIn = ({
  field,
  phoneNumberFieldName = `${field.name}PhoneNumber`,
  ctaLabel,
}: {
  field: CustomSmsOptIn;
  phoneNumberFieldName?: string;
  ctaLabel?: string;
}) => {
  const t = useTranslate();
  const { register, watch, errors } = useFormContext();
  const [validate] = useValidation();

  const smsOptInValue = watch('smsOptIn');

  return (
    <Stack>
      <InputCheckbox
        key={`${field.key}_checkbox`}
        name={field.name}
        id={field.id}
        dataTestId={field.dataTestId}
        ref={register()}
      >
        {t('smsOptInLabel')}
      </InputCheckbox>

      {smsOptInValue && (
        <>
          <InputText
            type="tel"
            key={`${field.key}_phoneNumber`}
            name={phoneNumberFieldName}
            id={`${field.id}-phoneNumber`}
            dataTestId={`${field.dataTestId}-phone-number`}
            label={t('smsOptInPhoneNumberLabel')}
            placeholder={t('smsOptInPhoneNumberPlaceholder')}
            required={true}
            errorText={errors[phoneNumberFieldName]?.message}
            ref={register({
              required: true,
              validate: validate.phoneNumber,
            })}
          />
          <SmsOptInTermsAndConditions ctaLabel={ctaLabel} />
        </>
      )}
    </Stack>
  );
};

export default memo(CustomFormSmsOptIn);
