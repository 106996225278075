import { Text } from '@global-ecom/nitro-uds/elements';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { ProductPrice } from 'ui/components/ProductPrice/ProductPrice';

import { SearchProductResult } from './types';

type SearchProductListItemProps = {
  product: SearchProductResult;
};

export const SearchProductListItem = ({
  product,
}: SearchProductListItemProps) => {
  const {
    staticFeatures: { showHeaderSubHeaderProductDetail },
  } = useSiteConfig();
  return (
    <div className="flex gap-4 items-center group">
      <img
        src={product.image?.href}
        alt={product.image?.alt}
        className="flex-none w-[100px] h-[100px]"
      />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col w-full">
          <Text
            dataTestId="search-product-name"
            weight="bold"
            className="block group-hover:text-underline"
          >
            {showHeaderSubHeaderProductDetail ? product.header : product.name}
          </Text>
          {showHeaderSubHeaderProductDetail && product.subHeader && (
            <Text
              dataTestId="search-product-subheader"
              className="w-full text-base font-normal text-puma-gray-60 line-clamp-2"
            >
              {product.subHeader}
            </Text>
          )}
        </div>
        <div className="flex gap-2 flex-wrap">
          <ProductPrice
            price={{
              amount: product.price,
              salePrice: product.salePrice,
              isSalePriceElapsed: product.isSalePriceElapsed,
            }}
            compact={true}
          />
        </div>
      </div>
    </div>
  );
};
