import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { OperationResult } from 'urql';

import {
  AdyenPaymentMethod,
  CancelGooglePayMutation,
  Cart,
  Maybe,
  useCancelGooglePayMutation,
  usePayWithGooglePayMutation,
  useStartAdyenPaymentMutation,
  useUpdateShippingAddressMutation,
  useUpdateBillingAddressMutation,
  useUpdateShipmentMethodMutation,
  Adjustment,
} from '__generated__/graphql';
import { useCart } from 'hooks/useCart';
import { useFeature } from 'hooks/useFeature';
import { useRadial } from 'hooks/useRadial';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { ActiveCart, ActiveCartWithoutPaymentMethods } from 'types/cart';
import { isServer, PaymentMethod, SUPPORTED_COUNTRY } from 'utils/constants';
import { isNumber } from 'utils/isNumber';
import { splitName } from 'utils/splitName';
import { getForterToken } from 'utils/forter';

export type GooglePayButtonType = 'pay' | 'plain';

type GooglePayContextValue = {
  enableGooglePay: () => void;
  isGooglePayEnabled: boolean;
  injectButtonStyles: (buttonType: GooglePayButtonType) => void;
  handleGooglePay: () => void;
};

const GooglePayContext = createContext<GooglePayContextValue>(
  {} as GooglePayContextValue
);

const SUPPORTED_PAYMENT_GATEWAYS = ['adyen', 'radialpayments'];
const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
};
const ALLOWED_CARD_AUTH_METHODS: google.payments.api.CardAuthMethod[] = [
  'PAN_ONLY',
  'CRYPTOGRAM_3DS',
];
const ALLOWED_CARD_NETWORKS: google.payments.api.CardNetwork[] = [
  'AMEX',
  'DISCOVER',
  'MASTERCARD',
  'VISA',
];
const PAYMENT_DATA_REQUEST_UPDATE_NOOP = {};

export const GooglePayProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const t = useTranslate();
  const isGooglePayEnabled = useFeature(PaymentMethod.GOOGLE_PAY);
  const { countryCode, env, currency, language, localizeUrlPath } =
    useSiteConfig();
  const paymentGateway = env.googlePayPaymentGateway ?? '';
  const isSupportedPaymentGateway =
    SUPPORTED_PAYMENT_GATEWAYS.includes(paymentGateway);
  const [shouldInitialize, setShouldInitialize] = useState(false);
  const [, googlePay] = usePayWithGooglePayMutation();
  const [, cancelGooglePay] = useCancelGooglePayMutation();
  const [, startAdyenPayment] = useStartAdyenPaymentMutation();
  const { radialFingerPrint, radialFrontendContext } = useRadial();
  const [, updateShipmentMethod] = useUpdateShipmentMethodMutation();
  const [, updateShippingAddress] = useUpdateShippingAddressMutation();
  const [, updateBillingAddress] = useUpdateBillingAddressMutation();
  const { cart: _activeCart } = useCart({
    pause: !shouldInitialize,
  });

  const activeCartRef = useRef<Maybe<ActiveCart>>();
  useEffect(() => {
    // ActiveCart reference for callback methods to prevent stale closures
    if (_activeCart) {
      activeCartRef.current = _activeCart;
    }
  }, [_activeCart]);

  const cancelGooglePayCallback = async (
    basketId: string
  ): Promise<OperationResult<CancelGooglePayMutation>> =>
    cancelGooglePay({ input: { basketId } });

  const getTransactionInfo = (
    cart: ActiveCart | ActiveCartWithoutPaymentMethods | Cart
  ): google.payments.api.TransactionInfo => ({
    countryCode: countryCode.toUpperCase(),
    currencyCode: currency.code,
    displayItems: [
      {
        label: t('subtotal'),
        price: `${(cart?.subTotal ?? 0).toFixed(2)}`,
        type: 'SUBTOTAL',
      },
      {
        label: t('estimatedSalesTax'),
        price: `${(cart?.taxTotal ?? 0).toFixed(2)}`,
        type: 'TAX',
      },
      {
        label: t('shippingCosts'),
        price: `${(cart?.shipment?.shipmentMethod?.price ?? 0).toFixed(2)}`,
        type: 'LINE_ITEM',
      },
      {
        label: t('orderDiscount'),
        price: `${(cart?.orderAdjustments as Adjustment[])
          .reduce((sum, item) => sum + (item.price ?? 0), 0)
          .toFixed(2)}`,
        type: 'DISCOUNT',
      },
      {
        label: t('shippingDiscount'),
        price: `${(cart?.shippingAdjustments as Adjustment[])
          .reduce((sum, item) => sum + (item.price ?? 0), 0)
          .toFixed(2)}`,
        type: 'DISCOUNT',
      },
    ],
    totalPriceStatus: 'FINAL',
    totalPrice: cart?.estimatedTotal?.toFixed(2) ?? '0.00',
    totalPriceLabel: t('total'),
  });

  const handleShippingOptionUpdate = async (
    cart: ActiveCart | Cart,
    shippingMethodId: string
  ): Promise<google.payments.api.PaymentDataRequestUpdate> => {
    const updateShipmentOperationResult = await updateShipmentMethod({
      input: { shippingMethodId, basketId: cart?.id ?? '' },
    });
    if (
      !updateShipmentOperationResult.data?.updateShipmentMethod ||
      updateShipmentOperationResult.error
    ) {
      return {
        error: {
          reason: 'SHIPPING_OPTION_INVALID',
          intent: 'SHIPPING_OPTION',
          message: 'Shipping option is invalid.',
        },
      };
    }
    return {
      newTransactionInfo: getTransactionInfo(
        updateShipmentOperationResult.data.updateShipmentMethod
      ),
    };
  };

  const handleIntermediateShippingAddressUpdate = async (
    cart: ActiveCart,
    intermediateShippingAddress: google.payments.api.IntermediateAddress
  ): Promise<google.payments.api.PaymentDataRequestUpdate> => {
    const { locality, countryCode, postalCode, administrativeArea } =
      intermediateShippingAddress;
    const updateShippingAddressOperationResult = await updateShippingAddress({
      input: {
        address1: 'Temporary',
        basketId: cart?.id ?? '',
        city: locality,
        countryCode,
        firstName: 'Temporary',
        lastName: 'Temporary',
        postalCode,
        stateCode: administrativeArea,
        useAsBilling: false,
      },
    });
    if (
      !updateShippingAddressOperationResult.data?.updateShippingAddress ||
      updateShippingAddressOperationResult.error
    ) {
      return {
        error: {
          reason: 'SHIPPING_ADDRESS_INVALID',
          intent: 'SHIPPING_ADDRESS',
          message: 'Shipping address is invalid.',
        },
      };
    }
    return {
      newTransactionInfo: getTransactionInfo(
        updateShippingAddressOperationResult.data.updateShippingAddress
      ),
    };
  };

  const onPaymentDataChanged = async (
    intermediatePaymentData: google.payments.api.IntermediatePaymentData
  ): Promise<google.payments.api.PaymentDataRequestUpdate> => {
    const activeCart = activeCartRef.current;
    if (!activeCart) return PAYMENT_DATA_REQUEST_UPDATE_NOOP;
    switch (intermediatePaymentData.callbackTrigger) {
      case 'INITIALIZE':
      case 'SHIPPING_ADDRESS': {
        if (!intermediatePaymentData.shippingAddress) {
          return PAYMENT_DATA_REQUEST_UPDATE_NOOP;
        }
        return handleIntermediateShippingAddressUpdate(
          activeCart,
          intermediatePaymentData.shippingAddress
        );
      }
      case 'SHIPPING_OPTION': {
        if (!intermediatePaymentData.shippingOptionData?.id) {
          return PAYMENT_DATA_REQUEST_UPDATE_NOOP;
        }
        return handleShippingOptionUpdate(
          activeCart,
          intermediatePaymentData.shippingOptionData.id
        );
      }
      default:
        return PAYMENT_DATA_REQUEST_UPDATE_NOOP;
    }
  };

  const onPaymentAuthorized = async (
    paymentData: google.payments.api.PaymentData
  ): Promise<google.payments.api.PaymentAuthorizationResult> => {
    const activeCart = activeCartRef.current;
    if (!activeCart) {
      return {
        transactionState: 'ERROR',
        error: {
          reason: 'OTHER_ERROR',
          intent: 'PAYMENT_AUTHORIZATION',
          message: 'Cart is required for payment authorization.',
        },
      };
    }

    const { email } = paymentData;
    if (!email) {
      return {
        transactionState: 'ERROR',
        error: {
          reason: 'PAYMENT_DATA_INVALID',
          intent: 'PAYMENT_AUTHORIZATION',
          message: 'Email is required for payment authorization.',
        },
      };
    }

    const shippingAddress = paymentData.shippingAddress;
    const billingAddress = paymentData.paymentMethodData.info?.billingAddress;

    const phone = shippingAddress?.phoneNumber || billingAddress?.phoneNumber;
    if (!phone) {
      return {
        transactionState: 'ERROR',
        error: {
          reason: 'PAYMENT_DATA_INVALID',
          intent: 'PAYMENT_AUTHORIZATION',
          message: 'Phone number is required for payment authorization.',
        },
      };
    }

    const shippingAddressInvalidResult: google.payments.api.PaymentAuthorizationResult =
      {
        transactionState: 'ERROR',
        error: {
          reason: 'SHIPPING_ADDRESS_INVALID',
          intent: 'PAYMENT_AUTHORIZATION',
          message: 'Shipping address is invalid.',
        },
      };
    if (shippingAddress && shippingAddress.address1 && shippingAddress.name) {
      const { firstName, lastName } = splitName(shippingAddress.name);
      const updateShippingAddressOperationResult = await updateShippingAddress({
        input: {
          address1: shippingAddress.address1,
          address2: shippingAddress.address2,
          basketId: activeCart.id,
          city: shippingAddress.locality,
          countryCode: shippingAddress.countryCode,
          firstName,
          lastName,
          phone,
          postalCode: shippingAddress.postalCode,
          stateCode: shippingAddress.administrativeArea,
          email,
          useAsBilling: false,
        },
      });
      if (
        !updateShippingAddressOperationResult.data?.updateShippingAddress ||
        updateShippingAddressOperationResult.error
      ) {
        await cancelGooglePayCallback(activeCart.id);
        return shippingAddressInvalidResult;
      }
    } else {
      return shippingAddressInvalidResult;
    }

    const billingAddressInvalidResult: google.payments.api.PaymentAuthorizationResult =
      {
        transactionState: 'ERROR',
        error: {
          reason: 'PAYMENT_DATA_INVALID',
          intent: 'PAYMENT_AUTHORIZATION',
          message: 'Billing address is invalid.',
        },
      };
    if (billingAddress && billingAddress.address1 && billingAddress.name) {
      const { firstName, lastName } = splitName(billingAddress.name);
      const updateBillingAddressOperationResult = await updateBillingAddress({
        input: {
          address1: billingAddress.address1,
          address2: billingAddress.address2,
          basketId: activeCart.id,
          city: billingAddress.locality,
          countryCode: billingAddress.countryCode,
          firstName,
          lastName,
          phone,
          postalCode: billingAddress.postalCode,
          stateCode: billingAddress.administrativeArea,
        },
      });
      if (
        !updateBillingAddressOperationResult.data?.updateBillingAddress ||
        updateBillingAddressOperationResult.error
      ) {
        await cancelGooglePayCallback(activeCart.id);
        return billingAddressInvalidResult;
      }
    } else {
      return billingAddressInvalidResult;
    }

    const paymentAuthorizationInvalidResult: google.payments.api.PaymentAuthorizationResult =
      {
        transactionState: 'ERROR',
        error: {
          reason: 'OTHER_ERROR',
          message: 'Payment could not be authorized.',
          intent: 'PAYMENT_AUTHORIZATION',
        },
      };
    const basketId = activeCart.id;
    const { token } = paymentData.paymentMethodData.tokenizationData;
    let orderNo = '';

    switch (paymentGateway) {
      case 'adyen': {
        const startAdyenPaymentOperationResult = await startAdyenPayment({
          input: {
            basketId,
            adyenPaymentData: {
              paymentMethodType: AdyenPaymentMethod.GooglePay,
              googlePay: {
                googlePayToken: token,
              },
            },
          },
        });

        if (
          !startAdyenPaymentOperationResult.data?.startAdyenPayment ||
          startAdyenPaymentOperationResult.error
        ) {
          return paymentAuthorizationInvalidResult;
        }

        orderNo =
          startAdyenPaymentOperationResult.data.startAdyenPayment.orderNo ?? '';
        break;
      }

      case 'radialpayments': {
        const parsedToken = JSON.parse(token);
        const googlePayOperationResult = await googlePay({
          input: {
            basketId,
            paymentData: {
              ...parsedToken,
              intermediateSigningKey: {
                ...parsedToken.intermediateSigningKey,
                signedKey: JSON.parse(
                  parsedToken.intermediateSigningKey.signedKey
                ),
              },
              signedMessage: JSON.parse(parsedToken.signedMessage),
            },
            radialFingerPrint,
            radialFrontendContext,
            forterToken: getForterToken(),
          },
        });

        if (
          !googlePayOperationResult.data?.googlePay ||
          googlePayOperationResult.error
        ) {
          return paymentAuthorizationInvalidResult;
        }

        orderNo = googlePayOperationResult.data.googlePay.orderNo;
        break;
      }

      default:
        return paymentAuthorizationInvalidResult;
    }

    window.sessionStorage.setItem('payment-method', 'Google Pay');

    router.push({
      pathname: localizeUrlPath('/checkout/confirmation'),
      query: { orderNo },
    });

    return {
      transactionState: 'SUCCESS',
    };
  };

  const [scriptDidLoad, setScriptDidLoad] = useState<boolean>(false);
  const [paymentsClient, setPaymentsClient] =
    useState<google.payments.api.PaymentsClient>();
  const [isReadyToPay, setIsReadyToPay] = useState<boolean>(false);

  useEffect(() => {
    // initialize paymentsClient instance
    if (isServer || !shouldInitialize || !scriptDidLoad || paymentsClient) {
      return;
    }
    const newPaymentsClient = new window.google.payments.api.PaymentsClient({
      environment: env.googlePayEnvironment as google.payments.api.Environment,
      paymentDataCallbacks: {
        onPaymentDataChanged,
        onPaymentAuthorized,
      },
    });
    setPaymentsClient(newPaymentsClient);
  }, [shouldInitialize, scriptDidLoad, paymentsClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set isReadyToPay
    if (!paymentsClient) return;
    const getIsReadyToPay = async () => {
      const { result } = await paymentsClient.isReadyToPay({
        ...BASE_REQUEST,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ALLOWED_CARD_AUTH_METHODS,
              allowedCardNetworks: ALLOWED_CARD_NETWORKS,
            },
          },
        ],
      });
      setIsReadyToPay(result);
    };
    getIsReadyToPay();
  }, [paymentsClient]);

  const injectButtonStyles = (buttonType: GooglePayButtonType) => {
    if (!paymentsClient || !isReadyToPay) return;
    paymentsClient.createButton({
      buttonColor: 'black',
      buttonType,
      buttonLocale: language,
      onClick: () => undefined,
    });
  };

  const formatShippingMethodPrice = (price: number) => {
    // google pay uses 'CA$' not 'CAD $' for canada
    const isCanada = countryCode.toUpperCase() === SUPPORTED_COUNTRY.CA;
    return `${isCanada ? 'CA$' : currency.symbol}${price.toFixed(2)}`;
  };

  const handleGooglePay = async () => {
    const activeCart = activeCartRef.current;
    if (!paymentsClient || !isReadyToPay || !activeCart) return;
    const cartHasProducts = activeCart.products.some(
      product => product.__typename !== 'GiftCardCartItem'
    );
    try {
      await paymentsClient.loadPaymentData({
        ...BASE_REQUEST,
        transactionInfo: getTransactionInfo(activeCart),
        merchantInfo: {
          merchantId: env.googlePayMerchantId ?? '',
          merchantName: env.googlePayMerchantName ?? '',
        },
        allowedPaymentMethods: [
          {
            type: 'CARD',
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: paymentGateway,
                gatewayMerchantId: env.googlePayPaymentGatewayMerchantId ?? '',
              },
            },
            parameters: {
              allowedAuthMethods: ALLOWED_CARD_AUTH_METHODS,
              allowedCardNetworks: ALLOWED_CARD_NETWORKS,
              billingAddressRequired: true,
              billingAddressParameters: {
                format: 'FULL',
              },
            },
          },
        ],
        emailRequired: true,
        shippingAddressRequired: true,
        shippingAddressParameters: {
          allowedCountryCodes: [countryCode.toUpperCase()],
          phoneNumberRequired: true,
        },
        shippingOptionRequired: true,
        shippingOptionParameters: {
          shippingOptions:
            activeCart.shipmentMethodInfo?.availableShipmentMethods
              .filter(m => (cartHasProducts ? true : m.id === 'Email'))
              .map(m => ({
                id: m.id,
                label: m.name ?? '',
                description: isNumber(m.price)
                  ? formatShippingMethodPrice(m.price)
                  : undefined,
              })) ?? [],
          defaultSelectedOptionId:
            activeCart.shipment?.shipmentMethod?.id ??
            activeCart.shipmentMethodInfo?.defaultShipmentMethodId ??
            undefined,
        },
        callbackIntents: [
          'SHIPPING_ADDRESS',
          'SHIPPING_OPTION',
          'PAYMENT_AUTHORIZATION',
        ],
      });
    } catch {
      await cancelGooglePayCallback(activeCart.id);
    }
  };

  return (
    <>
      {shouldInitialize && (
        <Script
          async
          src="https://pay.google.com/gp/p/js/pay.js"
          onLoad={() => setScriptDidLoad(true)}
        />
      )}
      <GooglePayContext.Provider
        value={{
          enableGooglePay: () => {
            // called when <GooglePayButton /> renders
            if (!isGooglePayEnabled || !isSupportedPaymentGateway) return;
            setShouldInitialize(true);
          },
          isGooglePayEnabled: isReadyToPay,
          injectButtonStyles,
          handleGooglePay,
        }}
      >
        {children}
      </GooglePayContext.Provider>
    </>
  );
};

export const useGooglePay = (): GooglePayContextValue =>
  useContext(GooglePayContext);
