import { Maybe } from '__generated__/graphql-sanity';

export const isAppExclusiveValidRange = (
  isAppOnly?: Maybe<boolean>,
  appOnlyDateTimeFrom?: Maybe<string>,
  appOnlyDateTimeTo?: Maybe<string>
): boolean => {
  if (!isAppOnly) return false;
  const currentDate = Date.now();

  const fromDateValid = appOnlyDateTimeFrom
    ? new Date(appOnlyDateTimeFrom).getTime() < currentDate
    : true;
  const toDateValid = appOnlyDateTimeTo
    ? new Date(appOnlyDateTimeTo).getTime() > currentDate
    : true;

  return fromDateValid && toDateValid;
};
