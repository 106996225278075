import { ComponentType } from 'react';
import { UseQueryState } from 'urql';

import { SuggestionsQuery } from '__generated__/graphql';

export enum SearchViewScenario {
  NewUser = 'search-scenario-new-user',
  ReturningUser = 'search-scenario-returning-user',
  SearchResults = 'search-scenario-search-results',
}

export type SearchViewComponentProps = {
  searchTerm?: string;
  recentlyViewedProducts?: string[];
  suggestionsResult?: UseQueryState<SuggestionsQuery>;
  setIsLoading: (loading: boolean) => void;
  setSessionStorageData?: (
    suggestedSectionClick: string,
    suggestedElementClick?: string,
    redirect?: boolean
  ) => void;
};

export type SearchViewComponent = ComponentType<SearchViewComponentProps>;

export type SearchProductResult = {
  masterId: string;
  colorValue: string;
  name: string;
  image: {
    href: string;
    alt: string;
  };
  price: number;
  salePrice?: number | null;
  isSalePriceElapsed?: boolean | null;
  header?: string | null;
  subHeader?: string | null;
};
