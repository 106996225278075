import { tw } from 'twind';

import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { VideoOrImage } from 'ui/components/VideoOrImage';
import ChevronButton from 'ui/components/ChevronButton';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { ProductGroupFeaturesDocumentType } from 'groq/documents/ProductGroupFeaturesDocument';

export const ProductGroupFeatures: React.FC<
  ProductGroupFeaturesDocumentType
> = props => {
  const { ref, prev, next, overflowing } = useHorizontalScroll();
  const gridStyles = 'grid gap-4 xs:grid-cols-2 md:grid-cols-3';
  const scrollStyles = 'flex w-full overflow-x-scroll pb-6 space-x-4';

  const gaTrackData: GaTrackData = {
    id: props.id || '',
    name: '',
    creative: props._type || 'ProductGroupFeatures',
  };
  usePromotionView(ref, gaTrackData, true);

  return (
    <section className="relative">
      <div
        ref={ref}
        className={tw({
          'desktop:': gridStyles,
          'mobile:': scrollStyles,
          'tablet:': scrollStyles,
        })}
        style={{ scrollSnapType: 'x mandatory' }}
      >
        {props.productFeatures?.map(feature => (
          <div
            key={feature.id}
            className={`space-y-4 text-${feature.horizontalAlignment} min-w-[50%] md:min-w-[25%] md:w-1/3 lg:min-w-64 lg:w-full`}
          >
            <div>
              <div className="relative pt-[150%]">
                <VideoOrImage
                  image={feature.image}
                  header={feature.header}
                  video={feature.video}
                />
              </div>
            </div>
            <div className="space-y-1">
              {feature.header && (
                <h1 className="font-bold text-xl">{feature.header}</h1>
              )}
              {feature.copy && (
                <p className="text-sm text-puma-black-300">{feature.copy}</p>
              )}
            </div>
          </div>
        ))}
      </div>
      {overflowing && (
        <ChevronButton
          className="mobile:hidden absolute -left-2 top-1/2 transform -translate-y-1/2"
          direction="left"
          onClick={prev}
        />
      )}
      {overflowing && (
        <ChevronButton
          className="mobile:hidden absolute -right-2 top-1/2 transform -translate-y-1/2"
          direction="right"
          onClick={next}
        />
      )}
    </section>
  );
};
