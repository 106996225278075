import { apply, tw } from 'twind';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { CountdownClock as CountdownClockType } from '__generated__/graphql';
import { useCountdown } from 'hooks/useCountdown';
import { useTranslate } from 'hooks/useTranslations';
import { getIsDesktop } from 'utils/media';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

type CountdownClockConfig = {
  containerClassName?: string;
  labelClassName?: string;
  showLabels?: boolean;
  timeUnitClassName?: string;
};

type CountdownClockProps = CountdownClockType & {
  config?: CountdownClockConfig;
};

type TimeUnitProps = {
  label?: string;
  labelClassName?: string;
  showLabels?: boolean;
  timeUnitClassName?: string;
  value: number;
};

const DEFAULT_CONTAINER_CLASS_NAME = 'text-base';

const DEFAULT_CONFIG = {
  containerClassName: DEFAULT_CONTAINER_CLASS_NAME,
  labelClassName: '',
  showLabels: false,
  timeUnitClassName: '',
};

export const useIsCountdownAvailable = (availableFrom, availableTo) => {
  const router = useRouter();

  const viewAt = router.query.viewAt ?? +new Date();
  let showClock = true;
  if (availableFrom && +new Date(availableFrom) > viewAt) showClock = false;
  if (availableTo && +new Date(availableTo) < viewAt) showClock = false;

  return showClock;
};

export const CountdownClock = ({
  availableFrom,
  availableTo,
  countdownEnd,
  config = DEFAULT_CONFIG,
  __typename,
  id,
}: CountdownClockProps) => {
  const isCountdownAvailable = useIsCountdownAvailable(
    availableFrom,
    availableTo
  );

  if (!isCountdownAvailable) return null;

  return (
    <Clock
      config={config}
      countdownEnd={countdownEnd}
      id={id}
      typename={__typename}
    />
  );
};

const Clock = ({
  config,
  countdownEnd,
  id,
  typename,
}: {
  config: CountdownClockConfig;
  countdownEnd: string;
  id: string;
  typename: 'CountdownClock' | undefined;
}) => {
  const translate = useTranslate();
  const isDesktop = getIsDesktop();

  const {
    containerClassName = DEFAULT_CONTAINER_CLASS_NAME,
    labelClassName,
    showLabels,
    timeUnitClassName,
  } = config;

  const {
    days,
    hours,
    lessThanAMinuteRemaining,
    lessThanAnHourRemaining,
    minutes,
    seconds,
  } = useCountdown({ countdownEnd });

  const sharedValueClassNames = apply('font-bold');
  const sharedSeparatorClassNames = apply([showLabels && 'hidden']);
  const unboldDaysAndHours =
    lessThanAnHourRemaining || lessThanAMinuteRemaining;

  const countdownClockRef = useRef(null);
  const promotionId = id || '';
  const promotionName = '';
  const creativeName = typename || 'CountdownClock';
  const gaTrackData: GaTrackData = {
    id: promotionId,
    name: promotionName,
    creative: creativeName,
  };
  usePromotionView(countdownClockRef, gaTrackData, true);

  return (
    <time
      className={tw('flex', containerClassName)}
      role="timer"
      dateTime={countdownEnd}
      ref={countdownClockRef}
    >
      {days > 0 && (
        <>
          <TimeUnit
            value={days}
            label={translate('days')}
            showLabels={showLabels}
            labelClassName={labelClassName}
            timeUnitClassName={tw([
              sharedValueClassNames,
              unboldDaysAndHours && 'font-normal',
              timeUnitClassName,
            ])}
          />
          <TimeSeparator
            className={tw([sharedSeparatorClassNames, timeUnitClassName])}
          />
        </>
      )}
      <TimeUnit
        value={hours}
        label={translate('hours')}
        showLabels={showLabels}
        labelClassName={labelClassName}
        timeUnitClassName={tw([
          sharedValueClassNames,
          unboldDaysAndHours && 'font-normal',
          timeUnitClassName,
        ])}
      />
      <TimeSeparator
        className={tw([sharedSeparatorClassNames, timeUnitClassName])}
      />
      <TimeUnit
        value={minutes}
        label={translate(isDesktop ? 'minutes' : 'minutesAbbreviation')}
        showLabels={showLabels}
        labelClassName={labelClassName}
        timeUnitClassName={tw([
          sharedValueClassNames,
          lessThanAMinuteRemaining && 'font-normal',
          timeUnitClassName,
        ])}
      />
      <TimeSeparator
        className={tw([sharedSeparatorClassNames, timeUnitClassName])}
      />
      <TimeUnit
        value={seconds}
        label={translate(isDesktop ? 'seconds' : 'secondsAbbreviation')}
        showLabels={showLabels}
        labelClassName={labelClassName}
        timeUnitClassName={tw([sharedValueClassNames, timeUnitClassName])}
      />
    </time>
  );
};

const TimeUnit = ({
  value,
  label,
  showLabels = false,
  timeUnitClassName,
  labelClassName,
}: TimeUnitProps) => {
  const showLeadingZero = value < 10;
  return (
    <div className="flex flex-col items-center">
      <span className={timeUnitClassName} suppressHydrationWarning>
        {showLeadingZero && '0'}
        {value}
      </span>
      {showLabels && (
        <span className={labelClassName} suppressHydrationWarning>
          {label}
        </span>
      )}
    </div>
  );
};

const TimeSeparator = ({ className }) => (
  <div className={tw([apply`font-bold`, className])} suppressHydrationWarning>
    :
  </div>
);
