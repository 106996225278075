import { Maybe } from 'utils/types';
import { ProductObjectType, Puid } from 'groq/global-types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/ProductCarousel.tsx

export type ProductCarouselDocumentType = {
  _id: string;
  _type: string;
  puid?: Maybe<Puid>;
  header?: Maybe<LocaleStringType>;
  subHeader?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  products: Array<ProductObjectType>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
};

export const ProductCarouselDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    ${LocaleString('header')},
    ${LocaleString('subHeader')},
    ${LocaleString('copy')},
    puid,
    products,
    availableFrom,
    availableTo
  }
`;
