// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/Iframe.ts

export type IframeDocumentType = {
  id: string;
  _id: string;
  _type: string;
  src: string;
  fullScreen: boolean;
  height: number;
};

export const IframeDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    src,
    fullScreen,
    height,
  }
`;
