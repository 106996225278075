import React from 'react';

import { isServer } from 'utils/constants';
import { isOpenNow } from 'utils/dateCalculations';
import { Maybe } from '__generated__/graphql';
import { TransType, useTranslate } from 'hooks/useTranslations';

import { useSiteConfig } from '../useSiteConfig';

const addPreChat = async (
  t: TransType,
  token?: Maybe<string>,
  host?: Maybe<string>,
  mainScript?: Maybe<string>
) => {
  if (isServer || !token || !host || !mainScript) return null;

  const scriptTags = Array.from(document.getElementsByTagName('script'));
  if (scriptTags.some(scriptTag => scriptTag.id === 'preFreshChat')) return;

  const preChat = document.createElement('script');
  preChat.src = '/assets/preFreshChat.js';
  preChat.defer = true;
  preChat.async = false;
  preChat.id = 'preFreshChat';

  window.fcSettings = {
    token,
    host,
    config: {
      cssNames: { widget: 'custom_fc_frame', expanded: 'custom_fc_expanded' },
      headerProperty: { hideChatButton: true },
    },
    onInit: () => {
      window.fcPreChatform?.fcWidgetInit({
        mainbgColor: '#000000',
        maintxColor: '#ffffff',
        heading: t('freshChatHeading'),
        textBanner: t('freshChatTextBanner'),
        InfoPageLabel: t('freshChatInfoPageLabel'),
        InfoPageID: 'PRIVACY_POLICY',
        SubmitLabel: t('startChat'),
        fields: {
          field1: {
            type: 'name',
            label: 'Name',
            fieldId: 'name',
            required: 'yes',
            error: t('validName'),
          },
          field2: {
            type: 'email',
            label: 'Email',
            fieldId: 'email',
            required: 'yes',
            error: t('validEmail'),
          },
        },
        InfoPageURI: '/de/en/privacy-policy/PRIVACY_POLICY.html',
      });
    },
  };

  preChat.onload = () => {
    const freshChatScript = document.createElement('script');
    freshChatScript.src = mainScript;
    freshChatScript.defer = true;
    freshChatScript.async = false;

    document.body.appendChild(freshChatScript);
  };

  document.body.appendChild(preChat);
};

export const useFreshChat = () => {
  const t = useTranslate();
  const {
    liveChat,
    env: { freshChatHost, freshChatToken },
  } = useSiteConfig();

  const isAvailableForRegion = Boolean(freshChatHost && freshChatToken);
  const liveChatIsOpen = isOpenNow(liveChat?.openingHours);

  const openLiveChatWindow = React.useCallback(() => {
    if (!isAvailableForRegion) return;

    window.fcWidget?.open?.();
  }, [isAvailableForRegion]);

  React.useEffect(() => {
    addPreChat(t, freshChatToken, freshChatHost, liveChat?.script);
  }, [freshChatHost, freshChatToken, liveChat?.script, t]);

  return {
    openLiveChatWindow,
    liveChatEnabled: isAvailableForRegion && liveChatIsOpen,
  };
};
