import { HorizontalAlignment, VerticalAlignment } from '__generated__/graphql';

/** Converts a horizontal or vertical alignment to a flex direction. */
export const alignmentToFlexDirection = (
  x: HorizontalAlignment | VerticalAlignment
) =>
  ({
    top: 'start',
    left: 'start',
    center: 'center',
    middle: 'center',
    right: 'end',
    bottom: 'end',
  }[x]);
