// Generates a URL based on the provided category.

type Category = string | string[] | undefined;

export const getUrlByCategory = (category: Category, fallback?: string) => {
  return category
    ? Array.isArray(category)
      ? `/${category.join('/')}`
      : `/${category}`
    : fallback ?? '';
};
