import { StyliticsCarousel } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';

export type StyliticsHeaderProps = {
  header: StyliticsCarousel['header'];
};
const StyliticsHeader = ({ header }: StyliticsHeaderProps) => {
  const t = useTranslate();
  return (
    <h2
      id="completeTheLook"
      data-test-id="stylitics-header"
      className="font-bold text-xl sm:text-2xl pb-4 text-uppercase"
    >
      {!header ? t('completeTheLook') : header}
    </h2>
  );
};

export default StyliticsHeader;
