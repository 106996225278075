import { css } from 'twind/css';

export const styliticsCarouselStyles = css`
  /* 
    *  Stylitics CSS Table of Contents
    *   
    *  1.0 - Stylitics Carousel
    *    1.1 - Stylitics Chevron Button
    *    1.2 - Carousel
    *  2.0 - Stylitics Outfit
    *    2.1 - Outfit Flyout Menu
    *       2.1.1 - Outfit Product Details
    *    2.2 - Outfit Mobile Modal
    *  3.0 - Animations
  */

  /*
    ****
    * 1.0 Stylitics Carousel
    ****
    */

  /*
    ****
    * 1.1 - Stylitics Chevron Button
    * Component: web/ui/components/StyliticsSanity/StyliticsChevronButton.tsx
    ****
    */
  .stylitics-chevron-button {
    cursor: pointer;

    position: absolute;
    z-index: 1;
    top: calc(50% + 10px);
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 44px;
    height: 44px;
    padding: 0;

    font-size: 60px;
    font-weight: 300;

    background-color: #191919;
    border: none;
    border-radius: 50%;
  }

  .stylitics-chevron-button path {
    fill: none;
    stroke: rgb(0, 0, 0);
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-width: 2;
  }

  .stylitics-chevron-button:hover {
    opacity: 0.5;
  }

  .stylitics-chevron-button.stylitics-chevron-button-prev {
    left: 10px;
    transform: translateY(-50%);
  }

  .stylitics-chevron-button.stylitics-chevron-button-prev
    .stylitics-chevron-arrow-svg {
    transform: rotate(180deg);
    margin-right: 3px;
    margin-left: 0;
  }
  .stylitics-chevron-button.stylitics-chevron-button-next {
    right: 10px;
    transform: translateY(-50%);
  }

  .stylitics-chevron-arrow-svg {
    z-index: 1;

    overflow: visible;

    width: 8px;
    height: 15px;
    margin-left: 3px;
  }

  .stylitics-chevron-arrow-svg path {
    stroke: #ffffff !important;
  }

  /*
  ****
  * 1.2 - Carousel
  * Component: web/ui/components/StyliticsSanity/StyliticsCarouselContainer.tsx
  ****
  */
  .stylitics-carousel-group {
    display: grid;
    grid-gap: 16px;

    height: 100%;
    padding-top: 15px;

    background-color: transparent;
  }

  .stylitics-carousel-group.desktop {
    will-change: transform;

    transform: translateX(calc(-0px));

    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
    transition-property: transform;
  }
  /*
  ****
  * 2.0 Stylitics Outfit
  ****
  * The outfit component is shared between the carousel
  * and grid. It's the same concept as a "bundle" in the
  * StyliticsWidget.
  * Component: web/ui/components/StyliticsSanity/StyliticsOutfitContainer.tsx
  ****
  */
  .stylitics-card {
    position: relative;
    z-index: 0;

    display: inline-block;

    min-width: auto;
    height: 460px;
    max-height: 460px;
    padding: 0px;
  }

  .stylitics-card.active {
    z-index: 1;
  }

  .stylitics-card:first-child {
    margin-left: 0;
  }

  .stylitics-carousel-card-wrap {
    cursor: pointer;
    transition-timing-function: ease;
    transition-duration: 200ms;
    transition-property: opacity, transform;
  }

  @media (min-width: 1024px) {
    .stylitics-carousel-card-wrap.slide-left {
      transform: translateX(calc(-100% - 16px));
    }
  }

  .stylitics-outfit-container {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 5%;

    font-size: 14px;

    opacity: 1;
    background-color: #fff;
    border-color: #efefef;
    border-style: solid;
    border-width: thin;
  }

  .stylitics-image-container {
    position: relative;
    transform: translateX(calc(100%-20px));
    flex-grow: 1;
    width: 100%;
  }

  .stylitics-main-image {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 0;
  }

  .stylitics-main-image:focus,
  .stylitics-main-image:focus-within {
    outline-offset: -5px !important;
  }

  .stylitics-outfit-footer {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    justify-content: flex-end;

    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0;

    color: inherit;
    text-decoration: none;

    transition-timing-function: ease-out;
    transition-duration: 0.25s;
    transition-property: opacity;
  }

  .stylitics-outfit-footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stylitics-outfit-footer button:hover,
  .stylitics-outfit-footer button:focus {
    background: transparent;
    border-color: transparent;
  }

  .stylitics-outfit-footer button::after {
    all: unset;
  }

  /*
  ****
  * 2.1 - Outfit Flyout Menu
  * Component: web/ui/components/StyliticsSanity/StyliticsOutfitFlyout.tsx
  ****
  */
  .stylitics-outfit-details {
    top: 0;

    justify-content: flex-start;

    width: calc(100%+16px);

    background-color: white;
    border: 1px solid #efefef;
    border-left-color: transparent;

    animation: 0.5s forwards slideIn;
  }

  .stylitics-product-list-container {
    overflow-y: auto;
  }

  .stylitics-product-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
  }

  .stylitics-product-list:focus,
  .stylitics-product-list:focus-visible {
    outline: none;
  }

  .stylitics-product-list::-webkit-scrollbar-track {
    background: transparent;
  }

  .stylitics-product-list::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border: 3px solid transparent;
  }

  .stylitics-product-container {
    flex-grow: 1;

    margin-left: 3px;
    padding: 8px 0px 5px;

    font-size: 0px;
    list-style: none;

    border-bottom: 1px solid rgb(238, 238, 238);
  }

  .stylitics-product-container:last-child {
    border-bottom: none;
  }

  .stylitics-product-inner-container {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    min-height: 80px;
    margin-left: 3px;
    padding: 0;

    font-family: inherit;
    font-size: 0;
    text-align: left;

    background-color: #fff;
    background-image: none;
    border: none;
    border-style: none;
  }

  .stylitics-product-image-wrapper {
    position: relative;
  }

  .stylitics-product-image {
    cursor: pointer;

    display: flex;
    flex: 0 0 36%;
    align-items: flex-end;

    width: auto;
    min-width: 60px;
    height: 100%;
    margin: auto;

    background-repeat: no-repeat;
    background-position: center 10%;
    background-position-y: 10%;
    background-size: contain;
    border: none;
    outline: none;

    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: background-image;
  }

  .stylitics-close {
    cursor: pointer;

    position: absolute;
    top: -10px;
    right: -8px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;

    font-weight: 700;

    background: #f5f5f5;
    border: none;
    border-radius: 25px;
  }

  @media (max-width: 1024px) {
    .stylitics-close {
      cursor: pointer;

      position: absolute;
      z-index: 1;
      top: 24px;
      right: 27px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 36px;
      height: 36px;

      background: #f5f5f5;
      border-radius: 25px;
    }
  }

  .stylitics-close:hover {
    opacity: 0.5;
  }

  /*
  ****
  * 2.1.1 - Outfit Product Details
  * Component: web/ui/components/StyliticsSanity/StyliticsOutfitFlyout.tsx
  ****
  */
  .stylitics-product-info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;

    height: auto;
    padding: 0px 10px 10px 16px;

    font-size: 15.5px;
    font-weight: 400;
    line-height: 1.5;
    color: gray;
  }

  .stylitics-product-info p {
    margin: 0;
  }

  .stylitics-product-name {
    overflow: hidden;

    margin: 0;

    font-size: 16px;
    font-weight: 700;
    color: black;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .stylitics-product-name:hover {
    opacity: 0.5;
  }

  .stylitics-product-name-wrap {
    overflow: hidden;
    display: -webkit-box;

    max-height: 40px;

    line-height: 20px;
    white-space: normal;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .stylitics-product-price-and-cta {
    display: flex;
    flex-basis: 50%;
    flex-direction: row;
    flex-shrink: 1;
    align-items: center;
    justify-content: space-between;

    padding-top: 10px;
  }

  .stylitics-product-price {
    margin: 0;
  }

  .stylitics-product-regular-price {
    font-weight: 500;
    color: black;
  }

  .stylitics-product-regular-price-slashed {
    font-weight: 400;
    color: #767676 !important;
    text-decoration: line-through;
  }

  .stylitics-product-sale-price {
    font-weight: 500;
    color: black;
  }

  /*
  ****
  * 2.2 - Outfit Mobile Modal
  * Component: web/ui/components/StyliticsSanity/StyliticsOutfitFlyoutMobile.tsx
  ****
  */
  .stylitics-modal-container {
    touch-action: none;

    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.33);
  }
  .stylitics-modal {
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: min-content;
    max-height: 80%;

    background: white;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .stylitics-modal-product-list {
    overflow: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 25px;
  }
  .stylitics-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }
  .stylitics-modal-header h1 {
    font-size: 16px;
    font-weight: 500;
  }

  /*
  ****
  * 3.0 Animations
  ****
  * Simple animations for translating the flyout menu and fading.
  * TODO: Expand this to add a loading shimmer for product images.
  ****
  */
  .is-faded {
    opacity: 0.1;
  }

  .is-unfading {
    opacity: 1;
    transition-delay: 0ms;
  }

  @keyframes slideIn {
    0% {
      left: 0%;
      opacity: 0;
    }
    8% {
      left: 8%;
      opacity: 6.394884621840902e-14;
    }
    16% {
      left: 16%;
      opacity: 0.0005794679287529636;
    }
    24% {
      left: 24%;
      opacity: 0.004632407963000018;
    }
    33% {
      left: 33%;
      opacity: 0.015630625675027043;
    }
    41% {
      left: 41%;
      opacity: 0.03704592663705597;
    }
    49% {
      left: 49%;
      opacity: 0.07235011642130895;
    }
    58% {
      left: 58%;
      opacity: 0.12501500060000792;
    }
    66% {
      left: 66%;
      opacity: 0.19851238474537503;
    }
    74% {
      left: 74%;
      opacity: 0.296314074429632;
    }
    83% {
      left: 83%;
      opacity: 0.42189187522500116;
    }
    91% {
      left: 91%;
      opacity: 0.5787175927037039;
    }
    99% {
      left: 99%;
      opacity: 0.7702630324379629;
    }
    100% {
      left: 100%;
      opacity: 1;
    }
  }
`;
