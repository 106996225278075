import { Maybe } from 'utils/types';
import { LocaleUrl } from 'groq/shared/LocaleUrl';
import { ProductObjectType } from 'groq/global-types';

import { CampaignDocument, CampaignDocumentType } from './CampaignDocument';
import {
  InfoBannerDocument,
  InfoBannerDocumentType,
} from './InfoBannerDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/SecondGlobalBanner.ts

type CategoryExclusion = {
  id: string;
  url: string;
};
export type DisplayOnlyOnObject = {
  home: boolean;
  clp: boolean;
  plp: boolean;
  pdp: boolean;
};
export type InfoBannerWithCategoryExclusion = {
  _key: string;
  _type: string;
  banner?: Maybe<InfoBannerDocumentType>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  campaign?: Maybe<Array<Maybe<CampaignDocumentType>>>;
  coreLinkExclusions?: Maybe<Array<string>>;
  exclusions?: Maybe<Array<CategoryExclusion>>;
  productsExclusions?: Maybe<Array<ProductObjectType>>;
  displayOnlyOn?: Maybe<DisplayOnlyOnObject>;
  isSticky?: Maybe<boolean>;
  targetDevices?: Maybe<Array<string>>;
};

export type SecondGlobalBannerDocumentType = {
  banners: InfoBannerWithCategoryExclusion[];
};

export const SecondGlobalBannerDocument = `
  *[_id == lower($country) + "-SecondGlobalBanner"][0] {
    banners[] {
      _key,
      "_type": "infoBannerWithCategoryExclusion",
      ${InfoBannerDocument('banner->')},
      availableFrom,
      availableTo,
      ${CampaignDocument('campaign[] ->')},
      coreLinkExclusions,
      exclusions[] -> {
        id,
        ${LocaleUrl}
      },
      displayOnlyOn,
      productsExclusions,
      isSticky,
      targetDevices,
    }
  }
`;
