import { SUPPORTED_COUNTRY } from './constants';

declare global {
  interface Window {
    BrTrk?: BloomReachSdk; // Bloomreach track
    br_data?: BloomReachData; // Bloomreach data
  }
}

export type BloomReachSdk = {
  getTracker(): BloomReachTracker;
};
export type BloomReachTracker = {
  updateBrData(brData: BloomReachData): void;
  logPageView(): void;
  logEvent(
    subject: string,
    action: string,
    data: object,
    arg4?: object,
    arg5?: unknown
  ): void;
};
export type BloomReachData = {
  acct_id: unknown;
  ptype: unknown;
  title: unknown;
  view_id: unknown;
  user_id: unknown;
  test_data: unknown;
  cat_id: unknown;
  cat: unknown;
  search_term: unknown;
  prod_id: unknown;
  prod_name: unknown;
  sku: unknown;
  currency: unknown;
  is_conversion: unknown;
  basket_value: unknown;
  order_id: unknown;
  basket: unknown;
  customer_country: unknown;
  orig_ref_url: unknown;
  ref: unknown;
};

type CountryCode = Lowercase<SUPPORTED_COUNTRY>;

const CATALOGS_BY_COUNTRY_CODE = {
  [SUPPORTED_COUNTRY.US]: [
    { name: 'puma-master-na' },
    { name: 'puma-catalog-autocat-na' },
  ],
  [SUPPORTED_COUNTRY.CA]: [
    { name: 'puma-master-na' },
    { name: 'puma-catalog-autocat-ca' },
  ],
};

const getCatalogsByCountryCode = (countryCode: CountryCode) =>
  CATALOGS_BY_COUNTRY_CODE[countryCode.toUpperCase()] || [];

const segmentDataIfValid = (visitorCountryCode: string | null | undefined) => {
  return visitorCountryCode
    ? { segment: `customer_country:${visitorCountryCode.toUpperCase()}` }
    : {};
};

export const trackAddToCartInBloomreach = ({
  productId,
  sku,
  currency,
}: {
  productId: string;
  sku: string;
  currency: string;
}) => {
  const regionCurrencyHandler = window.location.href;
  if (!window.BrTrk) return;

  if (
    regionCurrencyHandler.includes('/au/en') ||
    regionCurrencyHandler.includes('/nz/en')
  ) {
    window.BrTrk.getTracker().logEvent('cart', 'click-add', {
      prod_id: productId,
      sku,
      currency,
    });
  } else {
    window.BrTrk.getTracker().logEvent('cart', 'click-add', {
      prod_id: productId,
      sku,
    });
  }
};

export const trackSearchEventInBloomreach = ({
  query,
  siteCountryCode,
  visitorCountryCode,
}: {
  query: string;
  siteCountryCode: CountryCode;
  visitorCountryCode?: string | null;
}) => {
  if (!window.BrTrk) return;
  window.BrTrk.getTracker().logEvent(
    'suggest',
    'submit',
    {
      q: query,
      catalogs: JSON.stringify(getCatalogsByCountryCode(siteCountryCode)),
      ...segmentDataIfValid(visitorCountryCode),
    },
    {},
    true
  );
};

export const trackSuggestEventInBloomreach = ({
  query,
  suggestion,
  siteCountryCode,
  visitorCountryCode,
}: {
  query?: string;
  suggestion: string;
  siteCountryCode: CountryCode;
  visitorCountryCode?: string | null;
}) => {
  if (!window.BrTrk) return;
  window.BrTrk.getTracker().logEvent(
    'suggest',
    'click',
    {
      q: suggestion,
      aq: query,
      catalogs: JSON.stringify(getCatalogsByCountryCode(siteCountryCode)),
      ...segmentDataIfValid(visitorCountryCode),
    },
    {},
    true
  );
};
