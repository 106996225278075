import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export type EventName_Type = string;

interface State {
  firedEvents: EventName_Type[];
  setFiredEvents: (value: EventName_Type[]) => void;
}

const _useFiredEventsStore = create<State>(set => ({
  firedEvents: [],
  setFiredEvents: value => set({ firedEvents: value }),
}));

// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export const useFiredEventsStore = () => {
  const { firedEvents, setFiredEvents } = _useFiredEventsStore(
    useShallow(state => ({
      firedEvents: state.firedEvents,
      setFiredEvents: state.setFiredEvents,
    }))
  );

  const setEventFired = (eventName: EventName_Type) =>
    setFiredEvents(
      Array.from(new Set<EventName_Type>([...firedEvents, eventName]))
    );

  const eventHasFired = (eventName: EventName_Type) =>
    firedEvents.some(x => x === eventName);

  return { eventHasFired, setEventFired };
};
