import { createContext, useCallback, useContext } from 'react';

import {
  pushItemForCountryToProductHistory,
  ProductHistoryItem,
  getRecentlyViewedProductsForCountry,
} from 'utils/productHistory';

import { useLocalStorage } from './useLocalStorage';
import { useSiteConfig } from './useSiteConfig';

type ProductHistoryContextProps = {
  recentlyViewedProducts: string[];
  pushItemToProductHistory: (item: ProductHistoryItem) => void;
};

export const PRODUCT_HISTORY_LOCAL_STORAGE_KEY =
  process.env.NEXT_PUBLIC_GQL_MOCKS === 'true'
    ? 'product-history-mocks'
    : 'product-history';

const ProductHistoryContext = createContext<ProductHistoryContextProps>(
  null as any
);

export const ProductHistoryProvider = (props: {
  children: React.ReactNode;
}) => {
  const { country } = useSiteConfig();

  const [productHistoryInLocalStorage, setProductHistoryInLocalStorage] =
    useLocalStorage(PRODUCT_HISTORY_LOCAL_STORAGE_KEY, {});

  const pushItemToProductHistory = useCallback(
    (item: ProductHistoryItem) => {
      const newProductHistory = pushItemForCountryToProductHistory(
        item,
        country,
        productHistoryInLocalStorage
      );
      setProductHistoryInLocalStorage(newProductHistory);
    },
    [productHistoryInLocalStorage, setProductHistoryInLocalStorage, country]
  );

  return (
    <ProductHistoryContext.Provider
      value={{
        get recentlyViewedProducts() {
          return getRecentlyViewedProductsForCountry(
            country,
            productHistoryInLocalStorage
          );
        },
        pushItemToProductHistory,
      }}
    >
      {props.children}
    </ProductHistoryContext.Provider>
  );
};

export const useProductHistory = () => {
  return useContext(ProductHistoryContext);
};
