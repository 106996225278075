import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import { StyliticsClassicWidget_Interface } from '../types/Stylitics';

interface State {
  widgetInstance: StyliticsClassicWidget_Interface | null;
  setWidgetInstance: (value: StyliticsClassicWidget_Interface) => void;
}

const _useWidgetInstanceStore = create<State>(set => ({
  widgetInstance: null,
  setWidgetInstance: value => set({ widgetInstance: value }),
}));

// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export const useWidgetInstanceStore = () => {
  const { widgetInstance, setWidgetInstance } = _useWidgetInstanceStore(
    useShallow(state => ({
      widgetInstance: state.widgetInstance,
      setWidgetInstance: state.setWidgetInstance,
    }))
  );

  return { widgetInstance, setWidgetInstance };
};
