import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  AnalyticsEvents,
  addPrefixToAnalyticProp,
  event,
  getPageType,
  transformImageUrlToImageInteractionAnalyticsEvent,
  transformListProductToAnalyticsItem,
} from 'utils/analytics';
import { getGa4Item } from 'utils/ga4Items';

import { useSiteConfig } from './useSiteConfig';
import { usePageEventsContext } from './usePageEventsContext';

type ItemListData = {
  itemListId: string | undefined;
  itemListName: string | undefined;
};

type ImageData = {
  imageType: string | undefined;
  imageNumber: string | undefined;
};

type FireSelectItemEvent = (itemId?: string) => void;

type GA4ContextType = {
  product?: any;
  setProduct: Dispatch<SetStateAction<any>>;
  imageType?: string;
  imageNumber?: string;
  fireSelectItemEvent: FireSelectItemEvent;
  fireClickProductImage: (source: string) => void;
  fireEventGA4ForImageOpened: ({ itemListId, itemListName }) => void;
};

export const initialState: GA4ContextType = {
  setProduct: () => null,
  fireSelectItemEvent: () => null,
  fireClickProductImage: () => null,
  fireEventGA4ForImageOpened: () => null,
};

/*
 This context is used to share common information between
 different components in order to trigger GA4 events
*/
export const GA4Context = createContext<GA4ContextType>(initialState);

const getImageTypeAndNumber = (): ImageData | undefined => {
  if (typeof window === 'undefined') return undefined;
  const image_type = localStorage.getItem('image_type');
  const image_number = localStorage.getItem('image_number');
  return {
    imageType: image_type === null ? undefined : image_type,
    imageNumber:
      image_number === null
        ? undefined
        : image_number !== 'unavailable'
        ? addPrefixToAnalyticProp(image_number)
        : undefined,
  };
};

type LinkProduct = {
  id: string;
  colorValue: string;
};

export const getProductFromLinkUrl = (
  linkUrl: string
): LinkProduct | undefined => {
  const pageType = getPageType(linkUrl);
  if (pageType != 'pdp') return undefined;
  const [route, params] = linkUrl.split('?');
  const routeArr = route.split('/');
  const masterProductId = routeArr[routeArr.length - 1];
  const colorValue = params.match(/swatch=(.*?)(&|$)/i)?.[1];
  if (masterProductId && colorValue)
    return {
      id: `${masterProductId}_${colorValue}`,
      colorValue,
    };
  return undefined;
};

export const GA4Provider = ({ children }: any) => {
  const { currency } = useSiteConfig();
  const [product, setProduct] = useState<any>();
  const { pageviewEventHasFired } = usePageEventsContext();

  const providerValue = useMemo(() => {
    const imageData = getImageTypeAndNumber();

    const fireSelectItemEvent: FireSelectItemEvent = itemId => {
      if (!product) return;
      const id =
        itemId || `${product.masterId}_${product.colorValue ?? product?.color}`;
      const ga4Item = getGa4Item(id);

      const item = transformListProductToAnalyticsItem({
        product,
        currency: currency.code,
        quantity: 1,
        categories: { item_category: product.primaryCategoryId },
        itemListId: ga4Item?.lid,
        itemListName: ga4Item?.lname,
        index: ga4Item?.idx,
        creativeName: ga4Item?.cname,
        creativeSlot: ga4Item?.cslot,
        promotionId: ga4Item?.pid,
        promotionName: ga4Item?.pname,
      });
      event(AnalyticsEvents.GA4EC_ItemListClick, {
        event_name: AnalyticsEvents.SELECT_ITEM,
        ecommerce: {
          item_list_id: ga4Item?.lid,
          item_list_name: ga4Item?.lname,
          item_id_ep: id || product.productId,
          item_name_ep: product?.name || product.productName,
          image_type: imageData?.imageType,
          image_number: imageData?.imageNumber,
          items: [item],
        },
      });
      setProduct(undefined);
    };

    const fireClickProductImage = (source: string) => {
      const { imageType, imageNumber } =
        transformImageUrlToImageInteractionAnalyticsEvent(source);

      window.localStorage.setItem('image_type', imageType);
      window.localStorage.setItem('image_number', imageNumber);
    };

    const fireEventGA4ForImageOpened = ({
      itemListId,
      itemListName,
    }: ItemListData) => {
      if (pageviewEventHasFired) {
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.GA4_IMAGE_OPENED,
          event_params: {
            item_id_ep: itemListId,
            item_name_ep: itemListName,
          },
        });
      }
    };

    return {
      product,
      imageType: imageData?.imageType,
      imageNumber: imageData?.imageNumber,
      setProduct,
      fireSelectItemEvent,
      fireClickProductImage,
      fireEventGA4ForImageOpened,
    };
  }, [product, currency.code, pageviewEventHasFired, setProduct]);

  return (
    <GA4Context.Provider value={providerValue}>{children}</GA4Context.Provider>
  );
};

export const useGA4Events = (): GA4ContextType => useContext(GA4Context);
