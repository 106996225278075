import React from 'react';
import { tw } from 'twind';
export interface ImgProps extends React.ComponentProps<'img'> {
  alt: string;
  width?: number;
  ratio?: `${number} / ${number}`;
}

export const Img: React.FC<ImgProps> = ({
  src,
  width,
  ratio = '1 / 1',
  className,
  ...rest
}) => {
  const [horizontalRatio, verticalRatio] = ratio.split(' / ').map(Number);
  const decimalAspectRatio = verticalRatio / horizontalRatio;
  const height = width && width * decimalAspectRatio;
  return (
    <img
      className={tw(
        'w-full bg-puma-black-800',
        `aspect-${horizontalRatio}-${verticalRatio}`,
        className
      )}
      src={src}
      width={width}
      height={height}
      loading="lazy"
      {...rest}
    />
  );
};
