import { useEffect } from 'react';

import { useLocation } from 'hooks/useLocation';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { SearchTermList } from 'ui/components/Search/SearchTermList';
import { SearchViewComponent } from 'ui/components/Search/types';
import { AnalyticsEvents, event } from 'utils/analytics';
import { trackSuggestEventInBloomreach } from 'utils/bloomreach';

import TrendingSearchTerms from './TrendingSearchTerms';

const SuggestedSearchTerms: SearchViewComponent = ({
  searchTerm,
  suggestionsResult,
  setIsLoading,
  setSessionStorageData,
}) => {
  const t = useTranslate();
  const { countryCode } = useSiteConfig();
  const { location } = useLocation();

  useEffect(() => {
    setIsLoading(suggestionsResult?.fetching ?? false);
  }, [setIsLoading, suggestionsResult?.fetching]);

  const trackSuggestedSearchTermClick = (suggestedTerm: string) => {
    if (!searchTerm || !suggestedTerm) return;

    if (setSessionStorageData)
      setSessionStorageData(t('suggestedSearchTerms'), suggestedTerm);

    // GA tracking
    event(AnalyticsEvents.SEARCH_SUGGESTED_SEARCH_TERM_CLICK, {
      suggested_term: suggestedTerm,
    });

    // bloomreach tracking
    trackSuggestEventInBloomreach({
      query: searchTerm,
      suggestion: suggestedTerm,
      siteCountryCode: countryCode,
      visitorCountryCode: location?.userCountry?.id,
    });
  };

  const suggestions = suggestionsResult?.data?.suggestions;
  if (!suggestions) return null;

  const terms =
    suggestions.suggestedTerms?.map(x => x.suggestion).slice(0, 8) ?? [];

  const phrases =
    suggestions.suggestedPhrases?.map(x => x.suggestion).slice(0, 8) ?? [];

  const termsToDisplay = terms.length > 0 ? terms : phrases;

  return termsToDisplay.length > 0 ? (
    <SearchTermList
      heading={t('suggestedSearchTerms')}
      terms={termsToDisplay}
      onClick={trackSuggestedSearchTermClick}
    />
  ) : (
    <TrendingSearchTerms
      setIsLoading={setIsLoading}
      setSessionStorageData={setSessionStorageData}
    />
  );
};

export default SuggestedSearchTerms;
