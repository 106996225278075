import React, { useState } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { Resources } from 'locales/localeType';
import CloseButton from 'ui/components/CloseButton';

import Modal from '../../../components/Modal';
import { CustomTextToModal } from '../types';

const CustomFormTextToModal = ({ field }: { field: CustomTextToModal }) => {
  const t = useTranslate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <a
        onClick={e => {
          e.preventDefault();
          setOpenModal(true);
        }}
        data-test-id={`text-to-modal-link:${field.name || ''}`}
      >
        <p className="font-bold cursor-pointer">
          {t(field.text as keyof Resources)}
        </p>
      </a>
      {openModal && (
        <Modal
          variant="dark"
          className="w-full max-w-screen-lg"
          onClickOutside={() => {
            setOpenModal(false);
          }}
        >
          <CloseButton
            className="absolute right-0 top-0 p-3"
            onClick={() => setOpenModal(false)}
          />
          <h3 className="border-b p-5 font-bold text-2xl mt-3 mb-2">
            {t(field.modalTitle as keyof Resources)}
          </h3>
          <p className="p-5">{t(field.modalDescription as keyof Resources)}</p>
        </Modal>
      )}
    </div>
  );
};

export default CustomFormTextToModal;
