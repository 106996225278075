import { CardFrameIcon } from '../CardFrameIcon';

export const CreditCardLogo = ({ type }: { type: string }) => {
  switch (type) {
    case 'visa':
      return <CardFrameIcon name="payment-visa" dataTestId="card-icon-visa" />;
    case 'mastercard':
      return (
        <CardFrameIcon
          name="payment-mastercard"
          dataTestId="card-icon-mastercard"
        />
      );
    case 'amex':
      return <CardFrameIcon name="payment-amex" dataTestId="card-icon-amex" />;
    case 'discover':
      return (
        <CardFrameIcon
          name="payment-discover"
          dataTestId="card-icon-discover"
        />
      );
    case 'maestro':
      return (
        <CardFrameIcon name="payment-maestro" dataTestId="card-icon-maestro" />
      );
    case 'jcb':
      return <CardFrameIcon name="payment-jcb" dataTestId="card-icon-jcb" />;
    case 'diners':
    case 'diners_club_carte_blanche':
    case 'diners_club_international':
      return (
        <CardFrameIcon
          name="payment-diners-club"
          dataTestId="card-icon-diners-club"
        />
      );
    default:
      return null;
  }
};
