import { MutableRefObject, useRef, useState } from 'react';

import { isServer } from 'utils/constants';

import { useIntersectionObserver } from './useIntersectionObserver';

export const useInView = (): [
  MutableRefObject<HTMLDivElement | null>,
  boolean
] => {
  const [inView, setInView] = useState(isServer ? true : false);
  const ref = useRef(null);
  useIntersectionObserver(ref, () => setInView(true), true, {
    threshold: 0.01,
    rootMargin: '0px 0px 20% 0px',
  });
  return [ref, inView];
};
