import React from 'react';
import { Stack } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import Modal from 'ui/components/Modal';

import CloseButton from './CloseButton';

export const CvvModal = ({ handleClose }: { handleClose?: () => void }) => {
  const t = useTranslate();
  const fallbackHandleClose = () => null;

  return (
    <Modal
      variant="dark"
      onClickOutside={handleClose}
      className="w-full max-w-md px-8 pb-8 pt-9 relative"
      dataTestId="cvv-hint-modal"
      containerClassName="z-[1000]"
    >
      <CloseButton
        onClick={handleClose ?? fallbackHandleClose}
        className="absolute top-8 right-5 !mt-0"
      />
      <Stack>
        <h1 className="text-xl font-bold uppercase w-11/12">
          {t('whereToFindCVV')}
        </h1>

        <h2 className="block text-lg font-bold">Visa, MasterCard</h2>
        <img
          src="/assets/cvv_visa.png"
          className="w-full max-w-64"
          width="420"
          height="267"
        />
        <p>{t('cvvVisaInstructions')}</p>
        <h2 className="block text-lg font-bold">American Express</h2>
        <img
          src="/assets/cvv_express.png"
          className="w-full max-w-64"
          width="420"
          height="267"
        />
        <p>{t('cvvAmexInstructions')}</p>
      </Stack>
    </Modal>
  );
};
