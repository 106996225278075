import React, { MutableRefObject, useEffect, useRef } from 'react';
import { tw } from 'twind';

import { VideoPlayerWithGA4Events } from './VideoPlayerWithGA4Events';

type ProductVideoOptions = {
  controls?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
};

export interface VideoProps extends React.ComponentProps<'video'> {
  className?: string;
  playsInline?: boolean;
  videoOptions: ProductVideoOptions;
}

export type ProductVideo = {
  __typename: string;
  src: string;
  videoOptions: ProductVideoOptions;
  muted?: boolean;
};

export const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  (props, ref) => {
    const {
      src,
      videoOptions,
      className = 'zoom-video',
      playsInline = true,
      muted,
    } = props;
    const isMuted = Boolean(muted || videoOptions.muted);

    const videoRef = ref as MutableRefObject<HTMLVideoElement> | null;
    const sourceRef = useRef<HTMLSourceElement | null>(null);

    useEffect(() => {
      // We need to manually change the `src`
      // After the initial server render the `src` never changes on the client
      // https://stackoverflow.com/questions/41303012/updating-source-url-on-html5-video-with-react
      if (!sourceRef.current?.getAttribute('src') && src) {
        sourceRef.current?.setAttribute('src', src);
        videoRef?.current?.load();
      }
    }, [src, videoRef, sourceRef]);

    useEffect(() => {
      // React does not guarantee setting attributes on <video /> elements
      // https://github.com/facebook/react/issues/10389

      if (
        videoRef?.current &&
        videoRef.current.hasAttribute('muted') !== isMuted
      ) {
        if (isMuted) {
          videoRef.current.setAttribute('muted', '');
        } else {
          videoRef.current.removeAttribute('muted');
        }
      }
    }, [videoOptions, videoRef, isMuted]);

    return (
      <VideoPlayerWithGA4Events
        ref={videoRef}
        className={tw(className)}
        controls={videoOptions.controls}
        autoPlay={videoOptions.autoPlay}
        loop={videoOptions.loop}
        muted={isMuted}
        playsInline={playsInline}
        controlsList="nodownload"
      >
        <source src={src} type="video/mp4" data-test-id="video-source" />
      </VideoPlayerWithGA4Events>
    );
  }
);
Video.displayName = 'Video';
