import { css } from 'twind/style';

export const sizeGuideStyles = css`
  h1 {
    @apply pt-2 lg:pt-6;
  }
  h2 {
    @apply my-6;
  }
  table {
    @apply text-center w-full;
  }
  thead {
    @apply text-lg border-b;
  }
  thead th {
    @apply p-3 border;
  }
  thead th:first-child {
    @apply pl-3;
  }
  tbody td {
    @apply p-3 border font-normal;
  }
  tbody td:first-child {
    @apply font-bold pl-3;
  }
  tbody td:last-child,
  thead th:last-child {
    @apply pr-3;
  }
  .measurements {
    @apply text-left;
  }
  .measurements__image {
    @apply sm:w-1/3;
  }
  .measurements__image > img {
    @apply w-full mx-auto;
  }
  .measurements__text {
    @apply sm:w-1/2;
  }
  .left {
    @apply text-left;
  }
  .center {
    @apply text-center;
  }
  hr,
  p {
    @apply my-0!;
  }
  details summary strong {
    @apply cursor-pointer;
  }
  details {
    @apply overflow-x-auto;
  }
`;

export enum TileSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
