import { Maybe } from 'utils/types';
import { Puid } from 'groq/global-types';
import {
  CallToActionObjectType,
  CallToActionObject,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObjectType,
  SanityImageObject,
} from 'groq/objects/SanityImageObject';
import { LocaleString } from 'groq/shared/LocaleString';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';

import {
  CountdownClockDocument,
  CountdownClockDocumentType,
} from './CountdownClockDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/InfoBanner.ts

export type InfoBannerDocumentType = {
  id: string;
  _id: string;
  backgroundColor?: Maybe<string>;
  copy?: Maybe<string>;
  countdownClock?: Maybe<CountdownClockDocumentType>;
  ctas?: Maybe<CallToActionObjectType[]>;
  disclaimer?: Maybe<LocalePortableTextType>;
  footnote?: Maybe<string>;
  header?: Maybe<string>;
  mobileBackgroundImage?: Maybe<SanityImageObjectType>;
  optionalBackgroundImage?: Maybe<SanityImageObjectType>;
  puid?: Maybe<Puid>;
  showCountdownBeneathSubheading?: Maybe<boolean>;
  textColor?: Maybe<string>;
};

export const InfoBannerDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    puid,
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${LocaleString('footnote')},
    "optionalBackgroundImage": ${SanityImageObject(
      'backgroundImage',
      'desktop'
    )},
    ${SanityImageObject('mobileBackgroundImage', 'mobile')},
    backgroundColor,
    textColor,
    ${LocalePortableText('disclaimer')},
    ${CallToActionObject('ctas[]')},
    ${CountdownClockDocument('countdownClock->')},
    showCountdownBeneathSubheading,
  }
`;
