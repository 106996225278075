import { gql } from 'urql';

import { ADDRESS_FRAGMENT } from 'gql/fragments/address';
import { SAVED_AMBASSADOR_CARD_FRAGMENT } from 'gql/fragments/ambassadorCards';
import { SAVED_CARD_FRAGMENT } from 'gql/fragments/creditCards';
import { ORDER_PREVIEW_FRAGMENT } from 'gql/fragments/standardOrder';
import { USER_FRAGMENT } from 'gql/fragments/user';
import { STORE_CREDIT_FRAGMENT } from 'gql/fragments/storeCredit';

export const ME_QUERY = gql`
  query Me(
    $includeAddresses: Boolean = false
    $includeOrders: Boolean = false
    $includeAmbassadorCards: Boolean = false
    $includeSavedCards: Boolean = false
    $includeGroups: Boolean = false
    $includeNewsletter: Boolean = false
    $includeStoreCredit: Boolean = false
  ) {
    me {
      ...userFields
      newsletterSubscription @include(if: $includeNewsletter) {
        firstName
        lastName
        firstNamePronunciation
        lastNamePronunciation
        email
        gender
        memberId
        dob {
          day
          month
          year
        }
        emailPreference
        shoppingPreference
        iframeURL
        isConfirmed
      }
      addresses @include(if: $includeAddresses) {
        ...addressFields
      }
      orders(limit: 5) @include(if: $includeOrders) {
        nodes {
          ...orderPreviewFields
        }
      }
      ambassadorCards @include(if: $includeAmbassadorCards) {
        ...ambassadorCardFields
      }
      savedCards @include(if: $includeSavedCards) {
        ...savedCreditCard
      }
      storeCredit @include(if: $includeStoreCredit) {
        ...storeCreditFields
      }
      groups @include(if: $includeGroups)
    }
  }

  ${USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${ORDER_PREVIEW_FRAGMENT}
  ${SAVED_AMBASSADOR_CARD_FRAGMENT}
  ${SAVED_CARD_FRAGMENT}
  ${STORE_CREDIT_FRAGMENT}
`;
