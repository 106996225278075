import { useRef } from 'react';
import { css, tw } from 'twind/css';

import { TrendingTile } from 'ui/content/TrendingTile';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import {
  TrendingInline as TrendingInlineType,
  TrendingTile as TrendingTileType,
} from '__generated__/graphql';
import { BannerLinkType } from 'utils/types';
import { LinkType } from 'utils/constants';

type TrendingTileSanityType = TrendingTileType & {
  link?: BannerLinkType;
  linkType?: LinkType;
};

type TrendingInlineSanityType = TrendingTileSanityType[];

type TrendingInlineProps = TrendingInlineType & {
  requiredTiles: TrendingInlineSanityType;
  _id?: string;
};

export const TrendingInline = (props: TrendingInlineProps) => {
  const trendingInlineRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: props._id || '',
    name: '',
    creative: props.__typename || 'TrendingInline',
  };

  usePromotionView(trendingInlineRef, gaTrackData, true);

  return (
    <section
      className="relative w-full"
      data-test-id="trending-inline-section"
      ref={trendingInlineRef}
    >
      <div className={tw('grid gap-5', layouts[props.requiredTiles.length])}>
        {props.requiredTiles.map((tile, idx) => {
          const area = 'abcd'[idx];
          return (
            <div
              key={`${tile.id}-${idx}`}
              className={tw(
                css`
                  grid-area: ${area};
                `
              )}
            >
              <TrendingTile {...tile} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

const grid = (tmpl: string) => {
  const template = tmpl.trim().split('\n');
  return css`
    grid-template-columns: repeat(${template[0].split(' ').length}, 1fr);
    grid-template-areas: ${template.join(' ')};
  `;
};

const layouts = {
  1: {
    '': grid(`
      'a'
    `),
  },
  2: {
    'mobile:': grid(`
      'a'
      'b'
    `),
    'tablet:': grid(`
      'a b'
    `),
    'desktop:': grid(`
      'a b'
    `),
  },
  3: {
    'mobile:': grid(`
      'a'
      'b'
      'c'
    `),
    'tablet:': grid(`
      'a b'
      'c c'
    `),
    'desktop:': grid(`
      'a b c'
    `),
  },
  4: {
    'mobile:': grid(`
      'a'
      'b'
      'c'
      'd'
    `),
    'tablet:': grid(`
      'a b'
      'c d'
    `),
    'desktop:': grid(`
      'a b c d'
    `),
  },
};
