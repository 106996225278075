import React from 'react';
import { tw } from 'twind';

import { Link } from 'ui/elements/Link';

type CallToActionProps = {
  id: string;
  title: string;
  link: {
    href: string;
    newtab?: boolean;
    query?: string;
  };
  variant?: 'primary' | 'secondary' | 'black' | 'white';
  outlined?: boolean;
  width?: 'equal' | 'full' | 'fixed';
  onClick?: () => void;
};

export const CallToAction = (props: CallToActionProps) => (
  <Link
    data-test-id="call-to-action"
    key={props.id}
    as="button"
    variant={props.variant || 'black'}
    outlined={!!props.outlined}
    className={tw(
      'whitespace-normal',
      props.width === 'equal' && 'flex-1',
      props.width === 'full' && 'w-full',
      props.width === 'fixed' && 'w-full max-w-64'
    )}
    href={props.link.href}
    newtab={!!props.link.newtab}
    query={props.link.query}
    onClickCTA={props.onClick}
  >
    {props.title}
  </Link>
);
