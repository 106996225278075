import { Puid } from 'groq/global-types';
import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/Campaign.ts

export type CampaignDocumentType = {
  _id: string;
  _type: 'Campaign';
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  customerGroups?: Maybe<string[]>;
  puid?: Maybe<Puid>;
  sourceCode?: Maybe<string>;
};

export const CampaignDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    availableFrom,
    availableTo,
    customerGroups,
    puid,
    sourceCode
  }
`;
