/**
 * An animation where the button appears from off the
 * left hand side of the screen, moving in with a slight
 * slant which straightens up at the end of the buttons
 * travel
 */
export const attentionButtonAppearAnimation = () => {
  return `
    @keyframes attention-button-appear {
      0% { left: -100%; transform: skew(14deg); }
      80% { left: 0; transform: skew(14deg); }
      100% { left: 0; transform: skew(0deg); }
    }

    animation: attention-button-appear 0.65s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    transform-origin: bottom;
    transition: transform 0.4s, left 0.4s;
    will-change: transform;
  `;
};

/**
 * Creates a border edge which will start off out of view
 * and slide in sequentially with the others, giving the
 * effect of drawing a thicker border around the button
 * starting top-right and drawing anti-clockwise, then
 * reversing, and repeating until the 6th time when the
 * thicker border remains
 *
 */
export const attentionButtonBorderAnimation = (
  borderSide: 'top' | 'right' | 'bottom' | 'left',
  type: 'before' | 'after'
) => {
  const thickness = 2;
  const offset = 2;

  let direction = '';
  let width = `calc(100% + ${thickness}px)`;
  let height = `calc(100% + ${thickness}px)`;
  let frames = [] as number[];

  switch (borderSide) {
    case 'top': {
      direction = 'left';
      height = `${thickness}px`;
      frames = [15, 20, 50, 55];
      break;
    }
    case 'right': {
      direction = 'top';
      width = `${thickness}px`;
      frames = [0, 5, 65, 70];
      break;
    }
    case 'bottom': {
      direction = 'right';
      height = `${thickness}px`;
      frames = [5, 10, 60, 65];
      break;
    }
    case 'left': {
      direction = 'bottom';
      width = `${thickness}px`;
      frames = [10, 15, 55, 60];
      break;
    }
  }

  const outOfView = `calc(calc(100% + ${thickness * 2}px) * -1)`;
  const inView = `-${offset}px`;

  return `
    clip-path: polygon(-${offset}px -${offset}px, -${offset}px calc(100% + ${offset}px), calc(100% + ${offset}px) calc(100% + ${offset}px), calc(100% + ${offset}px) -${offset}px);
    @keyframes attention-button-${borderSide}-border {
      ${frames[0]}% { ${direction}: ${outOfView}; }
      ${frames[1]}% { ${direction}: ${inView}; }
      ${frames[2]}% { ${direction}: ${inView}; }
      ${frames[3]}% { ${direction}: ${outOfView}; }
    }
    &::${type} {
      content: '';
      position: absolute;
      ${borderSide}: ${inView};
      ${direction}: ${outOfView};
      width: ${width};
      height: ${height};
      background-color: black;
      animation: attention-button-${borderSide}-border 10s infinite;
      animation-fill-mode: forwards;
      animation-iteration-count: 5.5;
      animation-delay: 2s;
    }
  `;
};
