import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  setDYContext,
  dyContextValues,
  dyContext,
  setDYUserData,
  setDYCart,
} from 'utils/dynamicYield';
import { MeQuery, CartQuery, VariantProduct } from '__generated__/graphql';

import { useSiteConfig } from './useSiteConfig';
import { useFeature } from './useFeature';

interface UseDynamicYieldContext {
  type?: dyContextValues;
  data?: Array<VariantProduct['ean']>;
  pause?: boolean;
  user?: MeQuery['me'];
  cart?: CartQuery['activeCart'];
}

export const useDynamicYieldContext = ({
  type,
  data,
  pause = false,
  user,
  cart,
}: UseDynamicYieldContext = {}) => {
  const router = useRouter();
  const { staticFeatures, locale } = useSiteConfig();

  const dataEmpty = data?.every(element => !element);
  const cartQueryDY = useFeature('CART_QUERY_DYNAMIC_YIELD');

  useEffect(() => {
    if (!staticFeatures.injectDynamicYieldScripts) return;

    const isProductPage = router.asPath.includes('/pd');
    const isCartPage = router.asPath.includes('/cart');
    const isCategoryPage = !!router.query?.category;
    const isHomePage =
      router.pathname === '/[country]/[language]' && !isCategoryPage;
    const isOtherPage =
      !isProductPage && !isCartPage && !isCategoryPage && !isHomePage;

    const lng = staticFeatures.setDyLanguageInEnglish
      ? locale.replace('-', '_')
      : undefined;

    if ((isProductPage || isCategoryPage) && dataEmpty) return;

    if (!pause && type) {
      const newData = isHomePage ? undefined : data;
      setDYContext(type, newData, lng);
    }
    if (!pause && user) {
      setDYUserData(user.firstName, user.lastName);
    }
    if (isOtherPage) {
      setDYContext(dyContext.OTHER, undefined, lng);
    }

    if (!pause && cart && cartQueryDY) {
      setDYCart(cart);
    }
  }, [
    staticFeatures.injectDynamicYieldScripts,
    staticFeatures.setDyLanguageInEnglish,
    type,
    dataEmpty,
    router.asPath,
    locale,
    router.query?.category,
    pause,
    data,
    router.pathname,
    user,
    cart,
    cartQueryDY,
  ]);
};
