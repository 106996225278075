import type { NextRouter, Router } from 'next/router';
import type { CompletePrivateRouteInfo } from 'next/dist/shared/lib/router/router';

import { isServer } from './constants';

export function isInitialClientRender(router: NextRouter): boolean {
  if (isServer) return false;
  // The `components` field is only defined on the base Router type:
  const routeInfo = (router as Router)?.components?.[router.pathname];
  const r = routeInfo as Exclude<typeof routeInfo, CompletePrivateRouteInfo>;
  return !!r.initial;
}
