import { useEffect, useState } from 'react';

import { useSiteConfig } from 'hooks/useSiteConfig';
import { formatForterWebId, retrieveWebId } from 'utils/forter';

const ForterWebId = () => {
  const { env } = useSiteConfig();
  const [webIdToDisplay, setWebIdToDisplay] = useState<string | null>(null);

  const handleWebIdChange = e => {
    setWebIdToDisplay(e.detail);
  };

  useEffect(() => {
    if (!env.forterSiteId) return;
    setWebIdToDisplay(retrieveWebId());

    document.addEventListener('ftr:customerWebIdReady', handleWebIdChange);
    return () => {
      document.removeEventListener('ftr:customerWebIdReady', handleWebIdChange);
    };
  }, [env.forterSiteId]);

  if (!env.forterSiteId || !webIdToDisplay) return null;

  // formats the number to be in 3-digit groups (e.g., 000 000 000)
  const formattedWebId = formatForterWebId(webIdToDisplay);

  return (
    <div data-test-id="forter-web-id">
      Web ID: <span className="whitespace-nowrap">{formattedWebId}</span>
    </div>
  );
};

export default ForterWebId;
