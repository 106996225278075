import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { usePageEventsContext } from 'hooks/usePageEventsContext';
import {
  VIDEO_GA4_EVENT_PERCENTAGES,
  VIDEO_GA4_EVENT_PERCENTAGE_TYPE,
} from 'utils/constants';
import { useOnRouterChangeStart } from 'hooks/useOnRouterChangeStart';
import {
  firePreviousAndCurrentGA4VideoEvents,
  GA4VideoDataType,
} from 'utils/ga4VideoEvents';
import { useFiredEventsStore } from 'store';

type VideoPlayerWithGA4EventsProps = {
  children?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

// when import normally use this component
// this is a wrapper of VideoPlayerWithGA4Events_Child and only add the hook of pageEventContext
// NOTE: maybe in a future we can save and consume the pageviewEventHasFired with zustand
export const VideoPlayerWithGA4Events = forwardRef<
  HTMLVideoElement,
  VideoPlayerWithGA4EventsProps
>((props, ref: React.Ref<HTMLVideoElement>) => {
  const { pageviewEventHasFired } = usePageEventsContext();

  return (
    <VideoPlayerWithGA4Events_Child
      {...props}
      {...{ pageviewEventHasFired, ref }}
    />
  );
});
VideoPlayerWithGA4Events.displayName = 'VideoPlayerWithGA4Events';

// when testing use this export
// this is the component without using pageEventContext hook so we can hardcode the pageviewEventHasFired when testing
export const VideoPlayerWithGA4Events_Child = forwardRef<
  HTMLVideoElement,
  VideoPlayerWithGA4EventsProps & {
    pageviewEventHasFired: boolean;
    onVideoStartCallback?: () => void;
  }
>(
  (
    {
      children,
      // ───────────────────
      pageviewEventHasFired,
      onVideoStartCallback, // NOTE: this is only for testing purposes, to let storybook know when the video starts and use timeouts to check the fired events
      ...props
    },
    ref: React.Ref<HTMLVideoElement>
  ) => {
    const { eventHasFired, setEventFired } = useFiredEventsStore();

    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoDuration, setVideoDuration] = useState<number>(0);
    const [videoProgress, setVideoProgress] = useState<number>(-1);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [videoTitle, setVideoTitle] = useState<string>('');
    const [firstTime, setFirstTime] = useState<boolean>(true);

    const [currentPercentage, setCurrentPercentage] = useState<
      VIDEO_GA4_EVENT_PERCENTAGE_TYPE | undefined
    >(undefined);

    useOnRouterChangeStart(
      useCallback(() => {
        if (pageviewEventHasFired) {
          // when page start to change -> reset current local state
          // this avoid problems of fire again the events previously cleaned on pageEventContext
          setVideoProgress(-1);
          setCurrentPercentage(undefined);
          setVideoCurrentTime(0);
          setVideoDuration(0);
        }
      }, [pageviewEventHasFired])
    );

    const onTimeUpdate = useCallback(
      (e: React.SyntheticEvent<HTMLVideoElement>) => {
        const video = e.target as HTMLVideoElement;
        const duration = video.duration || 0;
        const currentTime = video.currentTime || 0;

        if (duration) {
          const toFixed0 = (value: number) => parseInt(value.toFixed(0), 10);
          const percent = toFixed0((currentTime / duration) * 100);

          setVideoProgress(parseInt(String(percent), 10));
          setVideoCurrentTime(currentTime);
          if (video.currentSrc && firstTime) {
            setVideoDuration(duration);
            setVideoUrl(video.currentSrc);
            setVideoTitle(video.title);
            setFirstTime(false);
          }
        }
      },
      [firstTime]
    );

    useEffect(() => {
      // get the "current" percentage from the percentages (0, 10, 25, 50, 75, 100)
      if (pageviewEventHasFired) {
        const percentageToFire = [...VIDEO_GA4_EVENT_PERCENTAGES]
          .reverse()
          .find(percentage => {
            if (videoProgress >= parseInt(percentage)) {
              return percentage;
            } else if (videoProgress === 99 && parseInt(percentage) === 100) {
              // catch cases when videoProgress is 99.99 and 100% never reached
              return '100';
            } else return null;
          });

        setCurrentPercentage(percentageToFire);
      }
    }, [videoProgress, pageviewEventHasFired]);

    const getVideoData = useCallback(async (): Promise<GA4VideoDataType> => {
      return {
        video_current_time: videoCurrentTime.toFixed(2),
        video_duration: videoDuration.toFixed(2),
        video_percent: 0, // the percentage will be set on the event
        video_provider: 'sanity',
        // NOTE: if some sanity videos doesn't have title -> send it as undefined
        video_title: !videoTitle ? undefined : videoTitle,
        video_url: videoUrl,
        visible: `${true}`,
      };
    }, [videoTitle, videoUrl, videoCurrentTime, videoDuration]);

    useEffect(() => {
      // only call function to fire event when the current percentage changes (0, 10, 25, 50, 75, 100)
      if (pageviewEventHasFired) {
        if (currentPercentage !== undefined) {
          firePreviousAndCurrentGA4VideoEvents({
            percentage: currentPercentage,
            eventHasFired,
            setEventFired,
            getVideoData,
            onVideoStartCallback,
          });
        }
      }
      // NOTE: do not add eventHasFired neither setEventFired to the dependencies array
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPercentage, pageviewEventHasFired, getVideoData]);

    return (
      <div data-test-id="video-player-with-ga4-events">
        <video
          {...props}
          ref={ref}
          onTimeUpdate={onTimeUpdate}
          onEnded={() => setCurrentPercentage('100')}
        >
          {children}
        </video>
      </div>
    );
  }
);
VideoPlayerWithGA4Events_Child.displayName = 'VideoPlayerWithGA4Events_Child';
