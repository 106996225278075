import { Maybe } from '__generated__/graphql';
import { CategoryByUrl } from 'groq/documents/Category';
import { CategoryPageByUrl } from 'groq/pages/CategoryPage';
import { MarketingPageByUrl } from 'groq/pages/MarketingPage';

type PageTypeFields = {
  _id: Maybe<string>;
  _type: Maybe<string>;
  online: Maybe<boolean>;
  onlineTo: Maybe<string>;
  onlineFrom: Maybe<string>;
};

export type PageType = {
  CategoryPage: PageTypeFields | null;
  MarketingPage: PageTypeFields | null;
  category: PageTypeFields | null;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    PageType: PageType;
  }
}

const PageTypeFields = `{
  _id,
  _type,
  online,
  onlineFrom,
  onlineTo,
}`;

export const PageTypeQuery = `{
  "MarketingPage": *${MarketingPageByUrl}[0] ${PageTypeFields},
  "CategoryPage": *${CategoryPageByUrl}[0] ${PageTypeFields},
  "category": *${CategoryByUrl}[0] ${PageTypeFields},
}`;
