import { tw } from 'twind';
import { Icon } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';

import { linkClassNames } from './shared';

export const PumaTracLink = ({ onClick }: { onClick?: () => void }) => {
  const t = useTranslate();
  return (
    <a
      rel="noopener noreferrer"
      href="http://pumatr.ac/app"
      target="_blank"
      data-test-id="puma-trac-footer-link"
      className={tw(linkClassNames)}
      onClick={onClick}
      title={t('tracAppTitle')}
    >
      <Icon name="puma-trac" size="7xl" color="base" invert className="p-3" />
    </a>
  );
};
