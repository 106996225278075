import { SearchViewScenario } from 'ui/components/Search/types';

export const SEARCH_DEBOUNCE_MS = 500;
export const RETURNING_USER_THRESHOLD_VIEWED_PRODUCT_COUNT = 4;

export const getSearchViewScenario = (
  recentlyViewedProducts: unknown[],
  hasSearchTerm: boolean
): SearchViewScenario => {
  if (
    recentlyViewedProducts.length >=
    RETURNING_USER_THRESHOLD_VIEWED_PRODUCT_COUNT
  ) {
    return SearchViewScenario.ReturningUser;
  } else {
    return hasSearchTerm
      ? SearchViewScenario.SearchResults
      : SearchViewScenario.NewUser;
  }
};
