import React, { useEffect, useRef, useState } from 'react';
import { tw, css } from 'twind/css';

const dot = css`
  position: relative;
  width: 0.9em;
  height: 0.9em;
  border-radius: 50%;
  background-color: currentColor;
  transform: scale(0);
`;

const dotPlaying = css`
  animation: dotPulse 1.3s infinite linear;
  @keyframes dotPulse {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(1);
    }
    80%,
    100% {
      transform: scale(0);
    }
  }
`;

const dotOne = css`
  animation-delay: 0s;
`;

const dotTwo = css`
  animation-delay: 0.25s;
`;

const dotThree = css`
  animation-delay: 0.5s;
`;

export const DotAnimation = ({
  className,
  on,
}: {
  className?: string;
  on?: boolean;
}) => {
  const [playing, setPlaying] = useState<boolean | undefined>(false);
  const playingTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => {
    playingTimeoutRef.current && clearTimeout(playingTimeoutRef.current);

    playingTimeoutRef.current = setTimeout(
      () => {
        setPlaying(on);
      },
      on ? 0 : 300
    );

    return () => {
      playingTimeoutRef.current && clearTimeout(playingTimeoutRef.current);
    };
  }, [on, setPlaying]);

  return playing ? (
    <div
      className={tw(
        className,
        'flex space-x-1.5 transition-opacity duration-300',
        on ? 'opacity-100' : 'opacity-0'
      )}
    >
      <div className={tw(dot, playing && dotPlaying, dotOne)}></div>
      <div className={tw(dot, playing && dotPlaying, dotTwo)}></div>
      <div className={tw(dot, playing && dotPlaying, dotThree)}></div>
    </div>
  ) : null;
};
