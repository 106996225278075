import { HeadersContent } from 'groq/global-types';
import { SlotsGroqQuery } from 'groq/ContentSlots';
import { LocaleSeo, LocaleSeoType } from 'groq/shared/LocaleSeo';
import { ComponentWithFilters, Maybe } from '__generated__/graphql-sanity';

export type MarketingPageContent = {
  _id: string;
  _type: 'MarketingPage';
  seo: LocaleSeoType;
  content: Maybe<Array<Maybe<ComponentWithFilters>>>;
  online: Maybe<boolean>;
  onlineFrom: Maybe<string>;
  onlineTo: Maybe<string>;
  headersContent: HeadersContent;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    MarketingPage: MarketingPageContent;
  }
}

export const MarketingPageByUrl = `
  [country == $country]
  [_type == "MarketingPage"]
  [url.current == $url]
`;

export const MarketingPageQuery = `
  *${MarketingPageByUrl}[0] {
    _id,
    _type,
    "online": true,
    "onlineFrom": null,
    "onlineTo": null,
    ${LocaleSeo('seo')},
    content[] ${SlotsGroqQuery()},
    headersContent,
  }
`;
