import { useState } from 'react';

import { useTranslate } from 'hooks/useTranslations';
import { ProductMeasurements, SizeChart } from '__generated__/graphql';
import { useFeature } from 'hooks/useFeature';

import { SizeTable } from './SizeTable';

type ProductMeasurementsTableProps = {
  productMeasurements: ProductMeasurements;
  measurementMessages?: SizeChart[];
  productDivision?: string | null;
};

export const ProductMeasurementsTable = ({
  productMeasurements,
  measurementMessages,
  productDivision,
}: ProductMeasurementsTableProps) => {
  const t = useTranslate();
  const showMetricAsDefault = useFeature('SHOW_METRIC_AS_DEFAULT');
  const hideWeightInProductMeasurements = useFeature(
    'HIDE_WEIGHT_IN_PRODUCT_MEASUREMENTS'
  );
  const [metric, setMetric] = useState(showMetricAsDefault ?? false);

  const measurementMessagesAsDefault =
    showMetricAsDefault && measurementMessages;

  const unitSwitcher = (
    <div className="uppercase text-xs font-bold cursor-pointer ml-auto">
      <span
        className={`pr-4 ${metric ? 'text-puma-black-400' : ''}`}
        onClick={() => setMetric(false)}
      >
        {t('inches')}
      </span>
      <span
        className={`${!metric ? 'text-puma-black-400' : ''}`}
        onClick={() => setMetric(true)}
      >
        {t('cm')}
      </span>
    </div>
  );

  let metricMeasurements = productMeasurements?.metric;
  let imperialMeasurements = productMeasurements?.imperial;

  //Hide Weight column for India on Footwear
  if (hideWeightInProductMeasurements && productDivision === 'Footwear') {
    metricMeasurements = metricMeasurements?.map(subArray =>
      subArray.filter(
        (_, index) => productMeasurements?.metric?.[0][index] !== 'Weight'
      )
    );

    imperialMeasurements = imperialMeasurements?.map(subArray =>
      subArray.filter(
        (_, index) => productMeasurements?.imperial?.[0][index] !== 'Weight'
      )
    );
  }

  return (
    <>
      <SizeTable
        title={t('productMeasurements')}
        data={metricMeasurements}
        hidden={!metric}
        measurementMessages={measurementMessagesAsDefault || []}
        showMetricAsDefault={showMetricAsDefault}
      >
        {unitSwitcher}
      </SizeTable>
      <SizeTable
        title={t('productMeasurements')}
        data={imperialMeasurements}
        hidden={metric}
        measurementMessages={measurementMessagesAsDefault || []}
        showMetricAsDefault={showMetricAsDefault}
      >
        {unitSwitcher}
      </SizeTable>
    </>
  );
};
