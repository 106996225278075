import { StyliticsLook } from '__generated__/graphql';
import { TileWithPositionDocumentType } from 'groq/objects/TileWithPosition';

import StyliticsOutfitContainer from './StyliticsOutfitContainer';
import StyliticsPromoTile from './StyliticsPromoTile';
type Tile = TileWithPositionDocumentType | StyliticsLook;
type Props = {
  tiles: Tile[];
};

const StyliticsTileList = ({ tiles }: Props) => {
  const isPromoTile = (tile: Tile): tile is TileWithPositionDocumentType => {
    return (tile as TileWithPositionDocumentType)._type === 'tileWithPosition';
  };
  return (
    <>
      {tiles?.map((tile, index) => {
        return isPromoTile(tile) ? (
          <StyliticsPromoTile key={tile._key} {...tile.tile} />
        ) : (
          <StyliticsOutfitContainer
            key={tile.id}
            outfit={tile as StyliticsLook}
            index={index}
          />
        );
      })}
    </>
  );
};

export default StyliticsTileList;
