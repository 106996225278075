import React, { useCallback } from 'react';
import { InputCombobox } from '@global-ecom/nitro-uds/elements';

import { isServer } from 'utils/constants';
import { useFeature } from 'hooks/useFeature';
import { useGoogleServices } from 'hooks/useGoogleServices';
import { debounce } from 'utils/debounce';
import {
  transformPlaces,
  TransformedPlacesResult,
} from 'utils/transformPlaces';

interface AddressLookupCompProps {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  defaultValue?: string | number;
  maxLength?: number;
  required?: boolean;
  errorText?: string;
  onSelect?: (props: TransformedPlacesResult) => void;
  disabled?: boolean;
  dataTestId?: string;
  lookupConstraints: string[];
  invert?: boolean;
}

type SelectProps = { id: string; [x: string]: any };
type FilterProps = {
  value: string;
  setOptions: (options: ReturnType<typeof generateOptions>) => void;
};

export const generateOptions = (
  addresses: google.maps.places.AutocompletePrediction[]
) => {
  return addresses.map((address, index) => {
    return {
      id: address.place_id ?? `${address.description}-${index}`,
      value: address.description,
    };
  });
};

export const checkPlaceDetailsRoute = (
  placeDetails: google.maps.places.PlaceResult
) => {
  // If API returns a 'route', pass through the street number from the original input
  if (placeDetails.types?.find(type => type === 'route')) {
    const newAddressComponent = {
      long_name: '',
      short_name: '',
      types: ['street_number'],
    };
    placeDetails.address_components?.unshift(newAddressComponent);
  }

  return placeDetails;
};

const AddressLookupComp = (
  { onSelect, lookupConstraints, ...rest }: AddressLookupCompProps,
  ref
) => {
  const googleAutocompleteEnabled = useFeature('GOOGLE_ADDRESS_LOOKUP');
  const { autoComplete, getPlaceDetails } = useGoogleServices();
  const onOptionSelect = async (address: SelectProps) => {
    try {
      let placeDetails = await getPlaceDetails(address.id);
      placeDetails = checkPlaceDetailsRoute(placeDetails);
      const { address1, ...rest } = transformPlaces(placeDetails);
      if (onSelect) {
        onSelect({ address1, ...rest });
      }
    } catch {}
  };

  const filter = useCallback(
    ({ value, setOptions }: FilterProps) => {
      const shouldAutocomplete =
        !isServer && googleAutocompleteEnabled && value?.length > 0;
      if (shouldAutocomplete) {
        autoComplete(value, lookupConstraints).then(addr =>
          setOptions(generateOptions(addr))
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lookupConstraints]
  );

  const debouncedFilter = debounce(filter, 250);

  return (
    <InputCombobox
      {...rest}
      typeahead={true}
      onOptionSelect={onOptionSelect}
      filter={debouncedFilter}
      ref={ref}
    />
  );
};

export const AddressLookup = React.forwardRef<any, AddressLookupCompProps>(
  AddressLookupComp
);
