import { OperationDefinitionNode } from '@0no-co/graphql.web';
import { CombinedError, Operation } from 'urql';

/**
 * Returns the name of the graphql operation the client
 * assigned to the query
 * @param operation The graphql operation
 * @returns The name of the operation
 */
export const getOperationName = (operation: Operation) =>
  (
    operation.query.definitions.find(definition => {
      return definition.kind === 'OperationDefinition';
    }) as OperationDefinitionNode
  ).name?.value;

/**
 * Returns true if the API response contains an
 * Authentication Error
 */
export const hasGraphQLAuthError = (error: CombinedError) => {
  return error.graphQLErrors.some(
    e =>
      e.extensions?.code === 'UNAUTHENTICATED' ||
      e.extensions?.code === 'FORBIDDEN'
  );
};
