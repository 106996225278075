import { DisplayOutOfStock, Variant } from '__generated__/graphql';

import { ProductTypeFromPDPQuery } from './product';

export enum Dimensions {
  d14 = 'dimension14', // Product Name
  d15 = 'dimension15', // Product master id
  d16 = 'dimension16', // Product Sport Name
  d17 = 'dimension17', // Product Technology
  d18 = 'dimension18', // Product Gender
  d19 = 'dimension19', // Product ageGroup
  d20 = 'dimension20', // Product lineName
  d21 = 'dimension21', // Product businessSegment
  d22 = 'dimension22', // Product division
  d23 = 'dimension23', // Product articleGroup
  d24 = 'dimension24', // Product articleType
  d25 = 'dimension25', // Product colorDescription
  d27 = 'dimension27', // Stylitics outfitID
  d76 = 'dimension76', // Product colorName
  d79 = 'dimension79', // Product businessUnit
  d80 = 'dimension80', // Product businessSegment
  d81 = 'dimension81', // Product franchise
  d82 = 'dimension82', // Product collectionMark
  d88 = 'dimension88', // Variant sizeName
  d92 = 'dimension92', // displayOutOfStock
  d94 = 'dimension94', // Variant assortmentAvailability
  d96 = 'dimension96', // Variant is product marked down
  d97 = 'dimension97', // Variantor is orderable
  d100 = 'dimension100', // Product Discovery
}

export type AnalyticsDimensions = {
  // arbitrary "dimension" properties for product analytics events
  [key in Dimensions]?: string;
};

export type BaseAnalyticsProduct = AnalyticsDimensions & {
  id: string; // style_id
  variant: string;
  ean: string;
  name: string;
  price: number;
  brand: string;
  category: string;
};

export type PageViewPayload = {
  pageTitle: string;
  searchQuery?: string;
  breadcrumbs?: string;
  language: string;
  country: string;
  currentURL: string | undefined;
  customerId: string;
  customerAuth: boolean;
  customerEmail: string | undefined;
  customerNo: string | undefined;
  cart?: {
    items: Array<any>;
    total: number;
    items_qty: number;
  };
};

export type AnalyticsPageType =
  | 'order confirmation'
  | 'payment'
  | 'checkout'
  | 'pdp'
  | 'account'
  | 'search'
  | 'other'
  | 'cart'
  | 'content'
  | 'home'
  | 'plp'
  | 'clp'
  | 'mlp'
  | 'elevated pdp'
  | null
  | undefined;

export type pathType = 'page path' | 'query string';

export type AnalyticsProduct = BaseAnalyticsProduct & {
  list?: string;
  position?: number;
  full_price?: number | null;
};

export type AnalyticsCartProduct = AnalyticsProduct & {
  quantity?: number;
  recipient_email?: string;
  recipient_name?: string;
  unitDiscount?: number;
  imageUrl: string;
  taxRate?: number;
  coupon?: string;
};

export type AnalyticsProductImage = {
  imageURL: string;
  imageRegion: string;
  imageType: string;
  imageNumber: string;
  productID: string;
  styleID: string;
  colorID: string;
};

export enum ItemAvailabilitySchema {
  instock = 'InStock',
  soldout = 'SoldOut',
  presale = 'PreOrder',
  backsoon = 'OutOfStock',
}

export type ItemAvailabilityValue = `${ItemAvailabilitySchema}`;

export type AdditionalDimensionArgs = {
  sizeName?: string;
  displayOutOfStock?: DisplayOutOfStock | null;
  assortmentAvailability?: string | null;
  isProductMarkedDown?: boolean;
  orderable?: boolean | null;
  ean?: string | null;
};

export type VariantAvailability =
  | 'unavailable'
  | 'available'
  | 'partially available';

export type ProductToAnalyticsProductType = {
  categoryId?: string;
  desiredSize?: string;
  includeExtraAnalyticsDimensions?: boolean;
  product: ProductTypeFromPDPQuery;
  variant: Variant;
};

export enum SignUpFormAnalyticsIdentifiers {
  EMAIL_SIGNUP = 'EMAIL_SIGNUP',
  SMS_OPT_IN = 'SMS_OPT_IN',
}

export enum SignUpActions {
  Closed = 'signup closed',
  ButtonDismissed = 'signup button dismissed',
  Error = 'signup error',
  Impression = 'signup impression',
  ModalImpression = 'signup modal impression',
  Opened = 'signup opened',
  Submit = 'signup submit',
  ToggleOn = 'signup toggled on',
  ToggleOff = 'signup toggled off',
}

export type SignUpFormAnalyticsEventType = {
  identifier?: SignUpFormAnalyticsIdentifiers;
  action: SignUpActions;
  location: SignUpLocationsKey | undefined;
};

export enum SignUpLocations {
  CartNewsletterSignUp = 'cart newsletter sign up',
  OrderConfirmation = 'order confirmation',
  SignUpFormHero = 'sign up form hero',
  SignUpFormPDPComingSoon = 'sign up form pdp coming soon',
  SiteFooter = 'site footer',
  StickySignUpButton = 'sticky sign up button',
  MyAccount = 'my account',
}

export type SignUpLocationsKey = keyof typeof SignUpLocations;

export enum EmailSignUpActions {
  Closed = 'email signup closed',
  ButtonDismissed = 'email signup button dismissed',
  Error = 'email signup error',
  Impression = 'email signup impression',
  ModalImpression = 'email signup modal impression',
  Opened = 'email signup opened',
  Submit = 'email signup submit',
}

export enum EmailSignUpLocations {
  CartNewsletterSignUp = 'cart newsletter sign up',
  OrderConfirmation = 'order confirmation',
  SignUpFormHero = 'sign up form hero',
  SignUpFormPDPComingSoon = 'sign up form pdp coming soon',
  SiteFooter = 'site footer',
  StickySignUpButton = 'sticky sign up button',
  MyAccount = 'my account',
}

export type ProductToTransform = {
  product: any;
  currency: string;
  quantity: number;
  categories?: any;
  itemListId?: string;
  itemListName?: string;
  size?: string;
  adjustments?: any[];
  index?: number;
  creativeName?: string;
  creativeSlot?: string;
  promotionId?: string;
  promotionName?: string;
  discovery?: string;
  assortmentAvailabilityItem?: string;
  itemId?: string;
  itemName?: string;
};

// item_product parameters from the docs: https://pumaglobal.atlassian.net/wiki/spaces/CKS/pages/6927713143/GA4+-+Web+-+Ecommerce+events
export interface ItemProductInterface {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon?: string;
  currency: string;
  discount?: number;
  index?: number;
  item_brand: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant: string;
  price?: number;
  quantity: number;
  creative_name: string;
  creative_slot: string;
  promotion_id: string;
  promotion_name: string;
  full_price?: string;
  sale_discount?: number;
  size?: string;
  availability: string;
  assortment_availability?: string;
  discounted?: string;
  orderable?: string;
  item_ean: string;
  color: string;
  style_number: string;
  marked_down?: string;
  discovery?: string;
  sport: string;
  gender: string;
  age_group: string;
  line_name: string;
  collection: string;
  division: string;
  department?: string;
  class?: string;
  color_code?: string;
  business_unit: string;
  business_subsegment: string;
  franchise: string;
}
