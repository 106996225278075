import React, { useEffect, useState } from 'react';
import { Link } from '@global-ecom/nitro-uds/elements';
import { css, tw } from 'twind/css';

import { useWishListTotalsQuery } from '__generated__/graphql';
import { useAuth } from 'hooks/useAuth';
import { useTranslate } from 'hooks/useTranslations';
import { isServer } from 'utils/constants';

import { ItemCountBadge } from './ItemCountBadge';

export const WishlistLink = (props: {
  darkMode: boolean;
  onClick?: () => void;
}) => {
  const t = useTranslate();
  const auth = useAuth();

  const [wishlist] = useWishListTotalsQuery({
    pause: !!auth.guest || isServer,
  });

  const count = wishlist.data?.wishList.items.length || 0;

  const [animate, setAnimate] = useState(false);

  const handleWishlistButton = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener(
      'PRODUCT_WISHLIST_BUTTON_CLICKED',
      handleWishlistButton
    );
    return () => {
      window.removeEventListener(
        'PRODUCT_WISHLIST_BUTTON_CLICKED',
        handleWishlistButton
      );
    };
  }, []);

  const dotPulse = css`
    animation: dotPulse 600ms ease-in-out;
    @keyframes dotPulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.25);
      }
      100% {
        transform: scale(1);
      }
    }
  `;

  return (
    <div className="relative" aria-hidden="true">
      <Link
        href="wishlist"
        id="nav-wishlist-link"
        label={`${t('wishlist')} ${count}`}
        onClick={props.onClick}
        icon="heart"
        mode="icon"
        variant="ghost"
        invert={props.darkMode}
      />
      <ItemCountBadge
        float
        count={count}
        dataTestId="nav-wishlist-tally-badge"
        className={tw(animate && dotPulse)}
      />
    </div>
  );
};
