import React, {
  FunctionComponent,
  ElementType,
  useCallback,
  useRef,
  useEffect,
} from 'react';

interface OwnProps {
  enterCallback: () => unknown;
  leaveCallback: () => unknown;
  enterWait?: number; // time in MS
  leaveWait?: number; // time in MS
  component?: ElementType; // Root element
}

type Props = OwnProps & { [key: string]: any };

export const HoverIntent: FunctionComponent<Props> = ({
  enterWait = 200,
  leaveWait = 200,
  enterCallback,
  leaveCallback,
  component: ComponentProp = 'div',
  children,
  ...otherProps // apply any remaining props to the specified root component
}) => {
  const isEnterEnvoked = useRef(false);
  const enterTimer = useRef<number>();
  const leaveTimer = useRef<number>();

  const cancelEnterTimer = useCallback(() => {
    clearTimeout(enterTimer.current);
  }, [enterTimer]);

  const cancelLeaveTimer = useCallback(() => {
    clearTimeout(leaveTimer.current);
  }, [leaveTimer]);

  const handleMouseEnter = useCallback(() => {
    cancelLeaveTimer();
    enterTimer.current = window.setTimeout(() => {
      enterCallback();
      isEnterEnvoked.current = true;
    }, enterWait);
  }, [cancelLeaveTimer, enterCallback, enterWait, ComponentProp]); // eslint-disable-line react-hooks/exhaustive-deps

  const handelMouseLeave = useCallback(() => {
    cancelEnterTimer();
    leaveTimer.current = window.setTimeout(() => {
      if (isEnterEnvoked.current) {
        leaveCallback();
        isEnterEnvoked.current = false;
      }
    }, leaveWait);
  }, [cancelEnterTimer, leaveCallback, leaveWait, ComponentProp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      cancelEnterTimer();
      cancelLeaveTimer();
    };
  }, [cancelEnterTimer, cancelLeaveTimer]);

  return (
    <ComponentProp
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handelMouseLeave}
      {...otherProps}
    >
      {children}
    </ComponentProp>
  );
};
