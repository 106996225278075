import { useCallback } from 'react';

import { Resources, ResourcesVariables } from 'locales/localeType';
import { useTranslate } from 'hooks/useTranslations';
import { capitalize } from 'utils/capitalize';

export const useTranslationFieldOrDefault = () => {
  const t = useTranslate();
  return useCallback(
    (
      field: string,
      base: keyof Resources,
      variables?: ResourcesVariables[keyof ResourcesVariables]
    ) => {
      const customTranslationKey = `${base}${capitalize(field)}`;
      const customTranslation = t(
        customTranslationKey as keyof Resources,
        variables
      );
      return customTranslation ? customTranslation : t(base);
    },
    [t]
  );
};
