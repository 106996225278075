import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import {
  SanityVideoObject,
  SanityVideoObjectType,
} from 'groq/objects/SanityVideoObject';
import { HorizontalAlignment } from 'groq/global-types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/ProductGroupFeatures.ts

export type ProductFeaturesType = {
  id: string;
  _key: string;
  header?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  horizontalAlignment: HorizontalAlignment;
  image: SanityImageObjectType;
  video?: Maybe<SanityVideoObjectType>;
};

export type ProductGroupFeaturesDocumentType = {
  id: string;
  _id: string;
  _type: string;
  productFeatures: ProductFeaturesType[];
};

export const ProductGroupFeaturesDocument = (field: string) => `
  ${field} {
    "id": _id,
     _id,
    _type,
    productFeatures[] {
      "id": _key,
      _key,
      ${LocaleString('header')},
      ${LocaleString('copy')},
      horizontalAlignment,
      ${SanityImageObject('image')},
      ${SanityVideoObject('video')},
    }
  }
`;
