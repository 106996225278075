import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputPassword } from '@global-ecom/nitro-uds/elements';

import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { useValidation } from 'hooks/useValidation';
import { useTranslate } from 'hooks/useTranslations';
import { CustomInput } from 'ui/forms/custom/types';

const CustomFormPassword = ({ field }: { field: CustomInput }) => {
  const form = useFormContext();
  const { resolveInputError, getErrorMessageOrDefault } = useFormField(field);
  const [validate, dynamicValidation] = useValidation();
  const t = useTranslate();

  const { register } = form;

  return (
    <InputPassword
      label={field.label}
      key={field.key}
      dataTestId={field.dataTestId}
      placeholder={field.placeholder}
      id={field.id}
      name={field.name}
      ref={register({
        validate: async value => {
          let errorMessage: string | undefined;
          if (field.pattern && !new RegExp(field.pattern).test(value)) {
            return field.patternError || t('invalidInput');
          }
          if (field.validations) {
            for (const v of field.validations) {
              if (typeof v === 'string') {
                errorMessage = validate[v](value);
              } else {
                errorMessage = dynamicValidation(value, form, v);
              }
              if (errorMessage) break;
            }
          }
          return errorMessage;
        },
        required: getErrorMessageOrDefault(field.name, 'requiredField'),
        minLength: field.minLength && {
          value: field.minLength,
          message: t<'passwordMinLength'>('passwordMinLength', {
            length: field.minLength,
          }),
        },
        maxLength: field.maxLength && {
          value: field.maxLength,
          message: t<'passwordMaxLength'>('passwordMaxLength', {
            length: field.maxLength,
          }),
        },
      })}
      minLength={field.minLength}
      maxLength={field.maxLength}
      errorText={resolveInputError()}
      required
    />
  );
};

export default React.memo(CustomFormPassword);
