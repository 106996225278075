import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/YouTubeVideoCarousel.ts

export type YouTubeVideoCarouselDocumentType = {
  id: string;
  _id: string;
  _type: string;
  playlistId?: Maybe<string>;
  videoIds?: Maybe<Array<string>>;
  autoplay: boolean;
  showThumbnails: boolean;
};

export const YouTubeVideoCarouselDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    playlistId,
    videoIds,
    autoplay,
    showThumbnails,
  }
`;
