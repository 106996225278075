import React, { useEffect } from 'react';
import { css, tw } from 'twind/style';

import { ComponentWithFilters, Maybe } from '__generated__/graphql';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { getComponentForContent } from 'utils/getComponentForContent';
import { getIsDesktop } from 'utils/media';
import ChevronButton from 'ui/components/ChevronButton';

export const WebCarousel = ({
  content,
}: {
  content: Maybe<Array<Maybe<ComponentWithFilters>>>;
}) => {
  const { ref, hasPrev, hasNext, next, prev } = useHorizontalScroll({
    infinite: false,
  });

  useEffect(() => {
    if (content?.length) {
      ref.current.dispatchEvent(new Event('scroll'));
    }
  }, [content, ref]);

  if (!content?.length) {
    return null;
  }

  const isDesktop = getIsDesktop();

  const gridClasses = tw([
    'grid overflow-x-auto pb-8 mb-4 mobile:pb-4',
    css({
      scrollSnapType: 'x mandatory',
      gridTemplateColumns: `repeat(${content.length}, 100%)`,
    }),
    css`
      [id*='WebCarousel-'] [data-test-id='full-bleed-hero-container'] {
        @apply m-0 left-0 right-0 top-0 w-full h-full flex;
      }
      [id*='WebCarousel-'] {
        @apply flex h-full overflow-y-hidden;
      }
      [id*='WebCarousel-'] [data-link-loc='banner'],
      [id*='WebCarousel-'] > div:first-child,
      [id*='WebCarousel-'][tag='section'] {
        @apply flex-1;
      }
    `,
  ]);

  return (
    <section
      className="group relative w-full"
      data-test-id="web-carousel-wrapper"
    >
      <div ref={ref} data-test-id="web-carousel" className={gridClasses}>
        {content?.map((item, index) => {
          const componentData = {
            component: item,
            targetDevices: ['mobile', 'tablet', 'desktop'],
          } as ComponentWithFilters;

          return (
            <div key={index} className={tw(css({ scrollSnapAlign: 'start' }))}>
              {getComponentForContent(
                componentData,
                index,
                content,
                'WebCarousel',
                {}
              )}
            </div>
          );
        })}
      </div>
      {hasPrev && isDesktop && (
        <ChevronButton
          className="invisible group-hover:visible mobile:hidden tablet:hidden absolute -left-2 top-1/2 transform -translate-y-1/2"
          direction="left"
          onClick={prev}
          invert
        />
      )}
      {hasNext && isDesktop && (
        <ChevronButton
          className="invisible group-hover:visible mobile:hidden tablet:hidden absolute -right-2 top-1/2 transform -translate-y-1/2"
          direction="right"
          onClick={next}
          invert
        />
      )}
    </section>
  );
};
