import {
  AuthPayloadFragment,
  TokenPayloadFragment,
} from '__generated__/graphql';

import { decodeToken } from './token';

export interface AuthState {
  accessToken?: string;
  refreshToken?: string;
  customerContext?: {
    hashKey?: string;
    customerGroups: string[];
  };
  customerId?: string;
  guest: boolean;
  uniqueShopperId?: string | null;
  user?: {
    customerNo?: string | null;
    email?: string | null;
  };
}

export type AuthStatePayload = TokenPayloadFragment | AuthPayloadFragment;

export function authStateFromPayload(payload: AuthStatePayload): AuthState {
  const decodedToken = decodeToken(payload.accessToken);
  const isGuest = decodedToken?.isb?.includes('::upn:Guest::') || false;

  const uniqueShopperId =
    payload.__typename === 'TokenPayload' && payload.uniqueShopperId;

  return {
    accessToken: payload.accessToken,
    guest: isGuest,
    refreshToken: payload.refreshToken,
    customerId: payload.customerId,
    uniqueShopperId: uniqueShopperId || '',
    customerContext: {
      hashKey: payload.customerContext?.hashKey || undefined,
      customerGroups: payload.customerContext?.customerGroups || [],
    },
    user: payload.user || undefined,
  };
}
