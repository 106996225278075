type SafeHtmlProps = {
  as?: string;
  html: string;
  [key: string]: unknown;
};

export const SafeHtml = ({ as = 'div', html, ...props }: SafeHtmlProps) => {
  const Tag = `${as}` as keyof JSX.IntrinsicElements;
  return (
    <>
      <Tag
        dangerouslySetInnerHTML={{
          __html: html,
        }}
        {...props}
      />
    </>
  );
};
