import { HeadersContent } from 'groq/global-types';
import { SlotsGroqQuery } from 'groq/ContentSlots';
import { LocaleSeo, LocaleSeoType } from 'groq/shared/LocaleSeo';
import { QueryByLocaleUrl } from 'groq/shared/LocaleUrl';
import { ComponentWithFilters, Maybe } from '__generated__/graphql-sanity';

export type CategoryPageContent = {
  _id: string;
  _type: 'CategoryPage';
  seo: LocaleSeoType;
  categoryPageSEO: {
    noIndex: Maybe<boolean>;
  };
  title: Maybe<string>;
  content: Maybe<Array<Maybe<ComponentWithFilters>>>;
  online: Maybe<boolean>;
  onlineFrom: Maybe<string>;
  onlineTo: Maybe<string>;
  headersContent?: HeadersContent;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    CategoryPage: CategoryPageContent;
  }
}

export const CategoryPageByUrl = `
  [country == $country]
  [_type == "CategoryPage"]
  [${QueryByLocaleUrl('category->')}]
`;

export const CategoryPageQuery = `
  *${CategoryPageByUrl}[0] {
    _id,
    _type,
    online,
    onlineFrom,
    onlineTo,
    ...(category -> {
      title,
      ${LocaleSeo('seo')},
    }),
    "categoryPageSEO": {"noIndex": seo.noIndex},
    content[] ${SlotsGroqQuery()},
    headersContent,
  }
`;
