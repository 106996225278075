import React from 'react';
import { tw } from 'twind';

import { useResizeObserver } from 'hooks/useResizeObserver';
import { alignmentToFlexDirection } from 'utils/alignmentToFlexDirection';
import { SanityImage } from 'ui/components/SanityImage';

import { Link } from './Link';
import { Text } from './Text';
import { CallToAction } from './CallToAction';

export const Tile = props => {
  const { ref, width, height } = useResizeObserver();

  const orientation =
    Math.abs(width / height - 1) < 0.1
      ? 'square'
      : width > height
      ? 'landscape'
      : 'portrait';

  const imageKey =
    props.image &&
    ((props.image[orientation] && orientation) ||
      Object.keys(props.image).find(k => props.image[k]?.asset));

  const ratio = { portrait: 133, square: 100, landscape: 66 }[
    props.aspectRatio
  ];

  return (
    <div
      ref={ref}
      className={tw(
        'relative flex overflow-hidden',
        `items-${alignmentToFlexDirection(props.alignment?.vertical)}`,
        props.style?.border && 'border',
        props.style?.shadow && 'shadow-lg',
        props.style?.rounded && 'rounded-lg',
        props.style?.background && `bg-[${props.style.background}]`,
        props.aspectRatio &&
          `before:(content-[''] float-left ${
            props.icon
              ? `mobile:pb-0 tablet:pb-[${ratio}%] desktop:pb-[${ratio}%]`
              : `pb-[${ratio}%]`
          }) after:(content-[''] table clear-both)`
      )}
    >
      {imageKey && <SanityImage source={props.image[imageKey]} />}
      {props.link && (
        <Link
          {...props.link}
          className="absolute inset-0"
          aria-label={`${props.link}`}
        />
      )}
      <div
        className={tw(
          'relative w-full space-y-3 p-5',
          `items-${alignmentToFlexDirection(props.alignment?.horizontal)}`,
          props.icon &&
            'flex flex-col justify-between h-full mobile:(px-4 py-6) tablet:p-8 desktop:p-8'
        )}
      >
        <div
          className={tw(
            'relative flex w-full',
            `justify-${alignmentToFlexDirection(props.alignment?.horizontal)}`
          )}
        >
          <Text {...props.text} icon={props.icon} />
        </div>
        {props.ctas && (
          <div
            className={tw(
              'flex flex-wrap flex-gap-3',
              `justify-${alignmentToFlexDirection(props.text?.align)}`
            )}
          >
            {props.ctas.map(cta => (
              <CallToAction
                key={cta.id}
                {...cta}
                onClick={
                  props.handleClickCTA
                    ? props.handleClickCTA?.(cta.title)
                    : undefined
                }
              />
            ))}
          </div>
        )}
        {props.icon?.img && (
          <img
            className="mobile:hidden"
            src={props.icon.img.asset.url}
            alt={props.icon.alt}
          />
        )}
      </div>
    </div>
  );
};
