import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseMutationState } from 'urql';
import { InputText } from '@global-ecom/nitro-uds/elements';

import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { CustomInput } from 'ui/forms/custom/types';
import { CreditCardLogo } from 'ui/forms/PaymentMethods/CreditCard/CreditCardLogo';
import { validateCreditCard } from 'utils/creditCardValidator';
import { formatCreditCard } from 'utils/creditCardFormatter';

import { buildName } from '../helpers';

const CustomFormCardNumber = ({
  field,
  required = false,
  prefix,
  formResult,
}: {
  field: CustomInput;
  required?: boolean;
  prefix?: string;
  formResult: UseMutationState | undefined;
}) => {
  const { watch, register, setValue, trigger } = useFormContext();
  const prevCardNumber = useRef<string>('');

  const cardNumber = watch('cardNumber');
  const { resolveInputError, getErrorMessageOrDefault } = useFormField(field);

  const cardNumberResult = validateCreditCard(cardNumber ?? '');
  useEffect(() => {
    if (
      !cardNumber ||
      prevCardNumber.current === cardNumber ||
      prevCardNumber.current.length > cardNumber.length
    ) {
      prevCardNumber.current = cardNumber;
      return;
    }

    setValue(
      buildName('cardNumber', prefix),
      formatCreditCard(cardNumber, cardNumberResult.card_type)
    );

    prevCardNumber.current = cardNumber;
  }, [cardNumber, cardNumberResult, prefix, setValue]);

  useEffect(() => {
    if (formResult?.error) {
      setValue(buildName('cardNumber', prefix), '');
    }
  }, [formResult?.error, prefix, setValue]);

  const creditCardType =
    typeof cardNumberResult.card_type === 'string'
      ? cardNumberResult.card_type
      : cardNumberResult?.card_type?.name;

  return (
    <InputText
      key={field.name}
      dataTestId={field.dataTestId}
      name={field.name}
      placeholder={field.placeholder || ''}
      errorText={resolveInputError()}
      required={required}
      autoComplete="cc-number"
      autoCorrect="off"
      spellCheck={false}
      pattern="\d*"
      inputMode="numeric"
      maxLength={20}
      type="tel"
      ref={register({
        required:
          required && getErrorMessageOrDefault(field.name, 'requiredField'),
        validate: v => {
          const result = validateCreditCard(v.replace(/ /g, ''));

          if (!result.valid) {
            return getErrorMessageOrDefault(field.name, 'validValue');
          }

          return true;
        },
      })}
      onChange={() => {
        trigger('cvv');
      }}
      label={field.label}
      id={field.name}
      inputSlot={
        creditCardType
          ? () => <CreditCardLogo type={creditCardType} />
          : undefined
      }
    />
  );
};
export default React.memo(CustomFormCardNumber);
