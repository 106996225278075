import { LoadingSpinner } from 'ui/components/LoadingSpinner';

export type StyliticsLoaderProps = {
  children: React.ReactNode;
  loading: boolean;
};
const StyliticsLoader = (props: StyliticsLoaderProps) => (
  <>
    {props.loading ? (
      <div className="col-span-10 h-full flex py-48">
        <LoadingSpinner
          dataTestId="stylitics-loader"
          className="w-32 h-32 m-auto"
        />
      </div>
    ) : (
      props.children
    )}
  </>
);

export default StyliticsLoader;
