import React, { useRef } from 'react';
import { tw } from 'twind/style';
import { useRouter } from 'next/router';

import { QuoteCallout as QuoteCalloutType } from '__generated__/graphql';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { CallToActions } from 'ui/content/CallToActions';
import { Markdown } from 'ui/elements/Markdown';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';

import { ContentHeading, ManageHeadingTag } from './ContentHeading';

export const QuoteCallout: React.FC<
  QuoteCalloutType & { headingTag?: ManageHeadingTag }
> = props => {
  const { asPath } = useRouter();
  const promotion_id = props.id || '';
  const promotion_name = props.header || '';
  const creative_name = props.__typename || 'QuoteCallout';

  const quoteCalloutRef = useRef(null);
  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    quoteCalloutRef,
    gaTrackData,
    true
  );

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    cta_click: undefined,
    fireEventFromPdp: asPath.includes('/pd/'),
  };

  return (
    <div
      className="text-puma-black relative pt-3 pb-6 sm:pt-6 sm:pb-8"
      data-test-id="quote-callout"
      ref={quoteCalloutRef}
    >
      <div className="flex flex-col items-center text-center space-y-4">
        {props.header && (
          <ContentHeading
            header={props.header}
            className={tw([
              'text-3xl md:text-4xl lg:text-5xl font-bold font-display leading-tight',
              props.headerColor
                ? `text-[${props.headerColor}]`
                : 'text-puma-black',
            ])}
            headingTag={props.headingTag}
            dataTestId="quote-callout-header"
          />
        )}
        <div className={tw(['lg:flex justify-center items-center'])}>
          {props.quote && (
            <div
              className="lg:flex flex-1 justify-center order-2"
              data-test-id="quote-callout-quote"
            >
              <q className="text-lg sm:text-2xl lg:text-4xl font-display max-w-5xl text-puma-black-300 m-0 lg:mx-8 xl:mx-14 pb-6 uppercase">
                <Markdown content={props.quote} />
              </q>
            </div>
          )}
          {Boolean(props.copy || props.ctas?.length) && (
            <div
              className={tw([
                'lg:flex flex-1 flex-wrap justify-center order-1 items-center flex-col max-w-5xl lg:px-10 xl:px-16',
                props.quote && 'lg:border-r-1',
              ])}
            >
              {props.copy && (
                <p
                  className={tw([
                    'md:text-lg font-display text-center',
                    props.copyColor
                      ? `text-[${props.copyColor}]`
                      : 'text-puma-black-300',
                  ])}
                  data-test-id="quote-callout-copy"
                >
                  <Markdown content={props.copy} />
                </p>
              )}
              {Boolean(props.ctas?.length) && (
                <div
                  className="flex flex-wrap justify-center flex-gap-4 pb-6 xs:pb-8 pt-4 py-4"
                  data-test-id="quote-callout-ctas"
                >
                  <CallToActions ctas={props.ctas} ga4Data={ga4Data} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
