// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/seo.ts

import { Seo } from '__generated__/graphql-sanity';
import { SanityImageObject } from 'groq/objects/SanityImageObject';
import { SanityVideoObject } from 'groq/objects/SanityVideoObject';

import { LocaleText } from './LocaleText';
import { LocaleString } from './LocaleString';

export type LocaleSeoType = {
  pageDescription: string;
  pageTitle: string;
  noIndex?: boolean;
  pageTitles?: string;
  pageDescriptions?: string;
  openGraphDescription?: string;
  openGraphImageUrl?: string;
  openGraphTitle?: string;
  openGraphType?: string;
  openGraphVideoUrl?: string;
  twitterDescription?: string;
  twitterImageUrl?: string;
  twitterTitle?: string;
};

export const LocaleSeo = (field: string) => `
  ${field} {
    ${LocaleText('pageDescriptions')},
    ${LocaleString('pageTitles')},
    ${LocaleText('openGraphDescription')},
    ${LocaleString('openGraphTitle')},
    ${LocaleText('twitterDescription')},
    ${LocaleString('twitterTitle')},
    ${SanityImageObject('twitterImageUrl')},
    ${SanityVideoObject('openGraphVideoUrl')},
    ${SanityImageObject('openGraphImageUrl')},
    openGraphType,
    noIndex,
    'pageDescription': coalesce(
      pageDescriptions[$locale],
      pageDescriptions[$language],
      pageDescription,
      ''
    ),
    'pageTitle': coalesce(
      pageTitles[$locale],
      pageTitles[$language],
      pageTitle,
      ''
    ),
  }
`;

export const LocaleSeoJSON = (args?: Partial<Seo>): Seo => ({
  pageDescription: '',
  pageTitle: '',
  ...args,
});
