import {
  CloudinaryImageOptimizationObject,
  CloudinaryOptimizationObjectType,
  CloudinaryPlatform,
} from 'groq/objects/CloudinaryOptimizationObject';
import { Maybe } from 'utils/types';

type ImageAssetMetaDataDimensions = {
  height: number;
  width: number;
};

type ImageAssetMetaData = {
  dimensions: ImageAssetMetaDataDimensions;
  lqip?: Maybe<string>;
};

export type ImageAssetDocumentType = {
  _id: string;
  extension: string;
  metadata: ImageAssetMetaData;
  path: string;
  url: string;
  cloudinary?: Maybe<CloudinaryOptimizationObjectType>;
};

export const ImageAssetDocument = (field, platform: CloudinaryPlatform) => `
  ${field} {
    _id,
    url,
    path,
    metadata {
      lqip,
      dimensions {
        width,
        height,
      }
    },
    extension,
    ${CloudinaryImageOptimizationObject('cloudinary', platform)}
  }
`;
