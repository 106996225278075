import { useCallback } from 'react';

import { SUPPORTED_LANGUAGE } from 'utils/constants';

import { Maybe } from '../__generated__/graphql';

import { useSiteConfig } from './useSiteConfig';

const useSerializeLocaleString = (): ((
  str: Maybe<string> | void | Partial<{ [key in SUPPORTED_LANGUAGE]: string }>
) => string) => {
  const { language } = useSiteConfig();
  return useCallback(
    str => {
      if (language && str && str[language]) {
        return typeof str[language] === 'string' ? str[language] : '';
      }
      return typeof str === 'string' ? str : '';
    },
    [language]
  );
};

export default useSerializeLocaleString;
