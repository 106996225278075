import { css } from 'twind/css';

export const shopTheLookBadge = css`
  & .stylitics-bundle-badge {
    top: 3%;
    z-index: 1;
  }

  & .stylitics-bundle-badge .stylitics-shop-the-look-badge {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 16px;

    background: #a1a8af;
    border-radius: 2px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &
    .stylitics-bundle-badge
    .stylitics-shop-the-look-badge
    .stylitics-shop-the-look-label {
    font-family: 'FFDINforPuma';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #ffffff;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

export const widgetOverrides = css`
  .stylitics-classic-base {
    font-family: 'FFDINforPuma' !important;
  }
  .stylitics-item-container {
    padding: 10px 0 !important;
    border-bottom: 1px solid #dfe0e1 !important;
  }
  .stylitics-item-name {
    font-weight: 700 !important;
    font-size: 16px;
  }
  .stylitics-item-price-and-cta {
    flex-direction: row !important;
  }
  .stylitics-item-regular-price {
    font-weight: 400 !important;
    color: gray-100 !important;
  }
  .stylitics-view-item-details-cta span {
    font-size: 14px !important;
  }
  .stylitics-view-item-details-cta span,
  .stylitics-cta-link {
    width: 81px;
    height: 16px;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.04rem !important;
  }
  .stylitics-view-item-details-cta,
  .stylitics-cta-link {
    padding-bottom: 4px !important;
    border-bottom: #a1a8af solid 2px !important;
    text-transform: uppercase;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    letter-spacing: 0.04rem !important;
  }
  .stylitics-arrow-btn.stylitics-previous {
    @media (hover: hover) {
      left: 16px !important;
    }

    @media (hover: none) {
      left: 45px !important;
      opacity: 1 !important;
    }
  }
  .stylitics-arrow-btn.stylitics-next {
    @media (hover: hover) {
      right: 32px !important;
    }

    @media (hover: none) {
      right: 45px !important;
      opacity: 1 !important;
    }
  }
  .stylitics-arrow-btn {
    background-color: #191919 !important;
    z-index: 1000 !important;
    position: absolute;
  }
  .stylitics-arrow {
    path {
      stroke: #ffffff !important;
    }​
  }
  .stylitics-bundle-container{
    border: 1px solid #DFE0E1 !important;
  }
  .stylitics-view-item-details-cta:hover,
  .stylitics-cta-link:hover {
    opacity: 1 !important;
    color: #000000 !important;
    border-bottom: #000000 solid 2px !important;
  }

  .stylitics-sequence.stylitics-sequence-scroll-snap {
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }
`;
