import { DateTime } from 'groq-js';

import {
  PromoTileDocument,
  PromoTileDocumentType,
} from 'groq/documents/PromoTileDocument';
import { Maybe } from 'utils/types';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/tileWithPosition.ts

export type TileWithPositionDocumentType = {
  _key: string;
  _type: Maybe<string>;
  position: number;
  availableFrom: Maybe<DateTime>;
  availableTo: Maybe<DateTime>;
  tile: PromoTileDocumentType;
};

export const TileWithPositionObject = (field: string) => `
  ${field} {
    _key,
    _type,
    position,
    availableFrom,
    availableTo,
    ${PromoTileDocument('tile->')}
  }
`;
