import { gql } from 'urql';

import { WISHLIST_FRAGMENT } from 'gql/fragments/wishList';

export const WISHLIST_QUERY = gql`
  query WishList {
    wishList {
      ...wishListFields
    }
  }

  ${WISHLIST_FRAGMENT}
`;
