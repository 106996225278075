import { SlotsGroqQuery } from 'groq/ContentSlots';
import {
  ProductCarouselDocument,
  ProductRecommenderDocument,
} from 'groq/documents';
import { ProductCarouselDocumentType } from 'groq/documents/ProductCarouselDocument';
import { ProductRecommenderDocumentType } from 'groq/documents/ProductRecommenderDocument';
import { LocalePortableText } from 'groq/shared/LocalePortableText';
import { ComponentWithFilters } from '__generated__/graphql';

export type ProductDetailsPageTemplateContent = {
  _id: string;
  _type: string;
  aboveProductStory: ComponentWithFilters[];
  belowProductStory: ComponentWithFilters[];
  belowProductGoogleShopping: ComponentWithFilters[];
  soldOutRecommender: (
    | ProductRecommenderDocumentType
    | ProductCarouselDocumentType
  )[];
  pdpCallout: any;
  stickyAddToCart: string;
};

export type AboveProductStory_PDPTemplateContent = Pick<
  ProductDetailsPageTemplateContent,
  '_id' | '_type' | 'aboveProductStory'
>;

export type BelowProductStory_PDPTemplateContent = Pick<
  ProductDetailsPageTemplateContent,
  '_id' | '_type' | 'belowProductStory'
>;

export type BelowProductGoogleShopping_PDPTemplateContent = Pick<
  ProductDetailsPageTemplateContent,
  '_id' | '_type' | 'belowProductGoogleShopping'
>;

export type StickyAddToCart_PDPTemplateContent = Pick<
  ProductDetailsPageTemplateContent,
  '_id' | '_type' | 'stickyAddToCart'
>;

export type ExtendedData_PDPTemplateContent = Pick<
  ProductDetailsPageTemplateContent,
  '_id' | '_type' | 'soldOutRecommender' | 'pdpCallout'
>;

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    ProductDetailsPageTemplate: ProductDetailsPageTemplateContent;
    AboveProductStory_PDPTemplate: AboveProductStory_PDPTemplateContent;
    BelowProductStory_PDPTemplate: BelowProductStory_PDPTemplateContent;
    BelowProductGoogleShopping_PDPTemplate: BelowProductGoogleShopping_PDPTemplateContent;
    ExtendedData_PDPTemplate: ExtendedData_PDPTemplateContent;
    StickyAddToCart_PDPTemplate: StickyAddToCart_PDPTemplateContent;
  }
}

export const AboveProductStory_PDPTemplateQuery = `
  *[_id == lower($country) + "-ProductDetailsPageTemplate"][0] {
    _id,
    _type,
    aboveProductStory[] ${SlotsGroqQuery()},
  }
`;

export const BelowProductStory_PDPTemplateQuery = `
  *[_id == lower($country) + "-ProductDetailsPageTemplate"][0] {
    _id,
    _type,
    belowProductStory[] ${SlotsGroqQuery()},
  }
`;

export const BelowProductGoogleShopping_PDPTemplateQuery = `
  *[_id == lower($country) + "-ProductDetailsPageTemplate"][0] {
    _id,
    _type,
    belowProductGoogleShopping[] ${SlotsGroqQuery()},
  }
`;

export const StickyAddToCart_PDPTemplateQuery = `
  *[_id == lower($country) + "-ProductDetailsPageTemplate"][0] {
    _id,
    _type,
    stickyAddToCart,
  }
`;

export const ExtendedData_PDPTemplateQuery = `
  *[_id == lower($country) + "-ProductDetailsPageTemplate"][0] {
    _id,
    _type,
    soldOutRecommender[] -> {
      ${ProductRecommenderDocument('_type == "ProductRecommender" =>')},
      ${ProductCarouselDocument('_type == "ProductCarousel" =>')},
    },
    ${LocalePortableText('pdpCallout')},
  }
`;
