import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { useResizeObserver } from './useResizeObserver';

export const useHorizontalScroll = ({
  infinite = true,
}: {
  infinite?: boolean;
} = {}): {
  next: () => void;
  prev: () => void;
  scrollToSelected: (v: number) => void;
  hasPrev: boolean;
  hasNext: boolean;
  ref: MutableRefObject<any>;
  overflowing: boolean;
} => {
  const { ref, width } = useResizeObserver();
  const [state, setState] = useState({
    hasPrev: !!infinite,
    hasNext: !!infinite,
    overflowing: false,
  });
  useEffect(() => {
    const scroller = ref.current;
    const update = () =>
      setState({
        hasPrev: infinite || scroller.scrollLeft != 0,
        hasNext:
          infinite ||
          Math.ceil(scroller.scrollLeft) + scroller.offsetWidth + 1 <
            scroller.scrollWidth,
        overflowing: scroller.scrollWidth > scroller.clientWidth,
      });
    if (ref.current) {
      update();
      scroller.addEventListener('scroll', update);
    }
  }, [ref, width, infinite]);

  const next = () => {
    const scroller = ref.current;
    const scrollByAmount = scroller.offsetWidth;
    if (
      Math.ceil(scroller.scrollLeft) + scrollByAmount + 1 <
      scroller.scrollWidth
    ) {
      scroller.scrollBy({
        left: scroller.offsetWidth,
        top: 0,
        behavior: 'smooth',
      });
    } else if (infinite)
      scroller.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const prev = () => {
    const scroller = ref.current;
    const scrollByAmount = scroller.offsetWidth;
    if (scroller.scrollLeft != 0) {
      scroller.scrollBy({
        left: -scrollByAmount,
        top: 0,
        behavior: 'smooth',
      });
    } else if (infinite)
      scroller.scrollTo({
        left: scroller.scrollWidth,
        top: 0,
        behavior: 'smooth',
      });
  };
  const scrollToSelected = useCallback(
    (whereToScroll: number) => {
      const scroller = ref.current;
      const scrollByAmount = scroller?.offsetWidth;
      scroller?.scrollTo({
        left: scrollByAmount * whereToScroll,
        top: 0,
        behavior: 'auto',
      });
    },
    [ref]
  );
  return { ref, next, prev, scrollToSelected, ...state };
};
