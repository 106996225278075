import { BreadcrumbProps } from '@global-ecom/nitro-uds/elements';

import { CategoryByIdQuery } from '__generated__/graphql';

export const constructCategoryPath = (
  category?: CategoryByIdQuery['categoryById'] | null,
  path: BreadcrumbProps['path'] = []
): BreadcrumbProps['path'] => {
  if (category) {
    if (category.parentCategory) {
      constructCategoryPath(category.parentCategory, path);
    }

    path.push({
      label: category.name,
      url: category.url,
    });
  }

  return path;
};
