import { Maybe } from 'utils/types';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/FeaturesGrid.ts

export type FeatureRowType = {
  _id: string;
  _type?: string;
  primaryImage?: Maybe<SanityImageObjectType>;
  mobilePrimaryImage?: Maybe<SanityImageObjectType>;
  header?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  secondaryImage?: Maybe<SanityImageObjectType>;
  layout?: string;
};

export type FeaturesGridDocumentType = {
  _id: string;
  _type: string;
  featureRows: Array<FeatureRowType>;
};

export const FeaturesGridRowDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    ${SanityImageObject('primaryImage', 'desktop')},
    ${SanityImageObject('mobilePrimaryImage', 'mobile')},
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${CallToActionObject('ctas[]')},
    ${SanityImageObject('secondaryImage')},
    layout,
  }
`;

export const FeaturesGridDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    ${CallToActionObject('link')},
    featureRows[]{
      _key,
      ${FeaturesGridRowDocument('...@->')}
    }
  }
`;
