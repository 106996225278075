import { useSiteConfig } from 'hooks/useSiteConfig';
import { SUPPORTED_COUNTRY } from 'utils/constants';
import { getIntlNumberFormatter } from 'utils/getIntlNumberFormatter';

type FormattedPriceOptions = {
  showSign?: boolean;
};

export const usePriceFormatter = (
  options?: FormattedPriceOptions
): ((amount: number) => string) => {
  const { locale, currency, countryCode } = useSiteConfig();

  if (countryCode.toUpperCase() === SUPPORTED_COUNTRY.CA) {
    return (amount = 0) =>
      Math.sign(amount) === -1
        ? `${currency.symbol.split(' ').join(' -')}${Math.abs(amount).toFixed(
            currency.decimalsToDisplay
          )}`
        : `${currency.symbol}${amount.toFixed(currency.decimalsToDisplay)}`;
  }
  const signDisplay: 'always' | 'auto' = options?.showSign ? 'always' : 'auto';

  return (amount = 0) => {
    const formatterOptions = {
      style: 'currency',
      currency: currency.code,
      currencyDisplay: 'narrowSymbol',
      signDisplay,
      // Display the configured number of decimals, unless `amount` requires them
      minimumFractionDigits: Number.isInteger(amount)
        ? currency.decimalsToDisplay
        : 2,
    };
    const formatter = getIntlNumberFormatter(locale, formatterOptions);
    return formatter.format(amount);
  };
};
