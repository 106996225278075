import { createContext, useContext, useState } from 'react';
import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

import { isTouchScreenDevice } from 'utils/isTouchScreenDevice';

type DeviceInteractionContextValue = {
  isKeyboardActive: any;
};

const DeviceInteractionContext = createContext<DeviceInteractionContextValue>(
  {} as DeviceInteractionContextValue
);

type DeviceInteractionProviderProps = {
  children: React.ReactNode;
};

export const DeviceInteractionProvider = ({
  children,
}: DeviceInteractionProviderProps) => {
  const [keyboardActive, setKeyboardActive] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const onNonKeyboardInteraction = (event: Event) => {
      if (event.defaultPrevented || !keyboardActive) return;
      setKeyboardActive(false);
    };

    const onKeyboardInteraction = () => {
      if (isTouchScreenDevice() || keyboardActive) return;
      setKeyboardActive(true);
    };

    document.addEventListener('keydown', onKeyboardInteraction, true);
    document.addEventListener('mousedown', onNonKeyboardInteraction);
    document.addEventListener('mouseup', onNonKeyboardInteraction);
    document.addEventListener('pointerdown', onNonKeyboardInteraction);
    document.addEventListener('pointerup', onNonKeyboardInteraction);
    document.addEventListener('touchstart', onNonKeyboardInteraction);
    document.addEventListener('touchend', onNonKeyboardInteraction);

    return () => {
      document.removeEventListener('keydown', onKeyboardInteraction, true);
      document.removeEventListener('mousedown', onNonKeyboardInteraction);
      document.removeEventListener('mouseup', onNonKeyboardInteraction);
      document.removeEventListener('pointerdown', onNonKeyboardInteraction);
      document.removeEventListener('pointerup', onNonKeyboardInteraction);
      document.removeEventListener('touchstart', onNonKeyboardInteraction);
      document.removeEventListener('touchend', onNonKeyboardInteraction);
    };
  }, [keyboardActive, setKeyboardActive]);

  return (
    <DeviceInteractionContext.Provider
      value={{
        isKeyboardActive: keyboardActive,
      }}
    >
      {children}
    </DeviceInteractionContext.Provider>
  );
};

export const useDeviceInteraction = () => useContext(DeviceInteractionContext);
