import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { CartItem, WishListItem } from '__generated__/graphql';

type MiniCartItem = WishListItem | CartItem;

type MiniCartProducts = MiniCartItem[];

type OpenMiniCartProductsType = MiniCartItem | MiniCartProducts;

type MiniCartContextType = {
  products?: MiniCartProducts;
  openMiniCart: (
    products: OpenMiniCartProductsType,
    tryingToOpenMiniCartFromGWP?: boolean
  ) => void;
  closeMiniCart: () => void;
  isMiniCartVisible: boolean;
  mixedCartNotAllowed: boolean;
  tryingToOpenMiniCartFromGWP: boolean;
  setMixedCartNotAllowed: (condition: boolean) => void;
};

export const MiniCartContext = createContext<MiniCartContextType>({
  products: undefined,
  openMiniCart: () => null,
  closeMiniCart: () => null,
  isMiniCartVisible: false,
  mixedCartNotAllowed: false,
  tryingToOpenMiniCartFromGWP: false,
  setMixedCartNotAllowed: () => null,
});

export const MiniCartProvider = props => {
  const [products, setProducts] = useState<MiniCartProducts>();
  const [isMiniCartVisible, setIsMiniCartVisible] = useState(false);
  const [mixedCartNotAllowed, setMixedCartNotAllowed] = useState(false);
  const [tryingToOpenMiniCartFromGWP, setTryingToOpenMiniCartFromGWP] =
    useState(false);

  const openMiniCart = useCallback(
    (
      products: OpenMiniCartProductsType,
      _tryingToOpenMiniCartFromGWP?: boolean
    ) => {
      const productsArray = Array.isArray(products) ? products : [products];
      setTryingToOpenMiniCartFromGWP(!!_tryingToOpenMiniCartFromGWP);

      if (productsArray.length) {
        setProducts(productsArray);
        setIsMiniCartVisible(true);
      }
    },
    []
  );

  const closeMiniCart = useCallback(() => {
    setProducts(undefined);
    setIsMiniCartVisible(false);
  }, []);

  const providerValue = useMemo(() => {
    return {
      products,
      openMiniCart,
      closeMiniCart,
      isMiniCartVisible,
      mixedCartNotAllowed,
      setMixedCartNotAllowed,
      tryingToOpenMiniCartFromGWP,
    };
  }, [
    products,
    openMiniCart,
    closeMiniCart,
    isMiniCartVisible,
    mixedCartNotAllowed,
    setMixedCartNotAllowed,
    tryingToOpenMiniCartFromGWP,
  ]);

  return (
    <MiniCartContext.Provider value={providerValue}>
      {props.children}
    </MiniCartContext.Provider>
  );
};

export const useMiniCart = (): MiniCartContextType =>
  useContext(MiniCartContext);
