import { AnalyticsEvents, event } from './analytics';
import {
  VIDEO_GA4_EVENT_PERCENTAGE_STRING,
  VIDEO_GA4_EVENT_PERCENTAGE_TYPE,
} from './constants';

export type GA4VideoDataType = {
  video_current_time: string;
  video_duration: string;
  video_percent: number;
  video_provider: 'youtube' | 'sanity';
  video_title?: string;
  video_url: string;
  visible: `${boolean}`;
};

export const firePreviousAndCurrentGA4VideoEvents = ({
  percentage,
  eventHasFired,
  setEventFired,
  getVideoData,
  onVideoStartCallback, // NOTE: this is only for testing purposes, to let storybook know when the video starts and use timeouts to check the fired events
}: {
  percentage: VIDEO_GA4_EVENT_PERCENTAGE_TYPE;
  eventHasFired: (event: string) => boolean;
  setEventFired: (event: string) => void;
  getVideoData: () => Promise<GA4VideoDataType>;
  onVideoStartCallback?: () => void;
}) => {
  const params = { setEventFired, eventHasFired };

  const fireEvents = async (percentages: VIDEO_GA4_EVENT_PERCENTAGE_TYPE[]) => {
    const _videoData = await getVideoData();

    percentages.forEach(async _percentage => {
      if (_percentage === '0') onVideoStartCallback?.();

      checkAndFireGA4VideoEvent({
        percentage: _percentage,
        videoData: { ..._videoData, video_percent: parseInt(_percentage, 10) },
        ...params,
      });
    });
  };

  switch (percentage) {
    case '0':
      fireEvents(['0']);
      break;
    case '10':
      fireEvents(['0', '10']);
      break;
    case '25':
      fireEvents(['0', '10', '25']);
      break;
    case '50':
      fireEvents(['0', '10', '25', '50']);
      break;
    case '75':
      fireEvents(['0', '10', '25', '50', '75']);
      break;
    case '100':
      fireEvents(['0', '10', '25', '50', '75', '100']);
      break;
  }
};

export const checkAndFireGA4VideoEvent = ({
  eventHasFired,
  setEventFired,
  percentage,
  videoData,
}: {
  percentage: VIDEO_GA4_EVENT_PERCENTAGE_TYPE;
  setEventFired: (event: string) => void;
  eventHasFired: (event: string) => boolean;
  videoData: GA4VideoDataType;
}) => {
  const eventName = `${VIDEO_GA4_EVENT_PERCENTAGE_STRING[percentage]}-${videoData.video_provider}`;

  const getEventName = (percentage: VIDEO_GA4_EVENT_PERCENTAGE_TYPE) => {
    switch (percentage) {
      case '0':
        return AnalyticsEvents.VIDEO_START;
      case '100':
        return AnalyticsEvents.VIDEO_COMPLETE;
      default:
        return AnalyticsEvents.VIDEO_PROGRESS;
    }
  };

  if (!eventHasFired(eventName)) {
    event(AnalyticsEvents.GA4_CustomEvent, {
      event_name: getEventName(percentage),
      event_params: videoData,
    });
    setEventFired(eventName);
  }
};

// ─── for storybook ──────────────────────────────────────────────────────────────────────────

const GA4_VIDEO_EVENT_NAMES = [
  AnalyticsEvents.VIDEO_START,
  AnalyticsEvents.VIDEO_PROGRESS,
  AnalyticsEvents.VIDEO_COMPLETE,
] as const;

type GA4_VIDEO_EVENT_NAMES_TYPE = (typeof GA4_VIDEO_EVENT_NAMES)[number];

export type GA4Event_Type = {
  event: string;
  event_name: GA4_VIDEO_EVENT_NAMES_TYPE;
  event_params: Partial<GA4VideoDataType>;
};

export const getLastVideoGA4Event = (): GA4Event_Type => {
  const events = window.dataLayer.filter(e =>
    GA4_VIDEO_EVENT_NAMES.includes(e.event_name as GA4_VIDEO_EVENT_NAMES_TYPE)
  );

  const event = events[events.length - 1] as unknown as GA4Event_Type;

  const {
    video_current_time: _, // we don't care about this value to compare
    video_duration: __, // we don't care about this value to compare
    ...event_params // we only care about this values to compare
  } = event?.event_params;

  return { ...event, event_params };
};

export const customExpectedVideoGA4Event = ({
  event_name,
  video_percent,
  eventData,
}: {
  event_name: GA4_VIDEO_EVENT_NAMES_TYPE;
  video_percent: VIDEO_GA4_EVENT_PERCENTAGE_TYPE;
  eventData: GA4Event_Type;
}): GA4Event_Type => ({
  ...eventData,
  event_name,
  event_params: {
    ...eventData.event_params,
    video_percent: parseInt(video_percent, 10),
  },
});
