export type TCardType =
  | {
      name: string;
      range: string;
      valid_length: Array<number>;
      format: Array<number> | { [index: number]: Array<number> };
    }
  | string;

const card_types: TCardType[] = [
  {
    name: 'amex',
    range: '34,37',
    valid_length: [15],
    format: [4, 6, 5],
  },
  {
    name: 'diners_club_carte_blanche',
    range: '300-305',
    valid_length: [14],
    format: [4, 6, 4],
  },
  {
    name: 'diners_club_international',
    range: '36',
    valid_length: [14],
    format: [4, 6, 4],
  },
  {
    name: 'jcb',
    range: '3528-3589',
    valid_length: [16],
    format: [4, 4, 4, 4],
  },
  {
    name: 'laser',
    range: '6304, 6706, 6709, 6771',
    valid_length: [16, 17, 18, 19],
    format: {
      16: [4, 4, 4, 4],
      17: [4, 4, 4, 5], // TBC
      18: [4, 4, 4, 4, 2], // TBC
      19: [4, 4, 4, 4, 3], // TBC
    },
  },
  {
    name: 'visa_electron',
    range: '4026, 417500, 4508, 4844, 4913, 4917',
    valid_length: [16],
    format: [4, 4, 4, 4],
  },
  {
    name: 'visa',
    range: '4',
    valid_length: [13, 14, 15, 16, 17, 18, 19],
    format: {
      13: [4, 4, 5], // TBC
      14: [4, 4, 4, 2], // TBC
      15: [4, 4, 4, 3], // TBC
      16: [4, 4, 4, 4],
      17: [4, 4, 4, 5], // TBC
      18: [4, 4, 4, 4, 2], // TBC
      19: [4, 4, 4, 4, 3], // TBC
    },
  },
  {
    name: 'mastercard',
    range: '51-55, 232100-272099, 22',
    valid_length: [16],
    format: [4, 4, 4, 4],
  },
  {
    name: 'discover',
    range: '6011, 622126-622925, 644-649, 65',
    valid_length: [16],
    format: [4, 4, 4, 4],
  },
  {
    name: 'dankort',
    range: '5019',
    valid_length: [16],
    format: [4, 4, 4, 4],
  },
  {
    name: 'maestro',
    range:
      '50, 56-59, 61-63, 66-69, 600-606, 609, 640-643, 7744, 700600, 706980, 707145, 708252, 709900, 724365, 800003, 822951, 849686, 927001, 990015',
    valid_length: [12, 13, 14, 15, 16, 17, 18, 19],
    format: {
      12: [4, 4, 4], // TBC
      13: [4, 4, 5],
      14: [4, 6, 4], // TBC
      15: [4, 6, 5],
      16: [4, 4, 4, 4],
      17: [4, 4, 4, 5], // TBC
      18: [4, 4, 4, 6], // TBC
      19: [4, 4, 4, 4, 3],
    },
  },
  {
    name: 'uatp',
    range: '1',
    valid_length: [15],
    format: [4, 5, 6],
  },
];

class Trie {
  trie: any;

  constructor() {
    this.trie = {};
  }

  push(value) {
    let char, i, j, len, obj;
    value = value.toString();
    obj = this.trie;
    const ref = value.split('');
    const results = [];
    for (i = j = 0, len = ref.length; j < len; i = ++j) {
      char = ref[i];
      if (obj[char] == null) {
        if (i === value.length - 1) {
          obj[char] = null;
        } else {
          obj[char] = {};
        }
      }
      // @ts-expect-error
      results.push((obj = obj[char]));
    }
    return results;
  }

  find(value) {
    let char, i, j, len, obj;
    value = value.toString();
    obj = this.trie;
    const ref = value.split('');
    for (i = j = 0, len = ref.length; j < len; i = ++j) {
      char = ref[i];
      if (obj.hasOwnProperty(char)) {
        if (obj[char] === null) {
          return true;
        }
      } else {
        return false;
      }
      obj = obj[char];
    }
  }
}

class Range {
  trie: Trie;

  constructor(trie: Trie) {
    this.trie = trie;
  }

  rangeWithString(ranges) {
    let j, k, len, n, r, range, ref, ref1;
    if (typeof ranges !== 'string') {
      throw Error('rangeWithString requires a string parameter');
    }
    ranges = ranges.replace(/ /g, '');
    ranges = ranges.split(',');
    const trie = new Trie();
    for (j = 0, len = ranges.length; j < len; j++) {
      range = ranges[j];
      if ((r = range.match(/^(\d+)-(\d+)$/))) {
        for (
          n = k = ref = r[1], ref1 = r[2];
          ref <= ref1 ? k <= ref1 : k >= ref1;
          n = ref <= ref1 ? ++k : --k
        ) {
          trie.push(n);
        }
      } else if (range.match(/^\d+$/)) {
        trie.push(range);
      } else {
        throw Error("Invalid range '" + r + "'");
      }
    }
    return new Range(trie);
  }

  match(number) {
    return this.trie.find(number);
  }
}

export function validateCreditCard(v: string): {
  valid?: boolean;
  card_type: TCardType;
  minCVV: number;
} {
  const indexOf = [].indexOf;

  let card, card_type: TCardType;

  const range = new Range(new Trie());

  const ref = card_types.map(x => (typeof x !== 'string' ? x.name : x));

  for (let j = 0, len = ref.length; j < len; j++) {
    card_type = ref[j];
    const t: string[] = (function () {
      let k, len1;
      const results: string[] = [];
      for (k = 0, len1 = card_types.length; k < len1; k++) {
        card = card_types[k];
        results.push(card.name);
      }
      return results;
    })();
    if (t.indexOf(card_type) < 0) {
      return { card_type: 'INVALID_CARD', valid: false, minCVV: 0 };
    }
  }

  const get_card_type = function (number) {
    let k, len1, r;
    const ref1 = (function () {
      let l, len1, ref1;
      const results: any[] = [];
      for (l = 0, len1 = card_types.length; l < len1; l++) {
        card = card_types[l];

        // @ts-expect-error
        if (((ref1 = card.name), indexOf.call(ref, ref1) >= 0)) {
          results.push(card);
        }
      }
      return results;
    })();
    for (k = 0, len1 = ref1.length; k < len1; k++) {
      card_type = ref1[k];
      //@ts-expect-error
      r = range.rangeWithString(card_type.range);
      if (r.match(number)) {
        return card_type;
      }
    }
    return null;
  };

  const is_valid_luhn = function (number) {
    let digit, k, len1, n, sum;
    sum = 0;
    const ref1 = number.split('').reverse();
    for (n = k = 0, len1 = ref1.length; k < len1; n = ++k) {
      digit = ref1[n];
      digit = +digit;
      if (n % 2) {
        digit *= 2;
        if (digit < 10) {
          sum += digit;
        } else {
          sum += digit - 9;
        }
      } else {
        sum += digit;
      }
    }
    return sum % 10 === 0;
  };
  const normalize = function (number) {
    return number.replace(/[ -]/g, '');
  };
  const is_valid_length = function (number, card_type) {
    let ref1;
    return (
      // @ts-expect-error
      (ref1 = number.length), indexOf.call(card_type.valid_length, ref1) >= 0
    );
  };

  const validate_number = function (number) {
    let length_valid, luhn_valid;
    //@ts-expect-error
    card_type = get_card_type(number);
    luhn_valid = false;
    length_valid = false;
    if (card_type != null) {
      luhn_valid = is_valid_luhn(number);
      length_valid = is_valid_length(number, card_type);
    }
    return {
      card_type: card_type || '',
      minCVV:
        card_type &&
        (typeof card_type === 'string' ? card_type : card_type.name) === 'amex'
          ? 4
          : 3,
      valid: luhn_valid && length_valid,
      luhn_valid: luhn_valid,
      length_valid: length_valid,
    };
  };

  return validate_number(normalize(v));
}
